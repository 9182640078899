<header *ngIf="false">
  <div class="headerText">
    <div
      class="expertiseHeading"
      (click)="courseExpertiseSelected('101')"
      [ngStyle]="
        selectCourseExpertise == '101'
          ? { 'background-color': '#318cf1' }
          : { 'background-color': 'lightgrey' }
      "
    >
      101
    </div>

    <div
      class="expertiseHeading"
      (click)="courseExpertiseSelected('201')"
      [ngStyle]="
        selectCourseExpertise == '201'
          ? { 'background-color': '#22b1a8' }
          : { 'background-color': 'lightgrey' }
      "
    >
      201
    </div>
  </div>
</header>
<main>
  <div fxLayout="column" >
    <div class="Bulkupload">Please click the button to download bulk upload template</div>
    <div class="defaultTemplate">
      <button
        mat-raised-button
        class="downloadTemplateButton"
        (click)="downloadTemplate()"
      >
        Download default 101 bulk upload template
      </button>
      <div
        class="messageTextdownload"
        *ngIf="templateDownloadInProgress"
      >
        Downloading...
      </div>
    
  
    <div>
      <button
        mat-raised-button
        class="downloadTemplateButton"
        (click)="download201Template()"
      >
        Download default 201 bulk upload template
      </button>
      <div
        class="messageTextdownload"
        *ngIf="templateDownload201InProgress"
      >
        Downloading...
      </div>
    </div>
    </div>
  </div>
  
  <div>
    <div class="Bulkupload">Please select a file to upload</div>
    <div class="BulkUploadFileUploadLabel action-button">
      <input
        type="file"
        id="BulkUploadFileUpload"
        #fileform
        (change)="onFilesAdded(fileform.files, $event)"
      />
      <div class="fileNameLabel">{{ fileName }}</div>
      <button
        mat-raised-button
        class="browseFileButton"
        (click)="fileform.click()"
        [disabled]="excelUploadInProgress"
          [ngClass]="{ disabled:excelUploadInProgress }"
          [ngStyle]="{color : excelUploadInProgress  ? 'white': 'white',
          opacity : excelUploadInProgress  ? 0.8: 1}"
      >
        Browse
      </button>
      <div
        class="messageText"
        [ngStyle]="{ color: isfilegreater ? 'red' : 'black' }"
      >
        Note: Please choose only .xlsx files of size less than 200KB.
      </div>
  
      <div class="actionButtonContainer">
        <button
          class="actionButton action-button"
          [disabled]="
            isUploadButtonDisabled ||
            disableProceed ||
            excelUploadInProgress ||
            isinvalidfile
          "
          [ngClass]="{
            disabled:
              isUploadButtonDisabled ||
              disableProceed ||
              excelUploadInProgress ||
              isinvalidfile
          }"
          (click)="upload()"
        >
          Upload
        </button>
        <button
          class="actionButton action-button"
          [disabled]="disableProceed || excelUploadInProgress"
          [ngClass]="{ disabled: disableProceed || excelUploadInProgress }"
          (click)="closePopup()"
        >
          Cancel
        </button>
        <div
          class="statusMessage"
          [ngStyle]="{ color: isUploadSuccess ? 'green' : 'red' }"
          *ngIf="'' !== actionMessage"
        >
          {{ actionMessage }}
        </div>
      </div>
    </div>
  </div>
  
  <div>
    <table class="bulkUploadTable" mat-table [dataSource]="contentList">
      <ng-container matColumnDef="UniqueId">
        <th mat-header-cell *matHeaderCellDef>Unique Id</th>
        <td mat-cell *matCellDef="let element" title="{{ element.UniqueId }}">
          {{ element.UniqueId }}
        </td>
      </ng-container>
  
      <ng-container matColumnDef="UploadedFilename">
        <th mat-header-cell *matHeaderCellDef>File Name</th>
        <td
          mat-cell
          *matCellDef="let element"
          title="{{ element.UploadedFilename }}"
        >
          <a [href]="element.UploadedFileLink">
            {{
              element.UploadedFilename.length > 40
                ? element.UploadedFilename.slice(0, 40) + '...'
                : element.UploadedFilename
            }}
          </a>
        </td>
      </ng-container>
  
     
  
      <ng-container matColumnDef="UploadedDate">
        <th mat-header-cell *matHeaderCellDef>Uploaded Date & Time</th>
        <td mat-cell *matCellDef="let element">
          {{ formatDate(element.UploadedDate) }}
        </td>
      </ng-container>
  
      <ng-container matColumnDef="UploadStatus">
        <th mat-header-cell *matHeaderCellDef>
          Upload Status
          <i
            class="material-icons dropDownIcon"
            [matMenuTriggerFor]="uploadStatusMenu"
            >arrow_drop_down</i
          >
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.UploadStatus }} 
          <div *ngIf="element.UploadStatus=='Inprogress'" class="info-icon" data-tool-tip="please wait for some time, file is getting processed">i</div>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="LogfileLink">
        <th mat-header-cell *matHeaderCellDef>Log File</th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.UploadStatus=='Error';else SuccessDiv" class="logFile" fxLayout="row" (click)="downloadLogFile(element.LogfileLink)">
            <!-- <img
              class="material-icons filterIcon"
              [matMenuTriggerFor]="uploadStatusMenu"
              src="../.././../../assets/icons/download.png"
            /> -->
            <div  class="yorbitSprite downloadSpriteIcon" ></div>
            <span>Download File</span>
          </div>
          <ng-template #SuccessDiv><span style="margin-left: 44px;">Not Available</span></ng-template>
          <div>
  
          </div>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  
  <mat-menu #uploadStatusMenu="matMenu">
    <div class="parentMenuDiv" (click)="$event.stopPropagation()">
      <div class="filterList noSearch">
        <div *ngIf="courseStatusFilter.length > 1" class="selectAllDiv">
          <input
            type="checkbox"
            class="selectAllCheckBox"
            [checked]="selectAllStatus"
            (change)="updateSelectAll('CourseStatus')"
            (click)="$event.stopPropagation()"
          />Select All
        </div>
  
        <div class="courseList font12" *ngFor="let status of courseStatusFilter">
          <input
            type="checkbox"
            [checked]="status.isSelected"
            class="checkboxStyle"
            (click)="$event.stopPropagation()"
            (change)="updateCourseStatusFilter(status.value)"
          />
          {{ status.value }}
        </div>
      </div>
    </div>
  </mat-menu>
  
</main>
