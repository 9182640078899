import { Injectable } from '@angular/core';
//import { AppInsightsService } from 'ng2-appinsights';

@Injectable({
  providedIn: 'root'
})
export class AppInsightService {

  constructor(
    //private _AppInsightService: AppInsightsService
  ) { }

  insightInit(key){
   // this._AppInsightService.Init
   // ({
  //    instrumentationKey: key
   // });
  }

  trackPage(page){
  //  this._AppInsightService.trackPageView(page);
  }
  trackEvent(eventName, eventObj){
   // this._AppInsightService.trackEvent(eventName, eventObj);
  }

  trackUser(user){
  //  this._AppInsightService.setAuthenticatedUserContext(user);
  }
}
