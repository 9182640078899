import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  EventEmitter,
  ElementRef
} from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef
} from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { PlaylistService } from '../playlist.service';
import * as fromUserDetailsStore from 'apps/yorbit-ui/src/app/shared/user-details/store';
import { Store } from '@ngrx/store';
import { ICertificatePopupComponent } from './i-certificate-popup/i-certificate-popup.component';
import { Globals } from '../../../globals';
import { AppInsightService } from '@YorbitWorkspace/app-insight';
import { SystemService } from '../../services/system.service';
import { LearningPathService } from '../../../learning-path/learning-path.service';
import { Router, RoutesRecognized } from '@angular/router';
import { ILPBreadcrumbs } from '../../../learning-path/learning-path-breadcrumbs.interface';
import { QuizService } from '../../../quiz/quiz.service';
import { ClassroomService } from '../../services/classroom.service';

@Component({
  selector: 'yorbit-learners-workflow',
  templateUrl: './learners-workflow.component.html',
  styleUrls: ['./learners-workflow.component.scss']
})
export class LearnersWorkflowComponent implements OnInit {
  @ViewChild('certificateForm', { static: false }) certificateForm: ElementRef;
  @ViewChild('projectForm', { static: false }) projectForm: ElementRef;

  @Input() course;
  @Input() workFlowPhaseDetails;
  @Input() packageId;
  @Output() updateWFStatus = new EventEmitter<any>();
  workflowTabExpanded: Array<any>;
  workFlowSteps: Array<any>;
  projectOrAssignmentFile: any;
  uploadBtnTitle: string;
  userMid: string;
  errorFileUrl: string;
  projectAssignmentProblemStatement: any;
  projectScoreCard: any;
  assignmentScoreCard: any;
  badgeDetails: any;
  uploadBtnError: string;
  selectedCertificationDate: any;
  selectedExpiryDate: any;
  minCertDate: any;
  minExpDate: any;
  disableExpiryDate: boolean;
  noExpDateCheckBox: boolean;
  certifcateUploadFile: any;
  certUploadBtnTitle: string;
  @ViewChild('certificateDate', { read: MatInput, static: false })
  certificateDate: MatInput;
  @ViewChild('expiryDate', { read: MatInput, static: false })
  expiryDate: MatInput;
  fileNotSelected: boolean;
  certificateDateNotSelected: boolean;
  expiryNotSelected: boolean;
  disableUploadButton: boolean;
  uploadBtnClicked: boolean;
  workFlowOrder: any;
  workFlowLoaded: boolean;
  currentAssignmentNumber: string;
  certifcateUploadFilename: any;
  showSelectFile: boolean;
  quizDetails;
  currentAttempt: number;
  allowedAttempt: number;
  currentDisplayAttempt: number;
  LPBreadcrumbs: ILPBreadcrumbs;
  LPCourseCompSubscriptions: any;
  quizAllowedAttempts;
  cancelCourseInProgress: boolean = false;
  msgDuringCancel: string = "";
  hasSMEEvaluation: boolean;
  isClassRoomCompleted: boolean = false;
  classroomSessionDetails: any;

  constructor(
    private playlistService: PlaylistService,
    // private appStore: Store<any>,
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>,
    private _popup: MatDialog,
    private globals: Globals,
    private _InsightService: AppInsightService,
    private _systemSvc: SystemService,
    private lpService: LearningPathService,
    private _quizSvc: QuizService,
    private router: Router,
    private _classroom: PlaylistService
  ) {
    this.workflowTabExpanded = [];
    this.workFlowSteps = [];
    this.projectAssignmentProblemStatement = '';
    this.projectScoreCard = [];
    this.assignmentScoreCard = [];
    this.quizDetails = {};
    this.hasSMEEvaluation = false;

    this.uploadBtnError =
      'Please choose only .zip/.7z files of size less than 50MB.';
    this.minCertDate = new Date();
    this.minExpDate = new Date();
    this.disableExpiryDate = false;
    this.noExpDateCheckBox = false;
    this.certifcateUploadFile = '';
    this.certifcateUploadFilename = 'Select a file';
    this.showSelectFile = true;
    this.certificateDateNotSelected = true;
    this.expiryNotSelected = true;
    this.disableUploadButton = true;
    this.fileNotSelected = true;
    this.certUploadBtnTitle = 'UPLOAD';
    this.uploadBtnClicked = false;
    this.workFlowOrder = {};
    this.workFlowLoaded = false;
    this.currentAssignmentNumber = '';
    this.LPCourseCompSubscriptions = {}
    this.classroomSessionDetails = [];
    this.LPCourseCompSubscriptions.breadcrumbsSubscription = this.lpService
      .getBreadCrumbs()
      .subscribe(breadcrumbs => {
        this.LPBreadcrumbs = breadcrumbs;
      });
  }

  ngOnInit() {
    this.uploadBtnTitle = 'UPLOAD';
    // this.appStore.select('userDetails').subscribe(userDetails => {
    //   if (userDetails.user.loaded) {
    //     this.userMid = userDetails.user.data.id;
    //   }
    // });
    this.userMid = this.globals.MId;
    // this.getLearnerWorkflowSteps();
    this.checkIfSMEEvaluated();

  }
  getLearnerWorkflowSteps() {
    this.workFlowSteps = [
      {
        step: 'Course Initiation',
        isCompleted:
          this.workFlowPhaseDetails.CompletedPhases.indexOf(
            'Course Initiation'
          ) != -1,
        id: 1
      }
    ];
    ////console.log('course details', this.course);
    //get the workflow order
    // this._classroom.getCompletedClassRoomUser(this.userMid, this.course.UniqueId)
    // .subscribe(data => {
    //     if(data.toString().toUpperCase() == "COMPLETED") {
    //       this.isClassRoomCompleted = true;
    //     }
    //   }
    // )
    this.playlistService
      .getLearnerFlowOrderDetails(this.course.UniqueId)
      .subscribe(order => {
        ////console.log('workflow order', order);
        this.workFlowOrder = order;
        let phase;
        let isCompleted = false;
        let index = 1;
        for (const key in this.workFlowOrder) {
          if (this.workFlowOrder.hasOwnProperty(key)) {
            if (this.workFlowOrder[key] === 'ProofForCourseCompletion') {
              if (this.workFlowPhaseDetails.PhaseDetails.Phase == 'SME Evaluation' || this.hasSMEEvaluation) {
                phase = 'SME Evaluation';
              } else {
                phase = 'Certificate Upload';
              }
              if (
                this.workFlowPhaseDetails.CompletedPhases.indexOf(
                  'Certificate Upload'
                ) != -1
              ) {
                isCompleted = true;
              }
              else if (
                this.workFlowPhaseDetails.CompletedPhases.indexOf(
                  'SME Evaluation'
                ) != -1
              ) {
                phase = 'SME Evaluation';
                isCompleted = true;
              } else {
                isCompleted = false;
              }
              index++;
            } else if (this.workFlowOrder[key] === 'ProjectWork') {
              phase = 'Project';
              if (
                this.workFlowPhaseDetails.CompletedPhases.indexOf('Project') !=
                -1
              ) {
                isCompleted = true;
              } else {
                isCompleted = false;
              }
              index++;
            } else if (this.workFlowOrder[key] === 'ClassRoom') {
              phase = 'ClassRoom';
              if (
                this.workFlowPhaseDetails.CompletedPhases.indexOf(
                  'ClassRoom'
                ) != -1
              ) {
                isCompleted = true;
              } else {
                if (this.workFlowPhaseDetails.SubStatus === 'Classroom Initiated and Scheduled') {
                  // this.getClassRoomInstanceDetail();
                  this.getClassroomSessionDetails();
                }
                isCompleted = false;
              }
              index++;
            } else if (this.workFlowOrder[key] === 'Assessment') {
              phase = 'Assessment';
              if (
                this.workFlowPhaseDetails.CompletedPhases.indexOf(
                  'Assessment'
                ) != -1
              ) {
                isCompleted = true;
              } else {
                isCompleted = false;
              }
              index++;
            } else if (this.workFlowOrder[key] === 'Quiz') {
              phase = 'Quiz';
              if (
                this.workFlowPhaseDetails.CompletedPhases.indexOf(
                  'Quiz'
                ) != -1
              ) {
                isCompleted = true;
              } else {
                isCompleted = false;
              }
              index++;
            } else {
              phase = 'Course Completion';
              if (
                this.workFlowPhaseDetails.CompletedPhases.indexOf(
                  'Course Completion'
                ) != -1
              ) {
                isCompleted = true;
              } else {
                isCompleted = false;
              }
              index++;
            }
            const obj = {
              step: phase,
              isCompleted: isCompleted,
              id: index
            };
            // ////console.log(key + ' -> ' + order[key]);
            this.workFlowSteps.push(obj);
          }
        }
        console.log("WorkflowSteps", this.workFlowSteps);
        this.configurePhaseDetails();
      });
  }
  configurePhaseDetails() {
    //check for intermediate phase
    if (
      this.workFlowPhaseDetails != null &&
      this.workFlowPhaseDetails.PhaseDetails != null &&
      this.workFlowPhaseDetails.PhaseDetails.Phase.toLowerCase() ===
      'intermediate'
    ) {
      const intObj = {
        step: 'Intermediate',
        isCompleted: false,
        id: null
      };
      if (
        this.workFlowPhaseDetails.PreviousPhase === '' ||
        this.workFlowPhaseDetails.PreviousPhase === null ||
        this.workFlowPhaseDetails.PreviousPhase === 'Course Initiation'
      ) {
        this.workFlowSteps.splice(1, 0, intObj);
      } else {
        let index;
        for (const key in this.workFlowOrder) {
          if (this.workFlowOrder.hasOwnProperty(key)) {
            if (this.workFlowOrder[key] === 'ProofForCourseCompletion') {
              if (
                this.workFlowPhaseDetails.PreviousPhase === 'Certificate Upload'
              ) {
                index = key;
              }
            } else if (this.workFlowOrder[key] === 'ProjectWork') {
              if (this.workFlowPhaseDetails.PreviousPhase === 'Project') {
                index = key;
              }
            } else if (this.workFlowOrder[key] === 'ClassRoom') {
              if (this.workFlowPhaseDetails.PreviousPhase === 'Assignment' || this.workFlowPhaseDetails.PreviousPhase === 'ClassRoom') {
                index = key;
              }
            } else if (this.workFlowOrder[key] === 'Assessment') {
              if (this.workFlowPhaseDetails.PreviousPhase === 'Assessment') {
                index = key;
              }
            }
            else if (this.workFlowOrder[key] === 'Quiz') {
              if (this.workFlowPhaseDetails.PreviousPhase === 'Quiz') {
                index = key;
              }
            }
            // else {
            //   index = key;
            // }
          }
        }
        this.workFlowSteps.splice(index * 1 + 1, 0, intObj);
      }
      console.log("WorkflowSteps", this.workFlowSteps);
    }


    //check for status
    this.getWorkFlowCompletedStatus();
    if (!this.workFlowPhaseDetails.IsProjectRequired) {
      const projectIndex = this.workFlowSteps.findIndex(
        k => k.step === 'Project'
      );
      if (projectIndex !== -1) this.workFlowSteps.splice(projectIndex, 1);
    }
    if (!this.workFlowPhaseDetails.IsAssignmentRequired) {
      const assignmentIndex = this.workFlowSteps.findIndex(
        k => k.step === 'Assignment'
      );
      if (assignmentIndex !== -1) this.workFlowSteps.splice(assignmentIndex, 1);
    }
    if (!this.workFlowPhaseDetails.IsAssessmentrequired) {
      const assessmentIndex = this.workFlowSteps.findIndex(
        k => k.step === 'Assessment'
      );
      if (assessmentIndex !== -1) this.workFlowSteps.splice(assessmentIndex, 1);
    }

    if (!this.workFlowPhaseDetails.IsQuizRequired) {
      const quizIndex = this.workFlowSteps.findIndex(
        k => k.step === 'Quiz'
      );
      if (quizIndex !== -1) this.workFlowSteps.splice(quizIndex, 1);
    }
    // open the current phase tab
    this.workFlowSteps.forEach((element, key) => {
      if (element.step === this.workFlowPhaseDetails.PhaseDetails.Phase) {
        this.workflowTabExpanded[key] = true;
      } else {
        this.workflowTabExpanded[key] = false;
      }
    });
  }


  getQuizDetails(courseId) {
    //console.log(courseId)
    let isDeclared = false;
    // get decalre status from declare store

    this.userDetailsStore
      .select(fromUserDetailsStore.getBadgesByCourseIdSelector(courseId))
      .subscribe(badge => {
        ////console.log("badges",badge);
        //console.log(badge)

      });
    this.userDetailsStore
      .select(
        fromUserDetailsStore.getCourseProgressEntitiesByIdSelector(courseId)
      )
      .subscribe(course => {
        if (course && course.CourseId) {
          //console.log(course);
        }
      });

    this.userDetailsStore
      .select(fromUserDetailsStore.getUserDetailObject)
      .subscribe(userDetails => {
        const quiz = userDetails.QuizDetails.find(
          quizdetails => quizdetails.EntityId == courseId
        );
        if (quiz && quiz.EntityId) {
          //console.log(quiz)
          this.quizDetails = { ...quiz };
        }
      });

    //console.log(this.course)

    this._quizSvc
      .getQuizByCourseId(courseId, this.course.ItemType, this.course)
      .subscribe(
        data => {
          this.quizAllowedAttempts = data.AllowedAttempt
          //console.log(data)
        },
        error => {
        }
      );

  }


  getWorkFlowCompletedStatus() {
    this.workFlowLoaded = true;
    this.projectScoreCard = [];
    this.assignmentScoreCard = [];
    // ////console.log('Steps :', this.workFlowSteps);
    // const index = this.workFlowSteps.findIndex(
    //   value => value.step === this.workFlowPhaseDetails.PhaseDetails.Phase
    // );
    // // ////console.log('current phase index :', index);
    // for (let i = 0; i < index; i++) {
    //   this.workFlowSteps[i].isCompleted = true;
    // }
    if (this.workFlowPhaseDetails.PhaseDetails.Phase === 'Certificate Upload' || this.workFlowPhaseDetails.PhaseDetails.Phase === 'SME Evaluation') {
      // this.workFlowSteps[0].isCompleted = true;
    } else if (this.workFlowPhaseDetails.PhaseDetails.Phase === 'Project') {
      // this.workFlowSteps[0].isCompleted = true;
      // this.workFlowSteps[1].isCompleted = true;
      // get problem statement
      this.getProjectOrAssignmentProblemStatements('project');
      // get score card
      if (this.workFlowPhaseDetails.IsAssignmentRequired) {
        this.getProjectOrAssignmentScoreCard('assignment');
      }
      // get score card
      this.getProjectOrAssignmentScoreCard('project');
      this.getProjectOrAssignmentErrorFile('project');
    } else if (this.workFlowPhaseDetails.PhaseDetails.Phase === 'Assignment') {
      // this.workFlowSteps[0].isCompleted = true;
      // this.workFlowSteps[1].isCompleted = true;
      // this.workFlowSteps[2].isCompleted = true;
      // get problem statement
      this.getProjectOrAssignmentProblemStatements('assignment');
      // get score card
      if (this.workFlowPhaseDetails.IsProjectRequired) {
        this.getProjectOrAssignmentScoreCard('project');
      }
      this.getProjectOrAssignmentScoreCard('assignment');
    } else if (this.workFlowPhaseDetails.PhaseDetails.Phase === 'Assessment') {
      // this.workFlowSteps[0].isCompleted = true;
      // this.workFlowSteps[1].isCompleted = true;
      // this.workFlowSteps[2].isCompleted = true;
      // this.workFlowSteps[3].isCompleted = true;
      if (this.workFlowPhaseDetails.IsProjectRequired) {
        this.getProjectOrAssignmentScoreCard('project');
      }
      if (this.workFlowPhaseDetails.IsAssignmentRequired) {
        this.getProjectOrAssignmentScoreCard('assignment');
      }
    } else if (this.workFlowPhaseDetails.PhaseDetails.Phase === 'Quiz') {
      if (this.workFlowPhaseDetails.IsQuizRequired) {
        this.getQuizDetails(this.course.Id);
      }

      if (this.workFlowPhaseDetails.IsProjectRequired) {
        this.getProjectOrAssignmentScoreCard('project');
      }
      if (this.workFlowPhaseDetails.IsAssignmentRequired) {
        this.getProjectOrAssignmentScoreCard('assignment');
      }
    } else if (
      this.workFlowPhaseDetails.PhaseDetails.Phase === 'Course Completion'
    ) {
      // this.workFlowSteps[0].isCompleted = true;
      // this.workFlowSteps[1].isCompleted = true;
      // this.workFlowSteps[2].isCompleted = true;
      // this.workFlowSteps[3].isCompleted = true;
      // this.workFlowSteps[4].isCompleted = true;
      if (this.workFlowPhaseDetails.IsProjectRequired) {
        this.getProjectOrAssignmentScoreCard('project');
      }
      if (this.workFlowPhaseDetails.IsAssignmentRequired) {
        this.getProjectOrAssignmentScoreCard('assignment');
      }
      // get badge
      this.getBadgeDetails();
    }
  }
  getProjectOrAssignmentProblemStatements(type) {
    let id;
    if (type === 'project') {
      id = this.course.RequestId;
    } else if (type === 'assignment') {
      if (
        this.workFlowPhaseDetails.PhaseDetails.CurrentAssignmentSessionId !==
        null
      ) {
        this.currentAssignmentNumber = this.workFlowPhaseDetails.PhaseDetails.CurrentAssignmentSessionId.split(
          '_'
        )[2];
      }
      id = this.workFlowPhaseDetails.PhaseDetails.CurrentAssignmentSessionId;
    }
    if (id !== null) {
      this.playlistService
        .getProblemStatementForProjectOrAssignment(type, id)
        .then(fileUrl => {
          ////console.log(`${type} problem statements url : `, fileUrl);
          this.projectAssignmentProblemStatement = fileUrl;
        })
        .catch(err => {
          this.projectAssignmentProblemStatement = null;
        });
    }
  }
  getProjectOrAssignmentErrorFile(type) {
    if (type === 'project') {
      this.callApiforErrorFile(
        type,
        this.workFlowPhaseDetails.PhaseDetails.ProjectId
      );
    }
  }
  getProjectOrAssignmentScoreCard(type) {
    if (type === 'project') {
      // if (
      //   !(
      //     this.workFlowPhaseDetails.SubStatus.toLowerCase() ===
      //       'result awaited for project' ||
      //     this.workFlowPhaseDetails.SubStatus.toLowerCase() ===
      //       'learningopm team to verify and publish result' ||
      //     this.workFlowPhaseDetails.SubStatus.toLowerCase() ===
      //       'cleared the assessment and yet to clear the project' ||
      //     this.workFlowPhaseDetails.SubStatus.toLowerCase() ===
      //       'cleared the assessment and project results awaited'
      //   )
      // )
      this.callApiforScoreCard(
        type,
        this.workFlowPhaseDetails.PhaseDetails.ProjectId
      );
    } else {
      if (
        this.workFlowPhaseDetails.PhaseDetails.SessionIds !== null &&
        this.workFlowPhaseDetails.PhaseDetails.SessionIds.length != 0
      ) {
        // if (this.workFlowPhaseDetails.PhaseDetails.SessionIds.length === 1) {
        //   if (
        //     !(
        //       this.workFlowPhaseDetails.SubStatus.toLowerCase() ===
        //         'result awaited for assignment' ||
        //       this.workFlowPhaseDetails.SubStatus.toLowerCase() ===
        //         'learningopm team to verify and publish result' ||
        //       this.workFlowPhaseDetails.SubStatus.toLowerCase() ===
        //         'cleared the assessment and yet to clear the assignment'
        //     )
        //   ) {
        //     this.callApiforScoreCard(
        //       type,
        //       this.workFlowPhaseDetails.PhaseDetails.SessionIds[0]
        //     );
        //   }
        // } else {
        //   // if (
        //   //   !(
        //   //     this.workFlowPhaseDetails.SubStatus.toLowerCase() ===
        //   //       'result awaited for assignment' ||
        //   //     this.workFlowPhaseDetails.SubStatus.toLowerCase() ===
        //   //       'learningopm team to verify and publish result' ||
        //   //     this.workFlowPhaseDetails.SubStatus.toLowerCase() ===
        //   //       'cleared the assessment and yet to clear the assignment'
        //   //   )
        //   // ) {
        //   //   for (
        //   //     let i = 0;
        //   //     i < this.workFlowPhaseDetails.PhaseDetails.SessionIds.length;
        //   //     i++
        //   //   ) {
        //   //     this.callApiforScoreCard(
        //   //       type,
        //   //       this.workFlowPhaseDetails.PhaseDetails.SessionIds[i]
        //   //     );
        //   //   }
        //   // } else {
        //   // }
        // }
        let index = this.workFlowPhaseDetails.PhaseDetails.SessionIds.findIndex(
          value =>
            value ===
            this.workFlowPhaseDetails.PhaseDetails.CurrentAssignmentSessionId
        );
        if (index == -1) {
          index = this.workFlowPhaseDetails.PhaseDetails.SessionIds.length - 1;
        }
        for (let i = 0; i < index + 1; i++) {
          this.callApiforScoreCard(
            type,
            this.workFlowPhaseDetails.PhaseDetails.SessionIds[i]
          );
        }
      }
    }
  }
  checkAttemptsCount(assignmentId) {
    let count = 0;
    this.assignmentScoreCard.forEach(val => {
      if (val.assignmentNumber == assignmentId.split('_')[2]) {
        count++;
      }
    });
    return count;
  }

  callApiforErrorFile(type, Id) {
    this.playlistService.getErrorFileForProjectOrAssignment(this.userMid, Id, this.course.RequestId)
      .then((res: any) => {
        if (res != null) {
          this.errorFileUrl = res;
        }
      })
  }
  callApiforScoreCard(type, Id) {
    this.playlistService
      .getScoreCardForProjectOrAssignment(
        this.userMid,
        Id,
        this.course.RequestId
      )
      .then((res: any) => {
        ////console.log(`${type} score card url : `, res);
        if (res != null) {
          if (type === 'project') {
            Object.entries(res).forEach(([key, value]) => {
              if (
                (this.workFlowPhaseDetails.SubStatus.toLowerCase() !=
                  'result awaited for project' &&
                  this.workFlowPhaseDetails.SubStatus.toLowerCase() !=
                  'learningopm team to verify and publish result') ||
                ((this.workFlowPhaseDetails.SubStatus.toLowerCase() ==
                  'result awaited for project' ||
                  this.workFlowPhaseDetails.SubStatus.toLowerCase() ==
                  'learningopm team to verify and publish result') &&
                  parseInt(key) <
                  parseInt(
                    this.workFlowPhaseDetails.PhaseDetails.AttemptCount
                  ))
              ) {
                const obj = {
                  scoreCard: value,
                  attempt: key
                };
                if (value && value !== null) this.projectScoreCard.push(obj);
              }
            });
          } else {
            const index = Id.split('_')[2];
            if (
              this.workFlowPhaseDetails.PhaseDetails
                .CurrentAssignmentSessionId == null
            ) {
              this.workFlowPhaseDetails.PhaseDetails.CurrentAssignmentSessionId =
                '00_00_00';
            }
            this.workFlowPhaseDetails.PhaseDetails.CurrentAssignmentSessionId;
            Object.entries(res).forEach(([key, value]) => {
              if (
                this.workFlowPhaseDetails.PhaseDetails.CurrentAssignmentSessionId.split(
                  '_'
                )[2] != index ||
                (this.workFlowPhaseDetails.SubStatus.toLowerCase() !=
                  'result awaited for assignment' &&
                  this.workFlowPhaseDetails.SubStatus.toLowerCase() !=
                  'learningopm team to verify and publish result') ||
                (this.workFlowPhaseDetails.PhaseDetails.CurrentAssignmentSessionId.split(
                  '_'
                )[2] == index &&
                  (this.workFlowPhaseDetails.SubStatus.toLowerCase() ==
                    'result awaited for assignment' ||
                    this.workFlowPhaseDetails.SubStatus.toLowerCase() ==
                    'learningopm team to verify and publish result') &&
                  parseInt(key) <
                  parseInt(
                    this.workFlowPhaseDetails.PhaseDetails.AttemptCount
                  ))
              ) {
                const obj = {
                  scoreCard: value,
                  attempt: key,
                  assignmentNumber: index
                };
                if (value && value !== null) this.assignmentScoreCard.push(obj);
              }
            });
          }
        }
      })
      .catch(err => { });
  }
  downloadProjectAssignmentProblemStatement(type) {
    try {
      const insightPayload = {
        Action: 'Download ' + type + ' statement',
        CourseUniqueID: this.course.UniqueId,
        User: this.globals.MId
      };
      this._InsightService.trackEvent('Learners Workflow', insightPayload);
    } catch (error) {
      //Insight Failed
    }
    if (this.projectAssignmentProblemStatement != null) {
      this._systemSvc.getBlobSasKey(this.projectAssignmentProblemStatement)
        .then((response: any) => {
          try {
            let decodedUri = this._systemSvc.getAtoBDecode(response);
            window.open(decodedUri, '_blank');
          }
          catch { }
        });
    }
    //window.open(this.projectAssignmentProblemStatement, '_blank');
  }

  downloadErrorFile(type) {
    //console.log('Error', this.errorFileUrl);
    try {
      const insightPayload = {
        Action: 'Download ' + type + ' statement',
        CourseUniqueID: this.course.UniqueId,
        User: this.globals.MId
      };
      this._InsightService.trackEvent('Learners Workflow', insightPayload);
    } catch (error) {
      //Insight Failed
    }
    if (this.errorFileUrl != null) {
      this._systemSvc.getBlobSasKey(this.errorFileUrl)
        .then((response: any) => {
          try {
            let decodedUri = window.atob(response);
            window.open(decodedUri, '_blank');
          }
          catch { }
        });
    }
  }

  downloadProjectAssignmentScoreCard(type, url, id) {
    if (url == null && type == 'assignment' && id != null) {
      try {
        const insightPayload = {
          Action: 'Download Assignment Score Card',
          CourseUniqueID: this.course.UniqueId,
          User: this.globals.MId
        };
        this._InsightService.trackEvent('Learners Workflow', insightPayload);
      } catch (error) {
        //Insight Failed
      }
      let assignmentDetails = this.assignmentScoreCard.find(
        obj => obj.assignmentNumber == id.split('_')[2]
      );
      if (assignmentDetails.scoreCard != null) {
        this._systemSvc.getBlobSasKey(assignmentDetails.scoreCard)
          .then((response: any) => {
            try {
              let decodedUri = this._systemSvc.getAtoBDecode(response);
              window.open(decodedUri, '_blank');
            }
            catch { }
          });
      }
      // window.open(assignmentDetails.scoreCard, '_blank');
    } else {
      try {
        const insightPayload = {
          Action: 'Download Project Score Card',
          CourseUniqueID: this.course.UniqueId,
          User: this.globals.MId
        };
        this._InsightService.trackEvent('Learners Workflow', insightPayload);
      } catch (error) {
        //Insight Failed
      }
      this._systemSvc.getBlobSasKey(url)
        .then((urlResponse: any) => {
          try {
            let decodedUri = this._systemSvc.getAtoBDecode(urlResponse);
            window.open(decodedUri, '_blank');
          }
          catch { }
        });
      //let decodedUri = this._systemSvc.getAtoBDecode(response);
      //window.open(decodedUri, '_blank');
    }

    // if (type === 'project') {
    //   window.open(this.projectScoreCard, '_blank');
    // } else {
    // }
  }

  certificateDateSelected(event: MatDatepickerInputEvent<Date>) {
    this.selectedCertificationDate = event.value;
    this.certificateDateNotSelected = false;
    this.validateConditionForCertificateUpload();
  }
  expiryDateSelected(event: MatDatepickerInputEvent<Date>) {
    this.selectedExpiryDate = event.value;
    this.expiryNotSelected = false;
    this.validateConditionForCertificateUpload();
  }
  disableExpDateOrCheckBoxFunction(ischecked) {
    this.noExpDateCheckBox = !ischecked;
    //this.noExpDateCheckBox=!this.noExpDateCheckBox;
    if (this.noExpDateCheckBox) {
      this.disableExpiryDate = true;
      // this.expiryDate.value = '';
      this.selectedExpiryDate = '';
      this.expiryNotSelected = false;
    } else {
      this.disableExpiryDate = false;
      this.expiryNotSelected = true;
    }
    this.validateConditionForCertificateUpload();
  }
  fileModel(phase, event) {
    //////console.log('selected file for upload', event);
    if (phase === 'project' || phase === 'assignment') {
      if (event.file == undefined) {
        this.setDefaultsForProject();
      } else {
        this.projectOrAssignmentFile = event.file;
        this.certifcateUploadFilename = event.file.name;
      }
    } else {
      if (event.file == undefined) {
        this.setDefaultsForCertificate();
      } else {
        this.certifcateUploadFile = event.file;
        this.certifcateUploadFilename = event.file.name;
        this.showSelectFile = false;
        this.fileNotSelected = false;
        this.validateConditionForCertificateUpload();
      }
      //setTimeout(()=>this.certifcateUploadFilename = event.file.name);
      // const ele = document.getElementById('fileUploadLabel');
      // ele.innerHTML = "<span>Browse</span><div title=''>" + event.file.name + '</div>';
    }
  }
  resetInputFile(phase) {
    if (phase === 'project' || phase === 'assignment') {
      const ele = document.getElementById('projectUploadLabel');
      ele.innerHTML =
        " <div class='input-label' fxFlex title=''>Select a file</div><div class='mousePointer yorbitSprite uploadIcon'></div>";
    } else {
      this.setDefaultsForCertificate();
      // const ele = document.getElementById('fileUploadLabel');
      // ele.innerHTML = "<span>Browse</span><div title=''>Select a file</div>";
      // this.certificateDate.value = '';
      // this.expiryDate.value = '';
    }
  }
  uploadCertificate() {
    this.uploadBtnClicked = true;
    if (
      this.selectedCertificationDate === undefined ||
      this.selectedCertificationDate === ''
    ) {
      this.certificateDateNotSelected = true;
      this.openpopup('Failure', 'Please select the certification date !');
      this.uploadBtnClicked = false;
    } else {
      this.certificateDateNotSelected = false;
    }
    if (
      (this.selectedExpiryDate === undefined ||
        this.selectedExpiryDate === '') &&
      !this.noExpDateCheckBox
    ) {
      this.expiryNotSelected = true;
      this.uploadBtnClicked = false;
      this.openpopup('Failure', 'Please select the expiry date !');
    } else {
      this.expiryNotSelected = false;
    }
    if (
      this.certifcateUploadFile === undefined ||
      this.certifcateUploadFile === ''
    ) {
      this.fileNotSelected = true;
      this.uploadBtnClicked = false;
      this.openpopup('Failure', 'Please select the file');
    } else {
      const uploadedFileSize = this.certifcateUploadFile.size / (1024 * 1024);
      let extension = this.certifcateUploadFile.name
        .split('.')
        .pop()
        .toLowerCase();
      if (
        extension == 'zip' ||
        extension == '7z' ||
        extension == 'pdf' ||
        extension == 'jpg' ||
        extension == 'jpeg'
      ) {
        if (uploadedFileSize < 10) {
          this.fileNotSelected = false;
          // this.openpopup('Success', 'Click to Upload Certificate');
        } else {
          this.fileNotSelected = true;
          this.uploadBtnClicked = false;
          this.openpopup('Failure', 'Please choose file less than 10MB !');
        }
      } else {
        this.fileNotSelected = true;
        this.uploadBtnClicked = false;
        this.openpopup(
          'Failure',
          'Please choose only pdf/jpg/jpeg/zip/7z file !'
        );
      }
    }
    this.validateConditionForCertificateUpload();
  }
  validateConditionForCertificateUpload() {
    if (
      this.certificateDateNotSelected ||
      this.expiryNotSelected ||
      this.fileNotSelected
    ) {
      this.disableUploadButton = true;
    } else {
      this.disableUploadButton = false;
      if (this.uploadBtnClicked) {
        this.uploadBtnClicked = false;
        this.certUploadBtnTitle = 'UPLOADING...';
        this.uploadCertificateToBlob(this.certifcateUploadFile);
        this.disableUploadButton = true;
      }
    }
  }
  uploadCertificateToBlob(file) {
    this.playlistService
      .uploadCertificateToBlob(file, this.course.Id)
      .then(res => {
        this.uploadCertificateToSql(res.Msg);
      })
      .catch(err => {
        this.resetInputFile('certificate');
        this.openpopup('Failure', 'file is not uploaded to blob');
        this.selectedCertificationDate = '';
        this.selectedExpiryDate = '';
        this.noExpDateCheckBox = false;
        this.certifcateUploadFile = '';
        this.certifcateUploadFilename = 'Select a file';
        this.showSelectFile = true;
      });
  }
  uploadCertificateToSql(fileUrl) {
    const certStartDate =
      this.selectedCertificationDate.getFullYear() +
      '-' +
      (this.selectedCertificationDate.getMonth() + 1) +
      '-' +
      this.selectedCertificationDate.getDate();
    let certExptDate;
    if (this.selectedExpiryDate !== '') {
      certExptDate =
        this.selectedExpiryDate.getFullYear() +
        '-' +
        (this.selectedExpiryDate.getMonth() + 1) +
        '-' +
        this.selectedExpiryDate.getDate();
    } else {
      certExptDate = this.selectedExpiryDate;
    }
    const obj = {
      RequestId: this.course.RequestId,
      CourseId: this.course.Id,
      filepath: fileUrl,
      CertificationCompletionDate: certStartDate,
      ExpiryDt: certExptDate,
      IsChecknoexpiry: this.noExpDateCheckBox
    };
    const successMessage =
      'Certificate has been uploaded successfully for ' +
      this.course.RequestId +
      ' - ' +
      this.course.Name;
    const errorMessage = 'Some thing went wrong';
    this.playlistService.uploadCertificateToSql(obj).subscribe(
      res => {
        try {
          const insightPayload = {
            Action: 'Upload Certificate',
            CourseUniqueID: this.course.UniqueId,
            User: this.globals.MId
          };
          this._InsightService.trackEvent('Learners Workflow', insightPayload);
        } catch (error) {
          //Insight Failed
        }
        this.certUploadBtnTitle = 'UPLOADED';
        this.resetInputFile('certificate');
        this.openpopup('Success', successMessage);
        this.selectedCertificationDate = '';
        this.selectedExpiryDate = '';
        this.noExpDateCheckBox = false;
        this.certifcateUploadFile = '';
        this.certifcateUploadFilename = 'Select a file';
        this.showSelectFile = true;
        this.callApiToGetWFDetails();
      },
      error => {
        this.certUploadBtnTitle = 'FAILED';
        this.resetInputFile('certificate');
        this.openpopup('Failure', errorMessage);
        this.selectedCertificationDate = '';
        this.selectedExpiryDate = '';
        this.noExpDateCheckBox = false;
        this.certifcateUploadFile = '';
        this.certifcateUploadFilename = 'Select a file';
        this.showSelectFile = true;
      }
    );
  }
  openpopup(status, data) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    dialogConfig.data = {
      status: status,
      data: data
    };
    const response = this._popup.open(ICertificatePopupComponent, dialogConfig);
    response.afterClosed().subscribe(res => {
      //do something after pop up close
    });
  }
  uploadProjectOrAssignment() {
    if (this.projectOrAssignmentFile !== undefined) {
      // ////console.log('file to upload is', this.projectOrAssignmentFile);
      // ////console.log('course request id', this.course.RequestId);
      // ////console.log('course unique id', this.course.UniqueId);
      // ////console.log('user id', this.userMid);
      // ////console.log(
      //   'assignment session id',
      //   this.workFlowPhaseDetails.PhaseDetails.SessionId
      // );
      const uploadedFileSize =
        this.projectOrAssignmentFile.size / (1024 * 1024);
      let extension = this.projectOrAssignmentFile.name
        .split('.')
        .pop()
        .toLowerCase();
      if (extension == 'zip' || extension == '7z') {
        if (uploadedFileSize < 50) {
          this.uploadBtnTitle = 'UPLOADING...';
          this.uploadBtnError = '';

          if (this.workFlowPhaseDetails.PhaseDetails.Phase === 'Project') {
            // project upload
            this.callProjectUploadAPI();
          }
          if (this.workFlowPhaseDetails.PhaseDetails.Phase === 'Assignment') {
            // assignment upload
            this.callAssignmentUploadAPI();
          }
        } else {
          this.uploadBtnError = 'Please choose file less than 50MB !';
        }
      } else {
        this.uploadBtnError = 'Please choose only zip/7z file !';
      }
    }
  }
  callProjectUploadAPI() {
    this.playlistService
      .uploadProject(
        this.projectOrAssignmentFile,
        this.course.UniqueId,
        this.course.RequestId
      )
      .then((response: any) => {
        try {
          const insightPayload = {
            Action: 'Upload Project',
            CourseUniqueID: this.course.UniqueId,
            User: this.globals.MId
          };
          this._InsightService.trackEvent('Learners Workflow', insightPayload);
        } catch (error) {
          //Insight Failed
        }
        this.uploadBtnTitle = 'UPLOADED';
        //this.openpopup('Failure', 'file is not uploaded to blob');
        this.resetInputFile('project');
        //get the latest course status
        // this.userDetailsStore
        //   .select(
        //     fromUserDetailsStore.getCourseProgressEntitiesByIdSelector(
        //       this.course.Id
        //     )
        //   )
        //   .subscribe(res => {
        //     ////console.log(res);
        //     // this.workFlowPhaseDetails.SubStatus = res.SubStatus;
        //   });
        this.callApiToGetWFDetails();
      })
      .catch((error: any) => {
        this.resetInputFile('project');
        //this.openpopup('Failure', 'file is not uploaded to blob');
        this.uploadBtnTitle = 'FAILED';
      });
  }
  callAssignmentUploadAPI() {
    this.playlistService
      .uploadAssignment(
        this.projectOrAssignmentFile,
        this.userMid,
        this.course.RequestId,
        this.workFlowPhaseDetails.PhaseDetails.CurrentAssignmentSessionId
      )
      .then((response: any) => {
        try {
          const insightPayload = {
            Action: 'Upload Assignment',
            CourseUniqueID: this.course.UniqueId,
            User: this.globals.MId
          };
          this._InsightService.trackEvent('Learners Workflow', insightPayload);
        } catch (error) {
          //Insight Failed
        }
        this.uploadBtnTitle = 'UPLOADED';
        //this.openpopup('Failure', 'file is not uploaded to blob');
        this.resetInputFile('assignment');
        //get the latest course status
        // this.userDetailsStore
        //   .select(
        //     fromUserDetailsStore.getCourseProgressEntitiesByIdSelector(
        //       this.course.Id
        //     )
        //   )
        //   .subscribe(res => {
        //     ////console.log(res);
        //     // this.workFlowPhaseDetails.SubStatus = res.SubStatus;
        //   });
        this.callApiToGetWFDetails();
      })
      .catch((error: any) => {
        //this.resetInputFile('assignment);
        //this.openpopup('Failure', 'file is not uploaded to blob');
        this.uploadBtnTitle = 'FAILED';
      });
  }
  callApiToGetWFDetails() {
    this.playlistService
      .getLearnerFlowStatus(this.course.Id)
      .subscribe(phase => {
        this.workFlowPhaseDetails = phase;
        if (this.workFlowPhaseDetails.PhaseDetails.AttemptCount != null)
          this.workFlowPhaseDetails.PhaseDetails.AttemptCount =
            this.workFlowPhaseDetails.PhaseDetails.AttemptCount * 1;
        this.updateWFStatus.emit(this.workFlowPhaseDetails);
      });
  }
  getBadgeDetails() {
    this.userDetailsStore
      .select(fromUserDetailsStore.getBadgesByCourseIdSelector(this.course.Id))
      .subscribe(badge => {
        this.badgeDetails = badge;
        this.badgeDetails.BadgeImage =
          'assets/images/Badges/badge_' + this.course.Expertise + '.png';
      });
  }
  setDefaultsForCertificate() {
    this.certificateForm.nativeElement.reset();
    this.showSelectFile = true;
    this.certifcateUploadFilename = 'Select a file';
  }
  setDefaultsForProject() {
    this.projectForm.nativeElement.reset();
    this.showSelectFile = true;
    this.certifcateUploadFilename = 'Select a file';
  }


  startQuiz() {

    //if(this.quizAllowedAttempts==0 || this.quizDetails.AttemptsCount<this.quizAllowedAttempts){
    //}

    this.lpService.getAttemptDetails(this.LPBreadcrumbs.CourseDetails.CourseId, true).subscribe(data => {
      this.currentAttempt = data.CurrentAttempt;
      this.allowedAttempt = data.AllowedAttempt;
      if (
        this.currentAttempt != 0 &&
        this.currentAttempt > this.allowedAttempt
      ) {
        if (this.currentAttempt % this.allowedAttempt == 0)
          this.currentDisplayAttempt = this.allowedAttempt;
        else
          this.currentDisplayAttempt =
            this.currentAttempt % this.allowedAttempt;
      } else if (this.currentAttempt <= this.allowedAttempt)
        this.currentDisplayAttempt = this.currentAttempt;


      //console.log(this.LPBreadcrumbs)
      this.router.navigate([
        'quiz/',
        this.LPBreadcrumbs.CourseDetails.CourseId,
        this.LPBreadcrumbs.LPDetails.LPId,
        this.LPBreadcrumbs.LPDetails.LPCategory,
        this.LPBreadcrumbs.PackageDetails.PackageId
          ? this.LPBreadcrumbs.PackageDetails.PackageId
          : 'null'
      ]);
    });
  }

  cancelNotClearedCourse() {
    let cancelComments = "Cancelling a not cleared course";
    this.cancelCourseInProgress = true;
    this.msgDuringCancel = "Restarting the course...";
    this.playlistService.cancelNotClearedCourse(this.course.RequestId, cancelComments, this.course.Id).then(response => {
      let apiResponse = response;
      if ('cancelled' == apiResponse[this.course.RequestId]) {
        this.cancelCourseInProgress = false;
        this.userDetailsStore.dispatch(
          new fromUserDetailsStore.UserDetailsGetDetails()
        );
        setTimeout(() => {
          // this.goBackToLP();
          this.navigateTo()
        }, 1000);

      } else {
        this.cancelCourseInProgress = false;
        this.msgDuringCancel = "Something went wrong. Please try again."
      }
    }, error => {
      this.cancelCourseInProgress = false;
      this.msgDuringCancel = "Something went wrong. Please try again."
    })
  }

  goBackToLP() {
    this.router.navigate([
      'learningpath/category/' +
      this.LPBreadcrumbs.LPDetails.LPCategory +
      '/id/' +
      this.LPBreadcrumbs.LPDetails.LPId
    ]);
  }

  navigateTo() {
    //click action
    //send other route data to highlight the content in LP
    if (this.packageId && this.packageId != "null" && this.course.Id) {
      this.router.navigate(
        [
          'learningpath/category/' +
          this.LPBreadcrumbs.LPDetails.LPCategory +
          '/id/' +
          this.LPBreadcrumbs.LPDetails.LPId +
          '/package/' +
          this.packageId
        ],
        {
          queryParams: {
            lpId: this.LPBreadcrumbs.LPDetails.LPId,
            courseId: this.course.Id,
            packageId: this.packageId
          }
        }
      );
    } else {
      this.router.navigate(
        [
          'learningpath/category/' +
          this.LPBreadcrumbs.LPDetails.LPCategory +
          '/id/' +
          this.LPBreadcrumbs.LPDetails.LPId
        ],
        {
          queryParams: {
            lpId: this.LPBreadcrumbs.LPDetails.LPId,
            courseId: this.course.Id,
            packageId: null
          }
        }
      );
    };
  }

  checkIfSMEEvaluated() {
    this.playlistService.checkIfSMEEvaluated(this.course.UniqueId).subscribe((isSMEEval) => {
      if (isSMEEval == true) {
        this.hasSMEEvaluation = true;
      } else {
        this.hasSMEEvaluation = false;
      }
      this.getLearnerWorkflowSteps();
    }, (error) => {
      this.hasSMEEvaluation = false;
    })
  }

  // getClassRoomInstanceDetail() {
  //   this.playlistService.getCompletedClassRoomUser(this.userMid, this.course.UniqueId).subscribe( {next: (instance) => {
  //       this.classRoomInstanceDetail.StartDate = instance["StartDate"];
  //       this.classRoomInstanceDetail.EndDate = instance["EndDate"];
  //       this.classRoomInstanceDetail.Venue = instance["Venue"];
  //       this.classRoomInstanceDetail.Location = instance["Location"];
  //   },
  //   error: (response) => {
  //     console.log(response);
  //   }})
  // }

  getClassroomSessionDetails() {
    this.playlistService.getClassroomSessionDetails(this.userMid, this.course.UniqueId).subscribe({
      next: (sessions) => {
        this.classroomSessionDetails = sessions
      },
      error: (response) => {
        // console.log(response);
      }
    })
  }
}
