import { Component, OnInit, Inject } from '@angular/core';
import { StartCourseRequestService } from './start-course-request.service';
import { Store } from '@ngrx/store';
import * as fromUserDetailsStore from '../../../../shared/user-details/store';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import * as fromDeclareStore from '../../../declare-store/store';
import { DeclareStoreService } from '../../../declare-store/declare-store.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Globals } from 'apps/yorbit-ui/src/app/globals';
import { LearnerBatchSelectionComponent } from 'apps/yorbit-ui/src/app/classroom/dialog/learner-batch-selection/learner-batch-selection.component';

@Component({
  selector: 'yorbit-start-course-request',
  templateUrl: './start-course-request.component.html',
  styleUrls: ['./start-course-request.component.scss']
})
export class StartCourseRequestComponent implements OnInit {
  processState: string;
  validationResponse: any;
  approvalResponse: any;
  cloudLabMessage: string;
  ShowRequestButtons: boolean;
  cloudLab: any;
  canClosePopup: boolean;
  showPreloader: boolean;
  recommendedCourses: Array<any>;
  prerequisiteCourses: Array<any>;
  showMoreCourses: boolean;
  limitRecommendation: number;
  requestNotAllowed: boolean;
  requestNotAllowedInfo: string;
  updatedStatus: string;
  declaredCourseList: any[];
  ProcessCompleted: boolean;
  prerequisiteCount: any;
  ProceedWithoutIntent: boolean;
  errorSpecialCase: boolean;
  setMultiLab: boolean;
  vendor: string;
  startCourseCompSubscritions: any;
  classroomBatches: [];
  isClassroom: boolean;
  isClassroomLoaded: boolean;
  isClassroomBatchSelected: boolean;
  selectedClassroomBatchId: string;
  constructor(
    private router: Router,
    private startCourseRequestService: StartCourseRequestService,
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>,
    private dialogRef: MatDialogRef<StartCourseRequestComponent>,
    @Inject(MAT_DIALOG_DATA) public cardDetails: any,
    private declareStore: Store<fromDeclareStore.IdeclareState>,
    private declareStoreService: DeclareStoreService,
    private globals: Globals,
    private dialog: MatDialog
  ) {
    this.canClosePopup = true;
    this.showPreloader = true;
    this.processState = 'validate';
    this.validationResponse = '';
    this.approvalResponse = '';
    this.cloudLabMessage = '';
    this.ShowRequestButtons = false;
    this.canClosePopup = false;
    this.recommendedCourses = [];
    this.prerequisiteCourses = [];
    this.showMoreCourses = true;
    this.limitRecommendation = 4;
    this.requestNotAllowed = false;
    this.declaredCourseList = [];
    this.ProceedWithoutIntent = false;
    this.errorSpecialCase = false;
    this.requestNotAllowedInfo = '';
    this.vendor = '';
    this.subscribeToRouterEvents();
    this.classroomBatches = [];
    this.selectedClassroomBatchId = '';
    //this.requestNotAllowedInfo = 'Dear Learner, This request cannot be processed further as courses from Coursera are currently not available for Learning on Yorbit. Please retry after &nbsp<div class="requestNotAllowed-Date"> 13<sup>th</sup>&nbspJune&nbsp2019</div>! If you have an urgency to take up any 201/301 to adhere to learning policy compliance timeline, you can opt for an alternative course.';
    this.requestNotAllowedInfo =
      'Dear Learner, This request cannot be processed further as courses from Udacity are currently not available for Learning on Shoshin School. Please opt for an alternate course.';
  }

  ngOnInit() {
    //this.validateCourse();
    this.ProcessCompleted = false;
    this.prerequisiteCount = 0;
    //Uncomment below after intent implementation
    if (
      this.cardDetails.GetIntent &&
      0 == this.cardDetails.Prerequisite.length
    ) {
      this.getCourseDetails();
    } else if (
      this.cardDetails.GetIntent &&
      this.cardDetails.Prerequisite &&
      0 < this.cardDetails.Prerequisite.length
    ) {
      //Prerequisite
      this.validationResponse = this.cardDetails.validationResponse;
      this.processState = 'processed';
      console.log("ooooooooooooooo", this.cardDetails);
      if (this.globals.declareBlockedAceingLeadership.indexOf(this.cardDetails.ItemId) != -1) {
        this.approvalResponse =
          "<div><span>Sorry your request cannot be processed! Please complete the below mentioned pre-requisite(s) before starting the course.</span></div>";

      } else {
        this.approvalResponse =
          "<div><span>Sorry your request cannot be processed! Please complete the below mentioned pre-requisite(s) before starting the course. You can also 'Declare </span><span class='yorbitSprite icon-declare-flag-Prerequisite'></span><span>' the prerequisites if you have prior knowledge with respect to the same. Ensure you read <a href='/info/faq/Declare'>FAQ</a> before declaring a course.</span></div>";
      }


      this.showPrerequisiteCourses(this.cardDetails.Prerequisite);
      //this.checkPrerequisite();
      //this.proceedRequest();
    } else {
      this.validationResponse = this.cardDetails.validationResponse;
      //this.processState='proceed';
      this.proceedRequest();
    }
  }
  subscribeToRouterEvents() {
    this.startCourseCompSubscritions = {};
    this.startCourseCompSubscritions.routerParamsSub = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        //console.log('url', this.router.url);
        if (
          this.router.url.indexOf('learningpath') != -1 &&
          (this.router.url.indexOf('courseId') != -1 ||
            this.router.url.indexOf('packageId') != -1)
        ) {
          this.dialogRef.close();
        }
      });
  }
  getCourseDetails() {
    this.startCourseRequestService.vendor = '';
    this.startCourseRequestService
      .getCourseOrPackageDetails(this.cardDetails.ItemId, 'Course')
      .then((response: any) => {
        if (
          response != null &&
          response.Vendor != null &&
          response.Vendor != undefined
        ) {
          this.startCourseRequestService.vendor = response.Vendor;
          this.isClassroom = response?.Classroom.toLowerCase() === "true";
          if (this.isClassroom) {
            this.startCourseRequestService.getClassroomDetailsForBatchSelection(this.cardDetails.ItemId).subscribe((batches: any) => {
              this.showPreloader = false;
              this.isClassroomLoaded = true;
              this.classroomBatches = batches;
              this.dialog.open(LearnerBatchSelectionComponent, {
                id: "ClassroomBatchSelection",
                panelClass: 'popupDialogContainer',
                disableClose: true,
                width: '500px',
                data: {
                  title: this.cardDetails.ItemName,
                  batchList: batches?.BatchDetails
                }
              });
              this.dialog.getDialogById('ClassroomBatchSelection').afterClosed().subscribe((batchId) => {
                if (batchId) {
                  this.isClassroomBatchSelected = true;
                  this.canClosePopup = false;
                  this.processState = 'processing';
                  this.selectedClassroomBatchId = batchId;
                  // intentFeat
                  //this.proceedRequest();
                } else {
                  //Close Learning Intent dialog if classroom course batch dialog is closed without selecting batch
                  this.dialogRef.close();
                }
                if (response.Vendor.indexOf('Udacity') != -1) {
                  this.requestNotAllowedInfo =
                    'Dear Learner, This request cannot be processed further as courses from Udacity are currently not available for Learning on Shoshin School. Please opt for an alternate course.';

                  this.requestNotAllowed = true;
                  this.showPreloader = false;
                } else {
                  if (this.selectedClassroomBatchId) {
                    this.requestNotAllowed = false;
                    this.validateCourse();
                  } else {
                    this.requestNotAllowed = true;
                    this.showPreloader = false;
                  }
                }
              });
            })
          } else {
            if (response.Vendor.indexOf('Coursera') != -1) {
              //this.requestNotAllowedInfo = 'Dear Learner, This request cannot be processed further as courses from Coursera are currently not available for Learning on Yorbit. Please retry after &nbsp<div class="requestNotAllowed-Date"> 13<sup>th</sup>&nbspJune&nbsp2019</div>! If you have an urgency to take up any 201/301 to adhere to learning policy compliance timeline, you can opt for an alternative course.';
              this.requestNotAllowedInfo =
                'Dear Learner, This request cannot be processed further as courses from Coursera are currently not available for Learning on Yorbit. Please opt for an alternate course.';

              this.requestNotAllowed = true;
              this.showPreloader = false;
            } else
              if (response.Vendor.indexOf('Udacity') != -1) {
                this.requestNotAllowedInfo =
                  'Dear Learner, This request cannot be processed further as courses from Udacity are currently not available for Learning on Shoshin School. Please opt for an alternate course.';

                this.requestNotAllowed = true;
                this.showPreloader = false;
              } else {
                this.requestNotAllowed = false;
                this.validateCourse();
              }
          }
        } else {
          //for hidden courses api gives null
          this.startCourseRequestService.vendor = '';
          this.requestNotAllowed = false;
          this.validateCourse();
        }
      })
      .catch(err => {
        this.requestNotAllowed = true;
        this.requestNotAllowedInfo = 'An error has occured';
      });
  }
  validateCourse() {
    this.startCourseRequestService
      .validateCourseRequest(this.cardDetails.ItemId)
      .then((response: any) => {
        this.showPreloader = false;
        this.canClosePopup = true;
        this.processState = 'proceed';
        this.validationResponse = response;
        // if (this.validationResponse.Recommendations != null) {
        //   this.showRecommendedCourses(this.validationResponse.Recommendations);
        // }

        if (
          null == this.validationResponse.Status ||
          '' == this.validationResponse.Status
        ) {
          this.processState = 'error';
          this.approvalResponse = 'An error has occured';
        } else if (
          'validation success' == this.validationResponse.Status.toLowerCase()
        ) {
          this.canClosePopup = false;
          this.showPreloader = true;
          this.processState = 'processing';
          // intentFeat
          //this.proceedRequest();
          this.checkPrerequisite();
        } else if (
          'validation failed' == this.validationResponse.Status.toLowerCase()
        ) {
          this.processState = 'error';
          this.approvalResponse = response.Alert;
        } else if (
          'validation failed but user can proceed' ==
          this.validationResponse.Status.toLowerCase()
        ) {
          this.processState = 'processing';
          this.checkPrerequisite();
        }
      })
      .catch(error => {
        this.showPreloader = false;
        this.processState = 'error';
        this.approvalResponse = 'An error has occured';
        this.canClosePopup = true;
      });
  }
  checkPrerequisite() {
    this.showPreloader = true;
    let payload = {
      CourseId: this.cardDetails.ItemId,
      PackageIdReference: this.cardDetails.PackageId,
      IsAutoApproval: this.validationResponse.IsAutoApproval,
      IsRecommended: this.validationResponse.IsRecommended,
      RequestId: this.cardDetails.RequestId,
      LearnerIntentId: null,
      LearnerIntentDescription: null
    };
    //if package id is not int then change it to empty string in the payload for course request
    if (!/^\d+$/.test(this.cardDetails.PackageId)) {
      // payload.PackageIdReference = '';
    }
    if (this.cardDetails.LPId == "Role Based Learning") {
      payload.PackageIdReference = 0;
    }
    this.startCourseRequestService
      .sendCourseRequestPrerequisiteCheck(payload)
      .then((response: any) => {
        let apiResponse = response;
        // this.showPreloader = false;
        this.processState = 'processed';
        if (
          (null != response.preRequisites && '' != response.preRequisites) ||
          0 < this.cardDetails.Prerequisite
        ) {
          this.canClosePopup = true;
          this.updatedStatus = response.status;
          //this.approvalResponse = '<div class="floatLeft ">Sorry your request cannot be processed! Please complete the below mentioned pre-requisite(s) before starting the course. You can also ‘Declare</div><div class="floatLeft yorbitSprite icon-declare-flag-faq"></div><div class="questionHt">’ the prerequisites if you have prior knowledge with respect to the same. Ensure you read <a href="/info/faq/Declare">FAQ</a> before declaring a course.</div>';

          if (this.globals.declareBlockedAceingLeadership.indexOf(this.cardDetails.ItemId) != -1) {
            this.approvalResponse =
              "<div><span>Sorry your request cannot be processed! Please complete the below mentioned pre-requisite(s) before starting the course.</span></div>";
          } else {
            this.approvalResponse =
              "<div><span>Sorry your request cannot be processed! Please complete the below mentioned pre-requisite(s) before starting the course. You can also 'Declare </span><span class='yorbitSprite icon-declare-flag-Prerequisite'></span><span>' the prerequisites if you have prior knowledge with respect to the same. Ensure you read <a href='/info/faq/Declare'>FAQ</a> before declaring a course.</span></div>";
          }

          this.showPreloader = true;
          this.showPrerequisiteCourses(response.preRequisites);
        } else {
          //intent popup launch
          this.validateIntentRequired();
        }
      })
      .catch(error => {
        this.canClosePopup = true;
        this.showPreloader = false;
        this.approvalResponse = 'An error has occured';
      });
  }
  validateIntentRequired() {

    if (
      (null == this.cardDetails.AssignorRole ||
        '' == this.cardDetails.AssignorRole) &&
      'ASSIGNED' != this.cardDetails.LPId &&
      this.cardDetails.GetIntent
    ) {
      //with intent
      this.closePopUp(false, false);
    } else {
      //without intent
      //this.proceedRequest();
      if (
        'validation success' == this.validationResponse.Status.toLowerCase()
      ) {
        this.proceedRequest();
      } else {
        this.processState = 'proceed';
        this.showPreloader = false;
        this.canClosePopup = true;
        this.prerequisiteCourses = [];

        this.ProceedWithoutIntent = true;
        //this.validationResponse = response;
      }
    }
    //this.proceedRequest();
  }
  proceedRequest() {
    this.ProceedWithoutIntent = false;

    this.showPreloader = true;
    this.processState = 'processing';
    this.canClosePopup = false;
    this.cardDetails.ItemId = parseInt(this.cardDetails.ItemId);
    if (
      this.cardDetails.RequestId == null ||
      this.cardDetails.RequestId == undefined
    ) {
      this.cardDetails.RequestId = 0;
    } else {
      this.cardDetails.RequestId = parseInt(this.cardDetails.RequestId);
    }
    if (
      this.cardDetails.WorflowStatus == null ||
      this.cardDetails.WorflowStatus == undefined
    ) {
      this.cardDetails.WorflowStatus = '';
    }
    if (
      this.cardDetails.WorflowStatus.toLowerCase() == 'cancelled' ||
      this.cardDetails.WorflowStatus.toLowerCase() == 'request cancelled' ||
      this.cardDetails.WorflowStatus.toLowerCase() == 'rejected'
    ) {
      this.cardDetails.RequestId = 0;
    }

    let payload = {
      CourseId: this.cardDetails.ItemId,
      PackageIdReference: this.cardDetails.PackageId,
      IsAutoApproval: this.validationResponse.IsAutoApproval,
      IsRecommended: this.validationResponse.IsRecommended,
      RequestId: this.cardDetails.RequestId,
      LearnerIntentId: this.cardDetails.LearnerIntentId,
      LearnerIntentDescription: this.cardDetails.LearnerIntentRemarks,
      ClassroomBatchId: this.cardDetails.ClassroomBatchId ?? this.selectedClassroomBatchId
    };
    //if package id is not int then change it to empty string in the payload for course request
    if (!/^\d+$/.test(this.cardDetails.PackageId)) {
      // payload.PackageIdReference = '';
    }
    if (this.cardDetails.LPId == "Role Based Learning") {
      payload.PackageIdReference = 0;
    }
    this.startCourseRequestService
      .sendCourseRequest(payload)
      .then((response: any) => {
        let apiResponse = response;
        this.processState = 'processed';
        this.displayResponse(apiResponse);
      })
      .catch(error => {
        this.canClosePopup = true;
        this.showPreloader = false;
        this.approvalResponse = 'An error has occured';
      });
  }
  displayResponse(response) {
    this.updatedStatus = response.status;
    this.ProcessCompleted = true;
    // if (
    //   (null != response.preRequisites && '' != response.preRequisites) ||
    //   0 < this.cardDetails.Prerequisite
    // ) {
    //   this.showPreloader = true;
    //   this.showPrerequisiteCourses(response.preRequisites);
    // } else {
    //   this.showPreloader = false;
    // }
    this.showPreloader = false;

    if (
      'Pending' == response.status ||
      'Under Review' == response.status ||
      'Approved' == response.status ||
      'Course In-Progress' == response.status ||
      'Planning In-Progress' == response.status ||
      'Course Procedure shared' == response.status
    ) {
      this.updateWorkflowStatus(response);

      //this.updateWorkflowStatus.emit({ popup: "Requesting201Approval", result: { "WorflowStatus": response.status, "RequestId": response.requestId } });
      if ('Pending' == response.status) {
        this.approvalResponse =
          'Your request has been sent successfully and will shortly receive communications with respect to the same.';
      } else if ('Under Review' == response.status) {
        this.approvalResponse =
          'Your request is under process and will be auto approved within 1 or 2 working day(s).<br><b> Until then no action is required from your end.</b>';
      } else if (
        'Approved' == response.status ||
        'Course In-Progress' == response.status ||
        'Planning In-Progress' == response.status ||
        'Course Procedure shared' == response.status
      ) {
        this.approvalResponse =
          'Your request has been approved and you will receive course procedure or status trigger mail within 7 working days. Until then no action is required from your end.';
      }

      this.checkCloudLab();
    } else if (
      'Send Request' == response.status ||
      'error' == response.status ||
      'Request Failed' == response
    ) {
      this.processState = 'error';
      this.approvalResponse = 'An error has occured';
      this.canClosePopup = true;
    } else if ('request already exists' == response) {
      this.processState = 'error';
      this.approvalResponse = 'Request already exists';
    } else if (
      'Sorry, something went wrong! Please try again after some time. If the issue persists please raise a ticket on ' + this.globals.isupportUrl ==
      response.status
    ) {
      this.processState = 'error';
      this.errorSpecialCase = true;
      this.approvalResponse = response.status;
      this.canClosePopup = true;
    } else {
      this.approvalResponse = response.status;
      this.canClosePopup = true;
    }

    if (this.validationResponse.Recommendations != null) {
      this.showRecommendedCourses(this.validationResponse.Recommendations);
    }
  }
  updateWorkflowStatus(response) {
    ////console.log(response);
    this.userDetailsStore.dispatch({
      type: '[UserDetails] Update WorkflowStatus',
      payload: {
        WorflowStatus: response.status,
        ItemId: this.cardDetails.ItemId.toString(),
        RequestId: response.requestId,
        PackageId: this.cardDetails.PackageId,
        LPId: this.cardDetails.LPId,
        IsLPMandatory: this.cardDetails.IsLPMandatory,
        ItemExpertise: this.cardDetails.ItemExpertise
      }
    });
  }
  checkCloudLab() {
    this.showPreloader = true;
    this.ShowRequestButtons = false;
    this.cloudLabMessage = 'Checking for cloud lab, Please wait...';
    this.startCourseRequestService
      .getCloudDetailsOfLoggedInUser(this.cardDetails.ItemId)
      .then((response: any) => {
        this.showPreloader = false;
        this.canClosePopup = true;
        this.cloudLab = response;
        let _201Message = 'Previously requested 201 Course should be completed';
        let _301Message = 'Previously requested 301 Course should be completed';

        if (this.cloudLab != null && null != this.cloudLab[0].cloudLabDetails) {
          if (1 < this.cloudLab.length) {
            this.cloudLabMessage =
              'Note: This course has multiple cloud labs available for practice. Would you like to raise a request?';
            this.setMultiLab = true;
            this.ShowRequestButtons = true;
          } else {
            this.setMultiLab = false;
            let status = this.cloudLab[0].cloudLabDetails.AccessStatus;
            let isInternal = this.cloudLab[0].cloudLabDetails.IsInternal;
            if (isInternal) {
              if (
                status == '' &&
                (_201Message.toLowerCase() ==
                  this.cloudLab[0].statusMessage.toLowerCase() ||
                  _301Message.toLowerCase() ==
                  this.cloudLab[0].statusMessage.toLowerCase())
              ) {
                this.ShowRequestButtons = false;
                this.cloudLabMessage = this.cloudLab[0].statusMessage;
              } else {
                this.cloudLabMessage =
                  'Note: This course has an associated cloud lab available for practice. Would you like to raise a request?';
                this.ShowRequestButtons = true;
              }
            } else {
              this.ShowRequestButtons = false;
              this.cloudLabMessage =
                'To get the best out of this course you can utilize the cloud lab provided by ' +
                this.startCourseRequestService.vendor;
            }
          }
        } else {
          this.ShowRequestButtons = false;
          this.cloudLabMessage = 'Cloud Lab Not Available';
        }
      })
      .catch(error => {
        this.showPreloader = false;
        this.canClosePopup = true;
        if (error.status == '403') {
          this.ShowRequestButtons = false;
          this.cloudLabMessage = 'Cloud Lab Not Available';
        } else {
          this.ShowRequestButtons = false;
          this.cloudLabMessage = 'Cloud Lab Not Available';
        }
      });
  }
  requestCloudLab() {
    if (this.setMultiLab) {
      this.closePopUp(true, true);
    } else {
      this.cloudLabMessage = 'Requesting for Cloud lab, Please wait';
      this.showPreloader = true;
      this.ShowRequestButtons = false;
      this.canClosePopup = false;
      var payload = {
        CourseId: this.cloudLab[0].cloudLabDetails.CourseId,
        CloudLabUniqueId: this.cloudLab[0].cloudLabDetails.CloudLabUniqueId
      };
      this.startCourseRequestService
        .requestCloudLabAccess(payload)
        .then((response: any) => {
          let apiResponse = response;
          this.showPreloader = false;
          this.canClosePopup = true;
          this.ShowRequestButtons = false;
          if (apiResponse.toLowerCase() == 'success') {
            this.cloudLabMessage = 'Cloud lab Access Requested';
          } else {
          }
        })
        .catch(error => {
          this.showPreloader = false;
          this.ShowRequestButtons = true;
          this.canClosePopup = true;
          this.cloudLabMessage =
            'Requesting for Cloud lab Failed, Would you like to Try Again?';
        });
    }
  }
  showRecommendedCourses(courseIds) {
    this.recommendedCourses = [];
    courseIds.forEach(id => {
      this.startCourseRequestService
        .getCourseOrPackageDetails(id, 'Course')
        .then(courseDetails => {
          if (courseDetails !== null)
            this.recommendedCourses.push(courseDetails);
        });
    });
  }
  showPrerequisiteCourses(courseIds) {
    if (0 < this.cardDetails.Prerequisite) {
      courseIds = this.cardDetails.Prerequisite;
    } else {
      this.cardDetails.Prerequisite = courseIds;
    }
    this.prerequisiteCourses = [];
    this.prerequisiteCount = this.cardDetails.Prerequisite.length;
    let count = 0;

    //Declared prerequisite____________________
    this.declareStore
      .select(fromDeclareStore.getDeclareStatus)
      .subscribe(status => {
        ////console.log(status);
        status.forEach(course => {
          if (
            courseIds.includes(course.CourseId) &&
            course.DeclareFlagStatus == 'DeclaredAndApproved'
          ) {
            if (!this.declaredCourseList.includes(course.CourseId)) {
              this.declaredCourseList.push(course.CourseId);
            }
          }
        });
      });

    //_________________________________________

    courseIds.forEach(id => {
      count++;
      this.startCourseRequestService
        .getCourseOrPackageDetails(id, 'Course')
        .then(courseDetails => {
          if (courseDetails !== null) {
            this.prerequisiteCourses.push(courseDetails);
          }
          if (
            count === courseIds.length &&
            this.prerequisiteCourses.length > 0
          ) {
            this.showPreloader = false;
          }
        });
    });
  }
  showMoreRecommendedCourses() {
    if (this.showMoreCourses) {
      this.limitRecommendation = 4;
    } else {
      this.limitRecommendation = 100;
    }
    this.showMoreCourses = !this.showMoreCourses;
  }
  closePopUp(close, canRouteToLPDetail) {
    if (canRouteToLPDetail) {
      this.startCourseRequestService.vendor = '';
    }

    //close modal
    this.dialogRef.close({
      canClose: this.requestNotAllowed || close,
      status: this.updatedStatus,
      getIntent: this.cardDetails.GetIntent,
      Prerequisite: this.cardDetails.Prerequisite,
      validationResponse: this.validationResponse,
      canRouteToLPDetailPage: canRouteToLPDetail,
      isClassroom: this.isClassroom,
      selectedBatchId: this.selectedClassroomBatchId
    });
  }
}
