import { AbstractControl } from '@angular/forms';

export function ValidatePaymentControls(control: AbstractControl) {
  const parent = control.parent;
  if (parent) {
    const isCoursePaid = parent.get('paid');
    const isCoursePaidValue = isCoursePaid.value;
    if (
      isCoursePaidValue === 'Yes' &&
      control.value !== '' &&
      control.value !== null
    ) {
      return null;
    } else {
      return { isInvalid: true };
    }
  }
}

export function ValidateVideoControls(control: AbstractControl) {
  const parent = control.parent;
  if (parent) {
    const introVideo = parent.get('isIntroductoryVideo');
    const introVideoValue = introVideo.value;
    if (
      (introVideoValue === 'Yes' &&
        control.value !== '' &&
        control.value !== null) ||
      introVideoValue === 'No'
    ) {
      return null;
    } else {
      return { isInvalid: true };
    }
  }
}

export function ValidatePreReqControl(control: AbstractControl) {
  const parent = control.parent;
  if (parent) {
    const preReq = parent.get('preRequisiteCourseIDs');
    const preReqValue = preReq.value;
    if (
      preReqValue != null &&
      ((preReqValue.length > 0 &&
        (control.value === 'Yes' || control.value === 'No')) ||
        preReqValue.length === 0)
    ) {
      return null;
    } else {
      return { isInvalid: true };
    }
  }
}

export function ValidateCredits(control: AbstractControl) {
  const regEx = new RegExp(/^([0-9]+)$/); //credits of format x(xxx).xx
  if (regEx.test(control.value)) {
    return null;
  } else {
    return {
      isInvalid: true,
      errMsg: "Please enter the credits in format 'xx.xx'"
    };
  }
}

export function ValidatePrice(control: AbstractControl) {
  //regex1 = RegExp(/^(([0-9]+)\.([0-9]{2}))/); //example 11.23 Course duration
  //regex1 = RegExp(/^(([0-9]+)\.([0-9]{2}))/); //example 11.23 course price
  const parent = control.parent;
  if (parent) {
    const isCoursePaid = parent.get('paid');
    const isCoursePaidValue = isCoursePaid.value;
    const regEx = new RegExp(/^(([0-9]+)\.{0,1})([0-9]{0,3})$/); //credits of format x(xxx).xxx
    if (isCoursePaidValue === 'Yes') {
      if (regEx.test(control.value)) {
        return null;
      }
      else{
        return {
          fieldIsInvalid: true,
          errMsg: "Please enter the credits in format 'xx.xxx'"
        };
      }
    }
    else{
      return null;
    }
  } else {
    return {
      fieldIsInvalid: true,
      errMsg: "Please enter the credits in format 'xx.xxx'"
    };
  }
}

export function ValidateDuration(control: AbstractControl) {
  //regex1 = RegExp(/^(([0-9]+)\.([0-9]{2}))/); //example 11.23 Course duration
  //regex1 = RegExp(/^(([0-9]+)\.([0-9]{2}))/); //example 11.23 course price
  //const regEx = new RegExp(/^(([0-9]+)\.{0,1})([0-9]{0,2})(\.{0,1})([0-9]{0,2})$/);//credits of format x(xxx).xx
 // const regEx = new RegExp(/^\d{1,3}.\d{2}.\d{2}$/);
  const regEx = new RegExp( /^[0-9]{1,3}\.[0-5]{1}[0-9]{1}\.[0-5]{1}[0-9]{1}$/);
  
  if (regEx.test(control.value)) {
    return null;
  } else {
    return {
      fieldIsInvalid: true,
      errMsg: "Please enter the credits in format 'xx.xx.xx'"
    };
  }
}

export function ValidateExpert(control: AbstractControl) {
  const regEx = new RegExp(/^(([Mm]{1}[0-9]{7}\,{0,1}\s{0,})+)$/, 'g');
  if (control.value) {
    //const isValid = regEx.test(control.value);
    const isValid = true;
    if (isValid === true) {
      return null;
    } else {
      return {
        isInvalid: true,
        errMsg: "Please enter the MIDs in format 'Mxxxxxxx'"
      };
    }
  }
}

export function ValidateMinLength(minLength: number) {
  return (control: AbstractControl) => {
    if (control.value != null) {
      if (control.value.toString().length >= minLength) {
        return null;
      } else {
        return {
          isInvalid: true,
          errMsg: 'Please enter at least' + minLength + 'characters.'
        };
      }
    }
  };
}

export function ValidateMaxLength(maxLength: number) {
  return (control: AbstractControl) => {
    if (control.value.toString().length <= maxLength) {
      return null;
    } else {
      return {
        isInvalid: true,
        errMsg: 'Please enter not more than' + maxLength + 'characters.'
      };
    }
  };
}
