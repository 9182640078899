import { Injectable } from '@angular/core';
import { Ienvironment, EnvironmentService } from '@YorbitWorkspace/global-environments';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContentVerificationService {

  config: Ienvironment;

  constructor(
    private http: HttpClient,
    private _envSvc: EnvironmentService,
  ) {
    this.config = this._envSvc.getEnvironment();
  }

  getContentUpload201Details(type) {
    const Url = this.config.apiUrl + "GetContentUpload201DetailsForOwners/" +type
    return this.http
      .get<any>(Url)
      .toPromise();
  }

  getPackageContentUploadDetails() {
    const Url = this.config.apiUrl + "GetPackageListForContentOwner";
    return this.http
      .get<any>(Url)
      .toPromise();
  }

  get101CourseWithoutQuiz() {
    const Url = this.config.apiUrl + "Course/Get101CourseWithoutQuiz";
    return this.http.get<any>(Url).toPromise();
  }

  getCourseData(expertise: any, role: any) {
    const Url = this.config.apiUrl + "GetCourseListForContentOwner/" + expertise + '/' + role;
    return this.http.get<any[]>(Url).toPromise();
  }

  get301Course(UniqueId: any) {
    return this.http.get<any>(this.config.apiUrl + '/GetCourseFor301/' + UniqueId);
  }

  get201CourseWithQuiz(){
    const Url = this.config.apiUrl + "Course/Get201CourseWithoutQuiz";
    return this.http.get<any>(Url).toPromise();
  }
}
