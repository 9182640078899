import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, share } from 'rxjs/operators';
import {
  Ienvironment,
  EnvironmentService
} from '@YorbitWorkspace/global-environments';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NewAndTrendingService {
  config: Ienvironment;
  newAndTrendingList: any;
  newAndTrendingListpromise: any;
  constructor(private http: HttpClient, private _envSvc: EnvironmentService) {
    this.config = this._envSvc.getEnvironment();
    this.newAndTrendingList = [];
  }
  getNewAndTrendingList() {
    if (this.newAndTrendingListpromise == undefined) {
      this.newAndTrendingListpromise = new Promise((resolve, reject) => {
        if (this.newAndTrendingList.length == 0) {
          this.http
            .get(this.config.apiUrl + 'HomePage/TrendingAndNew')
            .toPromise()
            .then(list => {
              this.newAndTrendingList = list;
              resolve(list);
            });
        } else {
          resolve(this.newAndTrendingList);
        }
      });
      return this.newAndTrendingListpromise;
    } else {
      return this.newAndTrendingListpromise;
    }
  }
}
