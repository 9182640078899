import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  observable,
  of,
} from 'rxjs';
import {
  Ienvironment,
  EnvironmentService,
} from '@YorbitWorkspace/global-environments';
import { HttpClient } from '@angular/common/http';
import * as fromUserDetailsStore from 'apps/yorbit-ui/src/app/shared/user-details/store';
import { Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MandatoryCourseListService } from '../services/mandatory-course-list.service';
import { AddedContentDataService } from '../services/added-content-data.service';
import { Globals } from '../../globals';
import { LearningPathService } from '../../learning-path/learning-path.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ScormCompletionPopupComponent } from './scorm-player/scorm-completion-popup/scorm-completion-popup.component';

@Injectable({ providedIn: 'root' })
export class VideoPlayersService {
  videosPlaylist: any;
  config: Ienvironment;
  videoDetailsToCaptureProgress: any;
  cacheVideoDetailsToCaptureProgress: any;
  quizPopupOpenStatus: any;
  videoPauseStatus: any;
  introductoryVideoPlayerObj: any;
  completionStatusSubject: any;
  contentOrderCompletionStatusSubject: any;
  completionPopupOpen: any;
  constructor(
    private http: HttpClient,
    private _envSvc: EnvironmentService,
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>,
    private _mandatoryCourseSvc: MandatoryCourseListService,
    private addedContentDataService: AddedContentDataService,
    private globals: Globals,
    private lpService: LearningPathService,
    private _popup: MatDialog
  ) {
    this.config = this._envSvc.getEnvironment();
    this.videosPlaylist = <BehaviorSubject<any>>(
      new BehaviorSubject({ playlist: [], allUnitsLoaded: false })
    );
    this.videoDetailsToCaptureProgress = <BehaviorSubject<any>>(
      new BehaviorSubject(null)
    );
    this.cacheVideoDetailsToCaptureProgress = <BehaviorSubject<any>>(
      new BehaviorSubject(null)
    );
    this.quizPopupOpenStatus = <BehaviorSubject<boolean>>(
      new BehaviorSubject(false)
    );
    this.videoPauseStatus = <BehaviorSubject<boolean>>(
      new BehaviorSubject(false)
    );
    this.completionStatusSubject = new BehaviorSubject<any>([]);
    this.contentOrderCompletionStatusSubject = new BehaviorSubject<any>([]);

    this.introductoryVideoPlayerObj = null;
    this.subscribeToCaptureVideoPorgress();
    this.completionPopupOpen = false;
  }

  getVideosPlaylist() {
    return this.videosPlaylist.asObservable();
  }

  updatePlaylist(videos) {
    this.videosPlaylist.next(videos);
  }

  getPlayBackURL(o365VideoUrl) {
    return this.http.get(o365VideoUrl + '/GetPlaybackUrl(1)');
  }

  getBearerToken(o365VideoUrl) {
    return this.http.get(o365VideoUrl + '/GetStreamingKeyAccessToken');
  }

  updateVideoDetailsToCaptureProgress(obj) {
    this.videoDetailsToCaptureProgress.next(obj);
  }

  updateCachedVideoDetailsToCaptureProgress(currObj) {
    this.quizPopupOpenStatus.subscribe((data) => {
      if (!data) this.cacheVideoDetailsToCaptureProgress.next(currObj);
    });
  }

  getVideoDetailsToCaptureProgress() {
    return this.videoDetailsToCaptureProgress.asObservable();
  }

  captureProgress(pathId, payload) {
    return this.http.put(
      this.config.apiUrl + 'User/Progress/' + pathId,
      payload
    );
  }
  captureProgressNoSubs(pathId, payload) {
    return this.http.put(
      this.config.apiUrl + 'User/Progress/' + pathId,
      payload
    );
  }

  subscribeToCaptureVideoPorgress() {
    this.videoDetailsToCaptureProgress
      .pipe(debounceTime(1000))
      .subscribe((videoDetails) => {
        //console.log("subscribeToCaptureVideoPorgress", videoDetails);
        if (videoDetails != null) {
          let quiPopupSub = this.getQuizPopupOpenStatus().subscribe(
            (isPopupOpen) => {
              if (!isPopupOpen) {
                let payload = {};
                payload['ContentId'] = videoDetails['ContentId'];
                payload['UnitId'] = videoDetails['UnitId'];
                payload['CourseId'] = videoDetails['CourseId'];
                payload['ItemId'] = videoDetails['ItemId'];
                payload['ItemType'] = videoDetails['ItemType'];
                payload['PathId'] = videoDetails['PathId'];
                payload['Expertise'] = videoDetails['Expertise'];
                payload['Time'] = videoDetails['CurrentTime'];
                payload['TotalTime'] = videoDetails['TotalTime'];
                //update lp resume details
                if (
                  payload['UnitId'] != null &&
                  payload['ContentId'] != null &&
                  payload['TotalTime'] != 0
                ) {
                  this.captureProgress(payload['PathId'], payload).subscribe(
                    (apiResponse: any) => {
                      this.updateResumeDetailsInStore(payload);
                      this.updateCourseProgressInStore(
                        videoDetails,
                        payload,
                        apiResponse
                      );
                    }
                  );
                }
                
                
                //if (typeof quiPopupSub !== 'undefined') {
                //  quiPopupSub.unsubscribe();
                //}
              }
            }
          );
        }
      });
  }

  captureCachedProgress() {
    let sub: Subscription;
    const promise = new Promise<boolean>((resolve, reject) => {
      sub = this.cacheVideoDetailsToCaptureProgress
        .pipe(debounceTime(1000))
        .subscribe(
          (videoDetails) => {
            if (videoDetails != null && videoDetails.ContentId) {
              let payload =
                this.constructPayloadToCaptureProgress(videoDetails);
              //update lp resume details
              if (payload['UnitId'] != null && payload['ContentId'] != null) {
                this.captureProgress(payload['PathId'], payload).subscribe(
                  (apiResponse: any) => {
                    this.updateResumeDetailsInStore(payload);
                    this.updateCourseProgressInStore(
                      videoDetails,
                      payload,
                      apiResponse
                    );
                    if (sub) sub.unsubscribe();
                    resolve(true);
                  },
                  (error: any) => {
                    ////console.log('Error', error);
                    if (sub) sub.unsubscribe();
                    resolve(false);
                  }
                );
              }
            } else {
              resolve(false);
            }
          },
          (err) => {
            resolve(false);
          }
        );
    });
    return promise;
  }

  constructPayloadToCaptureProgress(videoDetails) {
    let payload = {};
    payload['ContentId'] = videoDetails['ContentId'];
    payload['UnitId'] = videoDetails['UnitId'];
    payload['CourseId'] = videoDetails['CourseId'];
    payload['ItemId'] = videoDetails['ItemId'];
    payload['ItemType'] = videoDetails['ItemType'];
    payload['PathId'] = videoDetails['PathId'];
    payload['Expertise'] = videoDetails['Expertise'];
    payload['Time'] = videoDetails['CurrentTime'];
    payload['TotalTime'] = videoDetails['TotalTime'];
    return payload;
  }

  updateQuizPopupOpenStatus(isPopupOpen: boolean) {
    this.quizPopupOpenStatus.next(isPopupOpen);
  }

  getQuizPopupOpenStatus() {
    return this.quizPopupOpenStatus.asObservable();
  }
  updateVideoPauseStatus(isPaused: boolean) {
    this.videoPauseStatus.next(isPaused);
  }

  getVideoPauseStatus() {
    return this.videoPauseStatus.asObservable();
  }

  updateResumeDetailsInStore(payload) {
    this.userDetailsStore.dispatch(
      new fromUserDetailsStore.UpdateLPResumeDetails(payload)
    );
  }

  updateCourseProgressInStore(videoDetails, payload, response) {
    let videoProgress = {};
    let CalProgress = 0;
    if (payload['TotalTime'] != 0) {
      CalProgress = Math.ceil((payload['Time'] / payload['TotalTime']) * 100);
    }
    videoProgress['Progress'] = CalProgress;
    videoProgress['ContentId'] = payload['ContentId'];
    videoProgress['CourseId'] = payload['CourseId'];
    videoProgress['CurrentTime'] = payload['Time'];
    //update packageprogress list inside payload[itemid] in store
    // //console.log(
    //   'CalProgress update packageprogress list inside payload[itemid] in store'
    // );
    this.userDetailsStore.dispatch(
      new fromUserDetailsStore.UpdateVideoProgress({
        LPId: videoDetails.PathId,
        ItemId: videoDetails.ItemId,
        ContentId: videoDetails.ContentId,
        VideoProgressDetails: videoProgress,
      })
    );
    //update course progress list in store
    this.userDetailsStore.dispatch(
      new fromUserDetailsStore.UpdateCourseProgress({
        LPId: videoDetails.PathId,
        PackageId:
          videoDetails['ItemType'] == 'Course' ? null : videoDetails.ItemId,
        CourseId: videoDetails.CourseId,
        Progress: response.CourseProgress,
      })
    );
    if (videoDetails['ItemType'] == 'FamilyPackage') {
      this.addedContentDataService.updateCourseProgressInPackage(
        videoDetails.ItemId,
        videoDetails.CourseId,
        response.CourseProgress
      );
    }
  }

  getAESToken(contentId) {
    return this.http.post(
      this.config.apiUrl +
        'AMS/GetAuthTokenUsingVideoId?contentId=' +
        contentId,
      ''
    );
  }

  getPercentageRange(currentTime, totalTime) {
    enum result {
      _25Percent = 0,
      _50Percent = 1,
      _75Percent = 2,
      _90Percent = 3,
      NONE = -1,
    }
    let currtTime = currentTime;
    let _25Percent = Math.trunc((25 / 100) * totalTime);
    let _35Percent = Math.trunc((35 / 100) * totalTime);
    let _50Percent = Math.trunc((50 / 100) * totalTime);
    let _60Percent = Math.trunc((60 / 100) * totalTime);
    let _75Percent = Math.trunc((75 / 100) * totalTime);
    let _85Percent = Math.trunc((85 / 100) * totalTime);
    let _90Percent = Math.trunc((90 / 100) * totalTime);
    let _95Percent = Math.trunc((95 / 100) * totalTime);
    if (
      currentTime >= parseFloat(_25Percent.toString()) &&
      currentTime <= parseFloat(_35Percent.toString())
    ) {
      return result._25Percent;
    } else if (
      currentTime >= parseFloat(_50Percent.toString()) &&
      currentTime <= parseFloat(_60Percent.toString())
    ) {
      return result._50Percent;
    } else if (
      currentTime >= parseFloat(_75Percent.toString()) &&
      currentTime <= parseFloat(_85Percent.toString())
    ) {
      return result._75Percent;
    } else if (
      currentTime >= parseFloat(_90Percent.toString()) &&
      currentTime <= parseFloat(_95Percent.toString())
    ) {
      return result._90Percent;
    } else {
      return result.NONE;
    }
  }

  getAttemptsCount(courseId): number {
    let entityid = courseId.toString();
    let result = 0;
    let attemptsCountSubscription = this.userDetailsStore
      .select(fromUserDetailsStore.getQuizByCourseIdSelector(entityid))
      .subscribe((quiz) => {
        if (attemptsCountSubscription) {
          attemptsCountSubscription.unsubscribe();
        }
        if (quiz && quiz.EntityId) {
          result = quiz.AttemptsCount;
        } else {
          return result;
        }
      });
    return result;
  }

  getProgress(courseId): any {
    let entityid = courseId.toString();
    let result = 0;
    let attemptsCountSubscription = this.userDetailsStore
      .select(
        fromUserDetailsStore.getCourseProgressEntitiesByIdSelector(entityid)
      )
      .subscribe((course) => {
        if (attemptsCountSubscription) {
          attemptsCountSubscription.unsubscribe();
        }
        if (course && course.CourseId) {
          result = parseInt(course.Progress);
        } else {
          return result;
        }
      });
    return result;
  }

  canSeekMandatoryVideo(courseId): boolean {
    let canSeek = false;
    if (
      this._mandatoryCourseSvc.isMandatoryCourse(courseId) &&
      (this.getAttemptsCount(courseId) > 0 || this.getProgress(courseId) >= 50)
    ) {
      canSeek = true;
    } else {
      canSeek = false;
    }
    return canSeek;
  }

  insertContentCompletion(payload) {
    const Url = this.config.apiUrl + 'Course/CaptureCompletionForMultiContent';
    return this.http.post<any>(Url, payload);
  }

  fetchCompletionStatus(psid, courseId): Observable<any> {
    return this.http.get<any>(
      this.config.apiUrl +
        'Course/GetMultiContentCompletion/' +
        psid +
        '/' +
        courseId
    );
  }

  getCompletionStatus() {
    return this.completionStatusSubject.asObservable();
  }

  updateCompletionStatus(contentsCompletion: any) {
    this.completionStatusSubject.next(contentsCompletion);
  }

  getContentOrderCompletionStatusForQuiz() {
    return this.contentOrderCompletionStatusSubject.asObservable();
  }

  updateContentOrderCompletionStatusForQuiz(contentsDetails: any) {
    this.contentOrderCompletionStatusSubject.next(contentsDetails);
  }
  checkMultiContentCourse(courseId) {
    const Url = this.config.apiUrl + 'Course/GetMultiContentSPData/' + courseId;
    return this.http.get<any>(Url);
  }

  updateCourseProgressForMultiContent(
    LPBreadcrumbs: any,
    multiContentCompletion: any,
    contentHasOrder,
    contentHasQuiz
  ) {
    this.checkMultiContentCourse(
      LPBreadcrumbs.CourseDetails.CourseId.toString()
    ).subscribe((courseId) => {
      let badgeId;
      let badgeStatus;
      let userDetails$ = this.userDetailsStore.select(
        fromUserDetailsStore.getUserDetailObject
      );
      userDetails$.subscribe((UserDetails) => {
        if (UserDetails.id !== '') {
          UserDetails.Achievements.Badges.filter((course) => {
            if (course.CourseId == LPBreadcrumbs.CourseDetails.CourseId) {
              badgeId = course.BadgeId;
              badgeStatus = course.Status == 'true' ? true : false;
            }
          });
        }
      });
      let totalContent = 0;
      let totalContentCompletion = 0;
      for (let i = 0; i < multiContentCompletion.length; i++) {
        for(let j = 0; j < multiContentCompletion[i].length; j++) {
          if (multiContentCompletion[i][j].Completion) {
            totalContentCompletion++;
          }
          totalContent++;
        }
      }
      if (courseId && courseId != "") {
        let status = 'Course In-Progress';
        if (totalContent == totalContentCompletion && ((!contentHasOrder && !contentHasQuiz) || (contentHasOrder && !contentHasQuiz))) {
          status = 'Course Completed';
        }
        this.userDetailsStore.dispatch({
          type: '[UserDetails] Update WorkflowStatus',
          payload: {
            WorflowStatus: status,
            ItemId: LPBreadcrumbs.CourseDetails.CourseId.toString(),
            RequestId: null,
            PackageId: LPBreadcrumbs.PackageDetails.PackageId,
            LPId: LPBreadcrumbs.LPDetails.LPId,
            IsLPMandatory: false,
            ItemExpertise: LPBreadcrumbs.CourseDetails.Expertise,
          },
        });
        if (!badgeStatus && status == 'Course Completed') {
          this.courseCompletionPopup(LPBreadcrumbs);
        }
        if (
          -1 ==
          this.globals.scormResumeIds.indexOf(
            LPBreadcrumbs.CourseDetails.CourseId.toString()
          )
        ) {
          this.globals.scormResumeIds.push(
            LPBreadcrumbs.CourseDetails.CourseId.toString()
          );
        }
      } else {
        // let badgeId;
        // let badgeStatus;
        // let userDetails$ = this.userDetailsStore.select(
        //   fromUserDetailsStore.getUserDetailObject
        // );
        // userDetails$.subscribe((UserDetails) => {
        //   if (UserDetails.id !== '') {
        //     UserDetails.Achievements.Badges.filter((course) => {
        //       if (
        //         course.CourseId == LPBreadcrumbs.CourseDetails.CourseId
        //       ) {
        //         badgeId = course.BadgeId;
        //         badgeStatus = course.Status;
        //       }
        //     });
        //   }
        // });
        if (totalContent == totalContentCompletion && !badgeStatus && ((!contentHasOrder && !contentHasQuiz) || (contentHasOrder && !contentHasQuiz))) {
          let courseDetails = {
            IsAccount: LPBreadcrumbs.CourseDetails.IsAccount,
            IsProject: LPBreadcrumbs.CourseDetails.IsProject,
            AccountId: LPBreadcrumbs.CourseDetails.AccountId,
            ProjectId: LPBreadcrumbs.CourseDetails.ProjectId,
            entityType: 'course',
            courseId: LPBreadcrumbs.CourseDetails.CourseId,
          };
          this.lpService
            .quizAvailabilityCheck(courseDetails)
            .then((response: any) => {
              if (response != null) {
                this.userDetailsStore.dispatch(
                  new fromUserDetailsStore.AddQuizDetails(response)
                );
              } else {
                this.userDetailsStore.dispatch(
                  new fromUserDetailsStore.UpdateAchievements({
                    badgeId: badgeId,
                    badgeStatus: true,
                    //badgeImage: badgeImageURL,
                    quizScore: null,
                  })
                );
                this.courseCompletionPopup(LPBreadcrumbs);
              }
            });
        }
      }
    });
  }

  updateFetchCompletionStatus(completionContents, LPBreadcrumbs, contentId) {
    let sub: Subscription;
    const promise = new Promise<any>((resolve, reject) => {
    if (
      completionContents &&
      this.globals.MId &&
      LPBreadcrumbs.CourseDetails.CourseId &&
      contentId
    ) {
      this.fetchCompletionStatus(
        this.globals.MId,
        LPBreadcrumbs.CourseDetails.CourseId.toString()
        ).subscribe({ next: (res) => {
          let contents = res;
          let currentContentCompletionId = null;
          let totalContents = res.TotalContents;
          let totalContentCompletion = 0;
          if (contents.ContentCompletionStatus.find((x) => x.Id == contentId)) {
            for (let i = 0; i < completionContents.length; i++) {
            let nextContent = 0;
              for(let j = 0; j < completionContents[i].length; j++) {
                if (
                  completionContents[i][j].ContentId == contentId &&
                  completionContents[i][j].Completion == false
                ) {
                  completionContents[i][j].Completion = true;
                  completionContents[i][j].IsContentEnabled = 1;
                  currentContentCompletionId = completionContents[i][j].ContentId;
                  nextContent++;
                } else if (
                  completionContents[i][j].Completion == false &&
                  nextContent > 0
                ) {
                  completionContents[i][j].IsContentEnabled = 1;
                  break;
                }
                if(completionContents[i][j].Completion) {
                  totalContentCompletion++;
                }
              }
            }
            this.updateCompletionStatus(completionContents);
            this.updateCourseProgressForMultiContent(
              LPBreadcrumbs,
              completionContents,
              contents.HasOrder,
              contents.HasQuiz
            );
            if(contents.HasOrder && contents.HasQuiz) {
              this.updateContentOrderCompletionStatusForQuiz(totalContents == totalContentCompletion);
            }
            resolve({
              Response: true,
              HasOrder: contents.HasOrder,
              HasQuiz: contents.HasQuiz,
              ContentId: currentContentCompletionId
            });
          } else {
            resolve({
              Response: false,
              HasOrder: contents.HasOrder,
              HasQuiz: contents.HasQuiz,
              ContentId: currentContentCompletionId
            });
          }
        }, error: (err)=>{
          reject(err);
        } 
      });
    }
  });
  return promise;
  }

  courseCompletionPopup(LPBreadcrumbs) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    dialogConfig.data = {
      title: Object.assign({}, LPBreadcrumbs.CourseDetails).CourseName,
      courseCompletion: true,
    };
    if (!this.completionPopupOpen) {
      this.completionPopupOpen = true;
      let dialogPopup = this._popup.open(
        ScormCompletionPopupComponent,
        dialogConfig
      );
      dialogPopup.afterClosed().subscribe((res) => {
        this.completionPopupOpen = false;
      });
    }
  }
}
