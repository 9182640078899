import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ContentUploadPopupService } from './content-upload-popup.service';
import { Conentupload101Service } from '../content-upload-101/conentupload-101.service';

@Component({
  selector: 'yorbit-content-upload-popup',
  templateUrl: './content-upload-popup.component.html',
  styleUrls: ['./content-upload-popup.component.scss']
})
export class ContentUploadPopupComponent implements OnInit {
  formData: any;
  courseUniqueId: any;
  msg: any;
  keys: any;
  values: any;
  basicCourseDetails: any;
  operationalCourseDetails: any;
  isDataAvailable: boolean;
  courseLoginType: any;
  courseLoginList: any;
  modeOfPaymentList: any;
  courseEligibilityList: any;
  courseAccessibilityList: any;
  courseClassificationList: any;
  courseLoginDetails: any;
  learningMaterialDetails: any;
  infrastructureDetails: any;
  courseCompletionDetails: any;
  classRoomDetails: any;
  projectWorkDetails: any;
  assessmentDetails: any;
  quizDetails: any
  courseprocedurerefdocumentDetails: any;

  constructor(
    public dialogRef: MatDialogRef<ContentUploadPopupComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private _contentUpload101Service: Conentupload101Service,
    private contentUploadPopupService: ContentUploadPopupService
  ) {
    this.formData = data.data;
    this.courseLoginType = data.courseLoginType;
    this.courseLoginList = data.courseLoginList;
    this.modeOfPaymentList = data.paymentModeList;
    this.courseClassificationList = data.courseClassificationList;
    this.courseAccessibilityList = data.courseAccessibilityList;
    this.courseEligibilityList = data.courseEligibilityList;
    this.courseUniqueId = data.courseUniqueId;

    this.msg = 'This works !';
    this.isDataAvailable = false;
    this.basicCourseDetails = [];
    this.operationalCourseDetails = [];
  }

  ngOnInit() {
    ////console.log(this.formData);

    this.keys = Object.keys(this.formData);
    this.values = Object.values(this.formData);
    if (this.keys.length > 0) {
      this.isDataAvailable = true;
      let iterator = 0;
      this.keys.forEach(element => {
        const tabId = this.contentUploadPopupService.getTabId(element);
        const label = this.contentUploadPopupService.getLabel(element, tabId);
        //console.log(element);
        if (tabId === 0 && label !== '') {
          const value = this.getValues(this.values[iterator++], tabId, element);
          if (label.toLowerCase() == 'pre-requisite course ids') {
            this.basicCourseDetails.push({
              label: 'Pre-Requisite Course ID(s)',
              value: value,
              tabId: tabId
            });
          } else if (label.toLowerCase() == 'post recommended course ids') {
            this.basicCourseDetails.push({
              label: 'Post Recommended Course ID(s)',
              value: value,
              tabId: tabId
            });
          } else if (label.toLowerCase() == 'expert mids') {
            this.basicCourseDetails.push({
              label: 'Expert PSID(s)',
              value: value,
              tabId: tabId
            });
          } else if (label.toLowerCase() == 'contentowner') {
            this.basicCourseDetails.push({
              label: 'Content Partner',
              value: value,
              tabId: tabId
            });
          } else if (label.toLowerCase() == 'price for the course') {
            this.basicCourseDetails.push({
              label: 'Course Price',
              value: value,
              tabId: tabId
            });
          }else if (label.toLowerCase() == 'SkillIds') {
            this.basicCourseDetails.push({
              label: 'Skill Prism ID',
              value: value,
              tabId: tabId
            });
          }else if (label.toLowerCase() == 'is course internal?') {
          }else if (label == 'IsCertificateAvailable?') {
            this.basicCourseDetails.push({
              label: 'Course Completion Certificate to be generated by Shoshin School ?',
              value: value == "1" ? "Yes" : "No",
              tabId: tabId
            });
          } else if (label == 'IsCloudLabAvailable?') {
            this.basicCourseDetails.push({
              label: 'Is Cloud Lab Available?',
              value: value == "1" ? "Yes" : "No",
              tabId: tabId
            });
          }
          else {
            // let restrictedLabels = ['CONTENTOWNER'];
            // if(!restrictedLabels.includes(label.toUpperCase())){
            this.basicCourseDetails.push({
              label: label,
              value: value,
              tabId: tabId
            });
            //  }
          }

        } else if (tabId === 1 && label !== '') {
          const value = this.getValues(this.values[iterator++], tabId, element);
          if (
            label !== 'currency' &&
            label !== 'contentOrder' &&
            label !== 'certificateLink' &&
            label !== 'badgeLink' &&
            label !== 'sreenShotLink' &&
            label !== 'letterHeadLink' &&
            label !== 'badgeId'
          ) {
            this.operationalCourseDetails.push({
              label: label,
              value: value,
              tabId: tabId
            });
          }
        } else if ((tabId === 0 || tabId === 1) && label == '') {
          iterator++;
        }
      });
      //console.log('.........................',this.operationalCourseDetails);
    } else {
      this.isDataAvailable = false;
    }
    //console.log(this.basicCourseDetails);
  }

  getValues(value, tabId, fieldLabel) {
    if (tabId === 0) {
      if (value === true || value === 'true') return 'Yes';
      else if (value === false || value === 'false') {
        if (fieldLabel.toLowerCase() == 'isIntroductoryVideo') {
          return 'Not Available';
        }
        return 'No';
      } else {
        if (fieldLabel == 'IsCloudLabAvailable') {
          return value == "1" ? "Yes" : "No"
        }
        else if (fieldLabel == "IsCertificateAvailable") {
          return value == "1" ? "Yes" : "No"
        }
        else if (fieldLabel == 'duration') {
          return value + ' hrs';
        } else if (fieldLabel == 'price' && this.formData.currency) {
          return value + ' ' + this.formData.currency;
        } else if (fieldLabel == 'paymentMode' && this.formData.paymentMode) {
          let mode = this.modeOfPaymentList.filter(
            row => row.Id == this.formData.paymentMode
          )[0].Mode;
          return this.formData.paid == 'Yes' ? mode : 'Not Available';
        } else if (fieldLabel === 'eligibility') {
          let eligibleGroup = this.courseEligibilityList.filter(
            row => row.Id == this.formData.eligibility
          )[0].EligibleGroup;
          return eligibleGroup;
        } else if (fieldLabel === 'accessibility') {
          let accessGroup = this.courseAccessibilityList.filter(
            row => row.Id == this.formData.accessibility
          )[0].Access;
          return accessGroup;
        } else if (fieldLabel === 'classification') {
          let classification = this.courseClassificationList.filter(
            row => row.Id == this.formData.classification
          )[0].Classification;
          return classification;
        } else if (fieldLabel === 'isIntroductoryVideo') {
          if (value == 'No') {
            return 'Not Available';
          } else {
            return 'Available';
          }
        } else if (fieldLabel === 'sourceType') {
          if (value && value.toLowerCase() == 'o365 video') {
            return 'Azure Media Services';
          }
        }
        if (value !== null && value !== '') return value.toString().trim();
        else return value;
      }
    } else if (tabId === 1) {
      if (fieldLabel === 'isCourseProcedureReferenceDocument') {

        // console.log('labellll................',this.formData.courseProcedureRefDocGroup);
      }
      if (fieldLabel === 'courseProcedureRefDocGroup') {
        // console.log('labellll................',this.formData.courseProcedureRefDocGroup);
      }
      if (fieldLabel === 'isInfrastructure') {
        let infraTypeId;
        let infrastructureList = [];
        this.formData.infrastructure.forEach((type, key) => {
          if (type === true || type === 'true') {
            infraTypeId = key;
            switch (infraTypeId) {
              case 0:
                infrastructureList.push('Internal Cloud Lab');
                break;
              case 1:
                infrastructureList.push('Vendor Provided Cloud Lab');
                break;
              case 2:
                infrastructureList.push('White Listed Software');
                break;
              case 3:
                infrastructureList.push('Genie Request Software');
                break;
              case 4:
                infrastructureList.push('Not Available');
                break;
            }
          }
        });
        this.constructInfrastructureDetails(infrastructureList);
        return infrastructureList.length > 0
          ? infrastructureList
          : 'Not Available';
      }
      if (fieldLabel === 'loginType') {
        if (this.formData.isLoginRequired) {
          let loginTypeId;
          this.formData.loginType.forEach((type, key) => {
            if (type) {
              loginTypeId = key + 1;
            }
          });
          switch (loginTypeId) {
            case 1:
              return 'No Login Required';
            case 2:
              this.constructCourseLoginDetails(loginTypeId);
              return 'Learner to Self Register and make Payment';
            case 3:
              this.constructCourseLoginDetails(loginTypeId);
              return 'Learning OPM Team to Provide Credentials';
            case 4:
              return 'Learning Business Partner to Provide Credentials';
            default:
              return 'Not Available';
          }
        } else return 'Not Available';
      } else if (fieldLabel === 'isLearningMaterial') {
        if (this.formData.isLearningMaterial.toLowerCase() === 'yes') {
          this.constructLearningMaterialDetails('yes');
          return 'Available';
        } else if (this.formData.isLearningMaterial.toLowerCase() === 'no') {
          this.constructLearningMaterialDetails('no');
          return 'Not Available';
        } else {
          return '';
        }
      } else if (fieldLabel === 'isCourseProcedureReferenceDocument') {
        if (this.formData.isCourseProcedureReferenceDocument.toLowerCase() === 'yes') {
          this.constructCourseProcedureReferenceDocument('yes');
          return 'Available';
        } else if (this.formData.isCourseProcedureReferenceDocument.toLowerCase() === 'no') {
          this.constructCourseProcedureReferenceDocument('no');
          return 'Not Available';
        } else {
          return '';
        }
      }
      else if (fieldLabel === 'courseCompletionGroup') {
        let proofId = -1;
        let courseCompletionProofList = [];
        this.formData.courseCompletionGroup.courseCompletionFormArray.forEach(
          (type, key) => {
            if (type === true || type === 'true') {
              proofId = key;
              switch (proofId) {
                case 0:
                  courseCompletionProofList.push('Certificate Upload');
                  break;
                case 1:
                  courseCompletionProofList.push('Course Completion Badge');
                  break;
                case 2:
                  courseCompletionProofList.push('Screen Shot');
                  break;
                case 3:
                  courseCompletionProofList.push('Letter Head');
                  break;
                case 4:
                  courseCompletionProofList.push('SME Evaluation Upload');
                  break;
              }
            }
          }
        );
        this.constructCourseCompletionDetails(courseCompletionProofList);
        return courseCompletionProofList;
      } else if (fieldLabel === 'classRoomGroup') {
        if (
          this.formData.classRoomGroup.isClassRoomRequired.toLowerCase() ===
          'yes'
        ) {
          this.constructClassRoomDetails();
          return 'Available';
        } else if (
          this.formData.classRoomGroup.isClassRoomRequired.toLowerCase() ===
          'no'
        ) {
          this.classRoomDetails = null;
          return 'Not Available';
        } else {
          this.classRoomDetails = null;
          return '';
        }
      } else if (fieldLabel === 'projectGroup') {
        if (this.formData.projectGroup.hasProjectWork.toLowerCase() === 'yes') {
          this.constructProjectDetails();
          return 'Available';
        } else if (
          this.formData.projectGroup.hasProjectWork.toLowerCase() === 'no'
        ) {
          this.projectWorkDetails = null;
          return 'Not Available';
        } else {
          return '';
        }
      } else if (fieldLabel === 'assessmentGroup') {
        if (
          this.formData.assessmentGroup.assessmentRadio.toLowerCase() === 'yes'
        ) {
          this.constructAssessmentDetails();
          return 'Available';
        } else if (
          this.formData.assessmentGroup.assessmentRadio.toLowerCase() === 'no'
        ) {
          this.assessmentDetails = null;
          return 'Not Available';
        } else {
          this.assessmentDetails = null;
          return '';
        }
      } else if (fieldLabel === 'quizGroup') {
        if (
          this.formData.quizGroup.quizRadio.toLowerCase() === 'yes'
        ) {
          this.constructQuizDetails()
          return 'Available';
        } else if (
          this.formData.quizGroup.quizRadio.toLowerCase() === 'no'
        ) {
          this.quizDetails = null;
          return 'Not Available';
        } else {
          this.quizDetails = null;
          return '';
        }
      } else {
        return '';
      }
    }
  }

  close(canSubmit) {
    this.dialogRef.close(canSubmit);
  }

  // drop(event: CdkDragDrop<string[]>) {
  //   moveItemInArray(this.movies, event.previousIndex, event.currentIndex);
  //   //console.log(this.movies);
  // }
  constructCourseLoginDetails(type) {
    switch (type) {
      case 1:
        this.courseLoginDetails = null;
        break;
      case 2:
        const linktoCoursesForSelfRegistration = this.formData
          .linktoCoursesForSelfRegistration;
        const coursesNameForSelfRegistration = this.formData
          .coursesNameForSelfRegistration;
        this.courseLoginDetails = {
          courseLink: linktoCoursesForSelfRegistration,
          courseName: coursesNameForSelfRegistration,
          courseType: type
        };
        break;
      case 3:
        const loginDetails = this.formData.loginDetails;
        const linktoCoursesForLearningOPMTeam = this.formData
          .linktoCoursesForLearningOPMTeam;
        const coursesNameForLearningOPMTeam = this.formData
          .coursesNameForLearningOPMTeam;
        this.courseLoginDetails = {
          loginDetails: loginDetails,
          courseLink: linktoCoursesForLearningOPMTeam,
          courseName: coursesNameForLearningOPMTeam,
          courseType: type
        };
        break;
      case 4:
        this.courseLoginDetails = null;
        break;
    }
  }

  constructLearningMaterialDetails(isAvailable) {
    if (isAvailable === 'yes') {
      let presessionText = this.formData.preSessionGroup
        .preSessionLearningMaterialTextBox;
      let duringsessionText = this.formData.duringSessionGroup
        .duringSessionLearningMaterialTextBox;
      let postsessionText = this.formData.postSessionGroup
        .postSessionLearningMaterialTextBox;

      this.learningMaterialDetails = {
        preSessionTextBox:
          presessionText != '' && presessionText != null
            ? presessionText
            : null,
        preSessionFile:
          presessionText != '' && presessionText != null
            ? this.formData.preSessionGroup.preSessionLearningMaterialLink
            : null,
        duringSessionTextBox:
          duringsessionText != '' && duringsessionText != null
            ? duringsessionText
            : null,
        duringSessionFile:
          duringsessionText != '' && duringsessionText != null
            ? this.formData.duringSessionGroup.duringSessionLearningMaterialLink
            : null,
        postSessionTextBox:
          postsessionText != '' && postsessionText != null
            ? postsessionText
            : null,
        postSessionFile:
          postsessionText != '' && postsessionText != null
            ? this.formData.postSessionGroup.postSessionLearningMaterialLink
            : null
      };
    } else {
      this.learningMaterialDetails = null;
    }
  }
  constructInfrastructureDetails(infraList) {
    this.infrastructureDetails = [];
    if (infraList.length > 0) {
      let infraTypeId;
      this.formData.infrastructure.forEach((type, key) => {
        if (type === true || type === 'true') {
          infraTypeId = key;
          switch (infraTypeId) {
            case 0:
              this.infrastructureDetails.push({
                label: 'Internal Cloud Lab',
                value: this.formData.infrastructureType[0]
                  ? this.formData.infrastructureType[0]
                  : 'Not Available'
              });
              break;
            case 1:
              this.infrastructureDetails.push({
                label: 'Vendor Provided Cloud Lab',
                value: this.formData.infrastructureType[1]
                  ? this.formData.infrastructureType[1]
                  : 'Not Available'
              });
              break;
            case 2:
              this.infrastructureDetails.push({
                label: 'Name of White Listed Software',
                value: this.formData.infrastructureType[2]
                  ? this.formData.infrastructureType[2]
                  : 'Not Available'
              });
              this.infrastructureDetails.push({
                label: 'Link to download white listed software',
                value: this.formData.infrastructureType[3]
                  ? this.formData.infrastructureType[3]
                  : 'Not Available'
              });
              break;
            case 3:
              this.infrastructureDetails.push({
                label: 'Name of Genie Request Software ',
                value: this.formData.infrastructureType[4]
                  ? this.formData.infrastructureType[4]
                  : 'Not Available'
              });
              this.infrastructureDetails.push({
                label: 'Version of  Genie Request Software ',
                value: this.formData.infrastructureType[5]
                  ? this.formData.infrastructureType[5]
                  : 'Not Available'
              });
              break;
            case 4:
              // this.infrastructureDetails.push({
              //   label: 'Infrastructure Details ',
              //   value: 'Not Available'
              // });
              break;
          }
        }
      });
    }
  }

  constructCourseCompletionDetails(courseCompletionProofList) {
    this.courseCompletionDetails = [];
    if (courseCompletionProofList.length > 0) {
      let infraTypeId;
      const courseCompletionGroup = this.formData.courseCompletionGroup;
      courseCompletionGroup.courseCompletionFormArray.forEach((type, key) => {
        if (type === true || type === 'true') {
          infraTypeId = key;
          switch (infraTypeId) {
            case 0:
              this.courseCompletionDetails.push({
                label: 'No. of course completion certificate(s)',
                value: courseCompletionGroup.courseCompletionTypeFormArray[0]
                  ? courseCompletionGroup.courseCompletionTypeFormArray[0]
                  : 'Not Available'
              });
              this.courseCompletionDetails.push({
                label: 'Certificate Description',
                value: courseCompletionGroup.courseCompletionTypeFormArray[1]
                  ? courseCompletionGroup.courseCompletionTypeFormArray[1]
                  : 'Not Available'
              });
              this.courseCompletionDetails.push({
                label: 'Sample Certificate',
                value: courseCompletionGroup.courseCompletionTypeFormArray[2]
                  ? courseCompletionGroup.courseCompletionTypeFormArray[2]
                  : (this.formData.courseCompletionGroup.courseCompletionTypeFormArrayForPreview[2] ? this.formData.courseCompletionGroup.courseCompletionTypeFormArrayForPreview[2] : 'Not Available')
              });
              break;
            case 1:
              this.courseCompletionDetails.push({
                label: 'No. of Badge(s)',
                value: courseCompletionGroup.courseCompletionTypeFormArray[3]
                  ? courseCompletionGroup.courseCompletionTypeFormArray[3]
                  : 'Not Available'
              });
              this.courseCompletionDetails.push({
                label: 'Badge Description',
                value: courseCompletionGroup.courseCompletionTypeFormArray[4]
                  ? courseCompletionGroup.courseCompletionTypeFormArray[4]
                  : 'Not Available'
              });
              this.courseCompletionDetails.push({
                label: 'Sample Badge',
                value: courseCompletionGroup.courseCompletionTypeFormArray[5]
                  ? courseCompletionGroup.courseCompletionTypeFormArray[5]
                  : (this.formData.courseCompletionGroup.courseCompletionTypeFormArrayForPreview[5] ? this.formData.courseCompletionGroup.courseCompletionTypeFormArrayForPreview[5] : 'Not Available')
              });
              break;
            case 2:
              this.courseCompletionDetails.push({
                label: 'No. of Screenshot(s)',
                value: courseCompletionGroup.courseCompletionTypeFormArray[6]
                  ? courseCompletionGroup.courseCompletionTypeFormArray[6]
                  : 'Not Available'
              });
              this.courseCompletionDetails.push({
                label: 'Screenshot Description',
                value: courseCompletionGroup.courseCompletionTypeFormArray[7]
                  ? courseCompletionGroup.courseCompletionTypeFormArray[7]
                  : 'Not Available'
              });
              this.courseCompletionDetails.push({
                label: 'Sample Screenshot',
                value: courseCompletionGroup.courseCompletionTypeFormArray[8]
                  ? courseCompletionGroup.courseCompletionTypeFormArray[8]
                  : (this.formData.courseCompletionGroup.courseCompletionTypeFormArrayForPreview[8] ? this.formData.courseCompletionGroup.courseCompletionTypeFormArrayForPreview[8] : 'Not Available')
              });
              break;
            case 3:
              this.courseCompletionDetails.push({
                label: 'No. of Letter Head(s)',
                value: courseCompletionGroup.courseCompletionTypeFormArray[9]
                  ? courseCompletionGroup.courseCompletionTypeFormArray[9]
                  : 'Not Available'
              });
              this.courseCompletionDetails.push({
                label: 'Letter Head Description',
                value: courseCompletionGroup.courseCompletionTypeFormArray[10]
                  ? courseCompletionGroup.courseCompletionTypeFormArray[10]
                  : 'Not Available'
              });
              this.courseCompletionDetails.push({
                label: 'Sample Letter Head',
                value: courseCompletionGroup.courseCompletionTypeFormArray[11]
                  ? courseCompletionGroup.courseCompletionTypeFormArray[11]
                  : (this.formData.courseCompletionGroup.courseCompletionTypeFormArrayForPreview[11] ? this.formData.courseCompletionGroup.courseCompletionTypeFormArrayForPreview[11] : 'Not Available')
              });
              break;
          }
        }
      });
    }
  }

  constructClassRoomDetails() {
    this.classRoomDetails = null;
    const classRoomGroup = this.formData.classRoomGroup;
    this.classRoomDetails = {
      classRoomExecution: {
        label: 'Classroom Execution',
        value:
          classRoomGroup.classroomExecution === '1' ||
            classRoomGroup.classroomExecution === 1
            ? 'Managed by Vendor'
            : 'Managed by Learning OPM Team'
      },
      classrooms: []
    };
    classRoomGroup.classRoom.forEach((value, key) => {
      let details = [];
      if (
        value.nameOfClassRoomSessions !== null &&
        value.nameOfClassRoomSessions !== ''
      ) {
        const elm = {
          label: 'Name of Classroom Session',
          value: value.nameOfClassRoomSessions
        };
        details.push(elm);
      }
      if (
        value.classroomDescription !== null &&
        value.classroomDescription !== ''
      ) {
        const elm = {
          label: 'Classroom Description',
          value: value.classroomDescription
        };
        details.push(elm);
      }
      // if (
      //   value.vendorOrFaultyName !== null &&
      //   value.vendorOrFaultyName !== ''
      // ) {
      //   const elm = {
      //     label: 'Name of Vendor/Faculty',
      //     value: value.vendorOrFaultyName
      //   };
      //   details.push(elm);
      // }
      // if (value.isAssignmentRequired === 'Yes') {
      //   const elm_1 = {
      //     label: 'Assignment?',
      //     value: 'Available'
      //   };
      //   details.push(elm_1);
      //   if (
      //     value.assignmentDescription !== null &&
      //     value.assignmentDescription !== ''
      //   ) {
      //     const elm = {
      //       label: 'Assignment Description',
      //       value: value.assignmentDescription
      //     };
      //     details.push(elm);
      //   }
      //   if (
      //     (value.assignmentContentLink !== null && value.assignmentContentLink !== '') ||
      //     (value.assignmentContent !== null && value.assignmentContent !== '')
      //   ) {
      //     const elm = {
      //       label: 'Assignment Problem Statement',
      //       value: value.assignmentContent ? value.assignmentContent : (value.assignmentContentLink ? value.assignmentContentLink : 'Not Available')
      //     }
      //     details.push(elm);
      //   }
      // }else{
      //   const elm = {
      //     label: 'Assignment?',
      //     value: 'Not Available'
      //   };
      //   details.push(elm);
      // }
      if (value.isAssignmentRequired === 'Yes' && value.isEvaluationRequired === 'Yes') {
        if (value.evaluatorName !== null && value.evaluatorName !== '') {
          const elm = { label: "Evaluator's Name", value: value.evaluatorName };
          details.push(elm);
        }
        if (value.evaluatorEmailID !== null && value.evaluatorEmailID !== '') {
          const elm = {
            label: "Evaluator's Email Id",
            value: value.evaluatorEmailID
          };
          details.push(elm);
        }
        if (value.evaluatorVendor !== null && value.evaluatorVendor !== '') {
          const elm = {
            label: "Evaluator's Vendor Name",
            value: value.evaluatorVendor
          };
          details.push(elm);
        }
        if (value.evaluatorType !== null && value.evaluatorType !== '') {
          const elm = { label: 'Evaluator Type', value: value.evaluatorType };
          details.push(elm);
        }
        if (
          value.locationofEvaluator !== null &&
          value.locationofEvaluator !== ''
        ) {
          const elm = {
            label: 'Location of Evaluator',
            value: value.locationofEvaluator
          };
          details.push(elm);
        }
        if (
          (value.evaluationTemplateLink !== null &&
            value.evaluationTemplateLink !== '') || (value.evaluationTemplate !== null &&
              value.evaluationTemplate !== '')
        ) {
          const elm = {
            label: 'Evaluation Template for Assignment',
            value: value.evaluationTemplateLink ? value.evaluationTemplateLink : (value.evaluationTemplate ? value.evaluationTemplate : 'Not Available')
          };
          details.push(elm);
        }
        if (value.maxScore !== null && value.maxScore !== '') {
          const elm = { label: 'Maximum Score', value: value.maxScore };
          details.push(elm);
        }
        if (value.passingScore !== null && value.passingScore !== '') {
          const elm = { label: 'Passing Score', value: value.passingScore };
          details.push(elm);
        }
      }
      // if (value.isClassroomScheduled === "Yes") {
      //   if (value.classroomScheduledDateTime !== null && value.classroomScheduledDateTime !== '') {
      //     const elm = { 'label': 'Classroom Schedule', 'value': value.passingScore }
      //     details.push(elm);
      //   }
      // }
      this.classRoomDetails.classrooms.push(details);
    });
  }

  constructProjectDetails() {
    this.projectWorkDetails = null;
    const projectGroup = this.formData.projectGroup;
    this.projectWorkDetails = {
      isJumbled: {
        label: 'Jumble Project Problem Statement',
        value: projectGroup.hasJumbleProject
      },
      projectDetails: [],
      evaluatorDetails: []
    };
    projectGroup.projectWorkDetails.forEach((value, key) => {
      let projectWorkDetails = [];
      projectWorkDetails.push({
        label: 'Project Description',
        value:
          value.projectDescription !== null && value.projectDescription !== ''
            ? value.projectDescription
            : 'Not Available'
      });
      projectWorkDetails.push({
        label: 'Maximum Score',
        value:
          value.maxScore !== null && value.maxScore !== ''
            ? value.maxScore
            : 'Not Available'
      });
      projectWorkDetails.push({
        label: 'Passing Score',
        value:
          value.passingScore !== null && value.passingScore !== ''
            ? value.passingScore
            : 'Not Available'
      });
      projectWorkDetails.push({
        label: 'Does Course have Demo?',
        value:
          value.projectDemo !== null && value.projectDemo !== ''
            ? value.projectDemo
            : 'Not Available'
      });
      projectWorkDetails.push({
        label: 'Project Reference Material',
        value:
          value.projectMaterial !== null && value.projectMaterial !== ''
            ? value.projectMaterial
            : ((value.projectMaterialLink !== null && value.projectMaterialLink !== '') ? value.projectMaterialLink : 'Not Available')
      });
      projectWorkDetails.push({
        label: 'Problem Statement',
        value:
          value.problemStatement !== null && value.problemStatement !== ''
            ? value.problemStatement
            : ((value.problemStatementLink !== null && value.problemStatementLink !== '') ? value.problemStatementLink : 'Not Available')
      });
      projectWorkDetails.push({
        label: 'Evaluation Template for Project',
        value:
          value.evaluationTemplate !== null && value.evaluationTemplate !== ''
            ? value.evaluationTemplate
            : ((value.evaluationTemplateLink !== null && value.evaluationTemplateLink !== '') ? value.evaluationTemplateLink : 'Not Available')
      });
      this.projectWorkDetails.projectDetails.push(projectWorkDetails);
    });
    if (projectGroup.hasProjectEvaluation === 'Yes') {
      projectGroup.projectEvaluationDetails.forEach((value, key) => {
        let projectEvaluationDetails = [];
        projectEvaluationDetails.push({
          label: "Evaluator's Name",
          value:
            value.evaluatorName !== null && value.evaluatorName !== ''
              ? value.evaluatorName
              : 'Not Available'
        });
        projectEvaluationDetails.push({
          label: "Evaluator's Email Id",
          value:
            value.evaluatorEmailID !== null && value.evaluatorEmailID !== ''
              ? value.evaluatorEmailID
              : 'Not Available'
        });
        projectEvaluationDetails.push({
          label: 'Evaluator Vendor',
          value:
            value.evaluatorVendor !== null && value.evaluatorVendor !== ''
              ? value.evaluatorVendor
              : 'Not Available'
        });
        projectEvaluationDetails.push({
          label: 'Evaluator Type',
          value:
            value.evaluatorType !== null && value.evaluatorType !== ''
              ? value.evaluatorType
              : 'Not Available'
        });
        projectEvaluationDetails.push({
          label: 'Location of Evaluator',
          value:
            value.locationofEvaluator !== null &&
              value.locationofEvaluator !== ''
              ? value.locationofEvaluator
              : 'Not Available'
        });
        this.projectWorkDetails.evaluatorDetails.push(projectEvaluationDetails);
      });
    }
  }
  constructAssessmentDetails() {
    this.assessmentDetails = null;
    const assessmentGroup = this.formData.assessmentGroup;
    this.assessmentDetails = {
      assessmentInfrastructure: [],
      evaluatorList: [],
      otherDetails: []
    };

    this.assessmentDetails.otherDetails.push({
      label: 'Assessment Description',
      value:
        assessmentGroup.assessmentDescription !== null &&
          assessmentGroup.assessmentDescription !== ''
          ? assessmentGroup.assessmentDescription
          : 'Not Available'
    });

    this.assessmentDetails.otherDetails.push({
      label: 'Assessment Type',
      value:
        assessmentGroup.assessmentType !== null &&
          assessmentGroup.assessmentType !== ''
          ? assessmentGroup.assessmentType
          : 'Not Available'
    });

    if (
      assessmentGroup.assessmentType === 'Offline' ||
      assessmentGroup.assessmentType === 'Both'
    ) {
      this.assessmentDetails.otherDetails.push({
        label: 'Question Paper',
        value:
          assessmentGroup.questionPaperLink !== null &&
            assessmentGroup.questionPaperLink !== ''
            ? assessmentGroup.questionPaperLink
            : 'Not Available'
      });
      this.assessmentDetails.otherDetails.push({
        label: 'Maximum Score',
        value:
          assessmentGroup.maxScore !== null && assessmentGroup.maxScore !== ''
            ? assessmentGroup.maxScore
            : 'Not Available'
      });
      this.assessmentDetails.otherDetails.push({
        label: 'Passing Score',
        value:
          assessmentGroup.passingScore !== null &&
            assessmentGroup.passingScore !== ''
            ? assessmentGroup.passingScore
            : 'Not Available'
      });

      assessmentGroup.evaluatorList.forEach(element => {
        let evaluator = [];
        evaluator.push({
          label: "Evaluator's Name",
          value:
            element.evaluatorName !== null && element.evaluatorName !== ''
              ? element.evaluatorName
              : 'Not Available'
        });
        evaluator.push({
          label: "Evaluator's Email Id",
          value:
            element.evaluatorEmail !== null && element.evaluatorEmail !== ''
              ? element.evaluatorEmail
              : 'Not Available'
        });
        evaluator.push({
          label: "Evaluator's Vendor Name",
          value:
            element.evaluatorVendor !== null && element.evaluatorVendor !== ''
              ? element.evaluatorVendor
              : 'Not Available'
        });
        this.assessmentDetails.evaluatorList.push(evaluator);
      });

      this.assessmentDetails.otherDetails.push({
        label: 'Evaluator Type',
        value:
          assessmentGroup.evaluatorType !== null &&
            assessmentGroup.evaluatorType !== ''
            ? assessmentGroup.evaluatorType
            : 'Not Available'
      });
      this.assessmentDetails.otherDetails.push({
        label: 'Evaluation Template',
        value:
          assessmentGroup.evaluationTemplateLink !== null &&
            assessmentGroup.evaluationTemplateLink !== ''
            ? assessmentGroup.evaluationTemplateLink
            : 'Not Available'
      });
    }
    if (
      assessmentGroup.assessmentType === 'Online' ||
      assessmentGroup.assessmentType === 'Both'
    ) {
      this.assessmentDetails.otherDetails.push({
        label: 'Online Question Paper',
        value:
          assessmentGroup.onlineQuestionPaperLink !== null &&
            assessmentGroup.onlineQuestionPaperLink !== ''
            ? assessmentGroup.onlineQuestionPaperLink
            : 'Not Available'
      });
      this.assessmentDetails.otherDetails.push({
        label: 'Tool Name',
        value:
          assessmentGroup.toolName !== null && assessmentGroup.toolName !== ''
            ? assessmentGroup.toolName
            : 'Not Available'
      });
    }

    // this.assessmentDetails.otherDetails.push({
    //   'label': 'Is Assessment Scheduled', 'value':
    //     (assessmentGroup.isAssessmentScheduled !== null && assessmentGroup.isAssessmentScheduled !== '') ?
    //       assessmentGroup.isAssessmentScheduled : 'Not Available'
    // });

    // if (assessmentGroup.isAssessmentScheduled === 'Yes') {
    //   this.assessmentDetails.otherDetails.push({
    //     'label': 'Is Assessment Scheduled', 'value':
    //       (assessmentGroup.assessmentScheduledDate !== null && assessmentGroup.assessmentScheduledDate !== '') ?
    //         assessmentGroup.assessmentScheduledDate : 'Not Available'
    //   });
    // }

    assessmentGroup.assesmentInfrastructure.forEach((type, key) => {
      if (type === true || type === 'true') {
        const assessmentId = key;
        switch (assessmentId) {
          case 0:
            this.assessmentDetails.assessmentInfrastructure.push({
              label: 'Internet Cloud Lab',
              value: assessmentGroup.assesmentInfrastructureType[0]
                ? assessmentGroup.assesmentInfrastructureType[0]
                : 'Not Available'
            });
            break;
          case 1:
            this.assessmentDetails.assessmentInfrastructure.push({
              label: 'Vendor Provided Cloud Lab',
              value: assessmentGroup.assesmentInfrastructureType[1]
                ? assessmentGroup.assesmentInfrastructureType[1]
                : 'Not Available'
            });
            break;
          case 2:
            this.assessmentDetails.assessmentInfrastructure.push({
              label: 'Name of White Listed Software',
              value: assessmentGroup.assesmentInfrastructureType[2]
                ? assessmentGroup.assesmentInfrastructureType[2]
                : 'Not Available'
            });
            this.assessmentDetails.assessmentInfrastructure.push({
              label: 'Link to download white listed software',
              value: assessmentGroup.assesmentInfrastructureType[3]
                ? assessmentGroup.assesmentInfrastructureType[3]
                : 'Not Available'
            });
            break;
          case 3:
            this.assessmentDetails.assessmentInfrastructure.push({
              label: 'Name of Genie Request Software ',
              value: assessmentGroup.assesmentInfrastructureType[4]
                ? assessmentGroup.assesmentInfrastructureType[4]
                : 'Not Available'
            });
            this.assessmentDetails.assessmentInfrastructure.push({
              label: 'Version of  Genie Request Software ',
              value: assessmentGroup.assesmentInfrastructureType[5]
                ? assessmentGroup.assesmentInfrastructureType[5]
                : 'Not Available'
            });
            break;
          case 4:
            this.assessmentDetails.assessmentInfrastructure.push({
              label: 'Assessment Infrastructure Details ',
              value: 'Not Available'
            });
            break;
        }
      }
    });
  }
  constructCourseProcedureReferenceDocument(isAvailable) {
    if (isAvailable === 'yes') {
      let courserefdoc = this.formData.courseProcedureRefDocGroup.courseProcedureRefDocLink;
      this.courseprocedurerefdocumentDetails = [
        {
          "label": "Course Procedure Reference Document Link",
          "value": courserefdoc
        }
      ]
    }
    else {
      this.courseprocedurerefdocumentDetails = null;
    }
  }

  // canShowField(label){

  // }

  constructQuizDetails() {
    this._contentUpload101Service.getQuizDataFor101(this.courseUniqueId).subscribe(data => {
      //console.log(data)
      if (data != null && data.questionsAndAnswer != null) {
        this.quizDetails = data;
      }
    });

  }

}
