import { HttpHeaders } from '@angular/common/http';
//import { ValueTransformer } from '@angular/compiler/src/util';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fakeAsync } from '@angular/core/testing';
import { validateBasis } from '@angular/flex-layout';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ContentUploadHomePopupsComponent } from '../../../content-verification/content-upload-home-popups/content-upload-home-popups.component';
import { CoursesPopupComponent } from '../../../shared/yorbit-courses/courses-popup/courses-popup.component';
import { ValidateExpert } from '../../content-upload/content-upload-validators';
import { SearchEvaluatorPopupComponent } from '../../content-upload/search-evaluator/search-evaluator-popup/search-evaluator-popup.component';
import { Conentupload101Service } from '../conentupload-101.service';
import { ContentUpload101PopupsComponent } from '../content-upload101-popups/content-upload101-popups.component';
import { ValidateExpert101, ValidateMinLength101, ValidateDuration101, ValidateContentOwner101 } from '../contentupload-101-validators';
import { GraphDataService } from '@YorbitWorkspace/graph';
import { Store } from '@ngrx/store';
import * as fromGraphStore from '@YorbitWorkspace/graph';
import { Globals } from '../../../globals';
import * as _ from "lodash"
import { ContentUploadStatusPopupComponent } from '../../content-upload-status-popup/content-upload-status-popup.component';
import { YorbitCoursesService } from '../../../shared/services/yorbit-courses.service';

@Component({
  selector: 'yorbit-coursedetails-form101',
  templateUrl: './coursedetails-form101.component.html',
  styleUrls: ['./coursedetails-form101.component.scss']
})
export class CoursedetailsForm101Component implements OnInit {

  public courseDetailForm: FormGroup;
  @Input() courseUniqueIdData;
  @Output() enableContentVerificationComponent = new EventEmitter<any>();
  @Output() enableQuizUploadComponent = new EventEmitter<any>();
  @Output() enableUnitsComponent = new EventEmitter<any>();
  YesNoRadio = ['Yes', 'No'];
  //contentTypeRadio = ['Shoshin School', 'Account', 'Project'];
  contentTypeRadio = [{name:'Shoshin School',value:'Yorbit'},
  {name:'Account',value:'Account'},
  {name:'Project',value:'Project'}];

  courseMaterialTypeRadio = ['Download Content', 'Hyperlink'];
  academyList: any[];
  genreList: any[];
  skillList: any[];
  taxonomy: any[];
  genreSkillList: any[];
  vendorList: any[];
  courseTypeList: any[];
  isContentLoaded: boolean = false;
  selectedPrereqCourses: any[];
  selectedPostRecommCourses: any[];
  filteredPreReqCourses: any[];
  filteredPostRecommCourses: any[];
  yorbitCoursesLoading: boolean;
  saveAlertSubscription: any;
  epochDiffInMins: any;
  offsetTimeoutForAlert: any;
  yorbitCourses: any[];
  courseUniqueId = '';
  errorMsgs: any;
  contentUploadData101: any;
  courseMaterialFile: any;
  fileNotSelected: boolean;
  disableUploadButton: boolean;
  uploadBtnClicked: boolean;
  btnTitle: String;
  formErr: boolean = false;
  formErrMsg: string = '';
  insertInProgress: boolean = false;
  hasInsertFailed: boolean = false;
  isValid: boolean = false;
  submitDisabled: boolean = true;
  vendorErr: boolean = false;
  academyErr: boolean = false;
  genreErr: boolean = false;
  skillErr: boolean = false;
  courseNameErr: boolean = false;
  badgeNameErr: boolean = false;
  descriptionErr: boolean = false;
  topicsCoveredErr: boolean = false;
  durationErr: boolean = false;
  contentOwnerErr: boolean = false;
  courseMaterialLinkErr: boolean = false;
  courseMaterialFileErr: boolean = false;
  typeErr: boolean = false;
  expertMidsErr = false;
  accountErr = false;
  projectErr = false;
  categoryErr = false;
  accountList: any[];
  projectList: any[];
  categoryList: any[];
  accountProjectCategoryList: any[];
  ProjectCategoryList: any[];
  newCategoryFromPopUp: string = '';
  courseMaterialFileName: string = 'Select a file';
  iscourseMaterialFileInvalid = false;
  isEdit = false;
  isApproved = false;
  courseStatus = '';
  saveDisabled: boolean = false;
  existingCourseData: any;
  showloading = true;
  contentOwnerMid: string;
  contentOwnerName: string;
  graphStoreSub: any = null;
  isSubmittedOrApprovedCourse: boolean = false;
  courseNameError: string;
  existingCourseMaterialLink: string = '';
  courseProficiencyList: any[];

  constructor(private formBuilder: FormBuilder,
    private _contentUpload101Service: Conentupload101Service,
    private _popup: MatDialog,
    public confirmDialogRef: MatDialogRef<ContentUpload101PopupsComponent>,
    public dialog: MatDialog,
    private _graphSvc: GraphDataService,
    private graphStore: Store<fromGraphStore.IGraphReducerState>,
    private globals: Globals,
    private courseSvc: YorbitCoursesService
  ) {
    this.errorMsgs =
    {
      course_duration:
        'Please enter numbers in the format of HHH.MM.SS or HH.MM.SS.',
      course_name: 'Please enter a course name.',
      badge_name: 'Please enter a badge name.',
      expert_mids: 'Please enter PSID in the format of ‘xxxxxxxx’.',
      file_upload_err: 'Please choose only .zip/7z file of size less than 10MB.',
      duplicate_course_name: 'The course name already exists.',
    }
  }

  ngOnInit() {
    this.selectedPrereqCourses = [];
    this.selectedPostRecommCourses = [];
    this.filteredPreReqCourses = [];
    this.filteredPostRecommCourses = [];
    this.epochDiffInMins = null;
    this.offsetTimeoutForAlert = null;

    this.courseMaterialFile = '';
    this.disableUploadButton = true;
    this.fileNotSelected = true;
    this.uploadBtnClicked = false;
    this.btnTitle = 'Please choose only .zip files less than 10MB.';
    this.submitDisabled = true;
    this.courseMaterialFileName = 'select a file';
    this.iscourseMaterialFileInvalid = false;

    this.vendorErr = false;
    this.academyErr = false;
    this.genreErr = false;
    this.skillErr = false;
    this.courseNameErr = false;
    this.badgeNameErr = false;
    this.descriptionErr = false;
    this.topicsCoveredErr = false;
    this.durationErr = false;
    this.contentOwnerErr = false;
    this.courseMaterialLinkErr = false;
    this.courseMaterialFileErr = false;
    this.typeErr = false;
    this.expertMidsErr = false;
    this.accountErr = false;
    this.projectErr = false;
    this.newCategoryFromPopUp = '';
    this.saveDisabled = false;
    this.existingCourseData = null;
    this.isApproved = false;

    this.courseDetailForm = this.formBuilder.group(
      {
        repoType: 'Yorbit',
        isCourseInternal: 'No',
        academy: ['', Validators.required],
        genre: ['', Validators.required],
        IsCloudLabAvailable: 'No',
        skill: ['', Validators.required],
        courseName: ['', Validators.required],
        topicsCovered: ['', [Validators.required, ValidateMinLength101(50)]],
        description: ['', [Validators.required, ValidateMinLength101(250)]],
        badgeName: ['', Validators.required],
        isHiddenCourse: 'No',
        isRestrictedCourse: 'No',
        preRequisiteCourseIDs: [],
        postRecommendedCourseIDs: [null],
        type: ['', Validators.required],
        contentOwner: ['', [Validators.required]],
        expertMids: ['', ValidateExpert101],
        vendor: ['', Validators.required],
        isCourseMaterialAvailable: ['No', Validators.required],
        courseMaterialType: ['Download Content', Validators.required],
        courseMaterialLink: ['', Validators.required],
        uploadCourseMaterialContent: null,
        duration: ['', [Validators.required, ValidateDuration101]],
        account: ['', Validators.required],
        project: ['', Validators.required],
        category: ['', Validators.required],
        RepoId: new FormControl(''),
        RepoName: new FormControl(''),
        proficiency: ['', Validators.required],
        SkillIds: new FormControl(''),
      }
    )
    if (this.courseDetailForm.get('academy').value === '') {
      this.courseDetailForm.get('genre').disable();
    }
    if (this.courseDetailForm.get('genre').value === '' &&
      !this.courseDetailForm.get('genre').dirty
    ) {
      this.courseDetailForm.get('skill').disable();
    }
    if (this.courseDetailForm.get('account').value === '' || this.courseDetailForm.get('repoType').value === 'account') {
      this.courseDetailForm.get('project').disable();
    }
    if ((this.courseDetailForm.get('account').value === '' &&
      !this.courseDetailForm.get('account').dirty) ||
      (this.courseDetailForm.get('project').value === '' &&
        !this.courseDetailForm.get('project').dirty)
    ) {
      this.courseDetailForm.get('category').disable();
    }
    this.getVendor();
    this.getTaxanomy();

    this.getCourseTypeList('101');
    this.getCourseProficiencyList();
    this.getAccountProjectCategories();
    if (this.courseUniqueIdData != undefined && this.courseUniqueIdData != null) {
      this._contentUpload101Service.getCourseDetailsFor101(this.courseUniqueIdData).subscribe(
        (response: any) => {
          if (response != null && response != undefined) {
            this.existingCourseData = response;
            this.existingCourseData.IsCloudLabAvailable = this.existingCourseData.IsCloudLabAvailable == true ? "Yes" : "No";
            this.courseDetailForm.get('IsCloudLabAvailable').setValue(this.existingCourseData.IsCloudLabAvailable);
            //(response);
          }
          if (this.existingCourseData.CourseStatus == 'Submitted' || this.existingCourseData.CourseStatus == 'Approved') {
            this.submitDisabled = false;
            this.isSubmittedOrApprovedCourse = true;
          }
        });
    }
    else {
      this.graphStoreSub = this.graphStore
        .select(fromGraphStore.getGraphUserName)
        .subscribe(res => {
          this.contentOwnerMid = this.globals.MId;
          if (res) {
            this.courseDetailForm.controls['contentOwner'].setValue(res);
          } else {
            this.courseDetailForm.controls['contentOwner'].setValue(this.globals.MId);
          }
        });
      this.isContentLoaded = true;
    }
    // this.filterGenre();
    // this.filterSkill();
    this.formModificationDetect();
  }
  getVendor() {
    this._contentUpload101Service.getVendor().subscribe(
      (response: any) => {
        if (response != null && response.length > 0) {
          this.vendorList = response;
        } else {
          this.vendorList = [];
        }
      },
      (error: any) => {
        this.vendorList = [];
      }
    );
  }
  getTaxanomy() {
    this.academyList = [];
    this._contentUpload101Service.getTaxanomy().subscribe(
      (response: any) => {
        if (response !== null && response.AcademyList != undefined && response.AcademyList.length > 0) {
          this.taxonomy = response.AcademyList;
          this.taxonomy.forEach(value => {
            this.academyList.push(value.Academy);
          });
        }
        else {
          this.taxonomy = [];
        }
        this.getYorbitCourses();
      },
      (error: any) => {
        this.taxonomy = [];
      }
    );
  }
  filterGenre() {
    this.genreList = [];
    this.genreSkillList = [];
    this.courseDetailForm.controls['genre'].setValue('');
    if (this.taxonomy !== undefined) {
      this.taxonomy.forEach(value => {
        if (this.courseDetailForm.get('academy').value !== '') {
          this.courseDetailForm.get('genre').enable();
          if (
            value.Academy.toLowerCase() ===
            this.courseDetailForm.get('academy').value.toLowerCase()
          ) {
            this.genreSkillList = value.GenreSkillList;
          }
        }
      });
      if (this.genreSkillList.length > 0) {
        this.genreSkillList.forEach(value => {
          this.genreList.push(value.Genre);
        });
        this.courseDetailForm.controls['skill'].setValue('');
        this.courseDetailForm.get('skill').disable();
        return this.genreList;
      } else {
        return [];
      }
    }
  }

  filterSkill() {
    this.skillList = [];
    this.courseDetailForm.controls['skill'].setValue('');
    if (this.genreSkillList.length > 0) {
      this.genreSkillList.forEach(value => {
        if (this.courseDetailForm.get('genre').value !== '') {
          this.courseDetailForm.get('skill').enable();
          if (
            value.Genre.toLowerCase() ===
            this.courseDetailForm.get('genre').value.toLowerCase()
          ) {
            this.skillList = value.Skills;
          }
        }
      });
      if (this.skillList.length > 0) {
        return this.skillList;
      } else {
        // this.noResultsForSkill = true;
        return [];
      }
    } else {
      return [];
    }
  }
  getCourseTypeList(expertise) {
    this._contentUpload101Service.getCourseTypeList(expertise).subscribe(
      (response: any) => {
        if (response != null && response.length > 0) {
          this.courseTypeList = response;
        } else {
          this.courseTypeList = [];
        }
      },
      (error: any) => {
        this.courseTypeList = [];
      }
    );
  }
  openYorbitCourses(isPreReq) {
    //if (!this.isApproved) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    dialogConfig.data = {
      data: {
        selectedCourses: isPreReq
          ? this.selectedPrereqCourses
          : this.selectedPostRecommCourses,
        title: isPreReq ? 'Pre-requisite Courses' : 'Post Recommended Courses',
        expertise: '101'
      },
      width: '250px',
      height: '250px'
    };
    const response = this._popup.open(CoursesPopupComponent, dialogConfig);
    response.afterClosed().subscribe(res => {
      if (res.canSubmit) {
        if (res.selectedCourses && res.selectedCourses.length > 0) {
          if (isPreReq) {
            this.selectedPrereqCourses = [];
            res.selectedCourses.forEach((course, key) => {
              if (this.filteredPreReqCourses.length > 0) {
                let courseData = this.filteredPreReqCourses.filter(
                  c => c.Id === course.Id
                )[0];
                if (courseData) {
                  courseData.checkedPreReq = true;
                  courseData.Name = courseData.Name;
                  courseData.Expertise = courseData.Expertise;
                  this.selectedPrereqCourses.push(courseData);
                }
              }
            });
            this.courseDetailForm
              .get('preRequisiteCourseIDs')
              .setValue(this.selectedPrereqCourses.map(x => x.Id));
          }
          if (!isPreReq) {
            this.selectedPostRecommCourses = [];
            res.selectedCourses.forEach((course, key) => {
              if (this.filteredPostRecommCourses.length > 0) {
                let courseData = this.filteredPostRecommCourses.filter(
                  c => c.Id === course.Id
                )[0];
                if (courseData) {
                  courseData.checkedPostRecomm = true;
                  courseData.Name = courseData.Name;
                  courseData.Expertise = courseData.Expertise;
                  this.selectedPostRecommCourses.push(courseData);
                }
              }
            });
            this.courseDetailForm
              .get('postRecommendedCourseIDs')
              .setValue(this.selectedPostRecommCourses.map(x => x.Id));
          }
        }
      } else {
        // do nothing
      }
    });
    //}
  }
  isNullOrEmpty(value) {
    try {
      if (typeof value == 'object') {
        if (
          value == null ||
          value == undefined ||
          value == 	  _.isEmpty({}) ||
          value.length == 0
        )
          return true;
        else return false;
      } else {
        if (
          value == undefined ||
          value == '' ||
          value.toString().trim() == '' ||
          value == null ||
          value.length == 0
        )
          return true;
        else return false;
      }
    } catch (Error) {
     // //console.log(Error.message);
      return true;
    }
  }
  removeItem(course, isPreReq) {
    let itemInList = [];
    let courseList1 = [];
    let courseList2 = [];
    if (isPreReq) {
      courseList1 = this.selectedPrereqCourses;
      itemInList = courseList1.filter(x => x.Id === course.Id);
    } else {
      courseList2 = this.selectedPostRecommCourses;
      itemInList = courseList2.filter(x => x.Id === course.Id);
    }
    if (itemInList || itemInList.length !== 0) {
      if (isPreReq) {
        const pos = courseList1
          .map(function (e) {
            return e.Id;
          })
          .indexOf(course.Id);
        courseList1.splice(pos, 1);
      } else {
        const pos = courseList2
          .map(function (e) {
            return e.Id;
          })
          .indexOf(course.Id);
        courseList2.splice(pos, 1);
      }
    }
    if (isPreReq) {
      this.selectedPrereqCourses = courseList1.map(x => x);
      this.courseDetailForm
        .get('preRequisiteCourseIDs')
        .setValue(this.selectedPrereqCourses.map(x => x.Id));
      this.filteredPreReqCourses.filter(
        x => x.Id === course.Id
      )[0].checkedPreReq = false;
    } else if (!isPreReq) {
      this.selectedPostRecommCourses = courseList2.map(x => x);
      this.courseDetailForm
        .get('postRecommendedCourseIDs')
        .setValue(this.selectedPostRecommCourses.map(x => x.Id));
      this.filteredPostRecommCourses.filter(
        x => x.Id === course.Id
      )[0].checkedPostRecomm = false;
    }
  }
  getYorbitCourses() {
    this.yorbitCoursesLoading = true;
    this._contentUpload101Service.getAllYorbitcourses().subscribe(data => {

      //loading course data to show in the pre and post requisite drop down
      this.courseSvc.YorbitCoursesList = data.filter(x=>x.CourseStatus.toLowerCase() == "approved");

      //Added this snippet here to show save alert popup after the form is loaded
      this.saveAlertSubscription = setTimeout(() => {
        this.showSaveAlertPopup(this.epochDiffInMins);
      }, (this.epochDiffInMins - this.offsetTimeoutForAlert) * 60 * 1000);

      this.yorbitCoursesLoading = false;
      if (data != null && data.length > 0) {
        data.forEach(datum => {
          datum.checkedPreReq = false;
          datum.checkedPostRecomm = false;
        });
        this.yorbitCourses = data;
        this.filteredPreReqCourses = data.map(x => x);
        this.filteredPostRecommCourses = data.map(x => x)
        if (this.existingCourseData != undefined && this.existingCourseData != null) {
          this.courseUniqueId = this.courseUniqueIdData;
          this.courseStatus = this.existingCourseData.CourseStatus;
          //console.log(this.existingCourseData)
          this.existingCourseMaterialLink = this.existingCourseData.CourseMaterialLink;
          if (
            this.courseUniqueId &&
            (this.courseStatus.toLowerCase() === 'saved')
          ) {
            this.isEdit = true;
            this.editContentUpload(this.existingCourseData);
          } else if (
            this.courseUniqueId &&
            (this.courseStatus.toLowerCase() === 'submitted' ||
              this.courseStatus.toLowerCase() === 'approved')
          ) {
            this.isApproved = true;
            //this._contentUpload101Service.isApproved = true;

            this.editContentUpload(this.existingCourseData);
            this.courseDetailForm.disable();
          }
          else {
            this.showloading = false;
          }
        }
        else {
          this.courseUniqueId = null;
          this.showloading = false;
        }
      } else {
        this.yorbitCourses = [];
        this.filteredPreReqCourses = [];
        this.filteredPostRecommCourses = [];
        this.courseUniqueId = null;
      }
    });
  }
  showSaveAlertPopup(epochDiffInMins) {
    // if((epochDiffInMins-10) > 1){
    // if (epochDiffInMins >= 5 && epochDiffInMins < 15) {
    //if (!this.isApproved) {
    let type = 'saveAlert';
    let data = {
      msg: 'Session will expire soon. Please save the data.',
      title: 'Information'
    };
    //this.openStatusPopup(data, type);
    //}
    // }
  }
  popInformationBits(event, field) {
    event.stopPropagation();
    let message = '';
    switch (field) {
      case 'Duration':
        message =
          '<span class="text-center">Please provide duration in hours only.</span><br/>';
        message +=
          '<span class="text-center">Standard learning hours per day is 6 hours, hence if a course is for 5 days then the course duration will be 30 hours which should be entered as 30.00.00 (HHH.MM.SS). You can enter up to 3 digits as hours.</span>';
        break;
    }
  }
  fileModel(event) {
    if (event.file == undefined) {
      this.setDefaultValuesForUploads();
    }
    else {
      this.fileNotSelected = false;
      this.courseMaterialFile = event.file;
      this.courseMaterialFileName = event.file.name;
      this.disableUploadButton = false;
      //this.ValidateAllConditions();
    }
  }
  setDefaultValuesForUploads() {
    if (!this.isSubmittedOrApprovedCourse) {
      this.courseDetailForm.controls['uploadCourseMaterialContent'].setValue(null);
      this.fileNotSelected = true;
      this.disableUploadButton = false;
      this.courseMaterialFileName = 'Select a file';
      this.courseMaterialFile = '';
      this.btnTitle = 'Please choose only .zip files less than 10MB.';
    }
  }
  uploadfile() {
    if (this.courseMaterialFile !== undefined && !this.isNullOrEmpty(this.courseMaterialFile)
      && !this.isNullOrEmpty(this.courseMaterialFile.name)) {
      this.courseMaterialFileErr = false;
      const uploadedFileSize =
        this.courseMaterialFile.size / (1024 * 1024);
      let extension = this.courseMaterialFile.name
        .split('.')
        .pop()
        .toLowerCase();
      if (extension == 'zip' || extension == "7z") {
        if (uploadedFileSize < 10) {
          this.fileNotSelected = false;
          this.disableUploadButton = true;
          this.iscourseMaterialFileInvalid = false;
          this.btnTitle = 'File upload is success.';
        }
        else {
          this.iscourseMaterialFileInvalid = true;
          this.fileNotSelected = true;
          this.btnTitle = 'Please choose file less than 10MB !';
        }
      }
      else {
        this.iscourseMaterialFileInvalid = true;
        this.fileNotSelected = true;
        this.btnTitle = 'Please choose only zip/7z file !';
      }
    }
    else {
      this.iscourseMaterialFileInvalid = true;
      this.btnTitle = "Please choose a file to upload."
    }
  }
  submit() {
    this.submitDisabled = true;
    this.save('submit');
    //   if (this.courseUniqueIdData == null) 
    //   {

    //  this.submitDisabled = true;
    //  this.ValidateAllMandatoryFieldsInAFormm();

    //   const name = this.courseDetailForm.get('courseName').value;

    //     //this.setRadioValue();
    //     //console.log('logging', this.isValid);
    //     ////console.log('Save', this.courseDetailForm.valid, this.courseDetailForm);
    //     if (this.isValid) {
    //       this._contentUpload101Service
    //         .checkIfCourseNameExists101(name)
    //         .subscribe(isExists => {
    //           if (!isExists) {
    //             this.courseDetailForm.setErrors(null);
    //             this.submitDisabled = true;
    //             this.courseNameErr = false;
    //             this.courseDetailForm.controls['courseName'].markAsUntouched();
    //             this.OnContentFormSubmit('submit');
    //           } else {
    //             this.isValid = false;
    //             this.submitDisabled = false;
    //             // this.setFormErrorMsg(
    //             //   true,
    //             //   'Sorry we were unable to save the form! Please review the entered data.'
    //             // );
    //             this.courseDetailForm.controls['courseName'].markAsTouched();
    //             this.courseDetailForm.controls['courseName'].markAsDirty();
    //             //this.courseDetailForm.get('courseName').errors.errorMessage = 'Sorry we were unable to save the form! Please review the entered data.';
    //             this.courseDetailForm.get('courseName').setErrors({ isInvalid: true });
    //             this.courseDetailForm.setErrors({ invalid: true });
    //           }
    //         });
    //       //}
    //       //}
    //     }
    //     else {
    //       this.submitDisabled = false;
    //     }
    //   }
    //   else {
    if (this.isValid) {
      this.confirmDialogRef = this.dialog.open(ContentUpload101PopupsComponent, {
        disableClose: true,
        panelClass: "expertDialogContainer",
        data: 'Confirmation'
      });
      this.confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result == 'Yes') {
            var course = {
              'UniqueId': this.courseUniqueId,
              'Name': this.courseDetailForm.get('courseName').value,
              'IsSubmittedOrApprovedCourse': this.isSubmittedOrApprovedCourse,
              'IsYorbitCourse': this.courseDetailForm.get('repoType').value == 'Yorbit' ? true : false,
              'FromHomePage': false,
              'IsCourseInternal': this.courseDetailForm.value['isCourseInternal'] == "Yes" ? true : false,
              'expertise':'101'
            }
            if (this.courseDetailForm.value['isCourseInternal'] == "Yes") {
              this.enableUnitsComponent.emit(course);
            }
            else {
              this.enableQuizUploadComponent.emit(course);
            }
          } else {
            this.saveDisabled = false;
            this.submitDisabled = false;
          }
        }
        this.confirmDialogRef = null;
      });
    }

  }
  findInvalidControls() {
    const invalidctrl = [];
    const controls = this.courseDetailForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalidctrl.push(name);
      }
    }
    //  return invalid;
  }
  setFormErrorMsg(hasErr, errMsg) {
    this.formErr = hasErr;
    this.formErrMsg = errMsg;
  }
  setRadioValue() {
    this.courseDetailForm.get('isCourseInternal').value === 'No' ? (this.contentUploadData101.isCourseInternal = false)
      : (this.contentUploadData101.isCourseInternal = true);
    this.courseDetailForm.get('isHiddenCourse').value === 'No'
      ? (this.contentUploadData101.isHiddenCourse = false)
      : (this.contentUploadData101.isHiddenCourse = true);
    this.courseDetailForm.get('isRestrictedCourse').value === 'No'
      ? (this.contentUploadData101.isRestrictedCourse = false)
      : (this.contentUploadData101.isRestrictedCourse = true);
    this.courseDetailForm.get('isCourseMaterialAvailable').value === 'No'
      ? (this.contentUploadData101.isCourseMaterialAvailable = false)
      : (this.contentUploadData101.isCourseMaterialAvailable = true);
  }
  OnContentFormSubmit(actionType) {
    const headers = new HttpHeaders().set('content-type', 'application/json');

    this.contentUploadData101 = JSON.parse(
      JSON.stringify(this.courseDetailForm.getRawValue())
    );
    this.contentUploadData101.IsCloudLabAvailable =  this.contentUploadData101.IsCloudLabAvailable == "Yes" ? true : false;
    this.contentUploadData101.contentOwner = this.contentOwnerMid;
    this.setRadioValue();
    this.insertInProgress = true;
    this.hasInsertFailed = false;
    this._contentUpload101Service
      .insertContentFormFor101(
        this.contentUploadData101, this.courseUniqueId,
        { headers }
      )
      .subscribe(
        (response: any) => {
          this.insertInProgress = false;
          //  this.contentForm.enable();
          if (response && !this.isNullOrEmpty(response)) {
            this.courseUniqueId = response;
            this.isEdit = true;
            let data: any;
            /* if (actionType === 'save') {
              data = {
                msg:
                  'Your inputs have been saved successfully. <br/> Do you want to continue?',
                title: 'Information'
              };
            } else if (actionType == 'submit') {
              data = {
                msg:
                  'Your inputs have been submitted to PSS team for verification.',
                title: 'Information'
              };
            } */
            if (!this.isNullOrEmpty(this.existingCourseData)) {
              if (this.courseDetailForm.get('courseMaterialType').value == 'Download Content') {
                this.existingCourseData.CourseMaterialType = "Internal";
              } else {
                this.existingCourseData.CourseMaterialType = "External";
              }
              this.existingCourseMaterialLink = this.courseDetailForm.get('courseMaterialLink').value
            }


            if (this.courseDetailForm.get('courseMaterialType').value == 'Download Content'
              && this.courseMaterialFile !== undefined &&
              !this.isNullOrEmpty(this.courseMaterialFile)) {
              this._contentUpload101Service.
                UploadCourseMaterialFromUI
                (this.courseMaterialFile,
                  this.courseUniqueId, this.contentUploadData101.courseName);
            }
            // const data = {
            //   msg:
            //     'Your inputs have been submitted for furthur verification.',
            //   title: 'Information'
            // };
            var course = {
              'UniqueId': this.courseUniqueId,
              'Name': this.courseDetailForm.get('courseName').value,
              'FromHomePage': false
            }
            this.confirmDialogRef = null;
            if (response && actionType != 'submit') {
              this.confirmDialogRef = this.dialog.open(ContentUpload101PopupsComponent, {
                disableClose: true,
                panelClass: "expertDialogContainer",
                data: 'ContentSaveMessage'
              });
              this.confirmDialogRef.afterClosed().subscribe(() => {
                //if (this.courseDetailForm.value['isCourseInternal'] == "Yes") {
                // this.enableUnitsComponent.emit(course);
                // }
                //else {
                // this.enableQuizUploadComponent.emit(course);
                //}
                //this.enableQuizUploadComponent.emit(course);
                //this.enableUnitsComponent.emit(course);
              })
              this.submitDisabled = false;
            }
          }
          else {
            this.courseUniqueId = null;
            this.ngOnInit();
            this.courseDetailForm.reset();
          }
        }, (error: any) => {
          this.insertInProgress = false;
          this.hasInsertFailed = true;
          //this.courseAccessibilityList = [];
          //  this.contentForm.enable();
          const data =
            'Something went wrong while saving the content.<br/>Please try later.';
          //this.openStatusPopup(data, 'failed');
        });
    //this.contentUploadData.courseCompletionGroup.Status = null;

    //this.contentUploadData.courseCompletionGroup.SubStatus = null;

    // this.contentForm.disable();

  }
  numbersOnlyFields(type) {
    const reg = /[^0-9.]/g;
    const regDuration = /[0-9]{1,3}.[0-5]{1}[0-9]{1}.[0-5]{1}[0-9]{1}$/g;
    const regDigitsOnly = /[^0-9]/g;
    const timereg = /^\d{1,3}.\d{2}.\d{2}$/g;
    let fieldResult = '';
    switch (type) {
      case 'duration':
        const exp = new RegExp(/[0-9]{1,3}.[0-5]{1}[0-9]{1}.[0-5]{1}[0-9]{1}$/);
        let result = exp.test(this.courseDetailForm.get('duration').value);

        let minutes = this.courseDetailForm.get('duration').value.split('.')[1];
        let seconds = this.courseDetailForm.get('duration').value.split('.')[2];
        if (!result && minutes != undefined && seconds != undefined) {
          fieldResult = this.courseDetailForm.get('duration').value.replace(regDuration, '');
        } else {
          // this.contentForm.controls['duration'].setValue(fieldResult);
        }
        if (minutes > 59) {
          if (seconds > 59) {
            this.errorMsgs.course_duration =
              'Minutes and Seconds fields can not be more than 59';
          } else {
            this.errorMsgs.course_duration =
              'Minutes field can not be more than 59';
          }
        } else if (seconds > 59) {
          this.errorMsgs.course_duration =
            'Seconds field can not be more than 59';
        } else {
          this.errorMsgs.course_duration =
            'Please enter numbers in the format of HHH.MM.SS or HH.MM.SS.';
        }
        break;
    }
  }
  onExit() {

    var errorMsg =
      'Changes you have made (if any) and unsaved will be lost.<br/>Are you sure you want to Exit Form?';

    let dataPayload = {
      msg: errorMsg,
      title: 'Exit Form'
    };
    let type = 'exit';
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    dialogConfig.data = {
      data: dataPayload,
      width: '250px',
      height: '250px',
      type: type
    };
    const response = this._popup.open(
      ContentUploadStatusPopupComponent,
      dialogConfig
    );
    response.afterClosed().subscribe(res => {
      if (!res) {
        this.enableContentVerificationComponent.emit('101');
      }
      else {
        return;
      }
    });
  }
  setContentOwnerErrField() {
    if (this.isNullOrEmpty(this.courseDetailForm.get('contentOwner').value)) {
      this.contentOwnerErr = false;
    }
  }
  getAccountProjectCategories() {
    this.accountList = [];
    this._contentUpload101Service.getAccountProjectCategories().subscribe(
      (response: any) => {
        if (response !== null && response.AccountsList != undefined && response.AccountsList.length > 0) {
          this.accountProjectCategoryList = response.AccountsList;
          this.accountProjectCategoryList.forEach(value => {
            this.accountList.push({
              Id: value.AccountId,
              Name: value.AccountName
            });
          })
        }
        else {
          this.accountProjectCategoryList = [];
        }
      },
      (error: any) => {
        this.accountProjectCategoryList = [];
      }
    );
  }
  filterProject() {
    this.projectList = [];
    this.ProjectCategoryList = [];
    this.courseDetailForm.controls['project'].setValue('');

    if (this.accountProjectCategoryList !== undefined
      && this.courseDetailForm.get('repoType').value === 'Project') {
      this.accountProjectCategoryList.forEach(value => {
        if (this.courseDetailForm.get('account').value !== '') {
          this.courseDetailForm.get('project').enable();
          if (
            value.AccountId.toLowerCase() ===
            this.courseDetailForm.get('account').value.toLowerCase()
          ) {
            this.ProjectCategoryList = value.ProjectsList;
          }
        }
      });
      if (this.ProjectCategoryList != null && this.ProjectCategoryList != undefined && this.ProjectCategoryList.length > 0) {
        this.ProjectCategoryList.forEach(value => {
          this.projectList.push({
            Id: value.ProjectId,
            Name: value.ProjectName
          });
        });
        this.courseDetailForm.controls['category'].setValue('');
        this.courseDetailForm.get('category').disable();
        return this.projectList;
      } else {
        return [];
      }
    }
    else {
      if (this.existingCourseData && this.existingCourseData.RepoId != null) {
        this.accountProjectCategoryList.forEach(value => {
          if (this.courseDetailForm.get('account').value !== '') {
            this.courseDetailForm.get('project').enable();
            if (
              value.AccountId.toLowerCase() ===
              this.courseDetailForm.get('account').value.toLowerCase()
            ) {
              this.ProjectCategoryList = value.ProjectsList;
            }
          }
        });
        if (this.ProjectCategoryList != null && this.ProjectCategoryList != undefined && this.ProjectCategoryList.length > 0) {
          this.ProjectCategoryList.forEach(value => {
            this.projectList.push({
              Id: value.ProjectId,
              Name: value.ProjectName
            });
          });
          this.courseDetailForm.controls['category'].setValue('');
          this.courseDetailForm.get('category').disable();
          return this.projectList;
        }
      }
      else
        return [];
    }
  }

  filterCategory() {
    this.categoryList = [];
    this.courseDetailForm.controls['category'].setValue('');
    if (this.courseDetailForm.get('repoType').value == 'Project' && this.ProjectCategoryList.length > 0) {
      this.ProjectCategoryList.forEach(value => {
        if (this.courseDetailForm.get('project').value !== '') {
          this.courseDetailForm.get('category').enable();
          if (
            value.ProjectId.toLowerCase() ===
            this.courseDetailForm.get('project').value.toLowerCase() && value.CategoryList.length > 0
            && !this.isNullOrEmpty(value.CategoryList)
          ) {
            this.categoryList = value.CategoryList;
          }
        }
      });
      if (this.categoryList.length > 0) {
        return this.categoryList;
      } else {
        // this.noResultsForSkill = true;
        return [];
      }
    } else if (this.courseDetailForm.get('repoType').value == 'Account' && this.accountProjectCategoryList.length > 0) {

      if (this.accountProjectCategoryList) {
        this.accountProjectCategoryList.forEach(value => {
          if (value.AccountId.toLowerCase() ===
            this.courseDetailForm.get('account').value.toLowerCase()) {
            if (!this.isNullOrEmpty(value.CategoryList) && value.CategoryList.length > 0)
              this.courseDetailForm.get('category').enable();
            this.categoryList = value.CategoryList;
          }
          //else return [];
        });
      }
      if (this.categoryList.length > 0) {
        return this.categoryList;
      } else {
        // this.noResultsForSkill = true;
        return [];
      }
    }
    else {
      if (this.existingCourseData.Category != null) {
        if (this.ProjectCategoryList) {
          this.ProjectCategoryList.forEach(value => {
            if (this.courseDetailForm.get('project').value !== '') {
              this.courseDetailForm.get('category').enable();
              if (
                value.ProjectId.toLowerCase() ===
                this.courseDetailForm.get('project').value.toLowerCase() && value.CategoryList.length > 0
                && !this.isNullOrEmpty(value.CategoryList)
              ) {
                this.categoryList = value.CategoryList;
              }
            }
          });
        }
        else
          return [];
      }
    }
  }
  setDefaultValuesForTaxonomy() {
    if (this.courseDetailForm.get('repoType').value != '')
      this.courseDetailForm.get('isCourseInternal').setValue('Yes');
    else
      this.courseDetailForm.get('isCourseInternal').setValue('No');
    this.courseDetailForm.get('academy').setValue('');
    this.courseDetailForm.get('genre').setValue('');
    this.courseDetailForm.get('skill').setValue('');
    this.courseDetailForm.get('vendor').setValue('');
    this.courseDetailForm.get('account').setValue('');
    this.courseDetailForm.get('project').setValue('');
    this.courseDetailForm.get('category').setValue('');
    this.courseDetailForm.get('project').disable();
    this.courseDetailForm.get('category').disable();

    this.courseDetailForm.controls['academy'].markAsUntouched();
    this.courseDetailForm.controls['genre'].markAsUntouched();
    this.courseDetailForm.controls['skill'].markAsUntouched();
    this.courseDetailForm.controls['vendor'].markAsUntouched();
    this.courseDetailForm.controls['account'].markAsUntouched();
    this.courseDetailForm.controls['project'].markAsUntouched();
    this.courseDetailForm.controls['category'].markAsUntouched();

    this.courseDetailForm.controls['academy'].markAsPristine();
    this.courseDetailForm.controls['genre'].markAsPristine();
    this.courseDetailForm.controls['skill'].markAsPristine();
    this.courseDetailForm.controls['vendor'].markAsPristine();
    this.courseDetailForm.controls['account'].markAsPristine();
    this.courseDetailForm.controls['project'].markAsPristine();
    this.courseDetailForm.controls['category'].markAsPristine();
  }
  addNewCategory() {
    if ((this.courseDetailForm.get('project').value != '' && this.courseDetailForm.get('project').value != undefined)
      || (this.courseDetailForm.get('account').value != '' && this.courseDetailForm.get('account').value != undefined)) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'contentUploadHomePopupDialogContainer';
      dialogConfig.data = 'AddCategory';
      // if(this.courseDetailForm.get('account').value !== ''||this.courseDetailForm.get('project').value !== '' )
      // dialogConfig.data = 'EnableAddCategory'
      // else
      // dialogConfig.data='DisableAddCategory'
      const dialogRef = this._popup.open(
        ContentUploadHomePopupsComponent,
        dialogConfig)
      dialogRef.afterClosed().subscribe((response: any) => {
        if (response != null && response != '') {
          this.newCategoryFromPopUp = response;
          if (this.newCategoryFromPopUp != null && this.newCategoryFromPopUp != '' &&
            ((this.categoryList == undefined || this.categoryList.length == 0) ||
              (this.categoryList.length > 0 && this.categoryList.indexOf(this.newCategoryFromPopUp) < 0))) {
            if (this.categoryList == undefined)
              this.categoryList = [];
            this.categoryList.push(this.newCategoryFromPopUp);
            this.courseDetailForm.controls['category'].setValue('');
            this.courseDetailForm.get('category').enable();
          }
        }
      });
    }
  }
  save(actionType) {
    //this.saveDisabled = true;
    //this.submitDisabled = true;
    this.isValid = true;
    const name = this.courseDetailForm.get('courseName').value;

    this.ValidateAllMandatoryFieldsInAFormm();
    if (this.isValid) {
      // this.courseDetailForm.setErrors({ invalid: true });
      this.setFormErrorMsg(false, '');
      if (this.isEdit === true) {
        this._contentUpload101Service
          .checkCourseNameExistsForEdit(name, this.courseUniqueId)
          .subscribe(isExists => {
            if (!isExists) {
              this.courseDetailForm.setErrors(null);
              this.OnContentFormSubmit(actionType);
            } else {
              this.setFormErrorMsg(
                true,
                'Sorry we were unable to save the form! Please review the entered data.'
              );
              this.courseDetailForm.get('courseName').setErrors({ isInvalid: true });
              this.courseNameError = this.errorMsgs.duplicate_course_name;
              this.courseDetailForm.setErrors({ invalid: true });
            }
          });
      } else {
        this.isEdit = false;
        //this.contentForm.get('name').setErrors({ isInvalid: true });
        if (this.isValid && actionType === 'save') {
          this._contentUpload101Service
            .checkIfCourseNameExists101(name)
            .subscribe(isExists => {
              if (!isExists) {
                this.saveDisabled = true;
                this.courseDetailForm.setErrors(null);
                this.OnContentFormSubmit(actionType);
              } else {
                this.isValid = false;
                this.saveDisabled = false;
                this.setFormErrorMsg(
                  true,
                  'Sorry we were unable to save the form! Please review the entered data.'
                );
                this.courseDetailForm.get('courseName').setErrors({ isInvalid: true });
                this.courseNameError = this.errorMsgs.duplicate_course_name;
                this.courseDetailForm.setErrors({ invalid: true });
              }
            });
        }
        else {
          this.saveDisabled = false;
          this.submitDisabled = false;
        }
      }
    } else {
      this.saveDisabled = false;
    }
  }
  ValidateAllMandatoryFieldsInAFormm() {
    this.isValid = true;

    this.vendorErr = false;
    this.academyErr = false;
    this.genreErr = false;
    this.skillErr = false;
    this.courseNameErr = false;
    this.badgeNameErr = false;
    this.descriptionErr = false;
    this.topicsCoveredErr = false;
    this.durationErr = false;
    this.contentOwnerErr = false;
    this.courseMaterialLinkErr = false;
    this.courseMaterialFileErr = false;
    this.typeErr = false;
    this.expertMidsErr = false;
    //if(this.courseDetailForm.valid){
    if (this.isNullOrEmpty(this.courseDetailForm.get('repoType').value)) {
      this.isValid = false;
    }
    if (this.isNullOrEmpty(this.courseDetailForm.get('isCourseInternal').value)) {
      this.isValid = false;
    }
    if (!this.isNullOrEmpty(this.courseDetailForm.get('repoType').value) &&
      this.courseDetailForm.get('repoType').value == 'Yorbit') {
      if (this.isNullOrEmpty(this.courseDetailForm.get('academy').value)) {
        this.isValid = false;
        this.academyErr = true;
      }
      if (this.isNullOrEmpty(this.courseDetailForm.get('genre').value)) {
        this.isValid = false;
        this.genreErr = true;
      }
      if (this.isNullOrEmpty(this.courseDetailForm.get('skill').value)) {
        this.isValid = false;
        this.skillErr = true;
      }
      if (this.courseDetailForm.get('isCourseInternal').value != 'Yes') {
        if (this.isNullOrEmpty(this.courseDetailForm.get('vendor').value)) {
          this.isValid = false;
          this.vendorErr = true;
        }
      }
    }
    else if (!this.isNullOrEmpty(this.courseDetailForm.get('repoType').value) &&
      this.courseDetailForm.get('repoType').value != 'Yorbit') {
      if (this.courseDetailForm.get('repoType').value == 'Account') {
        this.courseDetailForm
          .get('RepoId')
          .setValue(this.courseDetailForm.get('account').value);
      }
      if (this.courseDetailForm.get('repoType').value == 'Project'
        && !this.isNullOrEmpty(this.courseDetailForm.get('project').value)) {
        let projectId = this.courseDetailForm.get('project').value;
        let projectDetails = this.projectList.filter(x => x.Id == projectId)[0];
        this.courseDetailForm
          .get('RepoId')
          .setValue(projectDetails.Id);
        this.courseDetailForm.get('RepoName').
          setValue(projectDetails.Name);
      }
      if (this.courseDetailForm.get('repoType').value == 'Account'
        && !this.isNullOrEmpty(this.courseDetailForm.get('account').value)) {
        let accountId = this.courseDetailForm.get('account').value;
        let accountDetails = this.accountList.filter(x => x.Id == accountId)[0];
        this.courseDetailForm
          .get('RepoId')
          .setValue(accountDetails.Id);
        this.courseDetailForm.get('RepoName').
          setValue(accountDetails.Name);
      }
      if (this.isNullOrEmpty(this.courseDetailForm.get('account').value)) {
        this.isValid = false;
        this.accountErr = true;
      }
      if (this.courseDetailForm.get('repoType').value == 'Project' && this.isNullOrEmpty(this.courseDetailForm.get('project').value)) {
        this.isValid = false;
        this.projectErr = true;
      }
      if (this.isNullOrEmpty(this.courseDetailForm.get('category').value)) {
        this.isValid = false;
        this.categoryErr = true;
      }
    }
    if (this.isNullOrEmpty(this.courseDetailForm.get('courseName').value)) {
      this.courseDetailForm.get('courseName').setErrors({ isInvalid: true });
      this.isValid = false;
      this.courseNameError = this.errorMsgs.course_name;
      //this.courseNameErr=true;
      //this.courseDetailForm.get('courseName').setErrors({ isInvalid: true });
      this.courseDetailForm.controls['courseName'].markAsTouched();
      this.courseDetailForm.controls['courseName'].markAsDirty();
    }
    if (this.courseDetailForm.get('isCourseInternal').value != 'Yes') {
      if (this.isNullOrEmpty(this.courseDetailForm.get('topicsCovered').value)
        || (!this.isNullOrEmpty(this.courseDetailForm.get('topicsCovered').value)
          && this.courseDetailForm.get('topicsCovered').value.toString().length < 50)) {
        this.isValid = false;
        this.topicsCoveredErr = true;
      }
    }
    if (this.isNullOrEmpty(this.courseDetailForm.get('description').value)
      || (!this.isNullOrEmpty(this.courseDetailForm.get('description').value)
        && this.courseDetailForm.get('description').value.toString().length < 250)) {
      this.isValid = false;
      this.descriptionErr = true;
    }
    if (this.isNullOrEmpty(this.courseDetailForm.get('badgeName').value)) {
      this.courseDetailForm.get('badgeName').setErrors({ isInvalid: true });
      this.isValid = false;
      this.courseDetailForm.controls['badgeName'].markAsTouched();
      this.courseDetailForm.controls['badgeName'].markAsDirty();
      //this.badgeNameErr=true;
      //this.courseDetailForm.get('courseName').setErrors({ isInvalid: false });
    }
    if (this.isNullOrEmpty(this.courseDetailForm.get('isHiddenCourse').value)) {
      this.isValid = false;
    }
    if (this.isNullOrEmpty(this.courseDetailForm.get('isRestrictedCourse').value)) {
      this.isValid = false;
    }
    if (this.isNullOrEmpty(this.courseDetailForm.get('type').value)) {
      this.isValid = false;
      this.typeErr = true;
    }
    if (this.isNullOrEmpty(this.courseDetailForm.get('proficiency').value)) {
      this.isValid = false;
      this.typeErr = true;
    }
    if (!this.isNullOrEmpty(this.courseDetailForm.get('expertMids').value) && ValidateExpert101(this.courseDetailForm.get('expertMids')).isInvalid) {
      this.isValid = false;
      this.expertMidsErr = true;
    }
    if (this.isNullOrEmpty(this.courseDetailForm.get('contentOwner').value)) {
      this.isValid = false;
      this.contentOwnerErr = true;
      this.courseDetailForm.get('contentOwner').setErrors({ isInvalid: true });
      this.courseDetailForm.controls['contentOwner'].markAsTouched();
      this.courseDetailForm.controls['contentOwner'].markAsDirty();
    }
    if (this.isNullOrEmpty(this.courseDetailForm.get('isCourseMaterialAvailable').value)) {
      this.isValid = false;
    }
    if (!this.isNullOrEmpty(this.courseDetailForm.get('isCourseMaterialAvailable').value)
      && this.courseDetailForm.get('isCourseMaterialAvailable').value == 'Yes') {
      if (this.isNullOrEmpty(this.courseDetailForm.get('courseMaterialType').value)) {
        this.isValid = false;
        //this.setFormErrorMsg(true, 'CourseMaterialType field is empty.');
      }
    }
    if (!this.isNullOrEmpty(this.courseDetailForm.get('isCourseMaterialAvailable').value)
      && this.courseDetailForm.get('isCourseMaterialAvailable').value == 'Yes' &&
      !this.isNullOrEmpty(this.courseDetailForm.get('courseMaterialType').value)
      && this.courseDetailForm.get('courseMaterialType').value == 'Hyperlink') {
      if (this.isNullOrEmpty(this.courseDetailForm.get('courseMaterialLink').value)) {
        this.isValid = false;
        this.courseMaterialLinkErr = true;
        //this.setFormErrorMsg(true, 'CourseMaterialLink field is empty.');
      }
    }
    if (this.courseDetailForm.get('isCourseInternal').value != 'Yes') {
      if (this.isNullOrEmpty(this.courseDetailForm.get('duration').value)
        || ValidateDuration101(this.courseDetailForm.get('duration')).fieldIsInvalid
        || this.errorMsgs.course_duration == 'Minutes and Seconds fields can not be more than 59'
        || this.errorMsgs.course_duration == 'Minutes field can not be more than 59'
        || this.errorMsgs.course_duration == 'Seconds field can not be more than 59') {

        this.isValid = false;
        this.durationErr = true;
        //this.setFormErrorMsg(true, 'duration field is empty.');
      }
    }
    if (!this.isNullOrEmpty(this.courseDetailForm.get('isCourseMaterialAvailable').value)
      && this.courseDetailForm.get('isCourseMaterialAvailable').value == 'Yes' &&
      !this.isNullOrEmpty(this.courseDetailForm.get('courseMaterialType').value)
      && this.courseDetailForm.get('courseMaterialType').value == 'Download Content') {
      //console.log(this.existingCourseData)
      if ((this.isNullOrEmpty(this.courseMaterialFile) || this.courseMaterialFile === undefined || this.btnTitle != 'File upload is success.')
        && (this.isNullOrEmpty(this.existingCourseData) || (!this.isNullOrEmpty(this.existingCourseData) && this.existingCourseData.CourseMaterialLink == null))) {
        this.isValid = false;
        this.courseMaterialFileErr = true;
        //this.setFormErrorMsg(true, 'courseMaterial field is empty.');
      }
    }
  }

  onMaterialChange() {
    this.courseMaterialFileName = "Select a file";
    this.courseMaterialFile = null;
    if (this.existingCourseData != null) {
      if (this.courseDetailForm.get('courseMaterialType').value == 'Hyperlink') {
        if (this.existingCourseData.CourseMaterialType == "Internal") {
          this.courseMaterialFileName = this.existingCourseMaterialLink != '' ? this.existingCourseMaterialLink.substring(this.existingCourseMaterialLink.lastIndexOf('/') + 1) : "Select a file";
          this.courseDetailForm.get('courseMaterialLink').setValue(this.existingCourseMaterialLink);
          this.existingCourseData.CourseMaterialLink = this.existingCourseMaterialLink;
        } else {
          this.courseDetailForm.get('courseMaterialLink').setValue('');
          this.existingCourseData.CourseMaterialLink = null;
          this.courseMaterialFileName = "Select a file";
        }
      } else {
        if (this.existingCourseData.CourseMaterialType == "External") {
          this.courseDetailForm.get('courseMaterialLink').setValue(this.existingCourseMaterialLink);
          this.existingCourseData.CourseMaterialLink = this.existingCourseMaterialLink;
        } else {
          this.courseDetailForm.get('courseMaterialLink').setValue('');
          this.existingCourseData.CourseMaterialLink = null;
        }
      }
    }

  }

  getExpertMIds(expertList) {
    let experts = '';
    if (expertList && expertList.length > 0) {
      for (let i = 0; i < expertList.length; i++) {
        if (!this.isNullOrEmpty(expertList[i]))
          experts = experts + expertList[i];
      }
      let expLen = experts.length;
      if (experts[expLen - 1] == ',') {
        experts = experts.slice(0, expLen - 2);
      }
      return experts.trim();
    } else {
      return null;
    }
  }
  editContentUpload(existingCourseToPopulateData) {
    this._graphSvc.getUserName(existingCourseToPopulateData.ContentOwner)
      .subscribe(response => {
        this.contentOwnerMid = existingCourseToPopulateData.ContentOwner;
        this.contentOwnerName = response.displayName;
        this.courseDetailForm.controls['contentOwner'].setValue(this.contentOwnerName);
      });
    this.courseDetailForm.controls['repoType'].disable();
    this.selectedPrereqCourses = [];
    this.selectedPostRecommCourses = [];
    //let expertMidSplit;
    // if (existingCourseToPopulateData.ExpertMids !== null) {
    //   expertMidSplit = existingCourseToPopulateData.ExpertMids.split('');
    // }
    if (existingCourseToPopulateData.RepoType != null && existingCourseToPopulateData.RepoType == 'Yorbit') {
      if (existingCourseToPopulateData.Academy != null) {
        this.courseDetailForm.patchValue({ academy: existingCourseToPopulateData.Academy });
        this.filterGenre();
      }
      if (existingCourseToPopulateData.Genre != null) {
        this.courseDetailForm.patchValue({ genre: existingCourseToPopulateData.Genre });
        this.filterSkill();
      }
    }

    this.contentOwnerMid = existingCourseToPopulateData.contentOwner;
    this.courseDetailForm.patchValue({
      skill: existingCourseToPopulateData.Skill,
      courseName: existingCourseToPopulateData.CourseName,
      topicsCovered: existingCourseToPopulateData.TopicsCovered,
      description: existingCourseToPopulateData.Description,
      type: existingCourseToPopulateData.Type,
      repoType: existingCourseToPopulateData.RepoType,
      isCourseInternal: existingCourseToPopulateData.IsCourseInternal === true ? 'Yes' : 'No',
      isHiddenCourse: existingCourseToPopulateData.IsHiddenCourse === true ? 'Yes' : 'No',
      isRestrictedCourse: existingCourseToPopulateData.IsRestrictedCourse === true ? 'Yes' : 'No',
      isCourseMaterialAvailable: existingCourseToPopulateData.IsCourseMaterialAvailable === true ? 'Yes' : 'No',
      courseMaterialType: existingCourseToPopulateData.CourseMaterialType == "Internal" ? "Download Content" : "Hyperlink",
      vendor: existingCourseToPopulateData.Vendor,
      duration: existingCourseToPopulateData.Duration,
      preRequisiteCourseIDs: existingCourseToPopulateData.PreRequisiteCourseIDs,
      postRecommendedCourseIDs: existingCourseToPopulateData.PostRecommendedCourseIDs,
      badgeId: existingCourseToPopulateData.Badge,
      expertMids: existingCourseToPopulateData.ExpertMids,

      // existingCourseToPopulateData.ExpertMids !== null
      //     ? this.getExpertMIds(expertMidSplit)
      //     : null,
      courseMaterialLink: existingCourseToPopulateData.CourseMaterialLink,
      badgeLink: existingCourseToPopulateData.BadgeLink,
      proficiency: existingCourseToPopulateData.Proficiency
    });
    this.courseMaterialFileName = existingCourseToPopulateData.CourseMaterialLink;

    this.courseMaterialFileName = this.courseMaterialFileName != null ? this.courseMaterialFileName.substring(this.courseMaterialFileName.lastIndexOf('/') + 1) : "";
    //this.courseDetailForm.setValue({project:existingCourseToPopulateData.RepoId});
    //this.courseDetailForm.controls['project'].setValue('RepoId');
    if (existingCourseToPopulateData.RepoType != null &&
      existingCourseToPopulateData.RepoType == 'Account') {
      if (existingCourseToPopulateData.RepoName != null) {
        this.courseDetailForm.patchValue({ account: existingCourseToPopulateData.RepoName });
        this.filterCategory();
      }
      if (existingCourseToPopulateData.Category != null) {
        this.courseDetailForm.patchValue({ category: existingCourseToPopulateData.Category });
      }
    }
    if (existingCourseToPopulateData.RepoType != null &&
      existingCourseToPopulateData.RepoType == 'Project') {
      if (existingCourseToPopulateData.AccountName != null) {
        this.courseDetailForm.patchValue({ account: existingCourseToPopulateData.AccountId });
        this.filterProject();
      }
      if (existingCourseToPopulateData.RepoId != null) {
        this.courseDetailForm.patchValue({ project: existingCourseToPopulateData.RepoId });
        this.filterCategory();
      }
      if (existingCourseToPopulateData.Category != null) {
        this.courseDetailForm.patchValue({ category: existingCourseToPopulateData.Category });
      }
    }
    if (existingCourseToPopulateData.PreRequisiteCourseIDs !== null) {
      existingCourseToPopulateData.PreRequisiteCourseIDs.forEach(splitId => {
        if (this.filteredPreReqCourses.length > 0) {
          this.filteredPreReqCourses.forEach(fliteredData => {
            if (fliteredData.Id == splitId) {
              fliteredData.checkedPreReq = true;
              fliteredData.Name = fliteredData.Name;
              fliteredData.Expertise = fliteredData.Expertise;
              this.selectedPrereqCourses.push(fliteredData);
            }
          });
        }
      });
    }
    if (existingCourseToPopulateData.PostRecommendedCourseIDs !== null) {
      existingCourseToPopulateData.PostRecommendedCourseIDs.forEach(splitId => {
        if (this.filteredPostRecommCourses.length > 0) {
          this.filteredPostRecommCourses.forEach(fliteredPostData => {
            if (fliteredPostData.Id == splitId) {
              fliteredPostData.checkedPostRecomm = true;
              fliteredPostData.Name = fliteredPostData.Name;
              fliteredPostData.Expertise = fliteredPostData.Expertise;
              this.selectedPostRecommCourses.push(fliteredPostData);
            }
          });
        }
      });
    }
    if (this.isApproved) {
      this.courseDetailForm.get('genre').disable();
      this.courseDetailForm.get('skill').disable();
    }
    this.courseDetailForm.patchValue({ badgeName: existingCourseToPopulateData.BadgeName });
    this.courseDetailForm.patchValue({ SkillIds: existingCourseToPopulateData.SkillIds });
    this.courseDetailForm.patchValue({ project: existingCourseToPopulateData.RepoId });
    this.isContentLoaded = true;
    // this._contentUpload101Service
    //   .getBadgeDetails(existingCourseToPopulateData.Badge)
    //   .subscribe(badgeDetails => {
    //     this.showloading = false;
    //     if (!this.isNullOrEmpty(badgeDetails.BadgeName)) {
    //       this.courseDetailForm.get('badge').setValue(badgeDetails.BadgeName);
    //     }
    //     this.findInvalidControls();
    //   });
  }
  openSearchCODialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    //shareDialogContainer

    dialogConfig.data = {
      type: 'contentOwner',
      list: [this.contentOwnerMid]
    };

    const dialogref = this._popup.open(SearchEvaluatorPopupComponent, dialogConfig);

    dialogref.afterClosed().subscribe(data => {
      if (null != data) {
        this.courseDetailForm.controls['contentOwner'].setValue(data[0].displayName);
        let mid = data[0].userPrincipalName.split("@")[0];
        this.contentOwnerMid = mid;
      }
    });
  }

  //Form Modification Detect
  formModificationDetect() {
    this.courseDetailForm.valueChanges.subscribe(data => {
      this.saveDisabled = false;
    })
  }

  popInformationSupport() {
    let data = {
      msg: "Please raise a ticket on  <a href='"+ this.globals.isupportUrl+"' target='_blank'>"+ this.globals.isupportUrl+"</a> if the project is not listed for the selected account",
      title: 'Information',
      field: ''
    };

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    dialogConfig.data = {
      data: data,
      width: '250px',
      height: '250px',
      type: 'info'
    };

    const response = this._popup.open(
      ContentUploadStatusPopupComponent,
      dialogConfig
    );
  }

  checkForEmptyText(fieldName) {
    if (fieldName == 'courseName') {
      if (this.courseDetailForm.get('courseName').value.trim().length == 0) {
        this.courseDetailForm.controls['courseName'].markAsTouched();
        this.courseDetailForm.controls['courseName'].markAsDirty();
        this.courseDetailForm.get('courseName').setErrors({ isInvalid: true });
        this.courseNameError = this.errorMsgs.course_name;
      }
    }
    if (fieldName == 'badgeName') {
      if (this.courseDetailForm.get('badgeName').value.trim().length == 0) {
        this.courseDetailForm.controls['badgeName'].markAsTouched();
        this.courseDetailForm.controls['badgeName'].markAsDirty();
        this.courseDetailForm.get('badgeName').setErrors({ isInvalid: true });
      }
    }
  }
  getCourseProficiencyList() {
    this._contentUpload101Service.getCourseProficiencyList().subscribe(
      (response: any) => {
        if (response != null && response.length > 0) {
          this.courseProficiencyList = response;
        } else {
          this.courseProficiencyList = [];
        }
      },
      (error: any) => {
        this.courseProficiencyList = [];
      }
    );
  }
}
