<mat-dialog-content>

    <div class="closePopupBtn" (click)="close()">
        <button class="mat-button mat-icon-button">
            <mat-icon class="close-popup-btn">close</mat-icon>
        </button>
    </div>
    <div mat-dialog-title class="popup-title title">{{ data.title }}</div>
    <div *ngIf="data.batchList.length > 0">
        <div fxLayout="column" fxLayoutGap="10px" class="batches">
            <div class="select-msg">Please Select Batch</div>
            <div>
                <div class="batch-details batch-header">
                    <div class="">Batch Name</div>
                    <div class="">Start Date</div>
                    <div class="">End Date</div>
                    <div class="">Seats Available</div>
                </div>
                <div class="data-container">
                    <div *ngFor="let batch of data.batchList; let i = index">
                        <div class="batch-details batch" [ngClass]="{'batch-pd': data.batchList.length > 3}">
                            <div class="batch-id">Batch {{ batch.BatchId.split('-')[1] }}</div>
                            <div>{{ batch.StartDate | date: "MM/dd/yyyy" }}</div>
                            <div>{{ batch.EndDate | date: "MM/dd/yyyy" }}</div>
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <div>{{ batch.TotalSeatsAvailable }}/{{ batch.MaximumSeatsAvailable }}</div>
                                <div [ngClass]="{'btn-radio': data.batchList.length > 3}">
                                    <input type="radio" id="i" name="batch" value="{{batch.BatchId}}"
                                        (change)="onRadioChange($event)">
                                </div>
                            </div>
                        </div>
                        <mat-divider *ngIf="data.batchList.length - 1 > i"></mat-divider>
                    </div>
                </div>
            </div>
        </div>
        <div class="action-btn">
            <button class="action-button btn-save" [ngClass]="{ 'mouseDisabled pointEventDisable': !selectedBatchId }"
                (click)="save()">Next</button>
        </div>
    </div>
    <div *ngIf="data.batchList.length === 0" class="no-batch-details">You can not proceed further because there is no
        upcoming batch. Please wait until that.</div>
    <div *ngIf="!data.batchList" class="no-batch-details">
        Something went wrong, please try again later
    </div>

</mat-dialog-content>