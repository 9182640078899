<!-- <p>
  coursedetails-form101 works!
</p> -->
<div *ngIf="!isContentLoaded">
  <app-preloader></app-preloader>
</div>
<div *ngIf="isContentLoaded">
  <div class="parentDiv">
    <div class="CourseDetailsParentDiv">
      <div class="contentUploadForm">
        <span class="toBeBold">101</span> <span class="fontSizeToBeSmall"> Content Upload Form</span>
      </div>
      <form [formGroup]="courseDetailForm" autocomplete="off">
        <div class="topDiv">
          <div fxLayout="row">
            <div class="contentDiv">
              <div ngClass="filedLabel">
                <label class="padding5RadioButtons">Content Type
                  <span class="mandatory-field-indicator">*</span>
                </label>
              </div>
              <div class="divDisplay paddingTop10" *ngFor="let radio of contentTypeRadio">
                <label class="paddingRight5">
                  <input class="verticalAlign" (change)="setDefaultValuesForTaxonomy()" type="radio"
                    value="{{radio.value}}" formControlName="repoType" />{{radio.name}}
                </label>
              </div>
            </div>
            <div class="contentDiv"
              *ngIf="courseDetailForm.get('repoType').value!='Account' && courseDetailForm.get('repoType').value!='Project'">
              <div ngClass="fieldLabel">
                <label class="padding5RadioButtons">Is Course Internal
                  <span class="mandatory-field-indicator">*</span>
                </label>
              </div>
              <div class="divDisplay paddingTop10 width60" *ngFor="let radio of YesNoRadio">
                <input class="verticalAlign leftMargin" type="radio" value={{radio}}
                  formControlName="isCourseInternal" />{{radio}}
              </div>
            </div>
            <div class="contentDiv">
              <div
                *ngIf="courseDetailForm.get('repoType').value!='Account' && courseDetailForm.get('repoType').value!='Project' && courseDetailForm.get('isCourseInternal').value != 'Yes'">
                <div ngClass="fieldLabel">
                  <label class="padding5">Vendor
                    <span class="mandatory-field-indicator">*</span>
                  </label>
                </div>
                <div class="paddingTop10" style="width: 250px;">
                  <select [ngClass]="{greyscaleDropdown:submitInProgress}"
                    class="verticalAlign contentwidth borderRadius height25" formControlName="vendor">
                    <option value="" disabled selected>Select a vendor</option>
                    <option *ngFor="let vendor of vendorList" title="{{vendor.Vendor}}" [value]="vendor.Vendor">
                      {{(vendor.Vendor.length>30)?(vendor.Vendor.slice(0,30)+"..."):(vendor.Vendor)}}</option>
                  </select>
                  <div *ngIf="
                    !courseDetailForm.controls.vendor.valid &&
                    (courseDetailForm.controls.vendor.dirty ||
                    courseDetailForm.controls.vendor.touched||vendorErr)
                    " class="error-msg">
                    Please choose a vendor.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div fxLayout="row" *ngIf="courseDetailForm.get('repoType').value=='Yorbit'">
            <!-- <div  ngClass="courseReqFormFields" [ngStyle.lt-md]="'border:none'">
        <div ngClass="courseReqFormField"> -->
            <div class="contentDiv">
              <div ngClass="fieldLabel">
                <label class="padding5">
                  Academy
                  <span class="mandatory-field-indicator">*</span>
                </label>
              </div>
              <div ngClass="fieldInput" class="paddingTop10">
                <select class="contentwidth borderRadius height25" [ngClass]="{mouseDisabled:submitInProgress}"
                  (change)="filterGenre()" formControlName="academy" required>
                  <option value="" disabled selected>Select an academy</option>
                  <option *ngFor="let academy of academyList" title="{{academy}}" [value]="academy">
                    {{(academy.length>30)?(academy.slice(0,30)+"..."):(academy) }}</option>
                </select>
                <div *ngIf="
              !courseDetailForm.controls.academy.valid &&
              (courseDetailForm['controls'].academy.dirty ||
              courseDetailForm['controls'].academy.touched ||academyErr)
            " class="error-msg">
                  Please choose the academy to which the course belongs.
                </div>
              </div>
            </div>
            <div class="contentDiv">
              <div ngClass="fieldLabel">
                <label class="padding5">
                  Genre
                  <span class="mandatory-field-indicator">*</span>
                </label>
              </div>
              <div ngClass="fieldInput" class="paddingTop10">
                <select class="contentwidth borderRadius height25" [ngClass]="{
              mouseDisabled:
              courseDetailForm.get('academy').value == '' ||
              courseDetailForm.get('academy').value == undefined ||
              courseDetailForm.get('academy').value == null
            }" (change)="filterSkill()" title="{{
              courseDetailForm.get('academy').value == ''
                ? 'Please choose an Academy'
                : ''
            }}" formControlName="genre">
                  <option disabled selected value="">Select a genre</option>
                  <option *ngFor="let genre of genreList" title="{{genre}}" [value]="genre">{{
                    (genre.length>30)?(genre.slice(0,30)+"..."):(genre) }}
                  </option>
                </select>
                <div *ngIf="
              !courseDetailForm.controls.genre.valid &&
              (courseDetailForm['controls'].genre.dirty ||
              courseDetailForm.controls.genre.touched||genreErr)
            " class="error-msg">
                  Please choose the genre to which the course belongs.
                </div>
              </div>
            </div>
            <div class="contentDiv">
              <div ngClass="fieldLabel">
                <label class="padding5">
                  Skill
                  <span class="mandatory-field-indicator">*</span>
                </label>
              </div>
              <div ngClass="fieldInput" class="paddingTop10">
                <select class="contentwidth borderRadius height25" [ngClass]="{
              mouseDisabled:
              courseDetailForm.get('genre').value == '' ||
              courseDetailForm.get('genre').value == undefined ||
              courseDetailForm.get('genre').value == null
            }" title="{{
              courseDetailForm.get('genre').value == ''
                ? 'Please choose a Genre'
                : ''
            }}" formControlName="skill">
                  <option disabled selected value="">Select a skill
                  </option>
                  <option *ngFor="let skill of skillList" title="{{skill}}" [value]="skill">{{
                    (skill.length>30)?(skill.slice(0,30)+"..."):(skill) }}
                  </option>
                </select>
                <div *ngIf="
              !courseDetailForm.controls.skill.valid &&
              (courseDetailForm.controls.skill.dirty ||
              courseDetailForm.controls.skill.touched||skillErr)
            " class="error-msg">
                  Please choose the skill to which the course belongs.
                </div>
              </div>
            </div>
            <!-- </div>
      </div> -->
          </div>
          <div fxLayout="row" *ngIf="courseDetailForm.get('repoType').value!='Yorbit'">
            <!-- <div  ngClass="courseReqFormFields" [ngStyle.lt-md]="'border:none'">
        <div ngClass="courseReqFormField"> -->
            <div class="contentDiv">
              <div ngClass="fieldLabel">
                <label class="padding5">
                  Account
                  <span class="mandatory-field-indicator">*</span>
                </label>
              </div>
              <div ngClass="fieldInput" class="paddingTop10">
                <select class="contentwidth borderRadius height25" [ngClass]="{mouseDisabled:submitInProgress}"
                  (change)="filterProject();filterCategory();" formControlName="account" required>
                  <option value="" disabled selected>Please select an account</option>
                  <option *ngFor="let account of accountList" title="{{account.Name}}" [value]="account.Id">
                    {{(account.Name.length>30)?(account.Name.slice(0,30)+"..."):(account.Name) }}</option>
                </select>
                <div *ngIf="
              !courseDetailForm.controls.account.valid &&
              (courseDetailForm['controls'].account.dirty ||
              courseDetailForm['controls'].account.touched ||accountErr)
            " class="error-msg">
                  Please choose the account to which the course belongs.
                </div>
              </div>
            </div>
            <div class="contentDiv">
              <div ngClass="fieldLabel">
                <label class="padding5">
                  Project
                  <span class="mandatory-field-indicator"
                    *ngIf="courseDetailForm.get('repoType').value =='Project'">*</span>
                </label>
              </div>
              <div ngClass="fieldInput" class="paddingTop10">
                <select class="contentwidth borderRadius height25" [ngClass]="{
              mouseDisabled:courseDetailForm.get('repoType').value=='Account'||
              courseDetailForm.get('account').value == '' ||
              courseDetailForm.get('account').value == undefined ||
              courseDetailForm.get('account').value == null
            }" (change)="filterCategory()" title="{{
              courseDetailForm.get('account').value == ''
                ? 'Please choose an account'
                : ''
            }}" formControlName="project">
                  <option disabled selected value="">Please select a project</option>
                  <option *ngFor="let projectId of projectList" title="{{projectId.Id}}" [value]="projectId.Id">{{
                    (projectId.Id.length>30)?(projectId.Id.slice(0,30)+"..."):(projectId.Id) }}
                  </option>
                </select>

                <div class="info-icon" *ngIf="courseDetailForm.get('repoType').value =='Project'"
                  (click)="popInformationSupport()">i</div>
                <div *ngIf="
              courseDetailForm.get('repoType').value=='Project' && 
              !courseDetailForm.controls.project.valid &&
              (courseDetailForm['controls'].project.dirty ||
              courseDetailForm.controls.project.touched||projectErr)
            " class="error-msg">
                  Please choose the project to which the course belongs.
                </div>
              </div>
            </div>
            <div class="contentDiv" style="width:27%;">
              <div ngClass="fieldLabel">
                <label class="padding5">
                  Category
                  <span class="mandatory-field-indicator">*</span>
                </label>
              </div>
              <div ngClass="fieldInput" class="paddingTop10">
                <select class="contentwidth borderRadius height25" [ngClass]="{
                mouseDisabled: courseDetailForm.get('repoType').value=='Account'?
                (newCategory==null &&(courseDetailForm.get('account').value == '' ||
                courseDetailForm.get('account').value == undefined ||
                courseDetailForm.get('account').value == null||
                categoryList==undefined ||categoryList.length==0)):(courseDetailForm.get('repoType').value=='Project'?
                (newCategory==null &&(courseDetailForm.get('project').value == '' ||
                courseDetailForm.get('project').value == undefined ||
                courseDetailForm.get('project').value == null||
                categoryList==undefined ||categoryList.length==0)):false)
              }" title="{{
              courseDetailForm.get('category').value == ''
                ? 'Please choose a category'
                : ''
            }}" formControlName="category">
                  <option disabled selected value="">Please select a category
                  </option>
                  <option *ngFor="let category of categoryList" title="{{category}}" [value]="category">{{
                    (category.length>30)?(category.slice(0,30)+"..."):(category) }}
                  </option>
                </select>
                <div *ngIf="
              !courseDetailForm.controls.category.valid &&
              (courseDetailForm.controls.category.dirty ||
              courseDetailForm.controls.category.touched||categoryErr)
            " class="error-msg">
                  Please choose the category to which the course belongs.
                </div>
              </div>
            </div>
            <div style="width: 6.3%;" title="Add Category">
              <div class="mousePointer spriteIcons addIcon" [ngClass]="{mouseDisabled:(courseDetailForm.get('repoType').value=='Account'?
                (courseDetailForm.get('account').value == '' ||
                courseDetailForm.get('account').value == undefined ||
                courseDetailForm.get('account').value == null):(courseDetailForm.get('repoType').value=='Project'?
                (courseDetailForm.get('project').value == '' ||
                courseDetailForm.get('project').value == undefined ||
                courseDetailForm.get('project').value == null):false))}" (click)="addNewCategory()"></div>
            </div>
            <!-- </div>
      </div> -->
          </div>
        </div>
        <div class="topDiv">
          <div fxLayout="row">
            <div class="contentDiv">
              <div ngClass="fieldLabel">
                <label class="padding5">Course Name
                  <span class="mandatory-field-indicator">*</span>
                </label>
              </div>
              <div class="divDisplay paddingTop10">
                <input class="contentwidth borderRadius height25 padding-left"
                  style="background-color: white !important" matInput placeholder="Enter a course name"
                  formControlName="courseName" (focusout)="checkForEmptyText('courseName')" />
              </div>
              <div *ngIf=" (!courseDetailForm.controls.courseName.valid &&
            (courseDetailForm.controls.courseName.dirty ||
            courseDetailForm.controls.courseName.touched))" class="error-msg">
                <span *ngIf="(!courseDetailForm.controls.courseName.valid &&
              (courseDetailForm.controls.courseName.dirty ||
              courseDetailForm.controls.courseName.touched) && !courseDetailForm.get('courseName').errors?.isInvalid)">
                  {{errorMsgs.course_name}}</span>
                <span
                  *ngIf="courseDetailForm.get('courseName').errors!=null && courseDetailForm.get('courseName').errors.isInvalid">
                  {{courseNameError}}
                </span>
              </div>
            </div>

            <div class="contentDiv">
              <div ngClass="fieldLabel">
                <label class="padding5">Badge Name
                  <span class="mandatory-field-indicator">*</span>
                </label>
              </div>
              <div class="divDisplay paddingTop10">
                <input YorbitWorkspaceAutocompleteOff class="contentwidth borderRadius height25 padding-left"
                  style="background-color: white !important" matInput placeholder="Enter a badge name"
                  formControlName="badgeName" (focusout)="checkForEmptyText('badgeName')" />
              </div>
              <div *ngIf="(!courseDetailForm.controls.badgeName.valid &&
            (courseDetailForm.controls.badgeName.dirty ||
            courseDetailForm.controls.badgeName.touched))" class="error-msg">
                {{errorMsgs.badge_name}}
              </div>
            </div>
            <div class="contentDiv">
              <div class="contentDivRight">
                <div ngClass=fieldLabel1>
                  <label class="padding5RadioButtons">Hidden course?
                    <span class="mandatory-field-indicator">*</span>
                  </label>
                </div>
                <div class="divDisplay paddingTop10 width60" *ngFor="let radio of YesNoRadio">
                  <input id="isHiddenCourse" class="verticalAlign leftMargin" type="radio"
                    [attr.disabled]="courseDetailForm.get('isRestrictedCourse').value=='Yes'?'':null" value={{radio}}
                    formControlName="isHiddenCourse" />{{radio}}
                </div>
              </div>
              <div class="contentDivRight">
                <div ngClass=fieldLabel1>
                  <label class="padding5RadioButtons">Restricted course?
                    <span class="mandatory-field-indicator">*</span>
                  </label>
                </div>
                <div class="divDisplay paddingTop10 width60" *ngFor="let radio of YesNoRadio">
                  <input class="verticalAlign leftMargin" type="radio"
                    [attr.disabled]="courseDetailForm.get('isHiddenCourse').value=='Yes'?'':null" value={{radio}}
                    formControlName="isRestrictedCourse" />{{radio}}
                </div>
              </div>
            </div>
          </div>
          <div fxLayout="row">
            <div class="contentDiv" *ngIf="courseDetailForm.get('isCourseInternal').value != 'Yes'">
              <div ngClass="fieldLabel">
                <label class="padding5">Topics Covered
                  <span class="mandatory-field-indicator">*</span>
                </label>
              </div>
              <div class="text-area-desc topics-covered">
                <div class="divDisplay paddingTop10" style="width: 250px;">
                  <textarea class="" placeholder="Enter topics covered (minimum 50 characters)"
                    formControlName="topicsCovered"></textarea>
                </div>
              </div>
              <div *ngIf="
        (courseDetailForm.controls.topicsCovered.dirty ||
        courseDetailForm.controls.topicsCovered.touched||topicsCoveredErr)
      " class="error-msg">
                <!-- <span *ngIf="isNullOrEmpty(courseDetailForm.get('topicsCovered').value)">Please mention the topics
              covered.</span> -->
                <span *ngIf="courseDetailForm.get('topicsCovered').errors?.isInvalid">Please enter at least 50
                  characters.</span>
              </div>
            </div>
            <div class="contentDiv" *ngIf="courseDetailForm.get('isCourseInternal').value == 'Yes'"></div>
            <div class="contentDiv">
              <div ngClass="fieldLabel">
                <label class="padding5">Pre-requisite Course ID(s)</label>
              </div>
              <!-- <div class="contentDivRight divDisplay paddingTop10"> -->
              <textarea class="textareaWidth contentheight1 borderRadius"
                placeholder="Please enter pre-requiste course IDs." formControlName="preRequisiteCourseIDs"
                style="display:none"></textarea>
              <!-- </div> -->
              <div class="textareaWidth contentheight1 borderRadius prereq-control" style="margin-top:10px;">
                <div class="selected-courses-box" *ngIf="!isNullOrEmpty(selectedPrereqCourses)">
                  <div *ngFor="let item of selectedPrereqCourses" fxLayout="row">
                    <div class="course-name" fxFlex="90" title="{{item.Name + '-' + item.Expertise}}">{{ item.Name + "-"
                      +
                      item.Expertise }}</div>
                    <div fxFlex="10" (click)="removeItem(item, true)" class="del-icon-holder floatLeft mousePointer">
                      <div class="yorbitSprite del-icon-grey-small"></div>
                    </div>
                  </div>
                </div>
                <div class="selected-courses-box" *ngIf="isNullOrEmpty(selectedPrereqCourses)" fxLayout="column"
                  fxLayoutAlign="center center">
                  <div class="placeholder-msg">Pre-requisite Courses selected by you will <br> be shown here.</div>
                  <div class="placeholder-msg">Please select from the below link.</div>
                </div>
              </div>
              <div class="back-btn mousePointer" (click)="openYorbitCourses(true)"
                [ngClass]="{'mouseDisabled':isApproved}">
                Select Pre-requisite Course(s)</div>
            </div>
            <div class="contentDiv alignRight">
              <div ngClass="fieldLabel">
                <label class="padding5">Post Recommended Course ID(s)</label>
              </div>
              <textarea class="textareaWidth contentheight1 borderRadius divDisplay paddingTop10"
                placeholder="Please enter post recommended course IDs." formControlName="postRecommendedCourseIDs"
                style="display:none"></textarea>
              <div class="textarea Width contentheight1 borderRadius" class="prereq-control" style="margin-top:10px;">
                <div class="selected-courses-box" *ngIf="!isNullOrEmpty(selectedPostRecommCourses)">
                  <div *ngFor="let item of selectedPostRecommCourses" fxLayout="row">
                    <div class="course-name" fxFlex="90" title="{{item.Name + '-' + item.Expertise}}">{{ item.Name + "-"
                      +
                      item.Expertise }}</div>
                    <div fxFlex="10" (click)="removeItem(item, false)" class="del-icon-holder floatLeft mousePointer">
                      <div class="yorbitSprite del-icon-grey-small"></div>
                    </div>
                  </div>
                </div>
                <div class="selected-courses-box" *ngIf="isNullOrEmpty(selectedPostRecommCourses)" fxLayout="column"
                  fxLayoutAlign="center center">
                  <div class="placeholder-msg">Post Recommended Courses selected by you will be shown here.
                  </div>
                  <div class="placeholder-msg">Please select from the below link.</div>
                </div>
              </div>
              <div class="back-btn mousePointer" (click)="openYorbitCourses(false)"
                [ngClass]="{'mouseDisabled':isApproved}">Select Post Recommended Course(s)</div>
            </div>
          </div>
          <div fxLayout="row">
            <div class="contentDiv"
              [ngClass]="{'internalCourse':courseDetailForm.get('isCourseInternal').value == 'Yes'}">
              <div ngClass="fieldLabel">
                <label class="padding5">Course Description
                  <span class="mandatory-field-indicator">*</span>
                </label>
              </div>
              <div class="text-area-desc description paddingTop10">
                <div>
                  <textarea class="" placeholder="Enter course description (minimum 250 characters)"
                    formControlName="description"></textarea>
                </div>
              </div>
              <div *ngIf="
        !courseDetailForm.controls.description.valid &&
        (courseDetailForm.controls.description.dirty ||
        courseDetailForm.controls.description.touched|| descriptionErr)
      " class="error-msg">
                <!-- <span *ngIf="isNullOrEmpty(courseDetailForm.get('description').value)">Please provide description for the
        course.</span> -->
                <span *ngIf="courseDetailForm.get('description').errors.isInvalid ">Please enter at least 250
                  characters.</span>
              </div>
            </div>



            <div class="contentDiv" style="padding-bottom: 102px;">
              <div flaxLayout="row" class="expertDetailsHeight">
                <div class="expertDiv">
                  <div ngClass="fieldLabel">
                    <label class="padding5">Expert PSID(s)</label>
                  </div>
                  <div class="divDisplay paddingTop10">
                    <input YorbitWorkspaceAutocompleteOff class="courseDropDown borderRadius" matInput
                      style="width:250px;height:60px" style="background-color: white !important" placeholder="Please enter PSID"
                      formControlName="expertMids" />
                  </div>
                  <div style="width:210px" *ngIf="
            !courseDetailForm.controls.expertMids.valid &&
            courseDetailForm.controls.expertMids.dirty &&
            courseDetailForm.get('expertMids').errors.isInvalid">
                    <span class="error-msg">{{errorMsgs.expert_mids}}</span>
                  </div>
                </div>
                <!--course proficiency start-->
                <div class="courseTypeDiv">
                  <div ngClass="fieldLabel">
                    <label class="padding5">
                      Course Proficiency
                      <span class="mandatory-field-indicator">*</span></label>
                  </div>
                  <div ngClass="fieldInput" class="paddingTop10">
                    <select formControlName="proficiency" class="contentwidth borderRadius height25">
                      <option value="" disabled selected>Please select a proficiency</option>
                      <option *ngFor="let coursePro of courseProficiencyList" [value]="coursePro.Proficiency">{{
                        coursePro.Proficiency }}
                      </option>
                    </select>
                    <div *ngIf="
                        !courseDetailForm.controls.proficiency.valid &&
                        (courseDetailForm.controls.proficiency.dirty ||
                        courseDetailForm.controls.proficiency.touched ||typeErr)
                      " class="error-msg">
                      Please choose a course proficiency.
                    </div>
                  </div>
                </div>
                <!--course proficiency end-->
                <div class="divWidth" *ngIf="courseDetailForm.get('isCourseInternal').value=='No'"
                  style="position:absolute;width:210px !important">
                  <div ngClass="fieldLabel">
                    <label class="padding5">Course Duration
                      <span class="mandatory-field-indicator">*</span>
                    </label>
                  </div>
                  <div ngClass="fieldInput" class="divDisplay paddingTop10">
                    <div class="alignLeft">
                      <input YorbitWorkspaceAutocompleteOff class="borderRadius height25 padding-left"
                        style="width:65px;text-align:start;margin-right:10px;"
                        style="background-color: white !important" matInput placeholder="00.00.00"
                        formControlName="duration" (input)="numbersOnlyFields('duration')"
                        (change)="numbersOnlyFields('duration')" />
                    </div>
                    <div ngClass="fieldLabel" class="hoursTextPadding">
                      <label>Hours</label>
                    </div>
                  </div>
                  <div *ngIf="
              !courseDetailForm.controls.duration.valid &&
              (courseDetailForm.controls.duration.dirty ||
              courseDetailForm.controls.duration.touched) &&
              courseDetailForm.get('duration').errors.fieldIsInvalid || durationErr
            " class="error-msg">
                    <!-- <span  *ngIf="isNullOrEmpty(courseDetailForm.get('duration').value)">Please provide course duration.
                  </span> -->
                    <span
                      *ngIf="courseDetailForm.get('duration').errors.fieldIsInvalid">{{errorMsgs.course_duration}}</span>
                  </div>
                </div>


              </div>
            </div>


            <div class="contentDiv">
              <div flaxLayout="row" style="position: relative;height:130px;">
                <div class="courseTypeDiv">
                  <div ngClass="fieldLabel">
                    <label class="padding5">
                      Course Type
                      <span class="mandatory-field-indicator">*</span></label>
                  </div>
                  <div ngClass="fieldInput" class="paddingTop10">
                    <select formControlName="type" class="contentwidth borderRadius height25">
                      <option value="" disabled selected>Please select a course type</option>
                      <option *ngFor="let courseType of courseTypeList" [value]="courseType.CourseTypeName">{{
                        courseType.CourseTypeName }}
                      </option>
                    </select>
                    <div *ngIf="
                        !courseDetailForm.controls.type.valid &&
                        (courseDetailForm.controls.type.dirty ||
                        courseDetailForm.controls.type.touched ||typeErr)
                      " class="error-msg">
                      Please choose a course type.
                    </div>
                  </div>
                </div>
                <div style="position:relative;">
                  <div ngClass="fieldLabel">
                    <label class="padding5">Content Owner
                      <span class="mandatory-field-indicator">*</span>
                    </label>
                  </div>

                  <div ngClass="fieldInput" class="divDisplay paddingTop10 ">
                    <input YorbitWorkspaceAutocompleteOff class="borderRadius height25 padding-left" matInput
                      style="text-align:start;margin-right:10px" style="background-color: white !important"
                      formControlName="contentOwner" readonly>
                    <div class="editIcon">
                      <div class="yorbitSprite editSpriteIcon mousePointer" (click)="openSearchCODialog()"></div>
                    </div>
                  </div>

                  <div style="width: 210px;" class="error-msg" *ngIf="contentOwnerErr">
                    Please choose a content owner.
                  </div>
                </div>
                <div class="Skill_Prism">
                  <div ngClass="fieldLabel">
                    <label class="padding5" style="position: relative; bottom: 11px;">Skill Prism ID
                    </label>
                  </div>
                  <input YorbitWorkspaceAutocompleteOff style="text-align:start"
                    class="currencyDDWidth borderRadius height21" matInput placeholder="Please enter Skill ID" formControlName="SkillIds" />
                </div>
              </div>
            </div>
          </div>

        </div>




        <div class="lastTopDiv">

          <div fxLayout="row" *ngIf="courseDetailForm.get('repoType').value=='Yorbit'">


            <div class="contentDiv">
              <div ngClass="fieldLabel">
                <Label class="padding5RadioButtons">Is Cloud Lab Available?
                  <span class="mandatory-field-indicator">*</span>
                </Label>
              </div>
              <div class="divDisplay paddingTop10 width60" *ngFor="let radio of YesNoRadio">
                <label class="paddingRight5">
                  <input class="verticalAlign leftMargin" type="radio" value="{{radio}}"
                    formControlName="IsCloudLabAvailable" />{{radio}}
                </label>
              </div>
            </div>
          </div>

          <div fxLayout="row">
            <div class="contentDiv">
              <div ngClass="fieldLabel">
                <Label class="padding5RadioButtons">Does this course have a course material?
                  <span class="mandatory-field-indicator">*</span>
                </Label>
              </div>
              <div class="divDisplay paddingTop10 width60" *ngFor="let radio of YesNoRadio">
                <label class="paddingRight5">
                  <input class="verticalAlign leftMargin" type="radio" value="{{radio}}"
                    formControlName="isCourseMaterialAvailable" />{{radio}}
                </label>
              </div>
            </div>
            <div class="contentDiv" *ngIf="courseDetailForm.get('isCourseMaterialAvailable').value=='Yes'">
              <div ngClass="fieldLabel">
                <label class="padding5RadioButtons">Course Material Type
                  <span class="mandatory-field-indicator">*</span>
                </label>
              </div>
              <div class="divDisplay paddingTop10 width130" *ngFor="let radio of courseMaterialTypeRadio">
                <label class="paddingRight5">
                  <input class="verticalAlign leftMargin" (click)="onMaterialChange()" type="radio" value="{{radio}}"
                    formControlName="courseMaterialType" />{{radio}}
                </label>
              </div>
            </div>
            <div class="contentDiv"
              *ngIf="courseDetailForm.get('isCourseMaterialAvailable').value=='Yes' && courseDetailForm.get('courseMaterialType').value=='Hyperlink'">
              <div ngClass="fieldLabel">
                <Label class="padding5">Course Material Link
                  <span class="mandatory-field-indicator">*</span>
                </Label>
              </div>
              <div class="divdisplay paddingTop10 verticalAlign">
                <div>
                  <input YorbitWorkspaceAutocompleteOff class="width250 borderRadius height25 padding-left"
                    style="background-color: white !important" formControlName="courseMaterialLink" matInput
                    placeholder="Enter a valid course material link" [ngClass]="{'mouseDisabled':isApproved}" />
                </div>
                <div *ngIf="
              !courseDetailForm.controls.courseMaterialLink.valid &&
              (courseDetailForm.controls.courseMaterialLink.dirty ||
                courseDetailForm.controls.courseMaterialLink.touched||courseMaterialLinkErr)
            " class="error-msg">
                  Please enter a valid course material link.
                </div>
              </div>
            </div>
            <div class="contentDiv" *ngIf="courseDetailForm.get('isCourseMaterialAvailable').value=='Yes' 
          && courseDetailForm.get('courseMaterialType').value!=='Hyperlink'">
              <div ngClass="fieldLabel">
                <Label clss="padding5" style="font-weight: 600">Upload Content
                  <span class="mandatory-field-indicator">*</span>
                </Label>
              </div>
              <div class="mousePointer fileUpload fileUploadStyle">
                <input *ngIf="!isSubmittedOrApprovedCourse" [inputFor]="'uploadCourseMaterialContent'"
                  id="CourseMaterialContentElement" type="file" placeholder="Select file" YorbitWorkspaceFileModel
                  class="mousePointer" (fileModel)="fileModel($event);uploadfile();"
                  formControlName="uploadCourseMaterialContent" />
                <label for="CourseMaterialContentElement" (click)="setDefaultValuesForUploads();">
                  <div title="select a file" fxFlex class="input-label">{{courseMaterialFileName.length > 30 ?
                    courseMaterialFileName.slice(0,30)+'...' : courseMaterialFileName}}</div>
                  <div class="mousePointer spriteIcons browseIcon" style="float:right;"></div>
                </label>
              </div>

              <!-- note -->
              <div fxLayout="row">
                <div style="display:flow-root;">

                  <span class="fileUpload-msg"
                    [ngClass]="{'error-msg':iscourseMaterialFileInvalid||courseMaterialFileErr}"
                    style="padding-right: 60px;">{{errorMsgs.file_upload_err}}</span>
                </div>
                <!-- <span class="fileUpload-msg" *ngIf="btnTitle=='File upload is success.'" style="padding-right: 60px;">{{btnTitle}}</span>
              <span *ngIf="btnTitle!=='File upload is success.'" class="fileUpload-msg error-msg" style="padding-right: 60px;">{{btnTitle}}</span> -->
                <!-- <div *ngIf="!isNullOrEmpty(btnTitle)" style="display:flow-root;" class="error-msg">{{btnTitle}}</div> -->
              </div>
              <!-- <div *ngIf="courseMaterialFileErr" class="error-msg">
                Please select a file.
              </div> -->
            </div>
            <!-- <div class="uploadButton" style="width:5%; padding: 7px 24px 0px 0px;margin-top: 30px;" fxLayoutAlign="center" *ngIf="courseDetailForm.get('isCourseMaterialAvailable').value=='Yes' 
            && courseDetailForm.get('courseMaterialType').value!=='Hyperlink'" (click)="uploadfile()">
            <div class="mousePointer spriteIcons uploadSpriteIcon"></div>
          </div> -->
          </div>
        </div>
        <!-- </div> -->

      </form>
    </div>
  </div>
  <div style="padding-top: 5px;">
    <div class="exitForm" (click)="onExit()">EXIT FORM</div>
    <div style="position: relative;">
      <button id="btnSubmit" type="submit" class="form-action-btn btn-enlarged" [disabled]="submitDisabled"
        mat-raised-button (click)="submit();" style="text-align: center;">
        Next
      </button>
      <button mat-raised-button class="form-action-btn btn-enlarged" [disabled]="saveDisabled" (click)="save('save')"
        *ngIf="isApproved==false" style="margin-right:15px;">
        Save
      </button>
    </div>
  </div>
</div>