import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  EnvironmentService,
  Ienvironment
} from '@YorbitWorkspace/global-environments';
import * as fromUserDetailsStore from '../../../user-details/store';
import { Store } from '@ngrx/store';
import { IdpService } from '../../../../idp/idp.service';
import { Globals } from '../../../../globals';
import { PackageCourseListService } from '../../../services/package-course-list.service';
import { AddedContentDataService } from '../../../services/added-content-data.service';
@Injectable({
  providedIn: 'root'
})
export class AddContentService {
  config: Ienvironment;

  riskBrigadeItemType : string = 'RiskBrigadeCourse';
  constructor(
    private http: HttpClient,
    private _envSvc: EnvironmentService,
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>,
    private globals: Globals,
    private _packageCourseListSvc: PackageCourseListService,
    private idpService: IdpService,
    private addedContentDataService: AddedContentDataService
  ) {
    this.config = this._envSvc.getEnvironment();
  }
  getBoolean(stringValue) {
    if (typeof stringValue === 'string') {
      return /true/i.test(stringValue);
    } else {
      return stringValue;
    }
  }

  addContent(learningPath, content) {
    const accountPackage = this.getBoolean(content.AccountPackage);
    const projectPackage = this.getBoolean(content.ProjectPackage);

    if (accountPackage || projectPackage) {
      let payload = {
        accountId: content.accountId,
        accountName: content.accountId,
        isAccount: content.AccountPackage,
        itemId: content.uniqueId,
        itemType: content.ItemType,
        learningPathId: learningPath.PathId,
        projectId: content.projectId,
        projectName: content.projectId
      };
      return this.addAccountContent(payload)
        .then((result: any) => {
          // update yorbitcourse or yorbitpackage data in added details
          let addedItem = null;
          if (content.ItemType == 'Course') {
            if (result && result.CourseProgressList) {
              addedItem = result.CourseProgressList.filter(
                x => x.CourseId == content.uniqueId
              )[0];
            }
          } else {
            let lp = result.LearningPaths.filter(
              x => !x.IsDeleted && x.PathId == learningPath.PathId
            )[0];
            if (lp != null) {
              addedItem = lp.PackageList.filter(
                x => !x.IsDeleted && x.ItemId == content.uniqueId
              )[0];
            }
          }
          let addedContentDetails = {
            PathId: learningPath.PathId,
            PathName: learningPath.PathName,
            IsMandatory: false,
            IsCrossSkill: false,
            IsFutureSkill: false,
            IsSkillPlan: false,
            Id: content.uniqueId,
            PackageId: content.ItemType == 'Course' ? '' : content.uniqueId,
            Expertise:
              content.ItemType == 'Course'
                ? addedItem.Expertise
                : addedItem.ItemExpertise,
            Name:
              content.ItemType == 'Course'
                ? addedItem.CourseName
                : addedItem.ItemName,
            AssignedBy: '',
            Type: content.ItemType,
            IsAccount: accountPackage,
            IsProject: projectPackage
          };
          let addedContentType =
            content.ItemType == 'Course'
              ? 'NonYorbitCourses'
              : 'NonYorbitPackages';
          this.addedContentDataService.addDataToAddedLPContentDetails(
            addedContentType,
            addedContentDetails
          );
          this.addedContentDataService.contentAddedDetailsLoaded.next(true);
          this.userDetailsStore.dispatch(
            new fromUserDetailsStore.UserDetailsGetDetailsSuccess(result)
          );
          if (
            content.ItemType.toLowerCase() === 'package' ||
            content.ItemType.toLowerCase() === 'familypackage'
          ) {
            this._packageCourseListSvc
              .getCourseIdsOfPackage({
                packageId: content.uniqueId,
                accountId: content.accountId,
                projectId: content.projectId
              })
              .then(courseIds => {
                if (courseIds) {
                  //console.log('updateCoursesInsidePackagesAfterAddition');
                  this.addedContentDataService.updateCoursesInsidePackagesAfterAddition(
                    'NonYorbit',
                    learningPath,
                    content.uniqueId,
                    courseIds
                  );
                  this.addedContentDataService.contentAddedDetailsLoaded.next(
                    true
                  );
                }
              });
          }
          const promise = new Promise(resolve => {
            const response = result;
            resolve(response);
          });
          return promise;
        })
        .catch(error => {
          const promise = new Promise(() => {
            const response = {
              errorMessage: 'An Error has occured',
              status: error.status
            };
            throw response;
          });
          return promise;
        });
    } else {
      if (content.ItemType == this.riskBrigadeItemType) {
        content.ItemType='Course'
      }

      return this.addYorbitContent(
        learningPath.PathId,
        content.Id,
        content.ItemType
      )
        .then((result: any) => {
          let addedItem = null;
          if (content.ItemType == 'Course') {
            if (result && result.CourseProgressList) {
              addedItem = result.CourseProgressList.filter(
                x => x.CourseId == content.Id
              )[0];
            }
          } else {
            let lp = result.LearningPaths.filter(
              x => !x.IsDeleted && x.PathId == learningPath.PathId
            )[0];
            if (lp != null) {
              addedItem = lp.PackageList.filter(
                x => !x.IsDeleted && x.ItemId == content.Id
              )[0];
            }
          }
          // update yorbitcourse or yorbitpackage data in added details
          let addedContentDetails = {
            PathId: learningPath.PathId,
            PathName: learningPath.PathName,
            IsMandatory: false,
            IsCrossSkill: false,
            IsFutureSkill: false,
            IsSkillPlan: false,
            Id: content.Id,
            PackageId: content.ItemType == 'Course' ? '' : content.Id,
            Expertise:
              content.ItemType == 'Course'
                ? addedItem.Expertise
                : addedItem.ItemExpertise,
            Name:
              content.ItemType == 'Course'
                ? addedItem.CourseName
                : addedItem.ItemName,
            AssignedBy: '',
            Type: content.ItemType,
            IsAccount: false,
            IsProject: false
          };
          let addedContentType =
            content.ItemType == 'Course' ? 'YorbitCourses' : 'YorbitPackages';
          this.addedContentDataService.addDataToAddedLPContentDetails(
            addedContentType,
            addedContentDetails
          );
          this.addedContentDataService.contentAddedDetailsLoaded.next(true);
          this.userDetailsStore.dispatch(
            new fromUserDetailsStore.UserDetailsGetDetailsSuccess(result)
          );
          if (
            content.ItemType.toLowerCase() === 'package' ||
            content.ItemType.toLowerCase() === 'familypackage'
          ) {
            //console.log('updateCoursesInsidePackagesAfterAddition');
            this._packageCourseListSvc
              .getCourseIdsOfPackage({
                packageId: content.Id,
                accountId: null,
                projectId: null
              })
              .then(courseIds => {
                if (courseIds) {
                  this.addedContentDataService.updateCoursesInsidePackagesAfterAddition(
                    'Yorbit',
                    learningPath,
                    content.Id,
                    courseIds
                  );
                  this.addedContentDataService.contentAddedDetailsLoaded.next(
                    true
                  );
                }
              });
          }
          const promise = new Promise(resolve => {
            const response = result;
            resolve(response);
          });
          return promise;
        })
        .catch(error => {
          const promise = new Promise(() => {
            const response = {
              errorMessage: 'An Error has occured',
              status: error.status
            };
            throw response;
          });
          return promise;
        });
    }
  }

  addYorbitContent(learningPlanId, id, type) {
    const payload = {
      learningPathId: learningPlanId,
      itemId: id,
      itemType: type,
      recommendationType: '',
      duration: ''
    };
    return this.http
      .put(this.config.apiUrl + 'User/Add/Package/', payload)
      .toPromise();
  }
  addAccountContent(payload) {
    return this.http
      .post(this.config.apiUrl + 'User/AddAPPackage/PackageItem', payload)
      .toPromise();
  }
  updateIdpContent(response) {
    this.userDetailsStore.dispatch(
      new fromUserDetailsStore.UserDetailsGetDetailsSuccess(response)
    );
  }
}
