import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FlexAlignStyleBuilder } from '@angular/flex-layout';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CoursesPopupComponent } from '../../shared/yorbit-courses/courses-popup/courses-popup.component';
import { ContentUpload301Service } from './content-upload301.service';
import { SearchEvaluatorPopupComponent } from '../content-upload/search-evaluator/search-evaluator-popup/search-evaluator-popup.component';
import { Globals } from '../../globals';
import { ContentUpload101PopupsComponent } from '../content-upload-101/content-upload101-popups/content-upload101-popups.component';
import { PssStatusMessageComponent } from '../../pss/pss-status-message/pss-status-message.component';
//import { runInThisContext } from 'vm';
import { ContentUploadStatusPopupComponent } from '../content-upload-status-popup/content-upload-status-popup.component';
import { YorbitCoursesService } from '../../shared/services/yorbit-courses.service';
import { GraphDataService } from '@YorbitWorkspace/graph';
import { ContentUploadService } from '../content-upload/content-upload.service';
import * as _ from "lodash"


@Component({
  selector: 'yorbit-content-upload301',
  templateUrl: './content-upload301.component.html',
  styleUrls: ['./content-upload301.component.scss']
})
export class ContentUpload301Component implements OnInit {

  constructor(private _service: ContentUpload301Service,
    private _popup: MatDialog,
    private _globals: Globals,
    private _graph: GraphDataService,
    private _courseComponent: YorbitCoursesService,
    private _contentUploadService: ContentUploadService) { }

  //Selected course details for save/edit features
  @Input() selectedCourse;
  //Course ID for saved course
  courseId: any = null;

  //Form group for the form controls
  contentUploadFormGroup = new FormGroup({
    courseType: new FormControl('yes'),
    academy: new FormControl(''),
    genre: new FormControl({ value: '', disabled: true }),
    skill: new FormControl({ value: '', disabled: true }),
    courseName: new FormControl(''),
    courseDesc: new FormControl(''),
    badgeName: new FormControl(''),
    credits: new FormControl(''),
    courseHidden: new FormControl('no'),
    restrictedCourse: new FormControl('no'),
    courseDuration: new FormControl(''),
    expertMIDS: new FormControl(''),
    learningPartnerApproval: new FormControl('no'),
    courseTypeDropdown: new FormControl(''),
    preReqMandatory: new FormControl({ value: 'no', disabled: true }),
    contentOwner: new FormControl({ value: '', disabled: true }),
    learningOPMSPOC: new FormControl({ value: '', disabled: true }),
    nameOfVendor: new FormControl(''),
    topicsCovered: new FormControl(''),
    coursePaid: new FormControl('yes'),
    coursePrice: new FormControl({ value: '', disabled: false }),
    currency: new FormControl({ value: 'inr', disabled: false }),
    stack1: new FormControl(''),
    stack1skill1: new FormControl(''),
    isCloudLabsRequired: new FormControl('no'),
    isCertificateAvailable: new FormControl('no'),
    isCertificateUpload: new FormControl('no'),
    proficiency: new FormControl(''),
    SkillIds: new FormControl('')
  });

  //Boolean variables for form validation
  academyError: boolean = false;
  genreError: boolean = false;
  skillError: boolean = false;
  academyHidden: boolean = false;
  genreHidden: boolean = false;
  skillHidden: boolean = false;
  vendorHidden: boolean = false;
  courseTypeHidden: boolean = false;
  courseNameError: boolean = false;
  courseDescError: boolean = false;
  courseTypeError: boolean = false;
  coursePriceError: boolean = false;
  badgeError: boolean = false;
  creditsError: boolean = false;
  durationError: boolean = false;
  minDurationError: boolean = false;
  vendorError: boolean = false;
  topicsCoveredError: boolean = false;
  courseExistsError: boolean = false;
  expertMIDError: boolean = false;
  duplicateMid: boolean = false;
  rangeError: boolean = false;
  disableAddStack: boolean = false;
  coursePriceErrorBackup: boolean = false;
  validationCheckFunction: any;
  disablePagination: boolean = true;
  nextButtonTitle: string = "One or more field(s) is invalid or empty";
  prevButtonTitle: string = "One or more field(s) is invalid or empty";
  saveButtonTitle: string = "One or more field(s) is invalid or empty";
  submitButtonTitle: string = "One or more field(s) is invalid or empty";
  disabledStackTitle = "";
  disabledSkillTitle = [""];
  isSaved: boolean = false;
  courseUniqueId = null;
  disableSave: boolean = true;
  disableSubmit: boolean = true;
  validCoursePrice: boolean = false;
  proficiencyError: boolean = false;
  proficiencyHidden: boolean = false;

  /* Error list for Skill boxes. If any stack name is entered, atleast one skill for that stack has to be entered.
  skillErrors[N] shows error status if no skills are entered for Nth stack. Error is shown under the first skill box
  of each stack. */
  skillErrors = [[false]];

  /* Error list for Stack boxes. Error is shows if stack name is not entered.
  stackErrors[N] shows error status if no stack name is entered for Nth stack. */
  stackErrors = [false];

  /* Add skill buttons to be disabled if the skill count is equal to 10. disabledSkillButtons[N]
  points to the disabled status of the Add skill button is Nth stack. */
  disabledSkillButtons = [false];

  //Currently active page
  pageActive: number = 1;

  //Arrays for API return values
  skillList = [];
  academyList = [];
  genreList = [];
  taxanomy = [];
  genreSkillList = [];
  vendors = [];
  courseTypes = [];
  preReqCourses = [];
  preReqCoursesList = [];
  postRecCourses = [];
  postRecCoursesList = [];
  proficiencyList = [];

  //Number of Stacks appended to list. [1] shows only one stack, [1,2] shows two stacks and so on
  stackBoxCount = [1];

  /*Skills per stack in array of array format. Index of each subarray corresponds to their respective stack
  [[1]] shows first stack has only one skill box 
  [[1],[1,2]] shows first stack has only one skill box and second stack has two skill boxes */
  skillBoxCount = [[1]];

  //MID and Email of CO and LOPM SPOC
  COMID = "";
  LOPMID = "";

  //Title for Disabled select boxes
  GenreTitle: string = "Select an Academy";
  SkillTitle: string = "Select a Genre";

  //Emitter for Account Owner page
  @Output() enableContentVerificationComponent = new EventEmitter<any>();

  ngOnInit(): void {
    // this.contentUploadFormGroup.valueChanges.subscribe((data)=>{
    //     this.isSaved = false;
    // });

    //Getting Taxanomy details here
    this._service.getTaxanomy().toPromise().then(response => {

      //For getting vendor list
      this._service.getVendorList().subscribe(res  => {
        this.vendors = res;
        this.vendors.sort((a, b) => a.Vendor.trim().localeCompare(b.Vendor.trim()));
      });

      //For getting course types for 301
      this._service.getCourseType().subscribe(res => {
        this.courseTypes = res;
        this.courseTypes.sort((a, b) => a.CourseTypeName.trim().localeCompare(b.CourseTypeName.trim()));
      });

       //For getting course proficiency list
       this._service.getCourseProficiencyList().subscribe(res  => {
        this.proficiencyList = res;
        this.proficiencyList.sort((a, b) => a.Proficiency.trim().localeCompare(b.Proficiency.trim()));
      });

      if (response !== null && response.AcademyList != undefined && response.AcademyList.length > 0) {
        this.taxanomy = response.AcademyList;
        this.taxanomy.sort((a, b) => a.Academy.localeCompare(b.Academy));
        this.taxanomy.forEach(value => {
          this.academyList.push(value.Academy);
        });
      }
      else {
        this.taxanomy = [];
      }
      this.academyList.sort((a, b) => a.localeCompare(b));

      //If selected course is not defined, meaning inserting a new course
      if (this.selectedCourse == undefined) {
        this.isSaved = false;
      }
      else {
        this.isSaved = true;
        this.courseUniqueId = this.selectedCourse.UniqueId;
      }

      //If the course status is saved, populate values and enable editing.
      if (this.selectedCourse && (this.selectedCourse.CourseStatus.toLowerCase() == 'saved' || this.selectedCourse.CourseStatus.toLowerCase() == 'denied') ) {

        /*Initially, set isSaved to false and disable next button. Set the course uniqueid from data */
        this.disablePagination = false;
        this.courseUniqueId = this.selectedCourse.UniqueId;

        this.contentUploadFormGroup.get('courseType').setValue(this.selectedCourse.IsCourseInternal == true ? 'yes' : 'no');
        this.contentUploadFormGroup.get('courseType').disable();

        this.contentUploadFormGroup.get('academy').setValue(this.selectedCourse.Academy);
        this.contentUploadFormGroup.get('proficiency').setValue(this.selectedCourse.Proficiency);

        this.contentUploadFormGroup.get('genre').enable();
        this.changeGenreOptions();
        this.contentUploadFormGroup.get('genre').setValue(this.selectedCourse.Genre);


        this.changeSkillOptions();
        this.contentUploadFormGroup.get('skill').setValue(this.selectedCourse.Skill);
        this.contentUploadFormGroup.get('skill').enable();

        this.contentUploadFormGroup.get('courseName').setValue(this.selectedCourse.Name);
        this.contentUploadFormGroup.get('courseDesc').setValue(this.selectedCourse.Description);

        //Fetching badge name
      //  this._contentUploadService.getBadgeDetails(this.selectedCourse.BadgeName).subscribe(res => {
          this.contentUploadFormGroup.get('badgeName').setValue(this.selectedCourse.BadgeName);
          this.contentUploadFormGroup.get ('SkillIds').setValue(this.selectedCourse.SkillIds);
       // });

        this.contentUploadFormGroup.get('courseTypeDropdown').setValue(this.selectedCourse.Type);
        this.contentUploadFormGroup.get('credits').setValue(Number(this.selectedCourse.Credits).toString());
        this.contentUploadFormGroup.get('courseHidden').setValue(this.selectedCourse.IsHiddenCourse == true ? 'yes' : 'no');
        this.contentUploadFormGroup.get('restrictedCourse').setValue(this.selectedCourse.IsRestrictedCourse == true ? 'yes' : 'no');
        this.contentUploadFormGroup.get('courseDuration').setValue(this.selectedCourse.Duration);
        this.contentUploadFormGroup.get('isCertificateAvailable').setValue(this.selectedCourse.IsCertificateAvailable == false ? "no" : "yes");
        this.contentUploadFormGroup.get('isCertificateUpload').setValue(this.selectedCourse.IsCertificateUpload == false ? "no" : "yes");

        if (this.selectedCourse.ExpertMids != null && this.selectedCourse.ExpertMids.trim() != "") {
          //Convert Email to MIDs
          var Mids = [];
          this.selectedCourse.ExpertMids.split(",").forEach((ele) => {
            Mids.push(ele.split("@")[0]);
          });
          this.contentUploadFormGroup.get('expertMIDS').setValue(Mids.join(','));
        }

        this.contentUploadFormGroup.get('learningPartnerApproval').setValue(this.selectedCourse.ManagerApproval == true ? 'yes' : 'no');

        if (this.selectedCourse.Vendor != null) {
          this.contentUploadFormGroup.get('nameOfVendor').setValue(this.selectedCourse.Vendor);
        }

        if (this.selectedCourse.IsPaid == true) {
          this.contentUploadFormGroup.get('coursePaid').enable();
          this.contentUploadFormGroup.get('coursePaid').setValue('yes');
          this.contentUploadFormGroup.get('coursePrice').setValue(Number(this.selectedCourse.Price.split(' ')[0]).toString());
          if (String(this.selectedCourse.Price).toLowerCase().includes("usd")) {
            this.contentUploadFormGroup.get('currency').setValue('usd');
          }
          else {
            this.contentUploadFormGroup.get('currency').setValue('inr');
          }
        }

          this.contentUploadFormGroup.get('topicsCovered').setValue(this.selectedCourse.TopicsCovered);
          this.contentUploadFormGroup.get('isCloudLabsRequired').setValue(this.selectedCourse.IsCloudLabAvailable == true ? "yes" : "no");
        
        this.preReqCourses = (this.selectedCourse.PreRequisiteCourseIDs != null && this.selectedCourse.PreRequisiteCourseIDs != _.isEmpty({})) ? this.selectedCourse.PreRequisiteCourseIDs : [];
        this.postRecCourses = (this.selectedCourse.PostRecommendedCourseIDs != null && this.selectedCourse.PostRecommendedCourseIDs != _.isEmpty({})) ? this.selectedCourse.PostRecommendedCourseIDs : [];

        var YorbitCourses;
        this._courseComponent.getAllYorbitCourses().subscribe(res => {
          YorbitCourses = res;

          if (this.preReqCourses.length != 0) {
            this.contentUploadFormGroup.get('preReqMandatory').enable();
            //extract name from json response
            this.preReqCourses.forEach(Cid => {
              this.preReqCoursesList.push(YorbitCourses.filter(x => String(x.Id).trim() == String(Cid).trim())[0]);
            });
            this.preReqCoursesList = this.preReqCoursesList.filter(x => x != undefined);
          }

          if (this.postRecCourses.length != 0) {
            //extract name from json response
            this.postRecCourses.forEach(Cid => {
              this.postRecCoursesList.push(YorbitCourses.filter(x => String(x.Id).trim() == String(Cid).trim())[0]);
            });
            this.postRecCoursesList = this.postRecCoursesList.filter(x => x != undefined);
          }
        });

        this.contentUploadFormGroup.get('preReqMandatory').setValue(this.selectedCourse.IsPreRequisiteMandatory == true ? 'yes' : 'no');

        this.COMID = this.selectedCourse.ContentOwner;
        this.LOPMID = this.selectedCourse.LearningSPOCMID;
        this._graph.getUserName(this.COMID).subscribe(res => {
          this.contentUploadFormGroup.get('contentOwner').setValue(res.displayName);
        })
        this._graph.getUserName(this.LOPMID).subscribe(res => {
          this.contentUploadFormGroup.get('learningOPMSPOC').setValue(res.displayName);
        });

        if (this.selectedCourse.StackAndSkills != null && this.selectedCourse.StackAndSkills != _.isEmpty({})) {
          //creating Required number of stacks
          for (var i = 0; i < this.selectedCourse.StackAndSkills.length - 1; i++) {
            this.addStack();
          }

          this.selectedCourse.StackAndSkills.forEach((stack, stackIndex) => {
            this.contentUploadFormGroup.get('stack' + (stackIndex + 1)).setValue(stack.Stack);
            var Skills = stack.Skills;
            Skills.forEach((skill, skillIndex) => {
              if (skillIndex == 0) {
                this.contentUploadFormGroup.get('stack' + (stackIndex + 1) + 'skill' + (skillIndex + 1)).setValue(skill);
              }
              else {
                this.addSkillFromNgOnInit((stackIndex + 1));
                this.contentUploadFormGroup.get('stack' + (stackIndex + 1) + 'skill' + (skillIndex + 1)).setValue(skill);
              }
            });
          });
        }
        this.isSaved = true;
      }
      //If the course status is submitted or launched, show the preview but disabled editing
      else if (this.selectedCourse && (this.selectedCourse.CourseStatus.toLowerCase() == 'submitted' || this.selectedCourse.CourseStatus.toLowerCase() == 'approved')) {
        //If it is non-saved status, enable pagination
        this.disablePagination = false;
        this.nextButtonTitle = "";
        this.submitButtonTitle = "";
        this.isSaved = true;


        this.contentUploadFormGroup.get('courseType').setValue(this.selectedCourse.IsCourseInternal == true ? 'yes' : 'no');
        this.contentUploadFormGroup.get('courseType').disable();

        this.contentUploadFormGroup.get('academy').setValue(this.selectedCourse.Academy);
        this.contentUploadFormGroup.get('academy').disable();

        this.contentUploadFormGroup.get('proficiency').setValue(this.selectedCourse.Proficiency);
        this.contentUploadFormGroup.get('proficiency').disable();

        this.changeGenreOptions();
        this.contentUploadFormGroup.get('genre').setValue(this.selectedCourse.Genre);
        this.contentUploadFormGroup.get('genre').disable();


        this.changeSkillOptions();
        this.contentUploadFormGroup.get('skill').setValue(this.selectedCourse.Skill);
        this.contentUploadFormGroup.get('skill').disable();

        this.contentUploadFormGroup.get('courseName').setValue(this.selectedCourse.Name);
        this.contentUploadFormGroup.get('courseName').disable();

        this.contentUploadFormGroup.get('courseDesc').setValue(this.selectedCourse.Description);
        this.contentUploadFormGroup.get('courseDesc').disable();

        //Fetching badge name
       // this._contentUploadService.getBadgeDetails(this.selectedCourse.BadgeName).subscribe(res => {
          this.contentUploadFormGroup.get('badgeName').setValue(this.selectedCourse.BadgeName);
          this.contentUploadFormGroup.get('badgeName').disable();
          this.contentUploadFormGroup.get('SkillIds').setValue(this.selectedCourse.SkillIds);
          this.contentUploadFormGroup.get('SkillIds').disable();
      //  });

        this.contentUploadFormGroup.get('courseTypeDropdown').setValue(this.selectedCourse.Type);
        this.contentUploadFormGroup.get('courseTypeDropdown').disable();

        this.contentUploadFormGroup.get('credits').setValue(Number(this.selectedCourse.Credits).toString());
        this.contentUploadFormGroup.get('credits').disable();

        this.contentUploadFormGroup.get('courseHidden').setValue(this.selectedCourse.IsHiddenCourse == true ? 'yes' : 'no');
        this.contentUploadFormGroup.get('courseHidden').disable();

        this.contentUploadFormGroup.get('restrictedCourse').setValue(this.selectedCourse.IsRestrictedCourse == true ? 'yes' : 'no');
        this.contentUploadFormGroup.get('restrictedCourse').disable();

        this.contentUploadFormGroup.get('courseDuration').setValue(this.selectedCourse.Duration);
        this.contentUploadFormGroup.get('courseDuration').disable();

        this.contentUploadFormGroup.get('isCertificateAvailable').setValue(this.selectedCourse.IsCertificateAvailable == false ? "no" : "yes");
        this.contentUploadFormGroup.get('isCertificateAvailable').disable();

        this.contentUploadFormGroup.get('isCertificateUpload').setValue(this.selectedCourse.IsCertificateUpload == false ? "no" : "yes");
        this.contentUploadFormGroup.get('isCertificateUpload').disable();

        if (this.selectedCourse.ExpertMids != null && this.selectedCourse.ExpertMids.trim() != "") {
          //Convert Email to MIDs
          var Mids = [];
          this.selectedCourse.ExpertMids.split(",").forEach((ele) => {
            Mids.push(ele.split("@")[0]);
          });
          this.contentUploadFormGroup.get('expertMIDS').setValue(Mids.join(','));
        }
        this.contentUploadFormGroup.get('expertMIDS').disable();

        this.contentUploadFormGroup.get('learningPartnerApproval').setValue(this.selectedCourse.ManagerApproval == true ? 'yes' : 'no');
        this.contentUploadFormGroup.get('learningPartnerApproval').disable();

        if (this.selectedCourse.Vendor != null) {
          this.contentUploadFormGroup.get('nameOfVendor').setValue(this.selectedCourse.Vendor);
          this.contentUploadFormGroup.get('nameOfVendor').disable();
        }

        if (this.selectedCourse.IsPaid == true) {
          this.contentUploadFormGroup.get('coursePaid').setValue('yes');
          this.contentUploadFormGroup.get('coursePaid').disable();
          this.contentUploadFormGroup.get('coursePrice').setValue(Number(this.selectedCourse.Price.split(' ')[0]).toString());
          this.contentUploadFormGroup.get('coursePrice').disable();

          if (String(this.selectedCourse.Price).toLowerCase().includes("usd")) {
            this.contentUploadFormGroup.get('currency').setValue('usd');
          }
          else {
            this.contentUploadFormGroup.get('currency').setValue('inr');
          }
          this.contentUploadFormGroup.get('currency').disable();
        }
        else {
          this.contentUploadFormGroup.get('coursePaid').disable();
        }
        
        this.contentUploadFormGroup.get('topicsCovered').setValue(this.selectedCourse.TopicsCovered);
        this.contentUploadFormGroup.get('topicsCovered').disable();
        this.contentUploadFormGroup.get('isCloudLabsRequired').setValue(this.selectedCourse.IsCloudLabAvailable == true ? "yes" : "no");
        this.contentUploadFormGroup.get('isCloudLabsRequired').disable();

        this.preReqCourses = (this.selectedCourse.PreRequisiteCourseIDs != null && this.selectedCourse.PreRequisiteCourseIDs != _.isEmpty({})) ? this.selectedCourse.PreRequisiteCourseIDs : [];
        this.postRecCourses = (this.selectedCourse.PostRecommendedCourseIDs != null && this.selectedCourse.PostRecommendedCourseIDs != _.isEmpty({})) ? this.selectedCourse.PostRecommendedCourseIDs : [];
        var YorbitCourses;
        this._courseComponent.getAllYorbitCourses().subscribe(res => {
          YorbitCourses = res;

          if (this.preReqCourses !=  _.isEmpty({})) {
            //extract name from json response
            this.preReqCourses.forEach(Cid => {
              this.preReqCoursesList.push(YorbitCourses.filter(x => String(x.Id).trim() == String(Cid).trim())[0]);
            });
            this.preReqCoursesList = this.preReqCoursesList.filter(x => x != undefined);
          }

          if (this.postRecCourses != _.isEmpty({})) {
            //extract name from json response
            this.postRecCourses.forEach(Cid => {
              this.postRecCoursesList.push(YorbitCourses.filter(x => String(x.Id).trim() == String(Cid).trim())[0]);
            });
            this.postRecCoursesList = this.postRecCoursesList.filter(x => x != undefined);
          }
        });


        this.contentUploadFormGroup.get('preReqMandatory').setValue(this.selectedCourse.IsPreRequisiteMandatory == true ? 'yes' : 'no');
        this.contentUploadFormGroup.get('preReqMandatory').disable();

        this.COMID = this.selectedCourse.ContentOwner;
        this.LOPMID = this.selectedCourse.LearningSPOCMID;

        this._graph.getUserName(this.COMID).subscribe(res => {
          this.contentUploadFormGroup.get('contentOwner').setValue(res.displayName);
        })
        this._graph.getUserName(this.LOPMID).subscribe(res => {
          this.contentUploadFormGroup.get('learningOPMSPOC').setValue(res.displayName);
        });

        if (this.selectedCourse.StackAndSkills != null && this.selectedCourse.StackAndSkills.length != 0) {
          //creating Required number of stacks
          for (var i = 0; i < this.selectedCourse.StackAndSkills.length - 1; i++) {
            this.addStack();
          }

          this.disableAddStack = true;
          this.selectedCourse.StackAndSkills.forEach((stack, stackIndex) => {
            this.contentUploadFormGroup.get('stack' + (stackIndex + 1)).setValue(stack.Stack);
            this.contentUploadFormGroup.get('stack' + (stackIndex + 1)).disable();
            var Skills = stack.Skills;
            Skills.forEach((skill, skillIndex) => {
              if (skillIndex == 0) {
                this.contentUploadFormGroup.get('stack' + (stackIndex + 1) + 'skill' + (skillIndex + 1)).setValue(skill);
              }
              else {
                this.addSkillFromNgOnInit((stackIndex + 1));
                this.contentUploadFormGroup.get('stack' + (stackIndex + 1) + 'skill' + (skillIndex + 1)).setValue(skill);
              }
              this.contentUploadFormGroup.get('stack' + (stackIndex + 1) + 'skill' + (skillIndex + 1)).disable();
              this.disabledSkillButtons[stackIndex] = true;
            });
          });
        }
        else {
          this.contentUploadFormGroup.get('stack1').disable();
          this.contentUploadFormGroup.get('stack1skill1').disable();
        }

      }
    });

    //If course is not in submitted status, setup validation function to check for invalid fields
    if ((this.selectedCourse && this.selectedCourse.CourseStatus.toLowerCase() != 'submitted' && this.selectedCourse.CourseStatus.toLowerCase() != 'approved') ||
      !this.selectedCourse) {
      this.validationCheckFunction = setInterval(()=> { this.checkFormDataAtIntervals(); }, 100);
    }

    //If course exists error is shown, remove that when field is edited
    this.contentUploadFormGroup.get('courseName').valueChanges.subscribe(res=>{
       this.courseExistsError = false;
    });

    //Disable certificate upload option by learner if the course type is External Certification
    this.contentUploadFormGroup.get('courseTypeDropdown').valueChanges.subscribe(res=>{
       if(res == "External Certification") {
        this.contentUploadFormGroup.get('isCertificateUpload').setValue('no');
        this.contentUploadFormGroup.get('isCertificateUpload').enable();
      }
      else {
        this.contentUploadFormGroup.get('isCertificateUpload').setValue('no');
        this.contentUploadFormGroup.get('isCertificateUpload').disable();
      }
    });

    //Event to validate credits input
    this.contentUploadFormGroup.get('credits').valueChanges.subscribe(res => {

      res = String(res);
      if (res.trim() == "" && res != "") {
        this.contentUploadFormGroup.get('credits').setValue('');
        this.creditsError = true;
      }
      if (String(Number(res)) == "NaN") {
        this.contentUploadFormGroup.get('credits').setValue('');
        this.creditsError = true;
      }
      if (Number(res) > 9) {
        this.contentUploadFormGroup.get('credits').setValue((res[0].toString().trim()));
        this.creditsError = false;
      }
      if (Number(res) < 0) {
        this.contentUploadFormGroup.get('credits').setValue('');
        this.creditsError = true;
      }
      if (Number(res) >= 0 && Number(res) <= 9) {
        this.creditsError = false;
      }
    });

    this.contentUploadFormGroup.get('isCertificateAvailable').valueChanges.subscribe(res=>{
       this.isSaved = false;
    });

    this.contentUploadFormGroup.get('isCertificateUpload').valueChanges.subscribe(res=>{
       this.isSaved = false;
    });

    this.contentUploadFormGroup.get('learningPartnerApproval').valueChanges.subscribe(res=>{
      this.isSaved = false;
   });

   this.contentUploadFormGroup.get('preReqMandatory').valueChanges.subscribe(res=>{
    this.isSaved = false;
   });

   this.contentUploadFormGroup.get('isCloudLabsRequired').valueChanges.subscribe(res=>{
    this.isSaved = false;
   });

    //If a new course is being entered, populate CO and LOPM SPOC with logged in user details
    if (this.selectedCourse == undefined) {
      //Populate CO and LOPM SPOC boxes with Logged in User name
      this._graph.getUserName(this._globals.MId).toPromise().then((res) => {
        this.contentUploadFormGroup.get('contentOwner').setValue(res.displayName);
        this.contentUploadFormGroup.get('learningOPMSPOC').setValue(res.displayName);
      });
      this.COMID = this.LOPMID = this._globals.MId;
    }

    //For removing initial select option once a valid value is selected
    this.contentUploadFormGroup.get('nameOfVendor').valueChanges.subscribe(res => {
      if (res != "") {
        this.vendorHidden = true;
      }
      else {
        this.vendorHidden = false;
      }
    });

    //For removing initial select option once a valid value is selected
    this.contentUploadFormGroup.get('courseTypeDropdown').valueChanges.subscribe(res => {
      if (this.contentUploadFormGroup.get('courseTypeDropdown').value == "") {
        this.courseTypeHidden = false;
      }
      else {
        this.courseTypeHidden = true;
      }
    });

    //Disabling Course Restricted radio button according to course hidden radio button value
    if (this.selectedCourse == undefined || this.selectedCourse.CourseStatus.toLowerCase() == 'saved') {
      this.contentUploadFormGroup.get('courseHidden').valueChanges.subscribe(res => {
        this.isSaved = false;
        if (res == 'yes') {
          this.contentUploadFormGroup.get('restrictedCourse').disable();
        }
        else {
          this.contentUploadFormGroup.get('restrictedCourse').enable();
        }
      });
    }

    //Disabling Course Hidden radio button according to course restricted radio button value
    if (this.selectedCourse == undefined || this.selectedCourse.CourseStatus.toLowerCase() == 'saved') {
      this.contentUploadFormGroup.get('restrictedCourse').valueChanges.subscribe(res => {
        this.isSaved = false;
        if (res == 'yes') {
          this.contentUploadFormGroup.get('courseHidden').disable({ emitEvent: false });
        }
        else {
          this.contentUploadFormGroup.get('courseHidden').enable({ emitEvent: false });
        }
      });
    }


    //Resetting form when the course type changes
    this.contentUploadFormGroup.get('courseType').valueChanges.subscribe(res => {

      this.academyError = false;
      this.genreError = false;
      this.skillError = false;
      this.academyHidden = false;
      this.genreHidden = false;
      this.skillHidden = false;
      this.vendorHidden = false;
      this.courseTypeHidden = false;
      this.courseNameError = false;
      this.courseDescError = false;
      this.courseTypeError = false;
      this.coursePriceError = false;
      this.validCoursePrice = false;
      this.badgeError = false;
      this.creditsError = false;
      this.durationError = false;
      this.vendorError = false;
      this.topicsCoveredError = false;
      this.courseExistsError = false;
      this.rangeError = false;
      this.expertMIDError = false;
      this.proficiencyError = false;
      this.proficiencyHidden=false;

      this.contentUploadFormGroup.get('academy').setValue('');
      this.contentUploadFormGroup.get('genre').disable();
      this.contentUploadFormGroup.get('skill').disable();
      this.contentUploadFormGroup.get('courseName').setValue('');
      this.contentUploadFormGroup.get('badgeName').setValue('');
      this.contentUploadFormGroup.get('courseDuration').setValue('');
      this.contentUploadFormGroup.get('courseDesc').setValue('');
      this.contentUploadFormGroup.get('credits').setValue('');
      this.contentUploadFormGroup.get('expertMIDS').setValue('');
      this.contentUploadFormGroup.get('courseTypeDropdown').setValue('');
      this.contentUploadFormGroup.get('nameOfVendor').setValue('');
      this.contentUploadFormGroup.get('topicsCovered').setValue('');
      this.contentUploadFormGroup.get('coursePrice').setValue('');
      this.contentUploadFormGroup.get('currency').setValue('inr');
      this.contentUploadFormGroup.get('proficiency').setValue('');
      this.contentUploadFormGroup.get('SkillIds').setValue('');

      if(res == 'no') {
        this.contentUploadFormGroup.get('coursePaid').setValue('yes');
      }
      else {
        this.contentUploadFormGroup.get('coursePaid').setValue('no');
      }

      if (this.preReqCoursesList.length == 0) {
        this.contentUploadFormGroup.get('preReqMandatory').disable();
      }
      else {
        this.contentUploadFormGroup.get('preReqMandatory').enable();
      }

      this.preReqCourses = [];
      this.preReqCoursesList = [];
      this.postRecCourses = [];
      this.postRecCoursesList = [];

      this.GenreTitle = "Select an Academy";
      this.SkillTitle = "Select a Genre";

      if (this.selectedCourse == undefined) {
        //Populate CO and LOPM SPOC boxes with Logged in User name
        this._graph.getUserName(this._globals.MId).toPromise().then((res) => {
          this.contentUploadFormGroup.get('contentOwner').setValue(res.displayName);
          this.contentUploadFormGroup.get('learningOPMSPOC').setValue(res.displayName);
        });
        this.COMID = this.LOPMID = this._globals.MId;
      }
    });
    this.getYorbitCourses();
  }


  //Function to check for form fields continuously
  checkFormDataAtIntervals() {
    
        //If any field in second page is invalid or empty, disable the submit and save button
        if (this.pageActive == 2) {
          if ([].concat(...this.skillErrors).filter(x => x == true).length > 0 ||
            this.stackErrors.filter(x => x == true).length > 0) {
            this.disableSubmit = true;
            this.disableSave = true;
            this.submitButtonTitle = "One or more field(s) is invalid or empty";
            this.saveButtonTitle = "One or more field(s) is invalid or empty";
            this.prevButtonTitle = "One or more field(s) is invalid or empty";
          }

          //If there are no errors in the second page and the course is saved, enable submit and save buttons
          else if ([].concat(...this.skillErrors).filter(x => x == true).length == 0 &&
            this.stackErrors.filter(x => x == true).length == 0 && this.isSaved) {
            this.disableSubmit = false;
            this.disableSave = false;
            this.submitButtonTitle = "";
            this.saveButtonTitle = "";
            this.prevButtonTitle = "";
          }

          //If there are no errors in the second page and the course is not saved, enable submit and save buttons
          else if ([].concat(...this.skillErrors).filter(x => x == true).length == 0 &&
            this.stackErrors.filter(x => x == true).length == 0 && !this.isSaved) {
            this.disableSubmit = true;
            this.disableSave = false;
            this.submitButtonTitle = this.prevButtonTitle = "Please save the data to proceed";
            this.saveButtonTitle = "";
          }
        }

        //If any field is invalid or empty in first page, disable Save and Next button
        if (this.pageActive == 1) {
          if (this.contentUploadFormGroup.get('academy').value == '' ||
            this.contentUploadFormGroup.get('genre').value == '' ||
            this.contentUploadFormGroup.get('skill').value == '' ||
            this.contentUploadFormGroup.get('courseName').value == '' ||
            this.contentUploadFormGroup.get('courseDesc').value == '' ||
            this.contentUploadFormGroup.get('badgeName').value == '' ||
            this.contentUploadFormGroup.get('credits').value == '' ||
            this.contentUploadFormGroup.get('courseTypeDropdown').value == '' ||
            this.contentUploadFormGroup.get('courseDuration').value == '' ||
            this.contentUploadFormGroup.get('proficiency').value == '' ||
            this.academyError ||
            this.genreError ||
            this.skillError ||
            this.courseNameError ||
            this.courseExistsError ||
            this.courseDescError ||
            this.courseTypeError ||
            this.badgeError ||
            this.creditsError ||
            this.durationError ||
            this.expertMIDError ||
            this.coursePriceError ||
            this.minDurationError ||
            this.proficiencyError ||
            this.contentUploadFormGroup.get('courseType').value == 'no' && this.contentUploadFormGroup.get('coursePrice').value == '' ||
            this.contentUploadFormGroup.get('topicsCovered').value.trim() == '' || this.contentUploadFormGroup.get('topicsCovered').value.trim().length < 50) {
            this.disablePagination = true;
            this.disableSave = true;
            this.nextButtonTitle = "One or more field(s) is invalid or empty";
            this.saveButtonTitle = "One or more field(s) is invalid or empty";

            //If it is an external course, disable the submit button since it's only one page.
            if (this.contentUploadFormGroup.get('courseType').value == 'no') {
              this.disableSubmit = true;
              this.submitButtonTitle = "One or more field(s) is invalid or empty";
            }
          }

          //If no errors are there
          else {
            this.saveButtonTitle = "";
            
            //If course is not saved disable pagination and change title. Enable save button.
            if (!this.isSaved) {
              this.disablePagination = true;
              this.disableSave = false;
              this.nextButtonTitle = "Please save the data to proceed";

              //For external courses, disable submit button if course is not saved.
              if (this.contentUploadFormGroup.get('courseType').value == 'no') {
                this.disableSubmit = true;
                this.submitButtonTitle = "Please save the data to proceed";
              }
            }

            //if course is saved disable pagination
            else {
              this.disableSave = false;
              this.disablePagination = false;
              this.nextButtonTitle = "";

              //For external courses, disable submit button since there is no pagination.
              if (this.contentUploadFormGroup.get('courseType').value == 'no') {
                this.disableSubmit = false;
                this.submitButtonTitle = "";
              }
            }
          }
        }

        //If the course with a name already exists, disable next button
        if (this.courseExistsError) {
          this.disablePagination = true;
        }
  }
  //Called from ngOnInit to get the academy, genre and skill details
  getTaxanomy() {
    this.academyList = [];
    this._service.getTaxanomy().subscribe(
      (response: any) => {
        if (response !== null && response.AcademyList != undefined && response.AcademyList.length > 0) {
          this.taxanomy = response.AcademyList;
          this.taxanomy.sort((a, b) => a.Academy.localeCompare(b.Academy));
          this.taxanomy.forEach(value => {
            this.academyList.push(value.Academy);
          });
        }
        else {
          this.taxanomy = [];
        }
        this.academyList.sort((a, b) => a.localeCompare(b));
      },
      (error: any) => {
        this.taxanomy = [];
      }
    );
  }

  //Called to show information popup
  showInfoPopUp() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    dialogConfig.data = 'StackAndSkillInfo';


    const response = this._popup.open(
      ContentUpload101PopupsComponent,
      dialogConfig
    );
  }

  //Called to validate input on typing
  validateInput(type: string, event: any) {
     this.isSaved = false;
    
     //Course Name
    if (type == 'courseName') {
      if (event.target.value.length + 1 == (event.target.value.split(" ").length) &&
        event.target.value != "") {
        this.courseNameError = true;
      }
      else {
        this.courseNameError = false;
      }
    }

    //Course Description
    else if (type == 'courseDesc') {
      if ((event.target.value.trim() == "" ||
        event.target.value.length < 250) &&
        event.target.value != "") {
        this.courseDescError = true;
      }
      else {
        this.courseDescError = false;
      }
    }

    //Course duration
    else if (type == 'duration') {
      var Duration = event.target.value;

      if (Duration == "") {
        this.durationError = false;
        return;
      }

      //Duration expects time in HH.MM.SS format
      if (Duration.split(".").length != 3 ||
        Duration.split(".")[0].trim() == "" ||
        Duration.split(".")[1] > 59 && Duration.split(".")[1].trim() != "" ||
        Duration.split(".")[2] > 59 && Duration.split(".")[1].trim() != "") {
        this.durationError = true;
        return;
      }
      else {
        this.durationError = false;
        }

        //Check if all parameters are numbers itself
        for (let element of Duration.split(".")) {
          if (String(Number(element)) == "NaN" || element == "") {
            this.durationError = true;
            break;
          }
          else {
            this.durationError = false;
          }
        }

        //Check if course is greater than or equal to 80 hours
        if(Number(Duration.split(".")[0]) < 80 && this.durationError == false) {
           this.minDurationError = true;
        }
        else {
            this.minDurationError = false;
        }
      }
    

    //Topics covered
    else if (type == 'topics') {
      if ((event.target.value.trim().length < 50 || event.target.value.trim() == "") && event.target.value != "") {
        this.topicsCoveredError = true;
      }
      else {
        this.topicsCoveredError = false;
      }
    }

    //Course price
    else if (type == 'price') {
      if ((String(Number(event.target.value)) == "NaN" ||
        event.target.value.trim() == "") &&
        event.target.value != "") {
        this.coursePriceError = true;
        this.validCoursePrice = false;
      }
      else if(Number(event.target.value) <= 0) {
        this.validCoursePrice = true;
        this.coursePriceError = false;
      }
      else {
        this.validCoursePrice = false;
        this.coursePriceError = false;
      }
    }

    //Badge Name
    if (type == 'badgeName') {
      if (event.target.value.length + 1 == event.target.value.split(" ").length &&
        event.target.value != "") {
        this.badgeError = true;
      }
      else {
        this.badgeError = false;
      }
    }

    //Expert MIDs
    if (type == 'expertMIDs') {

      //Check for empty spaces
      if (event.target.value.trim() == "" && event.target.value != "") {
        this.expertMIDError = true;
        return;
      }
      else {
        this.expertMIDError = false;
      }

      /*Split each entry and check for the format MXXXXXXX
      First Letter : M or m
      XXXXXXX : 7-digit Numbers only
      */
      var mids = event.target.value.split(",");

      for (let mid of mids) {
        mid = mid.trim();

        //Checking for duplicate MID entries
        if (mids.filter(x => x.trim() == mid).length > 1) {
          this.duplicateMid = true;
          return;
        }
        else {
          this.duplicateMid = false;
        }

        //split() sometimes gives empty entires if no entries are present after delimiter.
        if (mid == '') {
          continue;
        }

        if ((mid.length > 8 || mid.length < 5 || mid[0].toLowerCase() == "m" || String(Number(mid.substring(1, 8))) == "NaN") && mid != "") {
          this.expertMIDError = true;
          break;
        }
        else {
          this.expertMIDError = false;
        }
      }
    }

    if (type == 'skill') {
      var stackPos = Number(event.target.getAttribute('stackId'));
      var skillPos = Number(event.target.getAttribute('skillId'));
      var formName = 'stack' + this.stackBoxCount[stackPos];
      if (event.target.value.trim() == "" && this.contentUploadFormGroup.get(formName).value.trim() != "") {
        this.skillErrors[stackPos][skillPos] = true;
      }
      else {
        this.skillErrors[stackPos][skillPos] = false;
      }

      if (event.target.value.trim() != "" && this.contentUploadFormGroup.get(formName).value.trim() == "") {
        this.stackErrors[stackPos] = true;
      }
      else {
        this.stackErrors[stackPos] = false;
      }
    }

    if (type == 'stack') {
      var skillList = '';
      var stackPos = Number(event.target.getAttribute('stackId'));

      this.skillBoxCount[stackPos].forEach((ele, index) => {
        var formName = 'stack' + this.stackBoxCount[stackPos] + 'skill' + ele;
        skillList += this.contentUploadFormGroup.get(formName).value.trim();
      });

      if (event.target.value.trim() == "" && skillList.trim() != "") {
        this.stackErrors[stackPos] = true;
      }
      else {
        this.stackErrors[stackPos] = false;
      }

    }
    this.validationCheckFunction = setInterval(()=>this.checkFormDataAtIntervals(),100);
  }

  //Called to open Yorbit courses selection popup for Pre-requisite courses
  openPreReqCourses() {

    if (this.selectedCourse && this.selectedCourse.CourseStatus.toLowerCase() != 'saved' && this.selectedCourse.CourseStatus.toLowerCase() != 'denied') {
      return;
    }

    this.preReqCourses = [];
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    dialogConfig.data = {
      data: {
        selectedCourses: this.preReqCoursesList,
        title: 'Shoshin School Courses',
        expertise: 'Package',
      },
      width: '250px',
      height: '250px'
    };
    var freshCoursesList = [];
    const response = this._popup.open(CoursesPopupComponent, dialogConfig);
    response.afterClosed().subscribe(res => {
      if (res.canSubmit) {
        if (res.selectedCourses && res.selectedCourses.length > 0) {

          res.selectedCourses.forEach((course, key) => {
            freshCoursesList.push(course);
            this.preReqCourses.push(JSON.parse(JSON.stringify(course)).Id);
          })
          this.isSaved = false;
          this.preReqCoursesList = freshCoursesList;
        }
      } else {
        // do nothing
      }
    }).add(() => {
      if (this.preReqCourses.length > 0) {
        this.contentUploadFormGroup.get('preReqMandatory').enable();
      }
      else {
        this.contentUploadFormGroup.get('preReqMandatory').disable();
      }
    });
  }

  //Called to open Yorbit courses selection popup for Post Recommended courses
  openPostRecCourses() {

    if (this.selectedCourse && this.selectedCourse.CourseStatus.toLowerCase() != 'saved' && this.selectedCourse.CourseStatus.toLowerCase() != 'denied') {
      return;
    }

    this.postRecCourses = [];
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    dialogConfig.data = {
      data: {
        selectedCourses: this.postRecCoursesList,
        title: 'Shoshin School Courses',
        expertise: 'Package',
      },
      width: '250px',
      height: '250px'
    };
    var freshCoursesList = [];
    const response = this._popup.open(CoursesPopupComponent, dialogConfig);
    response.afterClosed().subscribe(res => {
      if (res.canSubmit) {
        if (res.selectedCourses && res.selectedCourses.length > 0) {

          res.selectedCourses.forEach((course, key) => {
            freshCoursesList.push(course);
            this.postRecCourses.push(JSON.parse(JSON.stringify(course)).Id);
          })
          this.isSaved = false;
          this.postRecCoursesList = freshCoursesList;
          this.isSaved = false;
        }
      } else {
        // do nothing
      }
    });
  }

  //Delete a Pre-Requisite Course
  deletePreReqCourse(event: any) {

    if (this.selectedCourse && this.selectedCourse.CourseStatus.toLowerCase() != 'saved') {
      return;
    }

    var Index = event.target.getAttribute('index');
    this.preReqCourses = this.preReqCourses.filter((ele, index) => index != Index);
    this.preReqCoursesList = this.preReqCoursesList.filter((ele, index) => index != Index);

    if (this.preReqCourses.length == 0) {
      this.contentUploadFormGroup.get('preReqMandatory').setValue('no');
      this.contentUploadFormGroup.get('preReqMandatory').disable();
    }
  }

  //Delete a Post-Recommended Course
  deletePostRecCourse(event: any) {

    if (this.selectedCourse && this.selectedCourse.CourseStatus.toLowerCase() != 'saved') {
      return;
    }

    var Index = event.target.getAttribute('index');
    this.postRecCourses = this.postRecCourses.filter((ele, index) => index != Index);
    this.postRecCoursesList = this.postRecCoursesList.filter((ele, index) => index != Index);
    this.isSaved = false;
  }

  //Show Genres depending on selected Academy
  changeGenreOptions() {

    if (this.contentUploadFormGroup.get('academy').value == "") {
      this.academyError = true;
      return;
    }
    else {
      this.academyHidden = true;
      this.academyError = false;
      this.GenreTitle = "";
      this.genreHidden = false;
      this.skillHidden = false;
      this.SkillTitle = "Select a Genre";
      this.contentUploadFormGroup.get('genre').disable();
      this.contentUploadFormGroup.get('skill').disable();
    }

    this.genreList = [];
    this.genreSkillList = [];
    this.contentUploadFormGroup.get('genre').setValue('');

    if (this.taxanomy !== undefined) {
      this.taxanomy.forEach(value => {
        if (this.contentUploadFormGroup.get('academy').value !== '') {
          this.contentUploadFormGroup.get('genre').enable();
          if (value.Academy.toLowerCase() ===
            this.contentUploadFormGroup.get('academy').value.toLowerCase()
          ) {
            this.genreSkillList = value.GenreSkillList;
            this.genreSkillList.sort((a, b) => a.Genre.localeCompare(b.Genre));
          }
        }
        else {
          this.contentUploadFormGroup.get('genre').disable();
          this.contentUploadFormGroup.get('skill').disable();
        }
      });
      if (this.genreSkillList.length > 0) {
        this.genreSkillList.forEach(value => {
          this.genreList.push(value.Genre);
        });
        this.contentUploadFormGroup.controls['skill'].setValue('');
        this.contentUploadFormGroup.get('skill').disable();
        return this.genreList;
      } else {
        return [];
      }
    }
  }

  //Show skills depending on selected Genre
  changeSkillOptions() {

    if (this.contentUploadFormGroup.get('genre').value == "") {
      this.genreError = true;
      return;
    }
    else {
      this.genreHidden = true;
      this.genreError = false;
      this.SkillTitle = "";
      this.skillHidden = false;
      this.contentUploadFormGroup.get('skill').disable();
    }

    this.skillList = [];
    this.contentUploadFormGroup.controls['skill'].setValue('');
    if (this.genreSkillList.length > 0) {
      this.genreSkillList.forEach(value => {
        if (this.contentUploadFormGroup.get('genre').value !== '' &&
          this.contentUploadFormGroup.get('academy').value !== '') {
          this.contentUploadFormGroup.get('skill').enable();
          if (
            value.Genre.toLowerCase() ===
            this.contentUploadFormGroup.get('genre').value.toLowerCase()
          ) {
            this.skillList = value.Skills;
            this.skillList.sort((a, b) => a.localeCompare(b));
          }

        }
        else {
          this.contentUploadFormGroup.get('skill').disable();
        }
      });
      if (this.skillList.length > 0) {
        return this.skillList;
      } else {
        return [];
      }
    } else {
      return [];
    }
  }

  //Used for validation of Skill Dropdown
  enableSkillValidation() {
    if (this.contentUploadFormGroup.get('skill').value == "") {
      this.skillError = true;
    }
    else {
      this.skillError = false;
      this.skillHidden = true;
    }
  }

  //Adds a new stack to the list
  /* Form Group updations when adding a stack : 
        stackN : Contains input for stack name for Nth stack
        stackNskill1 : By default, a new stack will contain 1 skill box. stackNskill1 shows skill box for
                       Nth stack*/
  addStack() {
    var Max = this.stackBoxCount.reduce((a, b) => { return Math.max(a, b) }) + 1;
    this.stackBoxCount.push(Max);
    this.skillBoxCount.push([1]);
    (<FormGroup>this.contentUploadFormGroup).addControl("stack" + Max, new FormControl(''));
    (<FormGroup>this.contentUploadFormGroup).addControl("stack" + Max + "skill1", new FormControl(''));
    this.skillErrors.push([false]);
    this.stackErrors.push(false);
    this.disabledSkillButtons.push(false);
    this.disabledSkillTitle.push("");

    //Maximum of 10 stacks allowed
    if (this.stackBoxCount.length == 10) {
      this.disableAddStack = true;
      this.disabledStackTitle = "A max of 10 Stacks can be added";
    }
    else {
      this.disableAddStack = false;
      this.disabledStackTitle = "";
    }
  }

  //Adds a skill box to the stackv
  /* Form group updations when adding a skill box : 
     stackNskillM : Mth skill box for Nth stack */
  addSkill(event: any) {
    this.isSaved = false;
    var stackId = Number(event.target.getAttribute('stackId'));
    var StackIndex = this.stackBoxCount.findIndex(x => x == stackId);
    var Max = this.skillBoxCount[StackIndex].reduce((a, b) => { return Math.max(a, b) }) + 1;
    this.skillBoxCount[StackIndex].push(Max);
    (<FormGroup>this.contentUploadFormGroup).addControl("stack" + String(stackId) + "skill" + Max, new FormControl(''));
    this.skillErrors[StackIndex].push(false);
    this.disabledSkillTitle.push("");

    //Maximum of 10 skills allowed per stack
    if (this.skillBoxCount[StackIndex].length == 10) {
      this.disabledSkillButtons[StackIndex] = true;
      this.disabledSkillTitle[StackIndex] = "A max of 10 Skills can be added";
    }
    else {
      this.disabledSkillButtons[StackIndex] = false;
      this.disabledSkillTitle[StackIndex] = "";
    }
  }

  /* Adds a skill box using stackId. Used for preview and save feature where skill boxes has to be 
  genereated depending on the received data. */
  addSkillFromNgOnInit(stackId: any) {
    var StackIndex = this.stackBoxCount.findIndex(x => x == stackId);
    var Max = this.skillBoxCount[StackIndex].reduce((a, b) => { return Math.max(a, b) }) + 1;
    this.skillBoxCount[StackIndex].push(Max);
    (<FormGroup>this.contentUploadFormGroup).addControl("stack" + String(stackId) + "skill" + Max, new FormControl(''));
    this.skillErrors[StackIndex].push(false);
    this.disabledSkillTitle.push("");

    //Maximum of 10 skills allowed per stack
    if (this.skillBoxCount[StackIndex].length == 10) {
      this.disabledSkillButtons[StackIndex] = true;
      this.disabledSkillTitle[StackIndex] = "A max of 10 Skills can be added";
    }
    else {
      this.disabledSkillButtons[StackIndex] = false;
      this.disabledSkillTitle[StackIndex] = "";
    }
  }

  //Move a stack up
  moveStackUp(event: any) {

    if (this.selectedCourse && this.selectedCourse.CourseStatus.toLowerCase() != 'saved') {
      return;
    }

    var stackId = Number(event.target.getAttribute('stackId'));

    if (stackId == 0) {
      return;
    }

    //Move stack in stack box
    var tempStackBox = this.stackBoxCount[stackId - 1];
    this.stackBoxCount[stackId - 1] = this.stackBoxCount[stackId];
    this.stackBoxCount[stackId] = tempStackBox;

    //Move its corresponding skill boxes
    var tempSkillBox = this.skillBoxCount[stackId - 1];
    this.skillBoxCount[stackId - 1] = this.skillBoxCount[stackId];
    this.skillBoxCount[stackId] = tempSkillBox;

    //Move its corresponding skill error statuses
    var tempSkillError = this.skillErrors[stackId - 1];
    this.skillErrors[stackId - 1] = this.skillErrors[stackId];
    this.skillErrors[stackId] = tempSkillError;

    //Move its corresponding disabled skill button statuses
    var tempDisabledSkillButtonStatuses = this.disabledSkillButtons[stackId - 1];
    this.disabledSkillButtons[stackId - 1] = this.disabledSkillButtons[stackId];
    this.disabledSkillButtons[stackId] = tempDisabledSkillButtonStatuses;
    //Move its corresponding disabled skill button titles

    var tempDisabledSkillButtons = this.disabledSkillTitle[stackId - 1];
    this.disabledSkillTitle[stackId - 1] = this.disabledSkillTitle[stackId];
    this.disabledSkillTitle[stackId] = tempDisabledSkillButtons;
  }

  //Move a stack down
  moveStackDown(event: any) {

    if (this.selectedCourse && this.selectedCourse.CourseStatus.toLowerCase() != 'saved') {
      return;
    }

    var stackId = Number(event.target.getAttribute('stackId'));

    if (stackId == this.stackBoxCount.length - 1) {
      return;
    }

    //Move stack in stack box
    var tempStackBox = this.stackBoxCount[stackId + 1];
    this.stackBoxCount[stackId + 1] = this.stackBoxCount[stackId];
    this.stackBoxCount[stackId] = tempStackBox;

    //Move its corresponding skill boxes
    var tempSkillBox = this.skillBoxCount[stackId + 1];
    this.skillBoxCount[stackId + 1] = this.skillBoxCount[stackId];
    this.skillBoxCount[stackId] = tempSkillBox;

    //Move its corresponding skill error statuses
    var tempSkillError = this.skillErrors[stackId + 1];
    this.skillErrors[stackId + 1] = this.skillErrors[stackId];
    this.skillErrors[stackId] = tempSkillError;

    //Move its corresponding disabled skill button statuses
    var tempDisabledSkillButtonStatuses = this.disabledSkillButtons[stackId + 1];
    this.disabledSkillButtons[stackId + 1] = this.disabledSkillButtons[stackId];
    this.disabledSkillButtons[stackId] = tempDisabledSkillButtonStatuses;

    //Move its corresponding disabled skill button titles
    var tempDisabledSkillButtonsTitles = this.disabledSkillTitle[stackId + 1];
    this.disabledSkillTitle[stackId + 1] = this.disabledSkillTitle[stackId];
    this.disabledSkillTitle[stackId] = tempDisabledSkillButtonsTitles;
  }

  //Deletes a stack and its corresponding form elements and error statuses
  deleteStack(event: any) {

    if (this.selectedCourse && this.selectedCourse.CourseStatus.toLowerCase() != 'saved') {
      return;
    }

    var stackId = Number(event.target.getAttribute('stackId'));
    var StackIndex = this.stackBoxCount.findIndex(x => x == stackId);
    var SkillIndex = StackIndex;


    (<FormGroup>this.contentUploadFormGroup).removeControl('stack' + (stackId));
    this.skillBoxCount[StackIndex].forEach((ele, index) => {
      (<FormGroup>this.contentUploadFormGroup).removeControl('stack' + (stackId) + 'skill' + ele);
    });

    this.stackBoxCount = this.stackBoxCount.filter((ele, index) => index != StackIndex);
    this.skillBoxCount.splice(SkillIndex, 1);
    this.skillErrors = this.skillErrors.filter((ele, index) => index != SkillIndex);

    //Maximum of 10 stacks allowed
    if (this.stackBoxCount.length == 10) {
      this.disableAddStack = true;
      this.disabledStackTitle = "A max of 10 Stacks can be added";
    }
    else {
      this.disableAddStack = false;
      this.disabledStackTitle = "";
    }

    this.disabledSkillButtons = this.disabledSkillButtons.filter((ele, index) => index != StackIndex);
    this.disabledSkillTitle = this.disabledSkillTitle.filter((ele, index) => index != StackIndex);
  }

  //Set stack name input in form control 
  setStackInput(event: any) {
    var StackId = Number(event.target.getAttribute('stackId')) + 1;
    this.contentUploadFormGroup.get('stack' + StackId).setValue(event.target.value);
  }

  //Set skill input in form control
  setSkillInput(event: any) {
    var StackId = Number(event.target.getAttribute('stackId')) + 1;
    var SkillId = Number(event.target.getAttribute('skillId')) + 1;
    this.contentUploadFormGroup.get('stack' + StackId + 'skill' + SkillId).setValue(event.target.value);
  }

  //Delete a skill
  deleteSkill(event: any) {

    this.isSaved = false;

    if (this.selectedCourse && this.selectedCourse.CourseStatus.toLowerCase() != 'saved') {
      return;
    }

    var StackId = Number(event.target.getAttribute('stackId'));
    var SkillId = Number(event.target.getAttribute('skillId'));
    var StackIndex = Number(this.stackBoxCount.findIndex(x => x == StackId));
    var SkillIndex = StackIndex;
    this.skillBoxCount[StackIndex].splice(this.skillBoxCount[StackIndex].findIndex(x => x == SkillId), 1);
    (<FormGroup>this.contentUploadFormGroup).removeControl('stack' + String(StackId) + 'skill' + String(SkillId));
    this.skillErrors[StackIndex] = this.skillErrors[StackIndex].filter((ele, index) => index != SkillIndex);

    //Maximum of 10 skills allowed per stack
    if (this.skillBoxCount[StackIndex].length == 10) {
      this.disabledSkillButtons[StackIndex] = true;
      this.disabledSkillTitle[StackIndex] = "A max of 10 Skills can be added";
    }
    else {
      this.disabledSkillButtons[StackIndex] = false;
      this.disabledSkillTitle[StackIndex] = "";
    }
  }

  //Method for choosing Content Owner and Learning OPM SPOC
  openMindsList(control: string) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    //shareDialogContainer

    dialogConfig.data = {
      type: control == 'contentOwner' ? 'contentOwner' : 'lopmspoc',
      list: control == "contentOwner" ? [this.COMID] : [this.LOPMID]
    };

    const dialogref = this._popup.open(SearchEvaluatorPopupComponent, dialogConfig);

    dialogref.afterClosed().subscribe(data => {
      if (null != data) {
        this.isSaved = false;
        this.contentUploadFormGroup.controls[control].setValue(data[0].displayName);

        if (control == 'contentOwner') {
          this.COMID = data[0].userPrincipalName.split("@")[0];
        }
        else {
          this.LOPMID = data[0].userPrincipalName.split("@")[0];
        }

      }
    });
  }

  //Used for navigating pages
  changePagination(page: number) {

    //If it is a preview, skip validation checking and jumpt to next page
    if (this.selectedCourse && (this.selectedCourse.CourseStatus.toLowerCase() != 'saved' && this.selectedCourse.CourseStatus.toLowerCase() != 'denied')) {
      this.pageActive = Number(page);
      this.isSaved = true;
    }

    //If the page is not saved, set appropriate error messages and return 
    else if (!this.isSaved) {
      this.prevButtonTitle = "Please save the data to proceed";
      return;
    }

    //If none of the above conditions, check and proceed to next page
    else {

      this.prevButtonTitle = "";
      //If page 2 is requested, perform validation of first page 
      if (page == 2) {
        if (this.contentUploadFormGroup.get('academy').value == '') {
          this.academyError = true;
        }
        else {
          this.academyError = false;
        }

        if (this.contentUploadFormGroup.get('genre').value == '') {
          this.genreError = true;
        }
        else {
          this.genreError = false;
        }

        if (this.contentUploadFormGroup.get('skill').value == '') {
          this.skillError = true;
        }
        else {
          this.skillError = false;
        }

        if (this.contentUploadFormGroup.get('courseName').value == '') {
          this.courseNameError = true;
        }


        if (this.contentUploadFormGroup.get('courseDesc').value == '') {
          this.courseDescError = true;
        }

        if (this.contentUploadFormGroup.get('badgeName').value == '') {
          this.badgeError = true;
        }


        if (this.contentUploadFormGroup.get('credits').value == '') {
          this.creditsError = true;
        }


        if (this.contentUploadFormGroup.get('courseTypeDropdown').value == '') {
          this.courseTypeError = true;
        }
        else {
          this.courseTypeError = false;
        }

        if (this.contentUploadFormGroup.get('courseDuration').value == '') {
          this.durationError = true;
        }
        
        if (this.contentUploadFormGroup.get('proficiency').value == '') {
          this.proficiencyError = true;
        }
        else {
          this.proficiencyError = false;
        }

        //Check if a course with the same name exists 
        this._contentUploadService.checkCourseNameExistsForEdit(this.contentUploadFormGroup.get('courseName').value, this.courseUniqueId).subscribe((res) => {
          //show error if it exists
          if (res == true) {
            this.courseExistsError = true;
            return;
          }
          else {
            this.courseExistsError = false;
          }
        }).add(() => {

          //If there are error values set, return from this method
          if (this.academyError ||
            this.genreError ||
            this.skillError ||
            this.courseNameError ||
            this.courseDescError ||
            this.courseTypeError ||
            this.coursePriceError ||
            this.validCoursePrice ||
            this.badgeError ||
            this.creditsError ||
            this.durationError ||
            this.vendorError ||
            this.topicsCoveredError ||
            this.expertMIDError ||
            this.rangeError ||
            this.courseExistsError ||
            this.proficiencyError) {
            return;
          }
          else {

            if (this.selectedCourse && (this.selectedCourse.CourseStatus.toLowerCase() != 'submitted' || this.selectedCourse.CourseStatus.toLowerCase() != 'approved')) {
              //Check if the user wishes to proceed with the uploading of content
              const dialogConfig = new MatDialogConfig();
              dialogConfig.disableClose = true;
              dialogConfig.autoFocus = true;
              dialogConfig.panelClass = 'popupDialogContainer';
              dialogConfig.data = "Confirmation";

              this._popup.open(ContentUpload101PopupsComponent, dialogConfig).afterClosed().subscribe(data => {

                //If the user wishes to upload then continue
                if (data.toLowerCase() == 'yes') {
                  this.pageActive = Number(page);
                  this.isSaved = true;
                }
                else {
                  return;
                }
              });
            }

            //If the course is in submitted status, change page without any validation
            else {
              this.isSaved = true;
              this.pageActive = Number(page);
            }
          }
        });
      }

      //If page 1 is requested, proceed without any validation and set isSaved to false
      else {
        this.pageActive = Number(page);
        this.isSaved = true;
      }

    }
  }

  //Exiting the form
  onExit() {

    var errorMsg =
      'Changes you have made (if any) and unsaved will be lost.<br/>Are you sure you want to Exit Form?';

    let dataPayload = {
      msg: errorMsg,
      title: 'Exit Form'
    };
    let type = 'exit';
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    dialogConfig.data = {
      data: dataPayload,
      width: '250px',
      height: '250px',
      type: type
    };
    const response = this._popup.open(
      ContentUploadStatusPopupComponent,
      dialogConfig
    );
    response.afterClosed().subscribe(res => {
      if (!res) {
        this.enableContentVerificationComponent.emit('301');
      }
      else {
        return;
      }
    });

  }

  //Validate the field when user moves out of the field
  validateOnMouseOut(event: any, fieldName: any) {


    if (fieldName == 'academy') {
      this.isSaved = false;
      if (event.target.value.trim() == "") {
        this.academyError = true;
      }
    }

    if (fieldName == 'genre') {
      this.isSaved = false;
      if (event.target.value.trim() == "") {
        this.genreError = true;
      }
    }

    if (fieldName == 'skillDropDown') {
      this.isSaved = false;
      if (event.target.value.trim() == "") {
        this.skillError = true;
      }
    }

    if (fieldName == 'courseName') {
      if (event.target.value.trim() == "") {
        this.courseNameError = true;
        this.courseExistsError = false;
      }
    }

    if (fieldName == 'courseDesc') {
      if (event.target.value.trim() == "") {
        this.courseDescError = true;
      }
    }

    if (fieldName == 'courseType') {
      if (event.target.value.trim() == "") {
        this.courseTypeError = true;
      }
      else {
        this.courseTypeError = false;
      }
    }

    if (fieldName == 'proficiency') {
      if (event.target.value.trim() == "") {
        this.proficiencyError = true;
      }
      else {
        this.proficiencyError = false;
      }
    }

    if (fieldName == 'duration') {
      if (event.target.value.trim() == "") {
        this.durationError = true;
      }
    }

    if (fieldName == 'credits') {
      if (event.target.value.trim() == "") {
        this.creditsError = true;
      }
    }

    if (fieldName == 'badgeName') {
      if (event.target.value.trim() == "") {
        this.badgeError = true;
      }
    }

    if (fieldName == 'topicsCovered') {
      if (event.target.value.trim() == "" || event.target.value.trim().length < 50) {
        this.topicsCoveredError = true;
      }
      else {
        this.topicsCoveredError = false;
      } 
    }

    if (fieldName == 'vendor') {
      this.isSaved = false;
      if (event.target.value.trim() == "") {
        this.vendorError = true;
      }
      else
       {
         this.vendorError = false;
       }
    }

    if (fieldName == 'coursePrice') {
      if (event.target.value.trim() == "") {
        this.validCoursePrice = true;
      }
    }

    if (fieldName == 'skill') {
      var stackPos = Number(event.target.getAttribute('stackId'));
      var skillPos = Number(event.target.getAttribute('skillId'));
      var formName = 'stack' + this.stackBoxCount[stackPos];
      if (event.target.value.trim() == "" && this.contentUploadFormGroup.get(formName).value.trim() != "") {
        this.skillErrors[stackPos][skillPos] = true;
      }
      else {
        this.skillErrors[stackPos][skillPos] = false;
      }

      if (event.target.value.trim() != "" && this.contentUploadFormGroup.get(formName).value.trim() == "") {
        this.stackErrors[stackPos] = true;
      }
      else {
        this.stackErrors[stackPos] = false;
      }
    }

    if (fieldName == 'stack') {
      var skillList = '';
      var stackPos = Number(event.target.getAttribute('stackId'));

      this.skillBoxCount[stackPos].forEach((ele, index) => {
        var formName = 'stack' + this.stackBoxCount[stackPos] + 'skill' + ele;
        skillList += this.contentUploadFormGroup.get(formName).value.trim();
      });

      if (event.target.value.trim() == "" && skillList.trim() != "") {
        this.stackErrors[stackPos] = true;
      }
      else {
        this.stackErrors[stackPos] = false;
      }

    }
    this.validationCheckFunction = setInterval(() => this.checkFormDataAtIntervals,100);
  }

  //Save form
  saveAndProceed() {
    this.validationCheckFunction = clearInterval(this.validationCheckFunction);
    this.disableSave = true;

  
    //For Internal Course
    if (this.contentUploadFormGroup.get('courseType').value == 'yes') {

      if (this.contentUploadFormGroup.get('academy').value == '') {
        this.academyError = true;
      }
      else {
        this.academyError = false;
      }

      if (this.contentUploadFormGroup.get('genre').value == '') {
        this.genreError = true;
      }
      else {
        this.genreError = false;
      }

      if (this.contentUploadFormGroup.get('skill').value == '') {
        this.skillError = true;
      }
      else {
        this.skillError = false;
      }

      if (this.contentUploadFormGroup.get('proficiency').value == '') {
        this.proficiencyError = true;
      }
      else {
        this.proficiencyError = false;
      }

      if (this.contentUploadFormGroup.get('courseName').value == '') {
        this.courseNameError = true;
      }


      if (this.contentUploadFormGroup.get('courseDesc').value == '') {
        this.courseDescError = true;
      }

      if (this.contentUploadFormGroup.get('badgeName').value == '') {
        this.badgeError = true;
      }


      if (this.contentUploadFormGroup.get('credits').value == '') {
        this.creditsError = true;
      }


      if (this.contentUploadFormGroup.get('courseTypeDropdown').value == '') {
        this.courseTypeError = true;
      }
      else {
        this.courseTypeError = false;
      }

      if (this.contentUploadFormGroup.get('courseDuration').value == '') {
        this.durationError = true;
      }

      if (this.contentUploadFormGroup.get('topicsCovered').value == '') {
        this.topicsCoveredError = true;
      }
    }

    //For external course
    else {

      if (this.contentUploadFormGroup.get('academy').value == '') {
        this.academyError = true;
      }
      else {
        this.academyError = false;
      }

      if (this.contentUploadFormGroup.get('genre').value == '') {
        this.genreError = true;
      }
      else {
        this.genreError = false;
      }

      if (this.contentUploadFormGroup.get('skill').value == '') {
        this.skillError = true;
      }
      else {
        this.skillError = false;
      }

      if (this.contentUploadFormGroup.get('proficiency').value == '') {
        this.proficiencyError = true;
      }
      else {
        this.proficiencyError = false;
      }

      if (this.contentUploadFormGroup.get('courseName').value == '') {
        this.courseNameError = true;
      }


      if (this.contentUploadFormGroup.get('courseDesc').value == '') {
        this.courseDescError = true;
      }

      if (this.contentUploadFormGroup.get('badgeName').value == '') {
        this.badgeError = true;
      }


      if (this.contentUploadFormGroup.get('credits').value == '') {
        this.creditsError = true;
      }

      if (this.contentUploadFormGroup.get('courseTypeDropdown').value == '') {
        this.courseTypeError = true;
      }
      else {
        this.courseTypeError = false;
      }

      if (this.contentUploadFormGroup.get('courseDuration').value == '') {
        this.durationError = true;
      }

      if (this.contentUploadFormGroup.get('coursePrice').value == '') {
        this.validCoursePrice = true;
      }


      if (this.contentUploadFormGroup.get('nameOfVendor').value == '') {
        this.vendorError = true;
      }
      else {
        this.vendorError = false;
      }

      if (this.contentUploadFormGroup.get('topicsCovered').value == '') {
        this.topicsCoveredError = true;
      }

    }


    //Collecting and setting stack and skill names
    var stacksAndSkills = [];

    this.stackBoxCount.forEach((stackEle, index) => {
      var skillList = [];
      var stackName = "";

      stackName = this.contentUploadFormGroup.get('stack' + stackEle).value;

      //If any skill list is emtpy, show error
      this.skillBoxCount[index].forEach((skillEle, skillIndex) => {
        if (this.contentUploadFormGroup.get('stack' + stackEle + 'skill' + skillEle).value.trim() == "" && this.contentUploadFormGroup.get('stack' + stackEle).value.trim() != "") {
          this.skillErrors[index][skillIndex] = true;
        }
        else {
          this.skillErrors[index][skillIndex] = false;
          skillList.push(this.contentUploadFormGroup.get('stack' + stackEle + 'skill' + skillEle).value.trim());
        }
      });


      //If the Stack Name is empty and skill list is not empty
      if ((stackName.trim() == "" && skillList.join("").trim() != "")) {
        this.stackErrors[index] = true;
      }
      else {
        this.stackErrors[index] = false;
      }


      //If stack and skill is not empty, push it to list
      if (stackName.trim() != "" && skillList.join("").trim() != "") {
        stacksAndSkills.push({
          "Stack": stackName.trim(),
          "Skills": skillList
        });
      }
    });


    //If there are error values set, return from this method
    if ([].concat(...this.skillErrors).filter(x => x == true).length > 0 ||
      this.stackErrors.filter(x => x == true).length > 0 ||
      this.academyError ||
      this.genreError ||
      this.skillError ||
      this.courseNameError ||
      this.courseDescError ||
      this.courseTypeError ||
      this.coursePriceError ||
      this.validCoursePrice ||
      this.badgeError ||
      this.creditsError ||
      this.durationError ||
      this.vendorError ||
      this.topicsCoveredError ||
      this.expertMIDError ||
      this.minDurationError ||
      this.rangeError ||
      this.proficiencyError) {
      //this.disableSave = false;
      this.validationCheckFunction = setInterval(() => this.checkFormDataAtIntervals(),100);
      return;
    }

    else {
      //Hide all error messages and continue submitting the form
      this.skillErrors.forEach((ele, index) => {
        ele.forEach((subItem, subIndex) => {
          ele[subIndex] = false;
        });
      });

      this.stackErrors.forEach((ele, index) => {
        this.stackErrors[index] = false;
      });

      var Content = {
        "UniqueId" : this.courseUniqueId,
        "Name": this.contentUploadFormGroup.get('courseName').value,
        "academy": this.contentUploadFormGroup.get('academy').value,
        "genre": this.contentUploadFormGroup.get('genre').value,
        "skill": this.contentUploadFormGroup.get('skill').value,
        "vendor": this.contentUploadFormGroup.get('nameOfVendor').value,
        "type": this.contentUploadFormGroup.get('courseTypeDropdown').value,
        "isHiddenCourse": this.contentUploadFormGroup.get('courseHidden').value == 'yes' ? 1 : 0,
        "isCourseInternal": this.contentUploadFormGroup.get('courseType').value == 'yes' ? 1 : 0,
        "topicsCovered": this.contentUploadFormGroup.get('topicsCovered').value,
        "PreRequisiteCourseIDs": this.preReqCourses,
        "PostRecommendedCourseIDs": this.postRecCourses,
        "BadgeName": this.contentUploadFormGroup.get('badgeName').value,
        "Description": this.contentUploadFormGroup.get('courseDesc').value,
        "ExpertMids": this.contentUploadFormGroup.get('expertMIDS').value,
        "IsRestrictedCourse": this.contentUploadFormGroup.get('restrictedCourse').value == 'yes' ? 1 : 0,
        "Duration": this.contentUploadFormGroup.get('courseDuration').value,
        "ContentOwner": this.COMID,
        "LearningSPOCMID": this.LOPMID,
        "ManagerApproval": this.contentUploadFormGroup.get('learningPartnerApproval').value == 'yes' ? 1 : 0,
        "IsPreRequisiteMandatory": this.contentUploadFormGroup.get('preReqMandatory').value == 'yes' ? 1 : 0,
        "Credits": this.contentUploadFormGroup.get('credits').value,
        "IsPaid": this.contentUploadFormGroup.get('courseType').value == 'no' ? true : false,
        "Price": this.contentUploadFormGroup.get('courseType').value == 'no' ? (this.contentUploadFormGroup.get('coursePrice').value + ' ' + this.contentUploadFormGroup.get('currency').value.toUpperCase()) : null,
        "StackAndSkills": stacksAndSkills,
        "IsCloudLabAvailable" : this.contentUploadFormGroup.get('isCloudLabsRequired').value == "yes" ? true : false,
        "IsCertificateAvailable" :  this.contentUploadFormGroup.get('isCertificateAvailable').value == "yes" ? true : false,
        "IsCertificateUpload" :  this.contentUploadFormGroup.get('isCertificateUpload').value == "yes" ? true : false,
        "proficiency": this.contentUploadFormGroup.get('proficiency').value,
        "SkillIds": this.contentUploadFormGroup.get('SkillIds').value,
      };

      //Check if a course with the same name exists if a saved course is selected for editing
      if (this.courseUniqueId != null) {
        this._contentUploadService.checkCourseNameExistsForEdit(Content.Name, this.courseUniqueId).toPromise().then((res) => {

          //show error if it exists
          if (res == true) {
            this.courseExistsError = true;
            this.disableSave = false;
            return;
          }

          //Else add the content
          else {
            this.courseExistsError = false;
            this._service.insert301Content(Content, this.courseUniqueId).toPromise().then((res) => {
              this.courseUniqueId = res;
              if (res == null) {
                //Show failure message
                const dialogConfig = new MatDialogConfig();
                dialogConfig.disableClose = true;
                dialogConfig.autoFocus = true;
                dialogConfig.panelClass = 'popupDialogContainer';
                dialogConfig.data = "ContentMessageFail";
                this._popup.open(ContentUpload101PopupsComponent, dialogConfig).afterClosed().subscribe(data => {
                  this.disableSave = false;
                  this.validationCheckFunction = setInterval(() => { this.checkFormDataAtIntervals(); }, 100);
                });
              }

              else {
                this.isSaved = true;

                const dialogConfig = new MatDialogConfig();
                dialogConfig.disableClose = true;
                dialogConfig.autoFocus = true;
                dialogConfig.panelClass = 'popupDialogContainer';
                dialogConfig.data = "ContentSaveMessage";
                this._popup.open(ContentUpload101PopupsComponent, dialogConfig).afterClosed().subscribe(data => {
                  this.disableSave = false;
                  this.validationCheckFunction = setInterval(() => { this.checkFormDataAtIntervals(); }, 100);
                });
              }
            });
          }
        });
      }

      //If a new course is being added
      else {
        this._contentUploadService.checkIfCourseNameExists(Content.Name).toPromise().then((res) => {

          //show error if it exists
          if (res == true) {
            this.courseExistsError = true;
            this.disableSave = false;
            return;
          }

          //Else add the content
          else {
            this.courseExistsError = false;
            this._service.insert301Content(Content, null).toPromise().then((res) => {
              if (res == null) {
                //Show failure message
                const dialogConfig = new MatDialogConfig();
                dialogConfig.disableClose = true;
                dialogConfig.autoFocus = true;
                dialogConfig.panelClass = 'popupDialogContainer';
                dialogConfig.data = "ContentMessageFail";
                this._popup.open(ContentUpload101PopupsComponent, dialogConfig).afterClosed().subscribe(data => {
                  this.disableSave = false;
                  this.validationCheckFunction = setInterval(() => { this.checkFormDataAtIntervals(); }, 100);
                });
              }

              else {
                this.isSaved = true;
                this.courseUniqueId = res;
                //Check if the user wishes to proceed with the saving of content
                const dialogConfig = new MatDialogConfig();
                dialogConfig.disableClose = true;
                dialogConfig.autoFocus = true;
                dialogConfig.panelClass = 'popupDialogContainer';
                dialogConfig.data = "ContentSaveMessage";
                this._popup.open(ContentUpload101PopupsComponent, dialogConfig).afterClosed().subscribe(data => {
                  this.disableSave = false;
                  this.validationCheckFunction = setInterval(() => { this.checkFormDataAtIntervals(); }, 100);
                });
              }
            });
          }
        });
      }
    }

  }

  //Validate and set respective error variables
  validateAndProceed() {

    //For Internal Course
    if (this.contentUploadFormGroup.get('courseType').value == 'yes') {

      if (this.contentUploadFormGroup.get('academy').value == '') {
        this.academyError = true;
      }
      else {
        this.academyError = false;
      }

      if (this.contentUploadFormGroup.get('genre').value == '') {
        this.genreError = true;
      }
      else {
        this.genreError = false;
      }

      if (this.contentUploadFormGroup.get('skill').value == '') {
        this.skillError = true;
      }
      else {
        this.skillError = false;
      }

      if (this.contentUploadFormGroup.get('proficiency').value == '') {
        this.proficiencyError = true;
      }
      else {
        this.proficiencyError = false;
      }

      if (this.contentUploadFormGroup.get('courseName').value == '') {
        this.courseNameError = true;
      }


      if (this.contentUploadFormGroup.get('courseDesc').value == '') {
        this.courseDescError = true;
      }

      if (this.contentUploadFormGroup.get('badgeName').value == '') {
        this.badgeError = true;
      }


      if (this.contentUploadFormGroup.get('credits').value == '') {
        this.creditsError = true;
      }


      if (this.contentUploadFormGroup.get('courseTypeDropdown').value == '') {
        this.courseTypeError = true;
      }
      else {
        this.courseTypeError = false;
      }

      if (this.contentUploadFormGroup.get('courseDuration').value == '') {
        this.durationError = true;
      }

      if (this.contentUploadFormGroup.get('topicsCovered').value == '') {
        this.topicsCoveredError = true;
      }
  
    }

    //For external course
    else {

      if (this.contentUploadFormGroup.get('academy').value == '') {
        this.academyError = true;
      }
      else {
        this.academyError = false;
      }

      if (this.contentUploadFormGroup.get('genre').value == '') {
        this.genreError = true;
      }
      else {
        this.genreError = false;
      }

      if (this.contentUploadFormGroup.get('skill').value == '') {
        this.skillError = true;
      }
      else {
        this.skillError = false;
      }

      if (this.contentUploadFormGroup.get('proficiency').value == '') {
        this.proficiencyError = true;
      }
      else {
        this.proficiencyError = false;
      }

      if (this.contentUploadFormGroup.get('courseName').value == '') {
        this.courseNameError = true;
      }


      if (this.contentUploadFormGroup.get('courseDesc').value == '') {
        this.courseDescError = true;
      }

      if (this.contentUploadFormGroup.get('badgeName').value == '') {
        this.badgeError = true;
      }


      if (this.contentUploadFormGroup.get('credits').value == '') {
        this.creditsError = true;
      }

      if (this.contentUploadFormGroup.get('courseTypeDropdown').value == '') {
        this.courseTypeError = true;
      }
      else {
        this.courseTypeError = false;
      }

      if (this.contentUploadFormGroup.get('courseDuration').value == '') {
        this.durationError = true;
      }

      if (this.contentUploadFormGroup.get('coursePaid').value == 'yes' && this.contentUploadFormGroup.get('coursePrice').value == '') {
        this.coursePriceError = true;
      }


      if (this.contentUploadFormGroup.get('nameOfVendor').value == '') {
        this.vendorError = true;
      }
      else {
        this.vendorError = false;
      }

      if (this.contentUploadFormGroup.get('topicsCovered').value == '') {
        this.topicsCoveredError = true;
      }

    }


    //Collecting and setting stack and skill names
    var stacksAndSkills = [];

    this.stackBoxCount.forEach((stackEle, index) => {
      var skillList = [];
      var stackName = "";

      stackName = this.contentUploadFormGroup.get('stack' + stackEle).value;

      //If any skill list is emtpy, show error
      this.skillBoxCount[index].forEach((skillEle, skillIndex) => {
        if (this.contentUploadFormGroup.get('stack' + stackEle + 'skill' + skillEle).value.trim() == "" && this.contentUploadFormGroup.get('stack' + stackEle).value.trim() != "") {
          this.skillErrors[index][skillIndex] = true;
        }
        else {
          this.skillErrors[index][skillIndex] = false;
          skillList.push(this.contentUploadFormGroup.get('stack' + stackEle + 'skill' + skillEle).value.trim());
        }
      });


      //If the Stack Name is empty and skill list is not empty
      if ((stackName.trim() == "" && skillList.join("").trim() != "")) {
        this.stackErrors[index] = true;
      }
      else {
        this.stackErrors[index] = false;
      }


      //If stack and skill is not empty, push it to list
      if (stackName.trim() != "" && skillList.join("").trim() != "") {
        stacksAndSkills.push({
          "Stack": stackName.trim(),
          "Skills": skillList
        });
      }
    });


    //If there are error values set, return from this method
    if ([].concat(...this.skillErrors).filter(x => x == true).length > 0 ||
      this.stackErrors.filter(x => x == true).length > 0 ||
      this.academyError ||
      this.genreError ||
      this.skillError ||
      this.courseNameError ||
      this.courseDescError ||
      this.courseTypeError ||
      this.coursePriceError ||
      this.validCoursePrice ||
      this.badgeError ||
      this.creditsError ||
      this.durationError ||
      this.vendorError ||
      this.topicsCoveredError ||
      this.expertMIDError ||
      this.rangeError ||
      this.proficiencyError) {
      return;
    }

    else {
      //Hide all error messages and continue submitting the form
      this.skillErrors.forEach((ele, index) => {
        ele.forEach((subItem, subIndex) => {
          ele[subIndex] = false;
        });
      });

      this.stackErrors.forEach((ele, index) => {
        this.stackErrors[index] = false;
      });

      var Content = {
        "UniqueId" : this.courseUniqueId,
        "Name": this.contentUploadFormGroup.get('courseName').value,
        "academy": this.contentUploadFormGroup.get('academy').value,
        "genre": this.contentUploadFormGroup.get('genre').value,
        "skill": this.contentUploadFormGroup.get('skill').value,
        "vendor": this.contentUploadFormGroup.get('nameOfVendor').value,
        "type": this.contentUploadFormGroup.get('courseTypeDropdown').value,
        "isHiddenCourse": this.contentUploadFormGroup.get('courseHidden').value == 'yes' ? 1 : 0,
        "isCourseInternal": this.contentUploadFormGroup.get('courseType').value == 'yes' ? 1 : 0,
        "topicsCovered": this.contentUploadFormGroup.get('topicsCovered').value,
        "PreRequisiteCourseIDs": this.preReqCourses,
        "PostRecommendedCourseIDs": this.postRecCourses,
        "BadgeName": this.contentUploadFormGroup.get('badgeName').value,
        "Description": this.contentUploadFormGroup.get('courseDesc').value,
        "ExpertMids": this.contentUploadFormGroup.get('expertMIDS').value,
        "IsRestrictedCourse": this.contentUploadFormGroup.get('restrictedCourse').value == 'yes' ? 1 : 0,
        "Duration": this.contentUploadFormGroup.get('courseDuration').value,
        "ContentOwner": this.COMID,
        "LearningSPOCMID": this.LOPMID,
        "ManagerApproval": this.contentUploadFormGroup.get('learningPartnerApproval').value == 'yes' ? 1 : 0,
        "IsPreRequisiteMandatory": this.contentUploadFormGroup.get('preReqMandatory').value == 'yes' ? 1 : 0,
        "Credits": this.contentUploadFormGroup.get('credits').value,
        "IsPaid": this.contentUploadFormGroup.get('courseType').value == 'no' ? true : false,
        "Price": this.contentUploadFormGroup.get('courseType').value == 'no' ? (this.contentUploadFormGroup.get('coursePrice').value + ' ' + this.contentUploadFormGroup.get('currency').value.toUpperCase()) : null,
        "StackAndSkills": stacksAndSkills,
        "IsCloudLabAvailable" : this.contentUploadFormGroup.get('isCloudLabsRequired').value == "yes" ? true : false,
        "IsCertificateAvailable" :  this.contentUploadFormGroup.get('isCertificateAvailable').value == "yes" ? true : false,
        "IsCertificateUpload" :  this.contentUploadFormGroup.get('isCertificateUpload').value == "yes" ? true : false,
        "proficiency": this.contentUploadFormGroup.get('proficiency').value,
        "SkillIds": this.contentUploadFormGroup.get('SkillIds').value,
      };

      //Check if the user wishes to proceed with the uploading of content
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'popupDialogContainer';
      dialogConfig.data = "Confirmation";

      this._popup.open(ContentUpload101PopupsComponent, dialogConfig).afterClosed().subscribe(data => {

        //If the user wishes to upload then continue
        if (data.toLowerCase() == 'yes') {

          //API call to update course status and trigger mail
          this._service.insert301Content(Content, this.courseUniqueId).subscribe(resp => {
            if (resp == false) {
              //Show failure message
              const dialogConfig = new MatDialogConfig();
              dialogConfig.disableClose = true;
              dialogConfig.autoFocus = true;
              dialogConfig.panelClass = 'popupDialogContainer';
              dialogConfig.data = "ContentMessageFail";
              this._popup.open(ContentUpload101PopupsComponent, dialogConfig).afterClosed().subscribe(data => {
              });
            }
            else {
              //Show success message
              const dialogConfig = new MatDialogConfig();
              dialogConfig.disableClose = true;
              dialogConfig.autoFocus = true;
              dialogConfig.panelClass = 'popupDialogContainer';
              dialogConfig.data = "ContentMessage";

              this._popup.open(ContentUpload101PopupsComponent, dialogConfig).afterClosed().subscribe(() => {
                // API call to update course status and trigger mail
                this._service.update301Status(this.courseUniqueId).subscribe(resp => {
                  this.enableContentVerificationComponent.emit('301');
                });
              });
            }
          });

          // else {
          //   this._service.insert301Content(Content, null).subscribe((res) => {

          //     //If the content is uploaded successfully, uniqueID will returned else null will be returned
          //     if (res == null) {
          //       //Show failure message
          //       const dialogConfig = new MatDialogConfig();
          //       dialogConfig.disableClose = true;
          //       dialogConfig.autoFocus = true;
          //       dialogConfig.panelClass = 'popupDialogContainer';
          //       dialogConfig.data = "ContentMessageFail";
          //       this._popup.open(ContentUpload101PopupsComponent, dialogConfig).afterClosed().subscribe(data => {
          //       });
          //     }

          //     else {
          //       //Show success message
          //       const dialogConfig = new MatDialogConfig();
          //       dialogConfig.disableClose = true;
          //       dialogConfig.autoFocus = true;
          //       dialogConfig.panelClass = 'popupDialogContainer';
          //       dialogConfig.data = "ContentMessage";
          //       this._popup.open(ContentUpload101PopupsComponent, dialogConfig).afterClosed().subscribe(() => {

          //         //API call to update course status and trigger mail
          //         this._service.update301Status(res).subscribe(resp => {
          //         });

          //         //Move back to AO page after successful submission
          //         this.enableContentVerificationComponent.emit('AO');
          //       });
          //     }
          //   });
          // }

        }
        //else just return
        else {
          return;
        }
      });
    }
  }
  getYorbitCourses(){
    this._courseComponent.getAllYorbitCourses().subscribe(
      data => {
        this._courseComponent.YorbitCoursesList = data.filter(x=>x.CourseStatus.toLowerCase() == "approved"); 
      });
  }
}
