import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { ContentVerificationService } from './content-verification.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSelect } from '@angular/material/select';
import { MatMenu } from '@angular/material/menu';
import { ContentUploadHomePopupsComponent } from './content-upload-home-popups/content-upload-home-popups.component';
import { CheckboxList } from '../pss/pss-evaluation/pss-evaluation.interface';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { Globals } from '../globals';
//import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import { ContentUploadService } from '../ao/content-upload/content-upload.service';
//import { I } from '@angular/cdk/keycodes';
//import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import { MatDatepicker } from '@angular/material/datepicker';
import { ThisReceiver } from '@angular/compiler';

@Component({
  selector: 'yorbit-content-verification',
  templateUrl: './content-verification.component.html',
  styleUrls: ['./content-verification.component.scss']
})
export class ContentVerificationComponent implements OnInit {
  @Input() role: string;
  @Input() classRoom: boolean;
  @Input() expertise: string;
  @Output() courseSelectedForVerification = new EventEmitter<any>();
  @Output() enableContentUploadComponent = new EventEmitter<any>();
  @Output() enableQuizUploadComponent = new EventEmitter<any>();
  @Output() enable101ContentUploadComponent = new EventEmitter<any>();
  @Output() enable301ContentUploadComponent = new EventEmitter<any>();
  @Output() enablePackageContentUploadComponent = new EventEmitter<any>();


  //contentList: any;
  @ViewChild('select') select: MatSelect;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  duplicatePaginator: any;
  public contentList: MatTableDataSource<any>;
  public records: any[] = [];
  masterContentList: any;
  courseUniqueId: string;
  errorOnLoad: boolean;
  exceptionMessage: string;
  getContentLoaded: boolean;
  titleMessage: string;
  courseExperise: string = '101';
  dataSource = new MatTableDataSource();
  courseNameSearchText: string;
  academySearchText: string;
  genreSearchText: string;
  skillSearchText: string;
  list: any[];
  isFilterApplied: boolean = false;

  distinctCourseName: string[] = [];
  courseNameFilter: CheckboxList[] = [];
  selectedCourseNameFilter: CheckboxList[] = [];
  checkedCourseName: string[] = [];
  selectAllCourseName: boolean = false;

  distinctType: string[] = [];
  courseTypeFilter: CheckboxList[] = [];
  selectedCourseTypeFilter: CheckboxList[] = [];
  checkedType: string[] = [];
  selectAllType: boolean = false;

  distinctRepoType: string[] = [];
  repoTypeFilter: CheckboxList[] = [];
  selectedRepoTypeFilter: CheckboxList[] = [];
  checkedRepoType: string[] = [];
  selectAllRepoType: boolean = false;

  distinctAcademy: string[] = [];
  academyFilter: CheckboxList[] = [];
  selectedAcademyFilter: CheckboxList[] = [];
  checkedAcademy: string[] = [];
  selectAllAcademy: boolean = false;

  distinctGenre: string[] = [];
  genreFilter: CheckboxList[] = [];
  selectedGenreFilter: CheckboxList[] = [];
  checkedGenre: string[] = [];
  selectAllGenre: boolean = false;

  distinctSkill: string[] = [];
  skillFilter: CheckboxList[] = [];
  selectedSkillFilter: CheckboxList[] = [];
  checkedSkill: string[] = [];
  selectAllSkill: boolean = false;
  allowed101List: string[];
  allowedAoList = [];
  allowedPCUList = [];
  //cususer
  @Input() cuuserroles: any;

  distinctStatus: string[] = [];
  courseStatusFilter: CheckboxList[] = [];
  selectedCourseStatusFilter: CheckboxList[] = [];
  checkedStatus: string[] = [];
  selectAllStatus: boolean = false;

  distinctCategory: string[] = [];
  categoryFilter: CheckboxList[] = [];
  selectedCategoryFilter: CheckboxList[] = [];
  checkedCategory: string[] = [];
  selectAllCategory: boolean = false;



  checkFilter: boolean = false;

  allSelected = false;
  displayedColumns: string[] = ['courseName', 'type', 'repoType', 'academy', 'genre', 'skill', 'category', 'courseStatus'];
  //private _contentUploadService: any;

  constructor(
    private _contentVerificationService: ContentVerificationService,
    private _contentUploadService: ContentUploadService,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private _popup: MatDialog,
    private globals: Globals
  ) {
    this.errorOnLoad = false;
    this.getContentLoaded = false;
  }

  ngAfterViewInit() {
    if (this.contentList) {
      this.contentList.paginator = this.paginator;
    }
  }

  ngOnInit() {
    //this.allowedAoList = this.globals.allowedAoList;
    //this.allowedPCUList = this.globals.allowedPCUList;
    //this.allowed101List = this.globals.allowedDevList;
    //console.log(this.cuuserroles);
    //this.getRoles(); 


    // this.activatedRoute.paramMap.subscribe(params => {
    //   const roleData = params.get('role');
    //   if (roleData)
    //   {
    //     this.role=roleData;
    //   }
    // });
    this.exceptionMessage =
      'opm' === this.role.toLowerCase()
        ? 'No course content has been uploaded by Content Partners for Verification.'
        : '';
    this.titleMessage =
      ('opm' || 'ld') === this.role.toLowerCase()
        ? 'Select a Course to Review'
        : 'Select the content to act on the same';

    // if (this.cuuserroles && (-1 != this.cuuserroles.indexOf('101'))) {
    //   this.courseExpertiseSelected('101');
    // }
    // else if (this.cuuserroles && (-1 != this.cuuserroles.indexOf('201'))) {
    //   this.courseExpertiseSelected('201');
    // }
    // else if (this.cuuserroles && (-1 != this.cuuserroles.indexOf('301'))) {
    //   this.courseExpertiseSelected('301');
    // }
    // else if (this.cuuserroles && (-1 != this.cuuserroles.indexOf('package'))) {
    // }
    //   this.courseExpertiseSelected('package');

    if (this.role.toLowerCase() == 'ao') {
      //if (this.cuuserroles && (-1 != this.cuuserroles.indexOf('101'))){
      if (this.expertise != undefined) {
        if (this.cuuserroles && (-1 != this.cuuserroles.indexOf(this.expertise))) {
          this.courseExperise = this.expertise;
        }
        else {
          this.courseExperise = this.experiseSelect();
        }
      }
      else {
        this.courseExperise = this.experiseSelect();
      }
      this.contentList = new MatTableDataSource<any>();
      this.courseExpertiseSelected(this.courseExperise);

      //   this.courseExperise = '101';
      //   this.expertise = '101';
      // //} else if (this.cuuserroles && (-1 != this.cuuserroles.indexOf('package'))){
      //   this.courseExperise = 'package';
      //   this.expertise = 'package';
      // //}
      // this.contentList = new MatTableDataSource<any>();
      // this.courseExpertiseSelected(this.courseExperise);
    }
    else if (this.role.toLowerCase() == 'ld' && this.expertise == undefined) {
      this.courseExperise = '301';
      if (this.classRoom) {
        this.getContentList('201');
      } else {
        this.getContentList('301');
      }
      console.log("ld")
    }
    else if (this.role.toLowerCase() == 'opm' && this.expertise == undefined) {
      this.courseExperise = '201';
      this.getContentList('201');
    }

    // if (this.expertise != undefined && this.role.toLowerCase() == 'ao' && this.cuuserroles && (-1 != this.cuuserroles.indexOf('101')) && (-1 != this.cuuserroles.indexOf('201')) && (-1 != this.cuuserroles.indexOf('301'))) {
    //   this.courseExperise = this.expertise;
    //   this.contentList = new MatTableDataSource<any>();
    //   this.courseExpertiseSelected(this.expertise);
    // }
    // else if (this.expertise == undefined && this.role.toLowerCase() == 'ao' && this.cuuserroles && (-1 != this.cuuserroles.indexOf('101'))) {
    //   this.courseExperise = '101';
    //   
    // }
  }

  courseExpertiseSelected(expertise) {
    this.courseExperise = expertise;
    if (this.courseExperise == '201') {
      this.displayedColumns = ['courseName', 'type', 'repoType', 'academy', 'genre', 'skill', 'category', 'courseStatus'];
      this.getContentList('201');
    }
    else if (this.courseExperise == 'package') {
      this.displayedColumns = ['courseName', 'repoType', 'academy', 'genre', 'skill', 'category', 'PackageStatus'];
      this.getContentList('package');
    }
    else if (this.courseExperise == '301') {
      this.displayedColumns = ['courseName', 'type', 'repoType', 'academy', 'genre', 'skill', 'category', 'courseStatus'];
      this.getContentList('301');
    }
    else {
      this.displayedColumns = ['courseName', 'type', 'repoType', 'academy', 'genre', 'skill', 'category', 'courseStatus'];
      this.getContentList('101');
    }
  }

  resetAllList() {
    this.contentList = new MatTableDataSource<any>();
    this.masterContentList = [];

    this.distinctCourseName = [];
    this.courseNameFilter = [];
    this.selectedCourseNameFilter = [];
    this.checkedCourseName = [];
    this.selectAllCourseName = false;

    this.distinctType = [];
    this.courseTypeFilter = [];
    this.selectedCourseTypeFilter = [];
    this.checkedType = [];
    this.selectAllType = false;

    this.distinctRepoType = [];
    this.repoTypeFilter = [];
    this.selectedRepoTypeFilter = [];
    this.checkedRepoType = [];
    this.selectAllRepoType = false;

    this.distinctAcademy = [];
    this.academyFilter = [];
    this.selectedAcademyFilter = [];
    this.checkedAcademy = [];
    this.selectAllAcademy = false;

    this.distinctGenre = [];
    this.genreFilter = [];
    this.selectedGenreFilter = [];
    this.checkedGenre = [];
    this.selectAllGenre = false;

    this.distinctSkill = [];
    this.skillFilter = [];
    this.selectedSkillFilter = [];
    this.checkedSkill = [];
    this.selectAllSkill = false;

    this.distinctStatus = [];
    this.courseStatusFilter = [];
    this.selectedCourseStatusFilter = [];
    this.checkedStatus = [];
    this.selectAllStatus = false;

    this.distinctCategory = [];
    this.categoryFilter = [];
    this.selectedCategoryFilter = [];
    this.checkedCategory = [];
    this.selectAllCategory = false;

  }

  resetAllFilters() {
    if (this.role == 'AO')
      this.getContentList(this.courseExperise);
    else if (this.role.toLowerCase() == 'ld') {
      if (this.classRoom == true) {
        this.getContentList('201');
      } else {
        this.getContentList('301');
      }
    }
    else
      this.getContentList('201');

    this.checkFilter = false;
  }

  //CUuserRole
  getRoles() {
    this._contentUploadService.getContentUploadUserRoles().toPromise().then(response => {
      this.cuuserroles = response
      // if (this.cuuserroles && (-1 != this.cuuserroles.indexOf('101'))) {
      //   this.courseExpertiseSelected('101');
      // }
      // else if(this.cuuserroles && (-1 != this.cuuserroles.indexOf('201')))  {
      //   this.courseExpertiseSelected('201');
      // }
      // else if(this.cuuserroles && (-1 != this.cuuserroles.indexOf('301')))  {
      //   this.courseExpertiseSelected('301');
      // }
      // else if(this.cuuserroles && (-1 != this.cuuserroles.indexOf('package')))  {
      //   this.courseExpertiseSelected('package');
      // }
      //console.log(this.cuuserroles)
    })
  }

  getContentList(expertise) {
    this.resetAllList();
    // let expertise='';
    //  if (-1 != this.cuuserroles.indexOf(selectedExpertise)) {
    //   expertise=selectedExpertise;
    //  }else{
    //   expertise=this.cuuserroles[0];
    //  }
    if (expertise == '201') {
      if (this.classRoom) {
        this.role = "opm";
      }
      this._contentVerificationService
        .getContentUpload201Details(this.role)
        .then(response => {
          //For class room tab in c2ops page
          if (this.classRoom) {
            var updatedResponse = response.filter(x => x.Classroom == true);
            this.records = updatedResponse as any[];
            this.contentList = new MatTableDataSource(this.records);
            this.contentList.paginator = this.paginator;
            this.masterContentList = updatedResponse;
          } else {
            // var updatedResonse = response.filter(x => x.Classroom == false);
            var updatedResonse = response;
            this.records = updatedResonse as any[];
            this.contentList = new MatTableDataSource(this.records);
            this.contentList.paginator = this.paginator;
            this.masterContentList = updatedResonse;
          }
          this.setFilterData();
          if (response == null || response == '') {
            if ('ao' === this.role.toLowerCase()) {
              //this.route.navigate(['contentupload']);
              //this.enableContentUploadComponent.emit(null);
            }
          }
          this.getContentLoaded = true;
        })
        .catch(error => {
          this.contentList = new MatTableDataSource<any>();
          this.errorOnLoad = true;
          this.exceptionMessage = 'An Error has occured, please refresh.';
          this.getContentLoaded = true;
        });
    }

    else if (expertise == '301') {
      console.log("301: ", this.role);
      this._contentVerificationService.getCourseData('301', this.role).then(response => {
        this.records = response as any[];
        this.contentList = new MatTableDataSource(this.records);
        this.contentList.paginator = this.paginator;
        this.masterContentList = response;
        this.setFilterData();

        if (response == null) {
          if ('ao' === this.role.toLowerCase()) {
            //this.route.navigate(['contentupload']);
            //this.enableContentUploadComponent.emit(null);
          }
        }
        this.getContentLoaded = true;
      })
        .catch(error => {
          this.contentList = new MatTableDataSource<any>();
          this.errorOnLoad = true;
          this.exceptionMessage = 'An Error has occured, please refresh.';
          this.getContentLoaded = true;
        });
    }
    else if (expertise == 'package') {
      this._contentVerificationService
        .getPackageContentUploadDetails()
        .then(response => {
          this.records = response as any[];
          this.contentList = new MatTableDataSource(this.records);
          this.contentList.paginator = this.paginator;
          this.masterContentList = response;
          this.setFilterData();

          if (response == null || response == '') {
            if ('ao' === this.role.toLowerCase()) {
              //this.route.navigate(['contentupload']);
              //this.enableContentUploadComponent.emit(null);
            }
          }
          this.getContentLoaded = true;
        })
        .catch(error => {
          this.contentList = new MatTableDataSource<any>();
          this.errorOnLoad = true;
          this.exceptionMessage = 'An Error has occured, please refresh.';
          this.getContentLoaded = true;
        });
    }
    else if (expertise == '101') {
      this._contentVerificationService.getCourseData('101', this.role).then(response => {
        if (response) {
          this.records = response as any[];
          this.contentList = new MatTableDataSource(this.records);
          this.contentList.paginator = this.paginator;
          this.masterContentList = response;
          this.setFilterData();
          this.getContentLoaded = true;
        }
      })
    }
  }

  setFilterData() {
    if (this.masterContentList) {
      this.masterContentList.forEach(element => {
        if (element.Type == "" || element.Type == null) {
          element.Type = "NA";
        }

        if (element.Academy == "" || element.Academy == null) {
          element.Academy = "NA";
        }

        if (element.Genre == "" || element.Genre == null) {
          element.Genre = "NA";
        }

        if (element.Skill == "" || element.Skill == null) {
          element.Skill = "NA";
        }

        if (element.Category == "" || element.Category == null) {
          element.Category = "NA";
        }

        if (this.courseExperise == 'package') {
          if (element.PackageStatus.toLowerCase() == 'approved') {
            element.PackageStatus = 'Launched';
          }
        }
        else {
          if (element.CourseStatus.toLowerCase() == 'approved') {
            element.CourseStatus = this.role.toLowerCase() == 'ao' ? 'Launched' : 'Approved';
          }
        }
      });
      this.distinctCourseName = Array.from(new Set(this.masterContentList.map(x => x.Name)));
      this.distinctCourseName.map(item => {
        return {
          value: item,
          isSelected: false
        }
      }).forEach(item => this.courseNameFilter.push(item));
      this.selectedCourseNameFilter = this.courseNameFilter;

      this.distinctType = Array.from(new Set(this.masterContentList.map(x => x.Type)));
      this.distinctType.map(item => {
        return {
          value: (item == undefined || item.trim() == "" || item == null) ? "NA" : item.trim(),
          isSelected: false
        }
      }).forEach(item => this.courseTypeFilter.push(item));
      this.selectedCourseTypeFilter = this.courseTypeFilter;

      this.distinctRepoType = Array.from(new Set(this.masterContentList.map(x => x.RepoType)));
      this.distinctRepoType.map(item => {
        return {
          value: (item == undefined || item.trim() == "" || item == null) ? "NA" : item.trim(),
          isSelected: false
        }
      }).forEach(item => this.repoTypeFilter.push(item));
      this.selectedRepoTypeFilter = this.repoTypeFilter;

      this.distinctAcademy = Array.from(new Set(this.masterContentList.map(x => x.Academy)));
      this.distinctAcademy.map(item => {
        return {
          value: (item == undefined || item.trim() == "" || item == null) ? "NA" : item.trim(),
          isSelected: false
        }
      }).forEach(item => this.academyFilter.push(item));
      this.selectedAcademyFilter = this.academyFilter;

      this.distinctGenre = Array.from(new Set(this.masterContentList.map(x => x.Genre)));
      this.distinctGenre.map(item => {
        return {
          value: (item == undefined || item.trim() == "" || item == null) ? "NA" : item.trim(),
          isSelected: false
        }
      }).forEach(item => this.genreFilter.push(item));
      this.selectedGenreFilter = this.genreFilter;

      this.distinctSkill = Array.from(new Set(this.masterContentList.map(x => x.Skill)));
      this.distinctSkill.map(item => {
        return {
          value: (item == undefined || item.trim() == "" || item == null) ? "NA" : item.trim(),
          isSelected: false
        }
      }).forEach(item => this.skillFilter.push(item));
      this.selectedSkillFilter = this.skillFilter;

      this.distinctCategory = Array.from(new Set(this.masterContentList.map(x => x.Category)));
      this.distinctCategory.map(item => {
        return {
          value: (item == undefined || item.trim() == "" || item == null) ? "NA" : item.trim(),
          isSelected: false
        }
      }).forEach(item => this.categoryFilter.push(item));
      this.selectedCategoryFilter = this.categoryFilter;

      this.distinctStatus = this.courseExperise != 'package' ? Array.from(new Set(this.masterContentList.map(x => x.CourseStatus))) : Array.from(new Set(this.masterContentList.map(x => x.PackageStatus)));
      this.distinctStatus.map(item => {
        return {
          value: (item.trim() == "" || item == null) ? "NA" : item.trim(),
          isSelected: false
        }
      }).forEach(item => this.courseStatusFilter.push(item));
      this.selectedCourseStatusFilter = this.courseStatusFilter;
    }
  }

  clearSearch(type) {
    if (type == 'academy')
      this.academySearchText = '';
    else if (type == 'genre')
      this.genreSearchText = '';
    else if (type == 'skill')
      this.skillSearchText = '';
    else if (type == 'courseName')
      this.courseNameSearchText = '';
  }

  updateSelectAll(type) {
    if (type == "CourseName") {
      this.selectAllCourseName = !this.selectAllCourseName;
      if (this.selectAllCourseName) {
        this.checkedCourseName = [];
        for (let i = 0; i < this.courseNameFilter.length; i++) {
          this.courseNameFilter[i].isSelected = true;
          this.checkedCourseName.push(this.courseNameFilter[i].value);
        }
      } else {
        this.checkedCourseName = [];
        for (let i = 0; i < this.courseNameFilter.length; i++) {
          this.courseNameFilter[i].isSelected = false;
        }
      }

      this.checkIfAnyFiltersApplied();

      this.updateSelectedCourseNameFilter();
      this.loadContentData();
      this.retainSelectedElements('CourseName');
    }
    if (type == "Type") {
      this.selectAllType = !this.selectAllType;
      if (this.selectAllType) {
        this.checkedType = [];
        for (let i = 0; i < this.courseTypeFilter.length; i++) {
          this.courseTypeFilter[i].isSelected = true;
          this.checkedType.push(this.courseTypeFilter[i].value);
        }
      } else {
        this.checkedType = [];
        for (let i = 0; i < this.courseTypeFilter.length; i++) {
          this.courseTypeFilter[i].isSelected = false;
        }
      }

      this.checkIfAnyFiltersApplied();

      this.updateSelectedTypeFilter();
      this.loadContentData();
      this.retainSelectedElements('Type');
    }
    if (type == "RepoType") {
      this.selectAllRepoType = !this.selectAllRepoType;
      if (this.selectAllRepoType) {
        this.checkedRepoType = [];
        for (let i = 0; i < this.repoTypeFilter.length; i++) {
          this.repoTypeFilter[i].isSelected = true;
          this.checkedRepoType.push(this.repoTypeFilter[i].value);
        }
      } else {
        this.checkedRepoType = [];
        for (let i = 0; i < this.repoTypeFilter.length; i++) {
          this.repoTypeFilter[i].isSelected = false;
        }
      }

      this.checkIfAnyFiltersApplied();

      this.updateSelectedRepoTypeFilter();
      this.loadContentData();
      this.retainSelectedElements('RepoType');
    }
    if (type == "Academy") {
      this.selectAllAcademy = !this.selectAllAcademy;
      if (this.selectAllAcademy) {
        this.checkedAcademy = [];
        for (let i = 0; i < this.academyFilter.length; i++) {
          this.academyFilter[i].isSelected = true;
          this.checkedAcademy.push(this.academyFilter[i].value);
        }
      } else {
        this.checkedAcademy = [];
        for (let i = 0; i < this.academyFilter.length; i++) {
          this.academyFilter[i].isSelected = false;
        }
      }

      this.checkIfAnyFiltersApplied();

      this.updateSelectedRepoTypeFilter();
      this.loadContentData();
      this.retainSelectedElements('Academy');
    }
    if (type == "Genre") {
      this.selectAllGenre = !this.selectAllGenre;
      if (this.selectAllGenre) {
        this.checkedGenre = [];
        for (let i = 0; i < this.genreFilter.length; i++) {
          this.genreFilter[i].isSelected = true;
          this.checkedGenre.push(this.genreFilter[i].value);
        }
      } else {
        this.checkedGenre = [];
        for (let i = 0; i < this.genreFilter.length; i++) {
          this.genreFilter[i].isSelected = false;
        }
      }

      this.checkIfAnyFiltersApplied();


      this.updateSelectedGenreFilter();
      this.loadContentData();
      this.retainSelectedElements('Genre');
    }
    if (type == "Skill") {
      this.selectAllSkill = !this.selectAllSkill;
      if (this.selectAllSkill) {
        this.checkedSkill = [];
        for (let i = 0; i < this.skillFilter.length; i++) {
          this.skillFilter[i].isSelected = true;
          this.checkedSkill.push(this.skillFilter[i].value);
        }
      } else {
        this.checkedSkill = [];
        for (let i = 0; i < this.skillFilter.length; i++) {
          this.skillFilter[i].isSelected = false;
        }
      }

      this.checkIfAnyFiltersApplied();

      this.updateSelectedSkillFilter();
      this.loadContentData();
      this.retainSelectedElements('Skill');
    }
    if (type == "Category") {
      this.selectAllCategory = !this.selectAllCategory;
      if (this.selectAllCategory) {
        this.checkedCategory = [];
        for (let i = 0; i < this.categoryFilter.length; i++) {
          this.categoryFilter[i].isSelected = true;
          this.checkedCategory.push(this.categoryFilter[i].value);
        }
      } else {
        this.checkedCategory = [];
        for (let i = 0; i < this.categoryFilter.length; i++) {
          this.categoryFilter[i].isSelected = false;
        }
      }

      this.checkIfAnyFiltersApplied();

      this.updateSelectedCategoryFilter();
      this.loadContentData();
      this.retainSelectedElements('Category');
    }
    if (type == "CourseStatus" || type == 'PackageStatus') {
      this.selectAllStatus = !this.selectAllStatus;
      if (this.selectAllStatus) {
        this.checkedStatus = [];
        for (let i = 0; i < this.courseStatusFilter.length; i++) {
          this.courseStatusFilter[i].isSelected = true;
          this.checkedStatus.push(this.courseStatusFilter[i].value);
        }
      } else {
        this.checkedStatus = [];
        for (let i = 0; i < this.courseStatusFilter.length; i++) {
          this.courseStatusFilter[i].isSelected = false;
        }
      }

      this.checkIfAnyFiltersApplied();

      this.updateSelectedCourseStatusFilter();
      this.loadContentData();
      this.retainSelectedElements('CourseStatus');
    }
  }

  updateSelectedCourseNameFilter() {
    this.selectedCourseNameFilter.forEach(x => x.isSelected = false);
    this.courseNameFilter.forEach(x => x.isSelected = false);
    for (let i = 0; i < this.checkedCourseName.length; i++) {
      const index = this.selectedCourseNameFilter.findIndex(x => x.value == this.checkedCourseName[i]);
      if (index != null)
        this.selectedCourseNameFilter[index].isSelected = true;
      const index2 = this.courseNameFilter.findIndex(x => x.value == this.checkedCourseName[i]);
      if (index2 != null)
        this.courseNameFilter[index2].isSelected = true;
    }
    this.selectAllCourseName = true;
    for (let i = 0; i < this.courseNameFilter.length; i++) {
      if (!this.courseNameFilter[i].isSelected) {
        this.selectAllCourseName = false;
      }
    }
  }

  updateSelectedTypeFilter() {
    this.selectedCourseTypeFilter.forEach(x => x.isSelected = false);
    this.courseTypeFilter.forEach(x => x.isSelected = false);
    for (let i = 0; i < this.checkedType.length; i++) {
      const index = this.selectedCourseTypeFilter.findIndex(x => x.value == this.checkedType[i]);
      if (index != null)
        this.selectedCourseTypeFilter[index].isSelected = true;
      const index2 = this.courseTypeFilter.findIndex(x => x.value == this.checkedType[i]);
      if (index2 != null)
        this.courseTypeFilter[index2].isSelected = true;
    }

    this.selectAllType = true;
    for (let i = 0; i < this.courseTypeFilter.length; i++) {
      if (!this.courseTypeFilter[i].isSelected) {
        this.selectAllType = false;
      }
    }
  }

  updateSelectedRepoTypeFilter() {
    this.selectedRepoTypeFilter.forEach(x => x.isSelected = false);
    this.repoTypeFilter.forEach(x => x.isSelected = false);
    for (let i = 0; i < this.checkedRepoType.length; i++) {
      const index = this.selectedRepoTypeFilter.findIndex(x => x.value == this.checkedRepoType[i]);
      if (index != null)
        this.selectedRepoTypeFilter[index].isSelected = true;
      const index2 = this.repoTypeFilter.findIndex(x => x.value == this.checkedRepoType[i]);
      if (index2 != null)
        this.repoTypeFilter[index2].isSelected = true;
    }

    this.selectAllRepoType = true;
    for (let i = 0; i < this.repoTypeFilter.length; i++) {
      if (!this.repoTypeFilter[i].isSelected) {
        this.selectAllRepoType = false;
      }
    }
  }

  updateSelectedAcademyFilter() {
    this.selectedAcademyFilter.forEach(x => x.isSelected = false);
    this.academyFilter.forEach(x => x.isSelected = false);
    for (let i = 0; i < this.checkedAcademy.length; i++) {
      const index = this.selectedAcademyFilter.findIndex(x => x.value == this.checkedAcademy[i]);
      if (index != null)
        this.selectedAcademyFilter[index].isSelected = true;
      const index2 = this.academyFilter.findIndex(x => x.value == this.checkedAcademy[i]);
      if (index2 != null)
        this.academyFilter[index2].isSelected = true;
    }

    this.selectAllAcademy = true;
    for (let i = 0; i < this.academyFilter.length; i++) {
      if (!this.academyFilter[i].isSelected) {
        this.selectAllAcademy = false;
      }
    }
  }

  updateSelectedGenreFilter() {
    this.selectedGenreFilter.forEach(x => x.isSelected = false);
    this.genreFilter.forEach(x => x.isSelected = false);
    for (let i = 0; i < this.checkedGenre.length; i++) {
      const index = this.selectedGenreFilter.findIndex(x => x.value == this.checkedGenre[i]);
      if (index != null)
        this.selectedGenreFilter[index].isSelected = true;
      const index2 = this.genreFilter.findIndex(x => x.value == this.checkedGenre[i]);
      if (index2 != null)
        this.genreFilter[index2].isSelected = true;
    }

    this.selectAllGenre = true;
    for (let i = 0; i < this.genreFilter.length; i++) {
      if (!this.genreFilter[i].isSelected) {
        this.selectAllGenre = false;
      }
    }
  }

  updateSelectedSkillFilter() {
    this.selectedSkillFilter.forEach(x => x.isSelected = false);
    this.skillFilter.forEach(x => x.isSelected = false);
    for (let i = 0; i < this.checkedSkill.length; i++) {
      const index = this.selectedSkillFilter.findIndex(x => x.value == this.checkedSkill[i]);
      if (index != null)
        this.selectedSkillFilter[index].isSelected = true;
      const index2 = this.skillFilter.findIndex(x => x.value == this.checkedSkill[i]);
      if (index2 != null)
        this.skillFilter[index2].isSelected = true;
    }

    this.selectAllSkill = true;
    for (let i = 0; i < this.skillFilter.length; i++) {
      if (!this.skillFilter[i].isSelected) {
        this.selectAllSkill = false;
      }
    }
  }

  updateSelectedCategoryFilter() {
    this.selectedCategoryFilter.forEach(x => x.isSelected = false);
    this.categoryFilter.forEach(x => x.isSelected = false);
    for (let i = 0; i < this.checkedCategory.length; i++) {
      const index = this.selectedCategoryFilter.findIndex(x => x.value == this.checkedCategory[i]);
      if (index != null)
        this.selectedCategoryFilter[index].isSelected = true;
      const index2 = this.categoryFilter.findIndex(x => x.value == this.checkedCategory[i]);
      if (index2 != null)
        this.categoryFilter[index2].isSelected = true;
    }

    this.selectAllCategory = true;
    for (let i = 0; i < this.categoryFilter.length; i++) {
      if (!this.categoryFilter[i].isSelected) {
        this.selectAllCategory = false;
      }
    }
  }

  updateSelectedCourseStatusFilter() {
    this.selectedCourseStatusFilter.forEach(x => x.isSelected = false);
    this.courseStatusFilter.forEach(x => x.isSelected = false);
    for (let i = 0; i < this.checkedStatus.length; i++) {
      const index = this.selectedCourseStatusFilter.findIndex(x => x.value == this.checkedStatus[i]);
      if (index != null)
        this.selectedCourseStatusFilter[index].isSelected = true;
      const index2 = this.courseStatusFilter.findIndex(x => x.value == this.checkedStatus[i]);
      if (index2 != null)
        this.courseStatusFilter[index2].isSelected = true;
    }

    this.selectAllStatus = true;
    for (let i = 0; i < this.courseStatusFilter.length; i++) {
      if (!this.courseStatusFilter[i].isSelected) {
        this.selectAllStatus = false;
      }
    }
  }

  updateCourseNameFilter(value) {
    this.selectedCourseNameFilter.forEach(x => x.isSelected = false);
    if (this.checkedCourseName.indexOf(value) == -1)
      this.checkedCourseName.push(value);
    else
      this.checkedCourseName.splice(this.checkedCourseName.indexOf(value), 1);

    this.checkIfAnyFiltersApplied();

    this.updateSelectedCourseNameFilter();
    this.loadContentData();
    this.retainSelectedElements('CourseName');
  }

  updateCourseTypeFilter(value) {
    this.selectedCourseTypeFilter.forEach(x => x.isSelected = false);
    if (this.checkedType.indexOf(value) == -1)
      this.checkedType.push(value);
    else
      this.checkedType.splice(this.checkedType.indexOf(value), 1);

    this.checkIfAnyFiltersApplied();

    this.updateSelectedTypeFilter();
    this.loadContentData();
    this.retainSelectedElements('Type');
  }

  updateRepoTypeFilter(value) {
    this.selectedRepoTypeFilter.forEach(x => x.isSelected = false);
    if (this.checkedRepoType.indexOf(value) == -1)
      this.checkedRepoType.push(value);
    else
      this.checkedRepoType.splice(this.checkedRepoType.indexOf(value), 1);

    this.checkIfAnyFiltersApplied();

    this.updateSelectedRepoTypeFilter();
    this.loadContentData();
    this.retainSelectedElements('RepoType');
  }

  updateAcademyFilter(value) {
    this.selectedAcademyFilter.forEach(x => x.isSelected = false);
    if (this.checkedAcademy.indexOf(value) == -1)
      this.checkedAcademy.push(value);
    else
      this.checkedAcademy.splice(this.checkedAcademy.indexOf(value), 1);

    this.checkIfAnyFiltersApplied();

    this.updateSelectedAcademyFilter();
    this.loadContentData();
    this.retainSelectedElements('Academy');

  }

  updateGenreFilter(value) {
    this.selectedGenreFilter.forEach(x => x.isSelected = false);
    if (this.checkedGenre.indexOf(value) == -1)
      this.checkedGenre.push(value);
    else
      this.checkedGenre.splice(this.checkedGenre.indexOf(value), 1);

    this.checkIfAnyFiltersApplied();

    this.updateSelectedGenreFilter();
    this.loadContentData();
    this.retainSelectedElements('Genre');
  }

  updateSkillFilter(value) {
    this.selectedSkillFilter.forEach(x => x.isSelected = false);
    if (this.checkedSkill.indexOf(value) == -1)
      this.checkedSkill.push(value);
    else
      this.checkedSkill.splice(this.checkedSkill.indexOf(value), 1);

    this.checkIfAnyFiltersApplied();

    this.updateSelectedSkillFilter();
    this.loadContentData();
    this.retainSelectedElements('Skill');
  }

  updateCategoryFilter(value) {
    this.selectedCategoryFilter.forEach(x => x.isSelected = false);
    if (this.checkedCategory.indexOf(value) == -1)
      this.checkedCategory.push(value);
    else
      this.checkedCategory.splice(this.checkedCategory.indexOf(value), 1);

    this.checkIfAnyFiltersApplied();

    this.updateSelectedCategoryFilter();
    this.loadContentData();
    this.retainSelectedElements('Category');
  }

  updateCourseStatusFilter(value) {
    this.selectedCourseStatusFilter.forEach(x => x.isSelected = false);
    if (this.checkedStatus.indexOf(value) == -1)
      this.checkedStatus.push(value);
    else
      this.checkedStatus.splice(this.checkedStatus.indexOf(value), 1);

    this.checkIfAnyFiltersApplied();

    this.updateSelectedCourseStatusFilter();
    this.loadContentData();
    this.retainSelectedElements('CourseStatus');
  }

  retainSelectedElements(type) {

    if (type != 'CourseName') {
      this.distinctCourseName = [];
      this.courseNameFilter = [];

      this.distinctCourseName = Array.from(new Set(this.contentList.data.map(x => x.Name)));
      this.distinctCourseName.map(item => {
        return {
          value: item,
          isSelected: false
        }
      }).forEach(item => this.courseNameFilter.push(item));

      this.updateSelectedCourseNameFilter();
    }

    if (type != 'Type') {
      this.distinctType = [];
      this.courseTypeFilter = [];

      this.distinctType = Array.from(new Set(this.contentList.data.map(x => x.Type)));
      this.distinctType.map(item => {
        return {
          value: item,
          isSelected: false
        }
      }).forEach(item => this.courseTypeFilter.push(item));

      this.updateSelectedTypeFilter();
    }

    if (type != 'RepoType') {
      this.distinctRepoType = [];
      this.repoTypeFilter = [];

      this.distinctRepoType = Array.from(new Set(this.contentList.data.map(x => x.RepoType)));
      this.distinctRepoType.map(item => {
        return {
          value: item,
          isSelected: false
        }
      }).forEach(item => this.repoTypeFilter.push(item));

      this.updateSelectedRepoTypeFilter();
    }

    if (type != 'Academy') {
      this.distinctAcademy = [];
      this.academyFilter = [];

      this.distinctAcademy = Array.from(new Set(this.contentList.data.map(x => x.Academy)));
      this.distinctAcademy.map(item => {
        return {
          value: item,
          isSelected: false
        }
      }).forEach(item => this.academyFilter.push(item));

      this.updateSelectedAcademyFilter();
    }

    if (type != 'Genre') {
      this.distinctGenre = [];
      this.genreFilter = [];

      this.distinctGenre = Array.from(new Set(this.contentList.data.map(x => x.Genre)));
      this.distinctGenre.map(item => {
        return {
          value: item,
          isSelected: false
        }
      }).forEach(item => this.genreFilter.push(item));

      this.updateSelectedGenreFilter();
    }

    if (type != 'Skill') {
      this.distinctSkill = [];
      this.skillFilter = [];

      this.distinctSkill = Array.from(new Set(this.contentList.data.map(x => x.Skill)));
      this.distinctSkill.map(item => {
        return {
          value: item,
          isSelected: false
        }
      }).forEach(item => this.skillFilter.push(item));

      this.updateSelectedSkillFilter();
    }

    if (type != 'Category') {
      this.categoryFilter = [];
      this.distinctCategory = [];

      this.distinctCategory = Array.from(new Set(this.contentList.data.map(x => x.Category)));
      this.distinctCategory.map(item => {
        return {
          value: item,
          isSelected: false
        }
      }).forEach(item => this.categoryFilter.push(item));

      this.updateSelectedCategoryFilter();
    }

    if (type != 'CourseStatus') {
      this.courseStatusFilter = [];
      this.distinctStatus = [];

      this.distinctStatus = this.courseExperise != 'package' ? Array.from(new Set(this.contentList.data.map(x => x.CourseStatus))) : Array.from(new Set(this.contentList.data.map(x => x.PackageStatus)));
      this.distinctStatus.map(item => {
        return {
          value: item,
          isSelected: false
        }
      }).forEach(item => this.courseStatusFilter.push(item));

      this.updateSelectedCourseStatusFilter();
    }
    this.contentList.paginator = this.paginator;
    this.contentList.paginator.firstPage();
  }

  loadContentData() {
    this.contentList = new MatTableDataSource<any>();
    this.contentList = new MatTableDataSource(this.masterContentList);

    if (this.checkedStatus.length > 0 || this.checkedType.length > 0 || this.checkedAcademy.length > 0
      || this.checkedGenre.length > 0 || this.checkedSkill.length > 0 || this.checkedRepoType.length > 0
      || this.checkedCourseName.length > 0 || this.checkedCategory.length > 0) {
      if (this.checkedType.length > 0)
        this.contentList.data = this.contentList.data.filter(x => this.checkedType.includes(x.Type));
      if (this.checkedStatus.length > 0)
        this.contentList.data = this.courseExperise != 'package' ? this.contentList.data.filter(x => this.checkedStatus.includes(x.CourseStatus)) : this.contentList.data.filter(x => this.checkedStatus.includes(x.PackageStatus));
      if (this.checkedAcademy.length > 0)
        this.contentList.data = this.contentList.data.filter(x => this.checkedAcademy.includes(x.Academy));
      if (this.checkedGenre.length > 0)
        this.contentList.data = this.contentList.data.filter(x => this.checkedGenre.includes(x.Genre));
      if (this.checkedSkill.length > 0)
        this.contentList.data = this.contentList.data.filter(x => this.checkedSkill.includes(x.Skill));
      if (this.checkedRepoType.length > 0)
        this.contentList.data = this.contentList.data.filter(x => this.checkedRepoType.includes(x.RepoType));
      if (this.checkedCourseName.length > 0)
        this.contentList.data = this.contentList.data.filter(x => this.checkedCourseName.includes(x.Name));
      if (this.checkedCategory.length > 0)
        this.contentList.data = this.contentList.data.filter(x => this.checkedCategory.includes(x.Category));
    }

  }

  gotoEditMode(row) {

    if (row.Expertise == 101 && this.courseExperise != 'package') {
      //if (row.CourseStatus == 'Saved')
      this.enable101ContentUploadComponent.emit(row.UniqueId);
    }
    else if ((row.Expertise == 101 || row.Expertise == 201 || row.Expertise == 301) && this.courseExperise == 'package') {
      this.enablePackageContentUploadComponent.emit(row);
    }

    else if (row.Expertise == 301) {
      if ('ld' == this.role.toLowerCase()) {
        this.courseSelectedForVerification.emit(row);
      }
      else {
        this._contentVerificationService.get301Course(row.UniqueId).subscribe(res => {
          // console.log(res);
          this.enable301ContentUploadComponent.emit(res);
        });

      }
    }

    else if (row.Expertise == 201) {
      if ('opm' === this.role.toLowerCase()) {
        this.courseSelectedForVerification.emit(row);
      } else {
        this.enableContentUploadComponent.emit(row);
      }
    }
  }

  // gotoEditMode(content) {
  //   if ('opm' === this.role.toLowerCase()) {
  //     this.courseSelectedForVerification.emit(content);
  //   } else {
  //     this.enableContentUploadComponent.emit(content);

  //     // this.route.navigate(['contentupload/',content.UniqueId,content.CourseStatus]);
  //   }
  // }
  createContent() {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'contentUploadHomePopupDialogContainer';
    dialogConfig.data = 'createContent'
    const dialogRef = this._popup.open(
      ContentUploadHomePopupsComponent,
      dialogConfig)
    dialogRef.afterClosed().subscribe((response: any) => {
      if (response == '201')
        this.enableContentUploadComponent.emit(null);
      else if (response == '101')
        this.enable101ContentUploadComponent.emit(null);
      else if (response == 'package')
        this.enablePackageContentUploadComponent.emit(null);
      else if (response == '301')
        this.enable301ContentUploadComponent.emit(null);

    });
    // this.route.navigate(['contentupload']);
    //
  }

  createQuiz() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'contentUploadHomePopupDialogContainer';
    dialogConfig.data = 'createQuiz'
    const dialogRef = this._popup.open(
      ContentUploadHomePopupsComponent,
      dialogConfig)
    dialogRef.afterClosed().subscribe((response: any) => {
      this.courseUniqueId = response;
      if (response != null) {
        response['createQuiz'] = true;
        this.enableQuizUploadComponent.emit(response);
      }
    });
  }

  checkIfAnyFiltersApplied() {
    if (this.checkedCourseName.length > 0 || this.checkedType.length > 0 || this.checkedRepoType.length > 0 || this.checkedAcademy.length > 0 || this.checkedGenre.length > 0 || this.checkedSkill.length > 0 || this.checkedCategory.length > 0 || this.checkedStatus.length > 0)
      this.checkFilter = true;
    else
      this.checkFilter = false;

  }

  experiseSelect() {
    if (this.cuuserroles && (-1 != this.cuuserroles.indexOf('101'))) {
      return "101";
    }
    else if (this.cuuserroles && (-1 != this.cuuserroles.indexOf('201'))) {
      return "201";
    }
    else if (this.cuuserroles && (-1 != this.cuuserroles.indexOf('301'))) {
      return "301";
    }
    else if (this.cuuserroles && (-1 != this.cuuserroles.indexOf('package'))) {
      return "package";
    }
    else {
      return undefined;
    }
  }
  openCalendar(picker: MatDatepicker<Date>) {
    picker.open();
  }
}
