<div *ngIf="
workFlowLoaded && workFlowPhaseDetails != null &&
  workFlowPhaseDetails.PhaseDetails != null &&
  (workFlowPhaseDetails.PhaseDetails.Message !== null ||
    workFlowPhaseDetails.PhaseDetails.Phase !== '')
">
  <div fxLayout="column" class="playlist-accordion" *ngFor="let wf of workFlowSteps; let i = index"
    [ngClass]="{
    accordionExpanded: workflowTabExpanded[i],
    noBackgroundForIntermediate:(wf.step == 'Intermediate'),
    disableMouse: !workflowTabExpanded[i],
    lessPadding:
      wf.isCompleted &&
      (wf.step == 'Project' && projectScoreCard.length != 0) ||(assignmentScoreCard.length!=0 && wf.step === 'Assignment')}">
    <!-- header -->
    <div class="header" fxLayout="row" *ngIf="!workflowTabExpanded[i]">
      <!-- step -->
      <div class="index">{{ wf.id }}</div>
      <!-- name -->
      <div fxFlex="80%" *ngIf="wf.step != 'Course Completion'" class="name">
        {{ wf.step }}
      </div>
      <!-- name -->
      <div fxFlex="80%" *ngIf="wf.step == 'Course Completion'" class="name">
        Completion
      </div>
      <!-- status-->
      <div *ngIf="wf.isCompleted" title="{{
        wf.step
      }} Completed" class="yorbitSprite stepCompleted"></div>
    </div>
    <!-- score card download -->
    <!-- <div
    *ngIf="
        (wf.step == 'Project' &&
          projectScoreCard != null &&
          projectScoreCard != '') ||
      (wf.step == 'Assignment' &&
        assignmentScoreCard != null &&
        assignmentScoreCard != '')
    "
    class="projectAssignment-score-card-download"
    fxFlex
    fxLayoutAlign="end center"
  >
    <div class="label">Download Score Card:</div>
    <div
      class="yorbitSprite icon"
      title="Click here to download score card of the {{
        wf.step.toLowerCase()
      }}."
      (click)="downloadProjectAssignmentScoreCard(wf.step.toLowerCase())"
    ></div>
  </div> -->
    <!-- expanded view -->
    <div fxLayout="column" *ngIf="workflowTabExpanded[i] && workFlowPhaseDetails.PhaseDetails!= null">
      <!-- intermediate phase -->
      <div *ngIf="workFlowPhaseDetails.PhaseDetails.Phase.toLowerCase()=='intermediate'" class="intermediateBox"
        [innerHTML]="workFlowPhaseDetails.PhaseDetails.Message | linky">
      </div>
      <div *ngIf="workFlowPhaseDetails.PhaseDetails.Phase.toLowerCase()!='intermediate'" fxLayout="row">
        <!-- step -->
        <div class="index">{{ wf.id}}</div>
        <!-- name -->
        <div *ngIf="wf.step != 'Course Completion'" class="name">
          {{ wf.step }}
        </div>
        <!-- info for certificate upload -->
        <div *ngIf="wf.step == 'Certificate Upload'" class="tooltipBox">
          <div class="mousePointer yorbitSprite helpIcon"></div>
          <span class="tooltiptext">
            Please do not upload certificate post expiry date. For any more details on certificate upload write to
            LearningPrograms.OPMTeam@mindtree.com.
          </span>
        </div>
        <div *ngIf="wf.step == 'Course Completion'" class="name">
          Completion
        </div>
      </div>
      <!-- download icon -->
      <div *ngIf="
      (wf.step === 'Project' || wf.step === 'Assignment') &&
      projectAssignmentProblemStatement != null &&
      projectAssignmentProblemStatement != ''
    " class="projectAssignment-problem-statement-download" fxLayoutAlign="start center">
        <div class="label"
          *ngIf="wf.step === 'Project'||(wf.step === 'Assignment' && workFlowPhaseDetails.PhaseDetails.SessionIds.length==1)"
          fxFlex fxLayoutAlign.xs="start center" fxLayoutAlign="end center" fxLayoutAlign.gt-md="start center">Download
          Problem Statement:</div>
        <div class="label" *ngIf="wf.step === 'Assignment' && workFlowPhaseDetails.PhaseDetails.SessionIds.length>1"
          fxFlex fxLayoutAlign.xs="start center" fxLayoutAlign="end center" fxLayoutAlign.gt-md="start center">Download
          Problem Statement for Assignment {{currentAssignmentNumber}}:</div>
        <!-- project -->
        <div *ngIf="wf.step === 'Project'" class="yorbitSprite documentDownloadIconRounded" title="Click here to download problem statement of the {{
        wf.step.toLowerCase()
      }}" (click)="downloadProjectAssignmentProblemStatement(wf.step)"></div>
        <!-- assignment -->
        <div *ngIf="wf.step === 'Assignment'" class="yorbitSprite documentDownloadIconRounded" title="Click here to download problem statement of the {{
        wf.step.toLowerCase()
      }} {{currentAssignmentNumber}}" (click)="downloadProjectAssignmentProblemStatement(wf.step)"></div>
      </div>

      <div *ngIf="wf.step == 'Quiz'"><br>
        <div fxLayout="column" fxLayoutGap="3px" class="quizline" *ngIf="quizDetails">
          <div class="quiz-text" fxLayout="row">Result :<span
              class="quiz-value">&nbsp;{{quizDetails.HasPassed?'Cleared':'Not Cleared'}}</span>
            <div class="yorbitSprite exclamationSpriteIcon" style="margin-left: 10px" *ngIf="!quizDetails.HasPassed">
            </div>
          </div>
          <div class="quiz-text">Score :<span class="quiz-value">&nbsp;{{quizDetails.Progress}}%</span></div>
          <div class="quiz-text">Attempt :<span
              class="quiz-value">&nbsp;{{quizAllowedAttempts==0?quizDetails.AttemptsCount:quizDetails.AttemptsCount+'/'+quizAllowedAttempts}}</span>
          </div>
          <div fxLayout="row" fxLayoutGap="5px">
            <button class="retakequiz" (click)="startQuiz()" *ngIf="quizDetails.AttemptsCount==0">TAKE QUIZ</button>
            <button class="retakequiz" (click)="startQuiz()" *ngIf="quizDetails.AttemptsCount!=0">RE-TAKE QUIZ</button>
            <!--<div class="scorecard">Download Score Card : <div class="mousePointer yorbitSprite downloadSpriteIconWhite size"></div></div> -->
          </div>
        </div>
      </div>

      <!-- details -->
      <!-- <div
      class="workflow-contents"
      fxLayout="row"
      *ngIf="wf.step != 'Course Completion'"
    >
      <div fxFlex="45px">Status :</div>
      <div
        fxFlex
        style="color:#fd5f5f;margin-left: 5px;font-family: 'open-sans-semi-bold';"
      >
        {{ workFlowPhaseDetails.SubStatus }}
      </div>
    </div> -->
      <!-- badge for completion -->
      <div class="workflow-contents" fxLayout="row" *ngIf="wf.step == 'Course Completion'">
        <div *ngIf="badgeDetails!=undefined" class="badgeBox" fxLayout="column">
          <img fxFlex="100px" fxLayoutAlign="center center" src="{{ badgeDetails.BadgeImage }}"
            title="{{ badgeDetails.BadgeName }}" />
          <!-- <div class="badge-name" [ngStyle.xs]="{ 'left.px': 105 }" title="{{ badgeDetails.BadgeName }}">
            {{ badgeDetails.BadgeName }}
          </div> -->
          <div>CONGRATULATIONS!</div>
          <div>You have completed the course successfully</div>
        </div>
      </div>
      <!-- message -->
      <div class="message" [ngClass]="{'noLeftPadding': workFlowPhaseDetails.SubStatus!=undefined && (workFlowPhaseDetails.SubStatus.toLowerCase() == 'learner to resubmit the course completion certificate on shoshin school' ||
      workFlowPhaseDetails.SubStatus.toLowerCase() == 'learner to resubmit the trailhead badge on shoshin school')}"
        *ngIf=" wf.step != 'Course Initiation' &&
        (workFlowPhaseDetails.SubStatus!=undefined &&
        (wf.step == 'Certificate Upload' &&
          (workFlowPhaseDetails.SubStatus.toLowerCase() == 'verification pending with pss' ||
          workFlowPhaseDetails.SubStatus.toLowerCase() == 'learner to resubmit the course completion certificate on shoshin school' ||
          workFlowPhaseDetails.SubStatus.toLowerCase() == 'learner to resubmit the trailhead badge on shoshin school')) ||
        (wf.step == 'Project' &&
          workFlowPhaseDetails.SubStatus.toLowerCase() == 'result awaited for project' && workFlowPhaseDetails.PhaseDetails.AttemptCount < 4) ||
        (wf.step == 'Assignment' &&
          workFlowPhaseDetails.SubStatus.toLowerCase() ==
            'result awaited for assignment' && workFlowPhaseDetails.PhaseDetails.AttemptCount < 4) ||
        wf.step == 'Assessment'||
        (workFlowPhaseDetails.SubStatus.toLowerCase() == 'learningopm team to verify and publish result' && workFlowPhaseDetails.PhaseDetails.AttemptCount < 4)
        )
      " [innerHTML]="workFlowPhaseDetails.PhaseDetails.Message | linky"></div>

      <!-- Restart Course option for Not Cleared courses -->
      <div class="message" [ngClass]="{'noLeftPadding': workFlowPhaseDetails.SubStatus!=undefined && (workFlowPhaseDetails.SubStatus.toLowerCase() == 'fail' ||
          workFlowPhaseDetails.SubStatus.toLowerCase() == 'violation' ||
          workFlowPhaseDetails.SubStatus.toLowerCase() == 'assessment termination' ||
          workFlowPhaseDetails.SubStatus.toLowerCase() == 'SME Evaluation not cleared')}"
        *ngIf="
        wf.step != 'Course Initiation' &&
        (workFlowPhaseDetails.SubStatus!=undefined &&
        (wf.step == 'Certificate Upload' &&
          (workFlowPhaseDetails.SubStatus.toLowerCase() == 'fail' ||
          workFlowPhaseDetails.SubStatus.toLowerCase() == 'violation' ||
          workFlowPhaseDetails.SubStatus.toLowerCase() == 'assessment termination')) ||
          (workFlowPhaseDetails.PhaseDetails.Phase.toLowerCase() == 'sme evaluation' && workFlowPhaseDetails.SubStatus.toLowerCase() == 'sme evaluation not cleared'))">
        <div class="start-course-status"> {{workFlowPhaseDetails.PhaseDetails.Message}}</div>
        <button class="start-course-btn" *ngIf="!cancelCourseInProgress" (click)="cancelNotClearedCourse()">Restart
          Course</button>
        <div *ngIf="cancelCourseInProgress">{{msgDuringCancel}}</div>
      </div>

      <!-- message for SME Evaluation Phase-->
      <div class="message" [ngClass]="{'noLeftPadding': true}"
        *ngIf="
      this.workFlowPhaseDetails.PhaseDetails.Phase == 'SME Evaluation' && workFlowPhaseDetails.SubStatus.toLowerCase() != 'sme evaluation not cleared'"
        [innerHTML]="workFlowPhaseDetails.PhaseDetails.Message | linky"></div>
      <!-- message for course initiation -->
      <div class="message" *ngIf="wf.step == 'Course Initiation'">
        {{workFlowPhaseDetails.PhaseDetails.Message}}
      </div>
      <div class="message" *ngIf="wf.step == 'ClassRoom'">
        <div *ngIf="(classroomSessionDetails && classroomSessionDetails.length > 0); else noClassInstance">
          ClassRoom is scheduled, find the below details:<br />
          <div *ngFor="let session of classroomSessionDetails">
            <span class="classroom-detail">Session no</span>: {{ session.SessionId.split('_')[2] }} <br />
            <span class="classroom-detail">Session Name</span>: {{ session.SessionName }} <br />
            <span class="classroom-detail">Start Date</span>: {{ session.StartDate | date: "MM/dd/yyyy" }}<br />
            <span class="classroom-detail">End Date</span>: {{ session.EndDate | date: "MM/dd/yyyy" }}<br />
            <span class="classroom-detail">StartTime</span>: {{ session.StartTime }}<br />
            <span class="classroom-detail">EndTime</span>: {{ session.EndTime }}<br />
            <span class="classroom-detail">TrainerName</span>: {{ session.TrainerName }}<br />
            <span class="classroom-detail">Venue</span>: {{ session.Venue }}<br />
            <div class="session-topics">
              <span class="classroom-detail">Topics covered</span>:
              <div class="tooltipBox topics">
                <div class="mousePointer yorbitSprite helpIcon topics-icon"></div>
                <span class="tooltiptext">
                  {{ session.TopicsCovered }}
                </span>
              </div>
            </div><br />
          </div>
        </div>
        <ng-template #noClassInstance>
          {{ workFlowPhaseDetails.PhaseDetails.Message }}
        </ng-template>
      </div>
      <!-- message for attempt>3 -->
      <div class="message"
        *ngIf="(wf.step == 'Project' || wf.step == 'Assignment') && workFlowPhaseDetails.PhaseDetails.AttemptCount>3">
        Please contact Learning OPM team for further details
      </div>
      <!-- certificate upload option -->
      <div class="certificateUploadBox" *ngIf="
        wf.step == 'Certificate Upload' &&
        (workFlowPhaseDetails.SubStatus!=undefined &&
        workFlowPhaseDetails.SubStatus.toLowerCase() != 'verification pending with pss' &&
        workFlowPhaseDetails.SubStatus.toLowerCase() != 'fail' &&
        workFlowPhaseDetails.SubStatus.toLowerCase() != 'violation' &&
        workFlowPhaseDetails.SubStatus.toLowerCase() != 'assessment termination' )
      ">
        <div fxLayout="row wrap" fxLayout.xs="column">
          <!-- certification date -->
          <div fxFlex="47.5%" fxFlex.xs="100%">
            <label for="CertDate">Certification Date <b style="color:red">*</b></label>
            <div class="inputHolder" [ngStyle.xs]="{ width: 100 }">
              <input #certificateDate matInput [matDatepicker]="iCertDate" placeholder="Choose a date" [ngClass]="{
                disableMouse: certUploadBtnTitle != 'UPLOAD'
              }" disabled (dateInput)="certificateDateSelected($event)" (dateChange)="certificateDateSelected($event)"
                class="certDate" [max]="minCertDate" [value]="selectedCertificationDate"
                [disabled]="certUploadBtnTitle != 'UPLOAD'" title="Choose a date" />
              <div class="datepicker-holder">
                <mat-datepicker-toggle matSuffix [ngClass]="{
                disableMouse: certUploadBtnTitle != 'UPLOAD'
              }" [for]="iCertDate"></mat-datepicker-toggle>
                <mat-datepicker [disabled]="false" #iCertDate></mat-datepicker>
              </div>
            </div>
          </div>
          <!-- expiry date -->
          <div fxFlex="47.5%" fxFlex.xs="100%" [ngStyle]="{ 'margin' : '0 0 0 5%' }" [ngStyle.xs]="{ 'margin' : '0'  }">
            <label for="CertDate">Expiry Date <b style="color:red">*</b></label>
            <div class="inputHolder" [ngStyle.xs]="{ 'width': '100' }">
              <input #expiryDate matInput [matDatepicker]="iExpDate" placeholder="Choose a date" [ngClass]="{
                disableMouse:(disableExpiryDate ||  certUploadBtnTitle != 'UPLOAD')
              }" disabled [value]="selectedExpiryDate" (dateInput)="expiryDateSelected($event)"
                (dateChange)="expiryDateSelected($event)" class="certDate" [min]="minExpDate"
                [disabled]="certUploadBtnTitle != 'UPLOAD'||disableExpiryDate" title="Choose a date" />
              <div class="datepicker-holder">
                <mat-datepicker-toggle matSuffix [ngClass]="{
                disableMouse:(disableExpiryDate ||  certUploadBtnTitle != 'UPLOAD')
              }" [for]="iExpDate"></mat-datepicker-toggle>
                <mat-datepicker [disabled]="disableExpiryDate || certUploadBtnTitle != 'UPLOAD'" #iExpDate>
                </mat-datepicker>
              </div>
            </div>
          </div>
        </div>
        <!-- no expiry checkbox -->
        <div class="noExpiryBox" fxLayout="row" fxLayoutAlign="end">
          <!-- <input fxFlexOffset="2px" id="iCertUploadNoExpiry"
          #iCertUploadNoExpiry
          type="checkbox"
          [checked]="noExpDateCheckBox"
           (change)="disableExpDateOrCheckBoxFunction(iCertUploadNoExpiry.checked)"
            class=" mousePointer BoxChecked{{noExpDateCheckBox}}"
            [ngClass]="{disableMouse: certUploadBtnTitle != 'UPLOAD','boxChecked':noExpDateCheckBox}"
            title="Check If Certificate Has No Expiry" [disabled]="certUploadBtnTitle != 'UPLOAD'" />
            {{noExpDateCheckBox}}
          <label for="iCertUploadNoExpiry"
          class="mousePointer label"
          [ngClass]="{disableMouse: certUploadBtnTitle != 'UPLOAD','boxChecked':noExpDateCheckBox}">
          No Expiry<b style="color:red">*</b></label> -->
          <label (click)="disableExpDateOrCheckBoxFunction(noExpDateCheckBox)" class="mousePointer label"
            [ngClass]="{disableMouse: certUploadBtnTitle != 'UPLOAD','boxChecked':noExpDateCheckBox}">
            No Expiry<b style="color:red">*</b></label>
        </div>
        <!-- browse -->
        <!-- <div fxLayout="row" class="fileUpload" [ngClass]="{
          disableMouse: certUploadBtnTitle != 'UPLOAD'
        }">
          <input type="file" id="iCertificate-upload" YorbitWorkspaceFileModel [inputFor]="'iCertitifcateUploadFile'" (fileModel)="fileModel('certificate', $event)"
            [disabled]="certUploadBtnTitle != 'UPLOAD'" [ngClass]="{
              disableMouse: certUploadBtnTitle != 'UPLOAD'
            }" />
          <label id="fileUploadLabel" fxFlex for="iCertificate-upload" [ngClass]="{
            disableMouse: certUploadBtnTitle != 'UPLOAD'
          }"><span [ngClass]="{
            disableMouse: certUploadBtnTitle != 'UPLOAD'
          }">Browse</span>
          <div title="" class="input-label">{{certifcateUploadFilename?certifcateUploadFilename:'Select a file'}}</div>
        </label>
      <div *ngIf="''!=certifcateUploadFilename">certifcateUploadFilename-{{this.certifcateUploadFilename}}</div>
        </div> -->

        <form #certificateForm>
          <div fxFlex class="FileUploadContainer">
            <input type="file" id="certificateUpload" #certificateUpload value='' YorbitWorkspaceFileModel
              [inputFor]="'certificateUploadFile'" class="inputOverlay" (fileModel)="fileModel('certificate',$event)" />
            <!-- <label class="browselabel" for="certificateUpload" (click)="setDefaultsForCertificate()"> -->
            <label class="browselabel" (click)="setDefaultsForCertificate();certificateUpload.click()">
              <span class="browseButton">Browse</span>
              <div class="input-label fakeLabelOverlay" title="{{certifcateUploadFilename}}">
                {{certifcateUploadFilename}}
              </div>
            </label>
            <!-- <div class="selectFileTagHolder" *ngIf="showSelectFile">
            <div class="selectFileTag">
              Select a file</div>
          </div> -->
          </div>
        </form>


        <!-- message -->
        <div fxLayout="row" class="certMessage">
          Note: Please choose only .pdf,.zip,.7z,.jpg or .jpeg files of size less than 10MB.
        </div>
        <!-- button -->
        <div fxLayout="row" fxLayoutAlign="end">
          <button style="margin-right: 15px;height: 38px" [disabled]="disableUploadButton"
            class="mousePointer uploadBtn" [ngClass]="{
            disableMouse: disableUploadButton
          }" (click)="uploadCertificate()">
            {{ certUploadBtnTitle }}
          </button>
        </div>
      </div>
      <!-- upload menu for project/assignment -->
      <div fxLayout="column" class="projectUploadBox" fxLayoutGap="5px" *ngIf="
        (wf.step === 'Project' || wf.step === 'Assignment') &&
        workFlowPhaseDetails.SubStatus!=undefined &&
        !(
          workFlowPhaseDetails.SubStatus.toLowerCase() == 'result awaited for project' ||
          workFlowPhaseDetails.SubStatus.toLowerCase() == 'result awaited for assignment'||
          workFlowPhaseDetails.SubStatus.toLowerCase() == 'learningopm team to verify and publish result' ||
          workFlowPhaseDetails.SubStatus.toLowerCase() == 'cleared the assessment and yet to clear the project' ||
          workFlowPhaseDetails.SubStatus.toLowerCase() == 'cleared the assessment and yet to clear the assignment'||
          workFlowPhaseDetails.SubStatus.toLowerCase() == 'cleared the assessment and project results awaited' ||
          workFlowPhaseDetails.PhaseDetails.AttemptCount > 3
        )
      ">
        <div
          *ngIf="workFlowPhaseDetails.PhaseDetails.Result!=null && (workFlowPhaseDetails.PhaseDetails.AttemptCount!=null || workFlowPhaseDetails.PhaseDetails.Result=='Error')">
          <div>
            Result : <span style="color: #fd5f5f">
              {{workFlowPhaseDetails.PhaseDetails.Result}}</span>
            <div class="yorbitSprite nonCompliantIcon exclamatoryIconPosition"
              title="{{null!=workFlowPhaseDetails.PhaseDetails.Result&&workFlowPhaseDetails.PhaseDetails.Result.toLowerCase()=='error'?'Please re-submit your '+wf.step+' after correcting error':'Please re-submit your '+wf.step}}">
            </div>
          </div>
          <div>
            Current Attempt : <span style="color: #fd5f5f">
              {{ workFlowPhaseDetails.PhaseDetails.AttemptCount==null
              ?'1':workFlowPhaseDetails.PhaseDetails.AttemptCount}}</span>
          </div>
        </div>


        <!-- <div fxLayout="row" fxFlex>
          <input id="projectUpload" class="mousePointer" type="file" YorbitWorkspaceFileModel [ngClass]="{
            disableMouse: uploadBtnTitle != 'UPLOAD'
          }" [inputFor]="'projectUploadFile'" (fileModel)="fileModel('project', $event)" [disabled]="uploadBtnTitle != 'UPLOAD'"
          />
          <lab el id="projectUploadLabel" for="projectUpload" class="mousePointer" fxFlex [ngClass]="{
            disableMouse: uploadBtnTitle != 'UPLOAD'
          }">
            <div class="input-label" title="" fxFlex [ngClass]="{
              disableMouse: uploadBtnTitle != 'UPLOAD'
            }">Select a file</div>
            <div class="mousePointer yorbitSprite uploadIcon" [ngClass]="{
              disableMouse: uploadBtnTitle != 'UPLOAD'
            }"></div>
          </label>
        </div> -->

        <form #projectForm>
          <div fxLayout="row" fxFlex="100">
            <input id="projectUpload" #projectUpload class="mousePointer" type="file" YorbitWorkspaceFileModel
              [ngClass]="{
            disableMouse: uploadBtnTitle != 'UPLOAD'
          }" [inputFor]="'projectUploadFile'" (fileModel)="fileModel('project', $event)"
              [disabled]="uploadBtnTitle != 'UPLOAD'" />
            <label id="projectUploadLabel"
              (click)="(uploadBtnTitle != 'UPLOAD')?'':setDefaultsForProject();projectUpload.click()"
              class="mousePointer" fxFlex [ngClass]="{
            disableMouse: uploadBtnTitle != 'UPLOAD'
          }">
              <div class="input-label" title="" fxFlex [ngClass]="{
              disableMouse: uploadBtnTitle != 'UPLOAD'
            }">{{certifcateUploadFilename}}</div>
              <div class="mousePointer yorbitSprite uploadIcon" [ngClass]="{
              disableMouse: uploadBtnTitle != 'UPLOAD'
            }"></div>
            </label>
          </div>
        </form>


        <button [disabled]="
          uploadBtnTitle === 'UPLOADING...' ||
          uploadBtnTitle === 'UPLOADED' ||
          uploadBtnTitle === 'FAILED' ||
          (workFlowPhaseDetails.SubStatus!=undefined &&
          (workFlowPhaseDetails.SubStatus.toLowerCase() === 'result awaited for project' ||
          workFlowPhaseDetails.SubStatus.toLowerCase() === 'result awaited for assignment'
          )) ||
          (projectOrAssignmentFile==undefined || projectOrAssignmentFile == '')
        " class="mousePointer uploadBtn" [ngClass]="{
          disableMouse:
            uploadBtnTitle === 'UPLOADING...' ||
            uploadBtnTitle === 'UPLOADED' ||
            uploadBtnTitle === 'FAILED' ||
            (workFlowPhaseDetails.SubStatus!= undefined &&
            (workFlowPhaseDetails.SubStatus.toLowerCase() ===
              'result awaited for project' ||
            workFlowPhaseDetails.SubStatus.toLowerCase() ===
              'result awaited for assignment'
            ))||
            (projectOrAssignmentFile==undefined || projectOrAssignmentFile == '')
        }" (click)="uploadProjectOrAssignment()">
          {{ uploadBtnTitle }}
        </button>
        <div
          *ngIf="null!=workFlowPhaseDetails.PhaseDetails.Result&&workFlowPhaseDetails.PhaseDetails.Result.toLowerCase()=='error'">
          <div class="label" fxFlex fxLayoutAlign.xs="start center" fxLayoutAlign="end center"
            fxLayoutAlign.gt-md="start center">Download
            Error Report:</div>
          <div *ngIf="wf.step === 'Project'" class="yorbitSprite documentDownloadIconRounded" title="Click here to download error file of the {{
              wf.step.toLowerCase()
            }}" (click)="downloadErrorFile(wf.step)"></div>
        </div>
        <span class="noteForUpload" *ngIf="uploadBtnError!=''">*{{ uploadBtnError }}</span>
        <span class="noteForUpload">*You have only 3 attempts to clear the Project/Assignment.</span>
      </div>
    </div>
    <!-- score card download -->
    <div *ngIf="
       (projectScoreCard.length!=0 && wf.step=='Project') ||
     (assignmentScoreCard.length!=0 && wf.step=='Assignment')
   " class="projectAssignment-score-card-download" fxFlex>
      <!-- project score card -->
      <div *ngIf="projectScoreCard.length!=0  && wf.step=='Project'" style="position: relative;display:flex"
        fxLayoutGap="5px">
        <div class="label" *ngIf="projectScoreCard.length>=1" fxFlex fxLayoutAlign.xs="start center"
          fxLayoutAlign.gt-md="start center" fxLayoutAlign="end center">Download Score Card: </div>
        <div class="yorbitSprite"
          [ngClass]="{'documentDownloadIconRounded':workFlowPhaseDetails.PhaseDetails.Phase=='Project','documentDownloadIconRoundedDark':workFlowPhaseDetails.PhaseDetails.Phase!='Project'}"
          *ngIf="projectScoreCard.length>1" [matMenuTriggerFor]="project" title="Click here to download score card of the {{
        wf.step.toLowerCase()
      }}"></div>
        <div class="yorbitSprite"
          [ngClass]="{'documentDownloadIconRounded':workFlowPhaseDetails.PhaseDetails.Phase=='Project','documentDownloadIconRoundedDark':workFlowPhaseDetails.PhaseDetails.Phase!='Project'}"
          *ngIf="projectScoreCard.length==1"
          (click)="downloadProjectAssignmentScoreCard(wf.step.toLowerCase(),projectScoreCard[0].scoreCard,null)" title="Click here to download score card of the {{
        wf.step.toLowerCase()
      }}"></div>
        <mat-menu #project="matMenu">
          <div *ngFor="let attemptObj of projectScoreCard">
            <button mat-menu-item
              (click)="downloadProjectAssignmentScoreCard(wf.step.toLowerCase(),attemptObj.scoreCard,null)"
              fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
              <div>Attempt {{attemptObj.attempt}}</div>
              <div class="yorbitSprite documentDownloadIcon"></div>
            </button>
          </div>
        </mat-menu>
      </div>

      <!-- assignment score card -->
      <div *ngIf="assignmentScoreCard.length!=0 && wf.step=='Assignment'" style="position: relative;" fxLayout="column"
        fxLayoutAlign="start stretch" fxLayoutGap="5px">
        <div *ngFor="let assignmentId of workFlowPhaseDetails.PhaseDetails.SessionIds;let index = index"
          class="assignment-scorecard-download-option" fxLayoutAlign="start center">
          <div class="label" fxFlex fxLayoutAlign.xs="start center" fxLayoutAlign.gt-md="start center"
            fxLayoutAlign="end center" *ngIf="checkAttemptsCount(assignmentId)>=1">Download Score Card of Assignment
            0{{index+1}} :</div>
          <div class="yorbitSprite"
            [ngClass]="{'documentDownloadIconRounded':workFlowPhaseDetails.PhaseDetails.Phase=='Assignment','documentDownloadIconRoundedDark':workFlowPhaseDetails.PhaseDetails.Phase!='Assignment'}"
            *ngIf="checkAttemptsCount(assignmentId)==1"
            (click)="downloadProjectAssignmentScoreCard(wf.step.toLowerCase(),null,assignmentId)" title="Click here to download score card of the {{
            wf.step.toLowerCase()
           }} {{index+1}}"></div>
          <div class="yorbitSprite"
            [ngClass]="{'documentDownloadIconRounded':workFlowPhaseDetails.PhaseDetails.Phase=='Assignment','documentDownloadIconRoundedDark':workFlowPhaseDetails.PhaseDetails.Phase!='Assignment'}"
            *ngIf="checkAttemptsCount(assignmentId)>1" [matMenuTriggerFor]="assignment" title="Click here to download score card of the {{
            wf.step.toLowerCase()
           }} {{index+1}}"></div>
          <mat-menu #assignment="matMenu">
            <div *ngFor="let attemptObj of assignmentScoreCard">
              <button mat-menu-item *ngIf="assignmentId.split('_')[2] == attemptObj.assignmentNumber"
                (click)="downloadProjectAssignmentScoreCard(wf.step.toLowerCase(),attemptObj.scoreCard,null)"
                fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                <div>Attempt {{attemptObj.attempt}}</div>
                <div class="yorbitSprite documentDownloadIcon"></div>
              </button>
            </div>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</div>