import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContentUploadPopupService {

  constructor() { }

  getLabel(element, tabId) {
    let generatedLabel = "";
    switch (element) {
      case "academy":
        generatedLabel = "Academy";
        break;
      case "genre":
        generatedLabel = "Genre";
        break;
      case "skill":
        generatedLabel = "Skill";
        break;
      case "name":
        generatedLabel = "Course Name";
        break;
      case "topicCovered":
        generatedLabel = "Topics Covered";
        break;
      case "description":
        generatedLabel = "Description";
        break;
      case "type":
        generatedLabel = "Course Type";
        break;
      case "internal":
        generatedLabel = "Is Course Internal?";
        break;
      case "vendor":
        generatedLabel = "Name of Vendor";
        break;
      case "paid":
        generatedLabel = "Is Course Paid?";
        break;
      case "price":
        generatedLabel = "Price for the Course";
        break;
      case "curreny":
        generatedLabel = "Currency";
        break;
      case "paymentMode":
        generatedLabel = "Payment Mode";
        break;
      case "duration":
        generatedLabel = "Course Duration";
        break;
      case "managerApproval":
        generatedLabel = "Is Learning Business Partner Approval Required?";
        break;
      case "preRequisiteCourseIDs":
        generatedLabel = "Pre-Requisite Course IDs";
        break;
      case "postRecommendedCourseIDs":
        generatedLabel = "Post Recommended Course IDs";
        break;
      case "isPreRequisiteMandatory":
        generatedLabel = "Is PreRequisite Mandatory?";
        break;
      case "eligibility":
        generatedLabel = "Who can take this Course?";
        break;
      case "accessibility":
        generatedLabel = "Course Accessibility";
        break;
      case "credits":
        generatedLabel = "Credits";
        break;
      case "classification":
        generatedLabel = "Course Classification";
        break;
      case "proficiency":
        generatedLabel = "Course Proficiency";
        break;
      case "badge":
        generatedLabel = "Badge Name";
        break;
      case "expertMids":
        generatedLabel = "Expert MIDs";
        break;
      case "contentOwner":
        generatedLabel = "Content Partner";
        break;
      case "isIntroductoryVideo":
        generatedLabel = "Introductory Video";
        break;
      case "sourceType":
        generatedLabel = "Video Type";
        break;
      case "introductoryVideo":
        generatedLabel = "Video Link";
        break;
      case "creditSpecial":
        generatedLabel = "Is Course Launched only for Providing Badge and Credits?";
        break;
      case "isInfrastructure":
        generatedLabel = "Infrastructure";
        break;
      case "loginType":
        generatedLabel = "Course Login Type";
        break;
      case "isLearningMaterial":
        generatedLabel = "Learning Material";
        break;
      case "courseCompletionGroup":
        generatedLabel = "Proof of Course Completion to be Submitted by Learner";
        break;
      case "classRoomGroup":
        generatedLabel = "Class Room";
        break;
      case "projectGroup":
        generatedLabel = "Project Work";
        break;
      case "assessmentGroup":
        generatedLabel = "Assessment";
        break;
      case "quizGroup":
        generatedLabel="Quiz Details"
        break;
      case "isCourseProcedureReferenceDocument":
          generatedLabel = "Course Procedure Reference Document";
          break;
      case "IsCloudLabAvailable":
          generatedLabel = "Is Cloud Lab Available?"
          break;
      case "IsCertificateAvailable":
          generatedLabel = "Course Completion Certificate to be generated by Shoshin School?"
          break;
      case "SkillIds":
          generatedLabel = "Skill Prism ID";
          break;
      case "infrastructure":
      case "infrastructureType":
      case "isLoginRequired":
      case "coursesNameForSelfRegistration":
      case "linktoCoursesForSelfRegistration":
      case "loginDetails":
      case "coursesNameForLearningOPMTeam":
      case "linktoCoursesForLearningOPMTeam":
      case "preSessionGroup":
      case "duringSessionGroup":
      case "postSessionGroup":
      case "courseProcedureRefDocGroup":
      case "":
        generatedLabel = "";
        break;
      default:
        generatedLabel = element;
    }
    return generatedLabel;
  }

  getTabId(element) {
    let generatedTabId;
    switch (element) {
      case "academy":
      case "genre":
      case "skill":
      case "name":
      case "topicCovered":
      case "description":
      case "type":
      case "internal":
      case "vendor":
      case "paid":
      case "price":
      case "curreny":
      case "paymentMode":
      case "duration":
      case "managerApproval":
      case "preRequisiteCourseIDs":
      case "postRecommendedCourseIDs":
      case "isPreRequisiteMandatory":
      case "eligibility":
      case "accessibility":
      case "credits":
      case "classification":
      case "proficiency":
      case "badge":
      case "expertMids":
      case "ContentOwner":
      case "isIntroductoryVideo":
      case "sourceType":
      case "introductoryVideo":
      case "creditSpecial":
      case "IsCloudLabAvailable":
      case "IsCertificateAvailable":
      case "SkillIds":
        generatedTabId = 0;
        break;
      default:
        generatedTabId = 1;
        break;
    }
    return generatedTabId;
  }

}
