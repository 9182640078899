

<div *ngIf="!pageLoading && pageLoadedSuccessfully && courseAvailable" fxLayout="column" class="course-view-page" fxFlexFill
  [ngStyle.lt-sm]="{'padding.px':10}">
  <div fxLayout="row" fxLayout.xs="row wrap" class="course-view-header" fxFlex="30px" fxFlex.xs="75px" fxLayoutAlign="space-between center">
    <div fxFlex.gt-sm="70%" fxLayout="row" fxLayoutAlign="space-between center" [ngStyle]="{'width':'100%'}">
      <mat-icon class="back-arrow-xs" [ngStyle.gt-xs]="{'display':'none'}" fxShow.gt-xs="false" fxShow.xs="true" (click)="goBackToLP()">keyboard_arrow_left</mat-icon>
      <div fxFlex fxFlex.gt-sm="75%" class="course-name" [ngClass.xs]="{'course-name-xs':true}">{{LPBreadcrumbs.CourseDetails.CourseName}}</div>
      <!--<div fxFlex="120px" class="icons-bar" fxFlexOrder.lt-lg="3" fxLayoutAlign="center center" fxLayoutGap="5px">
      <mat-icon>add_circle_outline</mat-icon>
      <mat-icon>share</mat-icon>
      <mat-icon>account_circle</mat-icon>
    </div>-->
      <div fxLayout="row" fxFlex.gt-sm="25%" fxHide.xs="true" *ngIf="badge.IsRetakeMandatory || isMandateCourse(LPBreadcrumbs.CourseDetails.CourseId)">
        <div fxLayout="column">
          <div class="courseCompletionHighlightDate" fxLayout="row" fxlayoutAlign="top center">
            <div>{{"Last Completion Date : "}}&nbsp;</div>
            <div *ngIf="!isDateValid(badge.CompletedDate)">{{'NA'}}</div>
            <div *ngIf="isDateValid(badge.CompletedDate)" [innerHTML]="getTime(badge.CompletedDate,'MMM d, y')">
              <!-- {{badge.CompletedDate | date :'dd/MM/yyyy'}} -->
            </div>
          </div>
          <div class="courseCompletionHighlightDate" fxLayout="row" fxlayoutAlign="top center">
            <div>Last Completion Time :&nbsp;</div>
            <div *ngIf="!isDateValid(badge.CompletedDate)">{{'NA'}}</div>
            <div *ngIf="isDateValid(badge.CompletedDate)" [innerHTML]="getTime(badge.CompletedDate,'h:mm a')">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex.gt-sm="30%" fxLayout="row" fxLayoutAlign="end center" [ngStyle.lt-sm]="{'width':'100%'}"
    [ngClass]="{'space-btwn': LPBreadcrumbs.CourseDetails.Expertise == '101'}">
      <div fxLayout="column" fxLayoutAlign="start center" fxLayoutAlign.xs="center center" class="course-badge" *ngIf="badge!=null"
         [ngClass]="{'badge-earned':((quizDetails.HasPassed && !badge.IsRetakeMandatory)|| badge.Status=='true'|| badge.Status==true)}">
        <img fxFlex="60px" fxFlex.xs="50px" src="{{badge.BadgeImage.trim()}}" title="{{badge.BadgeName}}">
        <!--<div class="badge-name" title="{{badge.BadgeName}}">{{badge.BadgeName}}</div>-->
        <div *ngIf="badge.IsRetakeMandatory && (LPBreadcrumbs.LPDetails.LPName == globals.CocLpName.toUpperCase() || LPBreadcrumbs.LPDetails.LPName == globals.BEACLpName.toUpperCase()
        ||LPBreadcrumbs.LPDetails.LPName == 'ASSIGNED')"
         class="retake-alert{{LPBreadcrumbs.CourseDetails.Expertise}} yorbitSprite nonCompliantIcon"
          title="{{(badge.Status=='true'|| badge.Status==true) ?'Kindly retake quiz and score 100% to be ' + (globals.LpName.trim() == 'coc' ? 'Mandatory Training' : 'Business Ethics') + ' Compliant' :'Kindly retake quiz and score 100% to gain the badge and be ' + (globals.LpName.trim() == 'coc' ? 'Mandatory Training' : 'Business Ethics') + ' Compliant'}}">
        </div>
        <div *ngIf="(LPBreadcrumbs.LPDetails.LPName == 'SEXUAL HARASSMENT PREVENTION TRAINING - USA' || LPBreadcrumbs.LPDetails.LPName.toUpperCase() == globals.newPOSHUSLpName) && (globals.mandatoryReminderCountForPoshUSA >= 2 && globals.counterPOSHUSA >= 2)"
          class="retake-alert{{LPBreadcrumbs.CourseDetails.Expertise}} yorbitSprite nonCompliantIcon" title="{{((badge.Status=='true'|| badge.Status==true) && !globals.isNewJoineeForPoshUSA) ? 'Kindly retake quiz and score 100% to be Sexual Harassment Prevention Training - USA Compliant' :'Kindly take quiz and score 100% to gain the badge and be Sexual Harassment Prevention Training - USA Compliant.'}}">
        </div>
      </div>
      <div *ngIf="LPBreadcrumbs.CourseDetails.Expertise=='101'" fxLayout="row" fxLayout.sm="column" fxLayoutAlign="center center"
        fxLayoutAlign.sm="center stretch" fxLayoutAlign.xs="start center" fxFlex.sm="120px" fxFlex.xs="100%" [ngStyle.xs]="{'margin-top.px':10}">
        <div fxFlex="120px" fxFlex.sm="25px" fxFlex.xs class="score" fxLayoutAlign="center center" fxLayoutAlign.sm="end center"
          fxLayoutAlign.xs="start center">
          <div>score</div>
          <div fxFlexOffset="2px">:</div>
          <div fxFlexOffset="2px">{{quizDetails.Progress == undefined ? 'NA' : quizDetails.Progress}}</div>
          <div fxFlexOffset="2px" *ngIf="quizDetails.Progress != undefined && quizDetails.Progress != null">%</div>
          <!-- <span *ngIf="badge.Score=='NA'">NA</span> -->
        </div>
        <div fxFlex.xs="100px" fxFlex="120px" fxFlex.sm="25px" fxFlex.xs fxFlexOffset.gt-sm="5px" class="quiz-button" fxLayoutAlign="center center"
          fxLayoutAlign.lt-md="end center" *ngIf="LPBreadcrumbs.CourseDetails.Expertise!='201' && LPBreadcrumbs.CourseDetails.Expertise!='301'">
          <button class="takeQuizButton" *ngIf="!(quizDetails.QuizId == null  && !badge.isMigrated) && (quizDetails.TakeQuiz && !quizDetails.HasPassed)"
            fxFlex.xs="100px" fxFlex="120px" (click)="startQuiz()" [disabled]="disableMandatoryRetake(LPBreadcrumbs.CourseDetails.CourseId) || disableQuizForMultiContentSequence"
            [ngClass]="{'mouseDisabled':disableMandatoryRetake(LPBreadcrumbs.CourseDetails.CourseId) || disableQuizForMultiContentSequence}" title="{{(disableMandatoryRetake(LPBreadcrumbs.CourseDetails.CourseId))?getMandatoryQuizTooltip():''}}">Take
          Quiz</button>
          <button *ngIf="!(quizDetails.QuizId == null  && !badge.isMigrated) &&(quizDetails.RetakeQuiz || quizDetails.ShowProgress)"
            fxFlex.xs="100px" fxFlex="120px" class="takeQuizButton" (click)="startQuiz()" [disabled]="disableMandatoryRetake(LPBreadcrumbs.CourseDetails.CourseId)"
            [ngClass]="{'mouseDisabled':disableMandatoryRetake(LPBreadcrumbs.CourseDetails.CourseId)}" title="{{(disableMandatoryRetake(LPBreadcrumbs.CourseDetails.CourseId))?getMandatoryQuizTooltip():''}}">Retake
          Quiz</button>
          <!--add check quiz button-->
          <button class="takeQuizButton" *ngIf="quizDetails.QuizId == null  && !badge.isMigrated" fxFlex="120px" class="checkQuizAvailabilityBtn"
            fxFlex.xs="100px" (click)="checkQuizAvailability()">
          <div *ngIf="!quizNotAvailable && !quizCheckInProgress">Check Quiz</div>
          <div *ngIf="!quizNotAvailable && quizCheckInProgress">Checking...</div>
          <div class="quizNotAvailable" *ngIf="quizNotAvailable">Not Available</div>
        </button>
        </div>

      </div>
    </div>
  </div>
  <div fxLayout="row" style="margin-top:15px" fxHide fxShow.xs="true" *ngIf="badge.IsRetakeMandatory || isMandateCourse(LPBreadcrumbs.CourseDetails.CourseId)">
    <div fxLayout="column">
      <div class="courseCompletionHighlightDate" fxLayout="row" fxlayoutAlign="top center">
        <div>{{"Course Completion Date &nbsp;: "}}</div>
        <div *ngIf="!isDateValid(badge.CompletedDate)">{{'NA'}}</div>
        <div *ngIf="isDateValid(badge.CompletedDate)" [innerHTML]="getTime(badge.CompletedDate,'MMM d, y')">
          <!-- {{badge.CompletedDate | date :'dd/MM/yyyy'}} -->
        </div>
      </div>
      <div class="courseCompletionHighlightDate" fxLayout="row" fxlayoutAlign="top center">
        <div>{{"Course Completion Time &nbsp;: "}}&nbsp;</div>
        <div *ngIf="!isDateValid(badge.CompletedDate)">{{' NA'}}</div>
        <div *ngIf="isDateValid(badge.CompletedDate)" [innerHTML]="getTime(badge.CompletedDate,'h:mm a')">
        </div>
      </div>
    </div>
  </div>
 
  <div class="accessibility">
    <app-tooltip
    [showsTooltip]="showsTooltip"
    [topPosition]="topPosition"
    [leftPosition]="leftPosition">
    </app-tooltip>
    <span >For queries or issues pertaining to Shoshin School please raise a ticket on 
      <span  (mouseover)="openiSupportMenu($event)"
            (mouseout)="closeiSupportMenu()"><a class="isupportLink"
            href="https://isupport.ltimindtree.com/knowp" target="_blank">
            https://isupport.ltimindtree.com/knowp</a> 
           </span>
    
     </span>
  </div>
  <div  fxLayoutAlign="start start" fxLayout.xs="column" fxFlex.xs="1 1 auto">
    <div fxLayout.gt-md="row" fxLayout.lt-lg="row wrap" fxFlex="1 1 auto" class="course-view-content" fxLayoutAlign="start start">
      <div class="video-or-description-container" fxFlex.gt-sm="70%" fxFlex.lt-md="100%" fxLayout="column" fxLayoutAlign="start stretch"
        [ngStyle.gt-sm]="{'border-right':'1px solid #7b8f9f'}" [ngStyle.lt-md]="{'border-bottom-style':'solid','border-bottom-width.px':'1'}">
       

        <div class="header" fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.lt-md="center start" fxFlex="1 1 100%">
          <div fxLayout="column" fxFlex fxLayoutAlign="center stretch">
            <div fxFlex="100%" *ngIf="courseDetails.Internal && courseDetails.Expertise!='301' && !(courseDetails.AccountPackage || courseDetails.ProjectPackage)">{{LPBreadcrumbs.VideoDetails.VideoName}}</div>
            <div fxFlex="100%" *ngIf="!courseDetails.Internal || courseDetails.Expertise=='301' || (courseDetails.AccountPackage || courseDetails.ProjectPackage)" fxLayoutAlign="start center">
              <b>Course Description:</b>
            </div>
            <div *ngIf="canSeekMandatoryVideo" fxFlex="100%">
              <div [ngClass.lt-md]="{'smallScreen':true, 'videoSeekMsg':true}" [ngClass.gt-sm]="{'largeScreen':true,'videoSeekMsg':true}">
                You can now navigate through the course content
              </div>
            </div>
          </div>


          <div fxFlex="50px" class="content-owner-details" fxLayoutAlign="center center" *ngIf="haveContentOwnerDetails || haveLearningOPMSpocDetails">
            <mat-menu #CPandLOPMdetails="matMenu" class="CPandLOPMdetails-lp" [overlapTrigger]="true" [xPosition]="'before'">
              <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="5px" class="info" [ngClass.xs]="['xs']">
                <div *ngIf="haveContentOwnerDetails">For queries related to course content, you can reach out to
                  <a class="name" href="mailto:{{courseDetails.ContentOwner}}@mindtree.com">{{contentOwnerName}}</a>
                </div>
                <div *ngIf="haveLearningOPMSpocDetails">For queries related to course operations and workflow, contact
                  <a class="name" href="mailto:{{courseDetails.LearningOPMSPOC}}@mindtree.com">{{learningopmSpocName}}</a>
                </div>
              </div>
              <div class="arrow-border-right" fxFlex="20px"></div>
              <div class="arrow-right" fxFlex="20px"></div>
            </mat-menu>
            <div>
              <div class="contentOwnerInfoIcon yorbitSprite" [matMenuTriggerFor]="CPandLOPMdetails"
              #CPandLOPMdetailsTrigger="matMenuTrigger"
              (touchmove)="openMenu($event)"
              (mouseenter)="openMenu('hover')"></div>

            </div>
          </div>
        </div>
        <router-outlet></router-outlet>
        <!-- <div fxFlex.gt-xs="280px" fxFlex.xs="350px" class="nasscom-recommendation-holder">
          <yorbit-nasscom-recommendations [number]="4" [showHeader]="true" [showDescription]="false" [location]="'LP'" fxFlexFill></yorbit-nasscom-recommendations>
        </div> -->
        <div fxLayout="row" fxFlex class="yammer-recommended-courses-container" [ngClass]="{'external':!LPBreadcrumbs.CourseDetails.IsInternal}">
          <div class="yammer-section" fxFlex fxShow.gt-sm="true" fxHide.lt-md="true">
            <div fxLayout="column" fxFlexFill fxLayoutAlign="start stretch" *ngIf="!isAccountRelated">
              <div>This forum is intended for peer contribution only.</div>
              <div>For queries or issues pertaining to Shoshin School please raise a ticket on
                <span  (mouseenter)="openiSupportMenu($event)"
                (mouseout)="closeiSupportMenu()"><a 
                href={{globals.isupportUrl}} target="_blank">
                {{globals.isupportUrl}}</a> 
               </span>
                 
              </div>
              <yorbit-yammer fxFlex [card]="{contentData:courseDetails}"></yorbit-yammer>
            </div>
          </div>
          <div class="recommended-courses-section" *ngIf="!isAccountRelated" fxLayout="column" fxLayoutAlign="start stretch" fxShow.gt-sm="true"
            fxHide.lt-md="true" fxFlex="266px">
            <div fxFlexOffset="20px" class="recommended-courses-header" fxFlex="50px" fxLayout>Other similar courses to this course
            </div>
            <div fxFlex="425px" fxLayout="row wrap" fxLayoutAlign="center start" *ngIf="!recommendationsNotAvailable" class="recommended-courses-list browse-content-tile-container">
              <div class="content-tile-holder" *ngFor="let item of recommendedCourses" fxFlex="250px" fxLayout="column" fxLayoutAlign="center center">
                <div fxFlex="300px" fxLayoutAlign="center center" class="cardMargin">
                  <yorbit-content-tile-browse fxFlex="225px" [cardDetails]='item' [featuredIn]="'Similar Recommendation for a Course'" fxLayoutAlign="start start"
                    (click)="navigateTo(item)" fxFlexFill></yorbit-content-tile-browse>
                </div>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" *ngIf="recommendationsNotAvailable">
              <div>Recommendations not available</div>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex.gt-sm="30%" fxFlex.lt-md="100%" fxLayoutAlign="start stretch" fxLayout.xs="column" fxFlex.xs="1 1 auto"
      [ngStyle.xs]="{'height':'100%'}" [ngStyle.gt-sm]="{'border-left':'1px solid #7b8f9f','height':'100%'}" >
        <div fxHide fxShow.lt-md ="true" class="playlist-cloud-yammer-container"
         fxFlexFill fxLayoutAlign="start stretch">
          <mat-tab-group mat-align-tabs="center" [@.disabled]="true" fxFlexFill>
            <mat-tab *ngFor="let tab of tabsList">
              <ng-template mat-tab-label>
                <div fxFlex *ngIf="tab=='playlist'">
                  <mat-icon>playlist_play</mat-icon>
                </div>
                <div fxFlex *ngIf="tab=='cloud'">
                  <mat-icon *ngIf="tab=='cloud'">cloud_queue</mat-icon>
                </div>
                <!-- <div fxFlex *ngIf="tab=='yammer'">
                  <mat-icon *ngIf="tab=='yammer'">comment</mat-icon>
                </div> -->
              </ng-template>
              <div *ngIf="tab=='cloud' && LPBreadcrumbs.LPDetails.LPName != 'MIGRATED'"
              fxLayout="column" class="cloud-lab">
                  <yorbit-cloud-lab fxFlex="1 1 auto"></yorbit-cloud-lab>
                </div>
              <div *ngIf="tab=='playlist'" class="playlist-component-container" fxLayout="column" fxLayoutAlign="start stretch">
                <yorbit-playlist-internal-course [courseMaterial]="courseMaterial" *ngIf="this.LPBreadcrumbs.CourseDetails.IsInternal && courseDetails.Expertise!='301' && !(this.LPBreadcrumbs.CourseDetails.IsAccount || this.LPBreadcrumbs.CourseDetails.IsProject)"></yorbit-playlist-internal-course>
                <yorbit-playlist-external-course [courseMaterial]="courseMaterial" [course]="courseDetails" *ngIf="(!this.LPBreadcrumbs.CourseDetails.IsInternal && !(this.LPBreadcrumbs.CourseDetails.IsAccount || this.LPBreadcrumbs.CourseDetails.IsProject))||courseDetails.Expertise=='301'"></yorbit-playlist-external-course>
                <yorbit-playlist-account-course [courseMaterial]="courseMaterial" *ngIf="this.LPBreadcrumbs.CourseDetails.IsAccount || this.LPBreadcrumbs.CourseDetails.IsProject"></yorbit-playlist-account-course>
              </div>
              <!-- <div fxLayout="column" *ngIf="tab=='yammer'" fxLayoutAlign="start stretch">
                <div>This forum is intended for peer contribution only.</div>
                <div>For queries or issues pertaining to Yorbit please write to
                  <a href="mailto:yorbit.support@mindtree.com">yorbit.support@mindtree.com</a> </div>
                <yorbit-yammer fxFlex="1 1 auto" [card]="{contentData:courseDetails}"></yorbit-yammer>
              </div> -->
            </mat-tab>
          </mat-tab-group>
        </div>
        <div fxHide fxShow.gt-sm="true" class="playlist-cloud-yammer-container" fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
            <div fxLayout="column" fxLayoutAlign="start stretch" *ngIf="LPBreadcrumbs.LPDetails.LPName != 'MIGRATED'">
                <yorbit-cloud-lab fxFlex="1 1 auto"></yorbit-cloud-lab>
              </div>
            <div class="playlist-component-container" fxLayout="column" fxLayoutAlign="start stretch">
                <yorbit-playlist-internal-course [courseMaterial]="courseMaterial" *ngIf="this.LPBreadcrumbs.CourseDetails.IsInternal && courseDetails.Expertise!='301' && !(this.LPBreadcrumbs.CourseDetails.IsAccount || this.LPBreadcrumbs.CourseDetails.IsProject)"></yorbit-playlist-internal-course>
                <yorbit-playlist-external-course [courseMaterial]="courseMaterial" [course]="courseDetails" [packageId]="packageId" *ngIf="(!this.LPBreadcrumbs.CourseDetails.IsInternal && !(this.LPBreadcrumbs.CourseDetails.IsAccount || this.LPBreadcrumbs.CourseDetails.IsProject))||courseDetails.Expertise=='301'"></yorbit-playlist-external-course>
                <yorbit-playlist-account-course [courseMaterial]="courseMaterial" *ngIf="this.LPBreadcrumbs.CourseDetails.IsAccount || this.LPBreadcrumbs.CourseDetails.IsProject"></yorbit-playlist-account-course>
              </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div fxFlexFill *ngIf="pageLoading" fxLayoutAlign="center center">
  <app-preloader></app-preloader>
</div>
<div fxFlexFill *ngIf="!pageLoading && !pageLoadedSuccessfully" fxLayoutAlign="center center">
  Sorry, please try again!. If issue persits, please raise a ticket on 
  <span  (mouseenter)="openiSupportMenu($event)"
  (mouseout)="closeiSupportMenu()"><a 
  href={{globals.isupportUrl}} target="_blank">
  {{globals.isupportUrl}}</a> 
 </span>
     

</div>
<div fxFlexFill *ngIf="!pageLoading && pageLoadedSuccessfully && !courseAvailable" fxLayoutAlign="center center">
  This course is no longer available.
</div>
<!-- <div fxFlexFill *ngIf="!pageLoading && pageLoadedSuccessfully" fxLayoutAlign="center center">
This course is no longer available.
</div> -->
<ng-template #yammerComp>
  <div fxLayout="column" fxFlexFill fxLayoutAlign="start stretch">
    <!-- <div>This forum is intended for peer contribution only.</div>
    <div>For issues or queries please reach out to Global Contact Center(extn: 12345-3) or raise a
      <a href="https://webapps.mindtree.com/Genie/forms/SearchPage.aspx?"  rel="noreferrer" target="_blank">Genie</a> </div> -->
    <yorbit-yammer fxFlex [card]="{contentData:courseDetails}"></yorbit-yammer>
  </div>
</ng-template>
