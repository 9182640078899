<div class="outer" *ngIf="pageActive == 1">
  <h2 class="heading"><b>301 <span>Content Upload Form</span></b></h2>

  <div class="first-half">
    <h5 class="label">Is Course Internal?</h5>

    <!--Radio buttons for selecting course type-->
    <form name="contentUpload" [formGroup]='contentUploadFormGroup'>

      <input type="radio" name="courseType" value="yes" formControlName="courseType">
      <label class="label" for="courseType">Yes</label>

      <input type="radio" name="courseType" value="no" formControlName="courseType">
      <label class="label" for="courseType">No</label>

      <br />

      <!--Dropdown for selecting Academy name-->
      <div class="zeroPadding first-half-padding">
        <label for="academy" class="label">Academy<sup class="error pl5 mt0">*</sup></label>
        <select name="academy" formControlName="academy" (change)="changeGenreOptions()"
          (focusout)="validateOnMouseOut($event,'academy')">
          <option value="" selected disabled *ngIf="academyHidden == false">Select an academy</option>
          <option *ngFor="let academy of academyList" title="{{academy}}" [value]="academy">
            {{(academy.length>30)?(academy.slice(0,30)+"..."):(academy) }}</option>
        </select> <br />
        <span class="error" *ngIf="academyError == true">Please choose the academy to which the course
          belongs</span>
      </div>

      <!--Dropdown for selecting Genre name-->
      <div class="first-half-padding">
        <label for="genre" class="label">Genre<sup class="error pl5 mt0">*</sup></label>
        <select name="genre" formControlName="genre" (change)="changeSkillOptions()" [title]="GenreTitle"
          (focusout)="validateOnMouseOut($event,'genre')">
          <option value="" selected disabled *ngIf="genreHidden == false">Select a genre</option>
          <option *ngFor="let genres of genreList" title="{{genres}}" [value]="genres">
            {{(genres.length>30)?(genres.slice(0,30)+"..."):(genres) }}</option>
        </select>
        <br />
        <span class="error" *ngIf="genreError == true">Please choose the genre to which the course belongs</span>
      </div>

      <!--Dropdown for selecting Skill name-->
      <div class="first-half-padding">
        <label for="skill" class="label">Skill<sup class="error pl5 mt0">*</sup></label>
        <select name="skill" formControlName="skill" (change)="enableSkillValidation()" [title]="SkillTitle"
          (focusout)="validateOnMouseOut($event,'skillDropDown')">
          <option value="" selected disabled *ngIf="skillHidden == false">Select a skill</option>
          <option *ngFor="let skills of skillList" title="{{skills}}" [value]="skills">
            {{(skills.length>30)?(skills.slice(0,30)+"..."):(skills) }}</option>
        </select>
        <br />
        <span class="error" *ngIf="skillError == true">Please choose the skill to which the course belongs</span>
      </div>

    </form>
  </div>


  <!--Second half of the form -->
  <div class="second-half">

    <!--First column-->
    <div class="vertical">
      <form name="contentUpload" [formGroup]='contentUploadFormGroup'>

        <!--Course name-->
        <label for="courseName" class="label">Course Name<sup class="error pl5 mt0">*</sup></label> <br />
        <input type="text" (keyup)="validateInput('courseName',$event)" name="courseName" formControlName='courseName'
          placeholder="Enter a course name" (focusout)="validateOnMouseOut($event,'courseName')" />
        <br />
        <span class="error" *ngIf="courseNameError == true">Please enter a course name</span>
        <span class="error" *ngIf="courseExistsError == true">Course already exists</span>
        <br />

        <!--Course Description-->
        <label for="courseDesc" class="label">Course Description<sup class="error pl5 mt0">*</sup></label> <br />
        <textarea name="courseDesc" (keyup)="validateInput('courseDesc',$event)" formControlName="courseDesc"
          class="bigHeight" placeholder="Enter course description (minimum 250 characters)"
          (focusout)="validateOnMouseOut($event,'courseDesc')"></textarea> <br />
        <div
          *ngIf="topicsCoveredError== true || minDurationError == true || durationError == true || courseDescError == true"
          [ngClass]="{'mw245 h15' : ((durationError == true || minDurationError == true) && topicsCoveredError == false), 'mw245 h0' : ((durationError == false && minDurationError == false) && topicsCoveredError == false), 'mw254 h15' : ((durationError == true || minDurationError == true) && topicsCoveredError == true)}">
          <span class="error" *ngIf="courseDescError == true">Please enter atleast 250 characters.</span>
        </div>
        <!-- <br *ngIf="contentUploadFormGroup.get('courseType').value == 'no' && topicsCoveredError == false && minDurationError == false && durationError == false" /> -->

        <!--Course type Dropdown for non-internal courses-->
        <!-- <br *ngIf="contentUploadFormGroup.get('courseType').value == 'no' && (durationError == true || minDurationError == true)" /> -->
        <h5
          [ngClass]="[((durationError == true || minDurationError == true) && topicsCoveredError == false) == true ? 'label mt6 pt29' : '', (topicsCoveredError == true && (durationError == false && minDurationError == false)) == true ? 'label mt0 pt0' : '', ((durationError == true || minDurationError == true) && topicsCoveredError == true ) == true ? 'label mt6 pt23' : '']"
          for="courseTypeDropdown" class="label mt0 pt22"
          *ngIf="contentUploadFormGroup.get('courseType').value == 'no'">Course Type<sup class="error pl5 mt0">*</sup>
        </h5>
        <br />
        <select *ngIf="contentUploadFormGroup.get('courseType').value == 'no'" formControlName="courseTypeDropdown"
          name="courseTypeDropdown" (change)="validateInput('courseType',$event)"
          (focusout)="validateOnMouseOut($event,'courseType')">
          <option value="" selected disabled *ngIf="courseTypeHidden == false">Select course type</option>
          <option *ngFor="let ctype of courseTypes" [value]="ctype.CourseTypeName" [title]="ctype.CourseTypeName">
            {{(ctype.CourseTypeName.length > 30) ? (ctype.CourseTypeName(0,30)+"...") : ctype.CourseTypeName}}
          </option>
        </select>
        <br *ngIf="contentUploadFormGroup.get('courseType').value == 'no'" />
        <span class="error"
          *ngIf="contentUploadFormGroup.get('courseType').value == 'no' && courseTypeError == true">Please choose a
          course type</span>

        <div *ngIf="contentUploadFormGroup.get('courseType').value == 'yes'">
          <div class="w248">
            <h5 class="label pt0 mt4"
              [ngClass]="[((durationError == true || minDurationError == true) && topicsCoveredError == false) == true ? 'label mt6 pt11' : '', (topicsCoveredError == true && (durationError == false && minDurationError == false)) == true ? 'label mt0 pt0' : '', ((durationError == true || minDurationError == true) && topicsCoveredError == true ) == true ? 'label mt6 pt11' : '']">
              Course Completion Certificate to be generated by Shoshin School ?</h5>
            <br />
          </div>
          <input type="radio" name="isCertificateAvailable" value="yes" formControlName="isCertificateAvailable"
            [ngClass]="contentUploadFormGroup.get('courseType').value == 'yes' ? '' : 'pRelative t10'">
          <label class="label" for="isCertificateAvailable"
            [ngClass]="contentUploadFormGroup.get('courseType').value == 'yes' ? 'label t0' : 'label pRelative t7'">Yes</label>
          <input type="radio" name="isCertificateAvailable" value="no" formControlName="isCertificateAvailable"
            [ngClass]="contentUploadFormGroup.get('courseType').value == 'yes' ? '' : 'pRelative t10'">
          <label class="label" for="isCertificateAvailable"
            [ngClass]="contentUploadFormGroup.get('courseType').value == 'yes' ? 'label t0' : 'label pRelative t7'">No</label>
        </div>

      </form>
    </div>

    <!--Second Column-->
    <div class="vertical pl30">
      <form name="contentUpload" [formGroup]='contentUploadFormGroup'>

        <!--Badge name-->
        <label for="badgeName" class="label">Badge Name<sup class="error pl5 mt0">*</sup></label>
        <br />
        <input type="text" (keyup)="validateInput('badgeName',$event)" name="badgeName" formControlName='badgeName'
          placeholder="Enter a badge name" (focusout)="validateOnMouseOut($event,'badgeName')" />
        <br />
        <span class="error" *ngIf="badgeError == true">Please enter a badge name</span>
        <br />

        <!--Course Credits-->
        <label for="credits" class="label">Credits<sup class="error pl5 mt0">*</sup></label>
        <br />
        <input type="text" name="credits" (keydown)="validateInput('credits',$event)" formControlName='credits'
          placeholder="Enter Credits" (focusout)="validateOnMouseOut($event,'credits')"
          (focusout)="validateOnMouseOut($event,'credits')" />
        <br />
        <span class="error" *ngIf="creditsError == true">Please enter number in the format of x</span>
        <span class="error" *ngIf="rangeError == true">Please enter number between 0 and 9</span>
        <br *ngIf="contentUploadFormGroup.get('courseType').value == 'yes'" />

        <!--Course type dropdown for Internal Courses-->
        <label *ngIf="contentUploadFormGroup.get('courseType').value == 'yes'" for="courseTypeDropdown"
          class="label">Course Type<sup class="error pl5 mt0">*</sup></label>
        <br *ngIf="contentUploadFormGroup.get('courseType').value == 'yes'" />
        <select *ngIf="contentUploadFormGroup.get('courseType').value == 'yes'" formControlName="courseTypeDropdown"
          name="courseTypeDropdown" (change)="validateInput('courseType',$event)"
          (focusout)="validateOnMouseOut($event,'courseType')">
          <option value="" selected disabled *ngIf="courseTypeHidden == false">Select a course type</option>
          <option *ngFor="let ctype of courseTypes" [value]="ctype.CourseTypeName" [title]="ctype.CourseTypeName">
            {{(ctype.CourseTypeName.length > 30) ? (ctype.CourseTypeName(0,30)+"...") : ctype.CourseTypeName}}
          </option>
        </select>
        <br *ngIf="contentUploadFormGroup.get('courseType').value == 'yes'" />
        <span class="error"
          *ngIf="contentUploadFormGroup.get('courseType').value == 'yes' && courseTypeError == true">Please choose a
          course type</span>
        <br />

        <!--Vendor Selector Dropdown-->
        <label for="nameOfVendor" class="label" *ngIf="contentUploadFormGroup.get('courseType').value == 'no'">Name of
          the Vendor<sup class="error pl5 mt0">*</sup></label>
        <br *ngIf="contentUploadFormGroup.get('courseType').value == 'no'" />
        <select *ngIf="contentUploadFormGroup.get('courseType').value == 'no'" formControlName="nameOfVendor"
          name="nameOfVendor" (change)="validateInput('vendor',$event)"
          (focusout)="validateOnMouseOut($event,'vendor')">
          <option value="" selected disabled *ngIf="vendorHidden == false">Select vendor</option>
          <option *ngFor="let vendor of vendors;" [value]="vendor.Vendor" [title]="vendor.Vendor">
            {{(vendor.Vendor.length>30)?(vendor.Vendor.slice(0,30)+"..."):(vendor.Vendor) }}
          </option>
        </select>
        <br *ngIf="contentUploadFormGroup.get('courseType').value == 'no'" />
        <span class="error" *ngIf="contentUploadFormGroup.get('courseType').value == 'no' && vendorError == true">Please
          choose a vendor name</span>
        <br *ngIf="contentUploadFormGroup.get('courseType').value == 'no'" />

        <!--Duration Input-->
        <label for="courseDuration" class="label">Duration<sup class="error pl5 mt0">*</sup></label>
        <br />
        <input (keyup)="validateInput('duration',$event)" type="text" name="courseDuration"
          formControlName="courseDuration" placeholder="HH.MM.SS or HHH.MM.SS"
          (focusout)="validateOnMouseOut($event,'duration')" />
        <label class="label pl3 mt10">Hrs</label>
        <div class="mw227" *ngIf="durationError == true || minDurationError == true || topicsCoveredError == true">
          <span class="error" *ngIf="durationError == true">Please enter numbers in the format of HHH.MM.SS or
            HH.MM.SS.</span>
          <span class="error" *ngIf="minDurationError == true">Course should be atleast 80 hours in duration</span>
        </div>
        <br />
        <!--Skill Prism ID-->
        <label for="SkillIds" class="label skillid">Skill Prism ID</label>
        <br />
        <input type="text" name="SkillIds" formControlName='SkillIds' placeholder="Enter Skill Prism ID" />
        <br />
      </form>


      <!--Hidden Course radio buttons-->
      <div class="pl30 dinline customPosition" [formGroup]="contentUploadFormGroup">
        <br *ngIf="durationError == false && minDurationError == false && topicsCoveredError == false" />
        <h5 class="label"
          [ngClass]="[((durationError || minDurationError) && topicsCoveredError == false) == true ? 'label mt6 pt0' : 'label pt0 mt16', (topicsCoveredError == true && (durationError == false && minDurationError == false)) == true ? 'label 0 pt22' : 'label pt0 mt16', (durationError && minDurationError && topicsCoveredError ) == true ? 'label mt6 pt0' : 'label pt0 mt16']">
          Hidden Course?</h5> <br />
        <input type="radio" name="courseHidden" value="yes" formControlName="courseHidden"
          [ngClass]="contentUploadFormGroup.get('courseType').value == 'yes' ? '' : 'pRelative t10'">
        <label for="courseHidden"
          [ngClass]="contentUploadFormGroup.get('courseType').value == 'yes' ? 'label t0' : 'label pRelative t7'">Yes</label>
        <input type="radio" name="courseHidden" value="no" formControlName="courseHidden"
          [ngClass]="contentUploadFormGroup.get('courseType').value == 'yes' ? '' : 'pRelative t10'">
        <label for="courseHidden"
          [ngClass]="contentUploadFormGroup.get('courseType').value == 'yes' ? 'label t0' : 'label pRelative t7'">No</label>
      </div>

      <!--Restricted course radio buttons-->
      <div class="pl30 dinline customPosition" [formGroup]="contentUploadFormGroup">
        <h5 class="label"
          [ngClass]="[((durationError || minDurationError) && topicsCoveredError == false) == true ? 'label mt6 pt0' : 'label pt0 mt16', (topicsCoveredError == true && (durationError == false && minDurationError == false)) == true ? 'label 0 pt22' : 'label pt0 mt16', (durationError && minDurationError && topicsCoveredError ) == true ? 'label mt6 pt0' : 'label pt0 mt16']">
          Course Restricted?</h5> <br />
        <input type="radio" name="restrictedCourse" value="yes" formControlName="restrictedCourse"
          [ngClass]="contentUploadFormGroup.get('courseType').value == 'yes' ? '' : 'pRelative t10'">
        <label class="label" for="restrictedCourse"
          [ngClass]="contentUploadFormGroup.get('courseType').value == 'yes' ? 'label t0' : 'label pRelative t7'">Yes</label>
        <input type="radio" name="restrictedCourse" value="no" formControlName="restrictedCourse"
          [ngClass]="contentUploadFormGroup.get('courseType').value == 'yes' ? 'pRelative l12' : 'pRelative t10 l12'">
        <label class="label" for="restrictedCourse"
          [ngClass]="contentUploadFormGroup.get('courseType').value == 'yes' ? 'label t0 pRelative l13' : 'label pRelative t7 l13'">No</label>
      </div>
    </div>


    <!--Third column-->
    <div class="vertical pl30">
      <form name="contentUpload" [formGroup]='contentUploadFormGroup'>

        <!--Expert MIDs textarea-->
        <label for="expertMIDS" class="label">Expert PSID(s)</label> <br />
        <textarea name="expertMIDS" formControlName="expertMIDS" placeholder="Please enter PSID"
          (keyup)="validateInput('expertMIDs',$event)"></textarea>
        <br />
        <span class="error" *ngIf="expertMIDError == true">Please enter PSID in XXXXXXXX format</span>
        <span class="error" *ngIf="duplicateMid == true">PSID already exists</span>
        <br />

        <!--Topics covered-->
        <label for="topicsCovered" class="label mt0">Topics Covered<sup class="error pl5 mt0">*</sup></label>
        <br />
        <textarea name="topicsCovered" formControlName="topicsCovered"
          placeholder="Enter topics covered (minimum 50 characters)"
          (focusout)="validateOnMouseOut($event,'topicsCovered')" (keyup)="validateInput('topics',$event)"></textarea>
        <br />
        <div *ngIf="topicsCoveredError== true || minDurationError == true || durationError == true"
          [ngClass]="{'mw245 h34' : ((durationError == true || minDurationError == true) && topicsCoveredError == false), 'mw245 h0' : ((durationError == false && minDurationError == false) && topicsCoveredError == false), 'mw254 h44' : ((durationError == true || minDurationError == true) && topicsCoveredError == true)}">
          <span class="error" *ngIf="topicsCoveredError == true">Please enter atleast 50 characters. </span>
          <!-- <br *ngIf="(minDurationError == true || durationError == true) && topicsCoveredError == false"/>
          <br *ngIf="(minDurationError == true || durationError == true) && topicsCoveredError == false"/> -->
        </div>

        <!--course proficiency start-->
        <label for="proficiency" class="label">Course Proficiency<sup class="error pl5 mt0">*</sup></label>
        <br />
        <select name="proficiency" formControlName="proficiency" (focusout)="validateOnMouseOut($event,'proficiency')">
          <option value="" selected disabled *ngIf="proficiencyHidden == false">Please select a proficiency</option>
          <option *ngFor="let proficiency of proficiencyList" title="{{proficiency}}" [value]="proficiency.Proficiency">
            {{proficiency.Proficiency}}</option>
        </select> <br />
        <span class="error" *ngIf="proficiencyError == true">Please choose a course proficiency.</span>
        <!--course proficiency end-->

        <br *ngIf="durationError == false && minDurationError == false && topicsCoveredError == false" />

        <!--Learning Partner Approval-->
        <!-- <br *ngIf="durationError == true || minDurationError == true" />
        <br *ngIf="durationError == true || minDurationError == true" /> -->
        <h5 class="label mt6 pt0">Is Learning Business Partner Approval Required?</h5>
        <br />
        <input type="radio" name="learningPartnerApproval" value="yes" formControlName="learningPartnerApproval"
          [ngClass]="contentUploadFormGroup.get('courseType').value == 'yes' ? '' : 'pRelative t10'">
        <label class="label" for="learningPartnerApproval"
          [ngClass]="contentUploadFormGroup.get('courseType').value == 'yes' ? 'label t0' : 'label pRelative t7'">Yes</label>
        <input type="radio" name="learningPartnerApproval" value="no" formControlName="learningPartnerApproval"
          [ngClass]="contentUploadFormGroup.get('courseType').value == 'yes' ? '' : 'pRelative t10'">
        <label class="label" for="learningPartnerApproval"
          [ngClass]="contentUploadFormGroup.get('courseType').value == 'yes' ? 'label t0' : 'label pRelative t7'">No</label>

      </form>
    </div>
  </div>


  <!--Third half of the form-->
  <div class="third-half">

    <!--First column-->
    <div class="vertical pl30">
      <!--Pre-Requisite course selection box-->
      <label class="label">Pre-requisite Course(s)</label> <br />
      <div
        [ngClass]="[selectedCourse && selectedCourse.CourseStatus && selectedCourse.CourseStatus.toLowerCase() != 'saved' && selectedCourse && selectedCourse.CourseStatus && selectedCourse.CourseStatus.toLowerCase() != 'denied'  ? ' courses disabledBackground restricted' : 'courses allowed']">
        <p class="div_placeholder" *ngIf="preReqCoursesList == 0">Pre-requisite Courses selected by you will be shown
          here.Please select from the below link.</p>
        <ul>
          <li *ngFor="let course of preReqCoursesList;index as i;">
            <span class="content">{{(course.Name.length > 35) ? course.Name.slice(0,33) + "..." : (course.Name)}}
              <span
                *ngIf="(selectedCourse && (selectedCourse.CourseStatus.toLowerCase() == 'saved' || selectedCourse.CourseStatus.toLowerCase() == 'denied')) || !selectedCourse"
                [ngClass]="[(selectedCourse && (selectedCourse.CourseStatus.toLowerCase() == 'saved' || selectedCourse.CourseStatus.toLowerCase() == 'denied')) || !selectedCourse? 'closeMark allowed' : 'closeMark restricted']"
                (mouseup)="deletePreReqCourse($event)" [attr.index]="i"></span></span>
          </li>
        </ul>
      </div>
      <br />
      <a *ngIf="selectedCourse != undefined && selectedCourse.CourseStatus && (selectedCourse.CourseStatus.toLowerCase() == 'saved' || selectedCourse.CourseStatus.toLowerCase() == 'denied')"
        [ngClass]="[(selectedCourse.CourseStatus.toLowerCase() == 'saved' || selectedCourse.CourseStatus.toLowerCase() == 'denied') ? 'label underline pointerCursor' : 'label underline restricted']"
        (click)="openPreReqCourses()">Select Pre-requisite Course(s)</a>
      <a *ngIf="selectedCourse == undefined" class="label underline pointerCursor" (click)="openPreReqCourses()">Select
        Pre-requisite Course(s)</a>
    </div>

    <!--Second Column-->
    <div class="vertical pl60">

      <!--Post-Recommended course selection box-->
      <label class="label">Post recommended Course(s)</label> <br />
      <div
        [ngClass]="[selectedCourse && selectedCourse.CourseStatus && (selectedCourse.CourseStatus.toLowerCase() != 'saved' && selectedCourse.CourseStatus.toLowerCase() != 'denied') ? 'courses disabledBackground restricted' : 'courses allowed']">
        <p class="div_placeholder" *ngIf="postRecCoursesList.length == 0">Post Recommended Courses selected by you will
          be shown here.
          Please select from the below link.
        </p>
        <ul>
          <li *ngFor="let course of postRecCoursesList;index as i;">
            <span class="content">{{(course.Name.length > 35) ? course.Name.slice(0,33) + "..." : (course.Name)}}
              <span
                *ngIf="(selectedCourse && (selectedCourse.CourseStatus.toLowerCase() == 'saved' || selectedCourse.CourseStatus.toLowerCase() == 'denied')) || !selectedCourse"
                [ngClass]="[(selectedCourse && (selectedCourse.CourseStatus.toLowerCase() == 'saved' || selectedCourse.CourseStatus.toLowerCase() == 'denied') || !selectedCourse)? 'closeMark allowed' : 'closeMark restricted']"
                (mouseup)="deletePostRecCourse($event)" [attr.index]="i"></span></span>
          </li>
        </ul>
      </div>
      <br />
      <a *ngIf="selectedCourse != undefined && selectedCourse.CourseStatus && (selectedCourse.CourseStatus.toLowerCase() == 'saved' || selectedCourse.CourseStatus.toLowerCase() == 'denied')"
        [ngClass]="[(selectedCourse.CourseStatus.toLowerCase() == 'saved' || selectedCourse.CourseStatus.toLowerCase() == 'denied') ? 'label underline pointerCursor' : 'label underline restricted']"
        (click)="openPostRecCourses()">Select Post recommended Course(s)</a>
      <a *ngIf="selectedCourse == undefined" class="label underline pointerCursor" (click)="openPostRecCourses()">Select
        Post recommended Course(s)</a>
    </div>

    <!--Third column-->
    <div class="vertical pl60">

      <!--Pre-requisite course Mandatory selection input-->
      <h5 class="label">Is Pre-requisite Mandatory?</h5>
      <form name="contentUpload" [formGroup]="contentUploadFormGroup">
        <input type="radio" name="preReqMandatory" value="yes" formControlName="preReqMandatory">
        <label class="label" for="preReqMandatory">Yes</label>
        <input type="radio" name="preReqMandatory" value="no" formControlName="preReqMandatory">
        <label class="label" for="preReqMandatory">No</label>
      </form>

      <!--Cloud Labs Availability input-->
      <h5 class="label">Is Cloud Labs Required?</h5>
      <form name="contentUpload" [formGroup]="contentUploadFormGroup">
        <input type="radio" name="isCloudLabsRequired" value="yes" formControlName="isCloudLabsRequired">
        <label class="label" for="isCloudLabsRequired">Yes</label>
        <input type="radio" name="isCloudLabsRequired" value="no" formControlName="isCloudLabsRequired">
        <label class="label" for="isCloudLabsRequired">No</label>
      </form>
    </div>
  </div>


  <!--Fourth half-->
  <div class="fourth-half" *ngIf="contentUploadFormGroup.get('courseType').value == 'yes'">

    <!--First column-->
    <div class="vertical pl30">
      <form name="contentUpload" [formGroup]="contentUploadFormGroup">

        <!--Content Owner choosable input-->
        <label class="label" for="contentOwner">Content Owner<sup class="error pl5 mt0">*</sup></label> <br />
        <input type="text" name="contentOwner" formControlName="contentOwner"
          [ngClass]="[selectedCourse && selectedCourse.CourseStatus &&  (selectedCourse.CourseStatus.toLowerCase() != 'saved' && selectedCourse.CourseStatus.toLowerCase() != 'denied') ? 'borderRight disabledBackground borderRadiusAdjusted mindSelector' : 'borderRadiusAdjusted mindSelector']">
        <button name="chooseCO" type="button"
          [ngClass]="[(selectedCourse &&  selectedCourse.CourseStatus && (selectedCourse.CourseStatus.toLowerCase() != 'saved' && selectedCourse.CourseStatus.toLowerCase() != 'denied')) ? 'dInlineButton restricted' : 'dInlineButton allowed']"
          (click)="openMindsList('contentOwner')"
          [disabled]="(selectedCourse && selectedCourse.CourseStatus && (selectedCourse.CourseStatus.toLowerCase() != 'saved' && selectedCourse.CourseStatus.toLowerCase() != 'denied'))"
          *ngIf="(selectedCourse && selectedCourse.CourseStatus && (selectedCourse.CourseStatus.toLowerCase() == 'saved' || selectedCourse.CourseStatus.toLowerCase() == 'denied')) || !selectedCourse"></button>
        <br />
      </form>
    </div>

    <!--Second column-->
    <div class="vertical pl60">
      <form name="contentUpload" [formGroup]="contentUploadFormGroup">

        <!--Learning OPM SPOC choosable input-->
        <label class="label" for="learningOPMSPOC"
          [ngClass]="(selectedCourse && selectedCourse.CourseStatus.toLowerCase() != 'saved' && selectedCourse.CourseStatus.toLowerCase() != 'denied' ? 'ml27' : '')">Learning
          OPM SPOC<sup class="error pl5 mt0">*</sup></label> <br />
        <input type="text" name="learningOPMSPOC" formControlName="learningOPMSPOC"
          [ngClass]="[selectedCourse && selectedCourse.CourseStatus && selectedCourse.CourseStatus.toLowerCase() != 'saved' && selectedCourse.CourseStatus.toLowerCase() != 'denied' ? 'borderRight disabledBackground borderRadiusAdjusted mindSelector ml27' : 'borderRadiusAdjusted mindSelector']">
        <button name="chooseCO" type="button"
          [ngClass]="[(selectedCourse && selectedCourse.CourseStatus &&  (selectedCourse.CourseStatus.toLowerCase() != 'saved' && selectedCourse.CourseStatus.toLowerCase() != 'denied')) ? 'dInlineButton restricted' : 'dInlineButton allowed']"
          (click)="openMindsList('learningOPMSPOC')"
          [disabled]="(selectedCourse && selectedCourse.CourseStatus && selectedCourse.CourseStatus.toLowerCase() != 'saved' && selectedCourse.CourseStatus.toLowerCase() != 'denied')"
          *ngIf="(selectedCourse && selectedCourse.CourseStatus && (selectedCourse.CourseStatus.toLowerCase() == 'saved' || selectedCourse.CourseStatus.toLowerCase() == 'denied')) || !selectedCourse"></button>
        <br />
      </form>
    </div>
  </div>


  <!-- Fourth half of the form for Non-internal courses -->
  <div class="fourth-half" *ngIf="contentUploadFormGroup.get('courseType').value == 'no'">

    <!--First column-->
    <div class="vertical pl30"
      [ngClass]="selectedCourse && selectedCourse.CourseStatus.toLowerCase() != 'saved' && selectedCourse.CourseStatus.toLowerCase() != 'denied' ? 'vertical pl30 pr27' : 'vertical pl30'">
      <form name="contentUpload" class="dinline" [formGroup]="contentUploadFormGroup">

        <!--Content owner choosable input-->
        <label class="label" for="contentOwner">Content Owner<sup class="error pl5 mt0">*</sup></label> <br />
        <input type="text" name="contentOwner" formControlName="contentOwner"
          [ngClass]="[selectedCourse && selectedCourse.CourseStatus.toLowerCase() != 'saved' && selectedCourse.CourseStatus.toLowerCase() != 'denied' ? 'borderRight disabledBackground borderRadiusAdjusted mindSelector' : 'borderRadiusAdjusted mindSelector']">
        <button name="chooseCO" type="button"
          [ngClass]="[(selectedCourse && selectedCourse.CourseStatus.toLowerCase() != 'saved') ? 'dInlineButton restricted' : 'dInlineButton allowed']"
          (click)="openMindsList('contentOwner')"
          [disabled]="(selectedCourse && selectedCourse.CourseStatus.toLowerCase() != 'saved' && selectedCourse.CourseStatus.toLowerCase() != 'denied')"
          *ngIf="(selectedCourse && (selectedCourse.CourseStatus.toLowerCase() == 'saved' || selectedCourse.CourseStatus.toLowerCase() == 'denied')) || !selectedCourse"></button>
        <br />

        <!--Learning OPM SPOC choosable input-->
        <label class="label" for="learningOPMSPOC">Learning OPM SPOC<sup class="error pl5 mt0">*</sup></label> <br />
        <input type="text" name="learningOPMSPOC" formControlName="learningOPMSPOC"
          [ngClass]="[selectedCourse && selectedCourse.CourseStatus.toLowerCase() != 'saved' && selectedCourse.CourseStatus.toLowerCase() != 'denied' ? 'borderRight disabledBackground borderRadiusAdjusted mindSelector' : 'borderRadiusAdjusted mindSelector']">
        <button name="chooseCO" type="button"
          [ngClass]="[(selectedCourse && selectedCourse.CourseStatus.toLowerCase() != 'saved') ? 'dInlineButton restricted' : 'dInlineButton allowed']"
          (click)="openMindsList('learningOPMSPOC')"
          [disabled]="(selectedCourse && selectedCourse.CourseStatus.toLowerCase() != 'saved' && selectedCourse.CourseStatus.toLowerCase() != 'denied')"
          *ngIf="(selectedCourse && (selectedCourse.CourseStatus.toLowerCase() == 'saved' || selectedCourse.CourseStatus.toLowerCase() == 'denied')) || !selectedCourse"></button>
        <br />
      </form>
    </div>


    <!--Second column-->
    <div class="vertical pl60 valignTop">
      <div>
        <h5 class="label">Is Certificate Upload required by learner for Course Completion?</h5>
        <form name="contentUpload" [formGroup]="contentUploadFormGroup">
          <input type="radio" name="isCertificateUpload" value="yes" formControlName="isCertificateUpload">
          <label class="label" for="isCertificateUpload">Yes</label>
          <input type="radio" name="isCertificateUpload" value="no" formControlName="isCertificateUpload">
          <label class="label" for="isCertificateUpload">No</label>
        </form>
      </div>

      <div>
        <!--Course price input-->
        <div class="dinline" [formGroup]="contentUploadFormGroup"
          *ngIf="this.contentUploadFormGroup.get('courseType').value == 'no'">
          <label class="label mt38" for="coursePrice">Course Price<sup class="error pl5 mt0"
              *ngIf="contentUploadFormGroup.get('coursePaid').value == 'yes'">*</sup></label> <br />
          <input class="mt8" type="text" (keyup)="validateInput('price',$event)" name="coursePrice"
            formControlName="coursePrice" placeholder="Enter course price"
            (focusout)="validateOnMouseOut($event,'coursePrice')"> <br />
          <span class="error displayTableCell pt5"
            *ngIf="coursePriceError == true && contentUploadFormGroup.get('coursePaid').value == 'yes'">Please enter
            numbers only.</span>
          <span class="error displayTableCell pt5"
            *ngIf="validCoursePrice == true && contentUploadFormGroup.get('coursePaid').value == 'yes'">Please enter a
            valid course price.</span>
        </div>

        <!--Currency selection dropdown-->
        <div class="dinline" [formGroup]="contentUploadFormGroup"
          *ngIf="this.contentUploadFormGroup.get('courseType').value == 'no'">
          <label class="label ml10 mt38" for="currency">Currency</label> <br />
          <select name="currency" class="ml10" formControlName="currency" (change)="validateInput('',$event)">
            <option value="usd" title="usd">USD</option>
            <option value="inr" title="inr" selected>INR</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</div>


<!--Second page of the form-->
<div *ngIf="pageActive == 2" class="outer">
  <h2 class="heading"><b>301 <span>Content Upload Form</span></b></h2>

  <!--Header-->
  <div class="header">
    <h2 class="pageHeading">Add Stack/Skill <span class="info-icon" (click)="showInfoPopUp()">i</span></h2>
    <button (click)="addStack()" [disabled]="disableAddStack" [title]="disabledStackTitle"
      *ngIf="selectedCourse && selectedCourse.CourseStatus.toLowerCase() == 'saved' || !selectedCourse">ADD NEW
      STACK</button>
  </div>

  <!--Stack Container-->
  <div class="stack" *ngFor="let stack of stackBoxCount; index as stackPos;" [attr.stackId]="stackPos">

    <!--Stack Options-->
    <div class="stackOptions">
      <div
        *ngIf="(selectedCourse && selectedCourse.CourseStatus.toLowerCase() == 'saved' && stackBoxCount.length != 1)  || (stackBoxCount.length != 1 && selectedCourse == undefined)"
        class="deleteIcon" (click)="deleteStack($event)" [attr.stackId]="stack" title="Delete this stack"></div>
      <div
        *ngIf="(selectedCourse && selectedCourse.CourseStatus.toLowerCase() == 'saved' && stackPos != (stackBoxCount.length-1) && stackBoxCount.length != 1)  || (stackPos != (stackBoxCount.length-1) && stackBoxCount.length != 1 && selectedCourse == undefined)"
        class="downIcon" (click)="moveStackDown($event)" [attr.stackId]="stackPos" title="Move this stack down"></div>
      <div
        *ngIf="(selectedCourse && selectedCourse.CourseStatus.toLowerCase() == 'saved' && stackPos != 0 && stackBoxCount.length != 1)  || (stackPos != 0 && stackBoxCount.length != 1 && selectedCourse == undefined)"
        class="upIcon" (click)="moveStackUp($event)" [attr.stackId]="stackPos" title="Move this stack up"></div>
    </div>

    <!--Stack Heading-->
    <div class="stackHeading pl30" [formGroup]="contentUploadFormGroup">
      <label class="label">{{"Stack " + (stackPos + 1) }}<sup class="error pl5 mt0"
          *ngIf="stackErrors[stackPos]">*</sup></label>
      <br />
      <input type="text" [formControlName]="'stack' + (stack)" [attr.stackId]="stackPos"
        (focusout)="validateOnMouseOut($event,'stack')" (keyup)="validateInput('stack',$event)">
      <br />
      <span class="error" *ngIf="stackErrors[stackPos] == true">Please enter a stack name.</span>
    </div>

    <div class="skills" fxLayout="row wrap" fxLayoutGap="50px">
      <!--Input group with skill input and label-->
      <div class="inputGroup pl30" [formGroup]="contentUploadFormGroup"
        *ngFor="let skill of skillBoxCount[stackPos]; index as skillPos;" [attr.stackId]="stackPos"
        [attr.skillId]="skillPos">
        <label class="label mr10 skillLabel">{{ "Skill" + " " + (skillPos + 1)}}<sup
            *ngIf="skillErrors[stackPos][skillPos] == true" class="error pl5 mt5">*</sup></label>
        <input type="text" [formControlName]="'stack' + (stack) + 'skill' + (skill)" [attr.stackId]="stackPos"
          [attr.skillId]="skillPos" (keyup)="validateInput('skill',$event)"
          (focusout)="validateOnMouseOut($event,'skill')">
        <div class="deleteIcon t5"
          *ngIf="(selectedCourse == undefined && skillBoxCount[stackPos].length > 1) || (skillBoxCount[stackPos].length > 1 && selectedCourse != undefined && selectedCourse.CourseStatus.toLowerCase() == 'saved')"
          (mouseup)="deleteSkill($event)" [attr.stackId]="stack" [attr.skillId]="skill" title="Delete this skill"></div>
        <br />
        <span class="error pl55" *ngIf="skillErrors[stackPos][skillPos] == true">Please enter a skill name.</span>
      </div>
    </div>

    <button type="button" class="ml30" (click)="addSkill($event)" [attr.stackId]="stack"
      [disabled]="disabledSkillButtons[stackPos]" [title]="disabledSkillTitle[stackPos]"
      *ngIf="selectedCourse && selectedCourse.CourseStatus.toLowerCase() == 'saved' || !selectedCourse"></button>
    <label class="label addSkillLabel"
      *ngIf="selectedCourse && selectedCourse.CourseStatus.toLowerCase() == 'saved' || !selectedCourse">ADD
      SKILL</label>

  </div>
</div>


<!--Footer of the form with Exit, Navigation and Submit buttons-->
<div class="buttons">

  <!--EXIT FORM-->
  <div class="left">
    <a (click)="onExit()" class="exit pRelative t10">EXIT FORM</a>
  </div>

  <!--Pagination-->
  <div class="middle" *ngIf="contentUploadFormGroup.get('courseType').value == 'yes'">
    <a class="pagination"
      [ngClass]="[pageActive == 1 ? 'pagination textDecoration' : 'pagination', (pageActive == 2 && isSaved == false) ? 'restricted' : 'allowed']"
      (click)="changePagination(1)" [title]="prevButtonTitle">1</a>
    <a class="pagination"
      [ngClass]="[pageActive == 2 ? 'pagination textDecoration' : 'pagination', disablePagination == true && pageActive != 2 ? 'restricted' : 'allowed']"
      [title]="nextButtonTitle" (click)="changePagination(2)">2</a>
  </div>

  <!--Submit form button-->
  <div class="right">
    <button name="next" type="button" *ngIf="contentUploadFormGroup.get('courseType').value == 'yes' && pageActive == 1"
      (mouseup)="changePagination(2)" [title]="nextButtonTitle" [disabled]="disablePagination">Next</button>
    <button name="proceed" type="button"
      *ngIf="((contentUploadFormGroup.get('courseType').value == 'yes' && pageActive == 2) || contentUploadFormGroup.get('courseType').value == 'no') && ((selectedCourse && selectedCourse.CourseStatus.toLowerCase() == 'saved' || selectedCourse && selectedCourse.CourseStatus.toLowerCase() == 'denied' ) || !selectedCourse)"
      (click)="validateAndProceed()"
      [disabled]="(selectedCourse && (selectedCourse.CourseStatus.toLowerCase() != 'saved' && selectedCourse.CourseStatus.toLowerCase() != 'denied')) || disableSubmit"
      [title]="submitButtonTitle">SUBMIT</button>
    <button name="save" type="button" class="mr10"
      *ngIf="(selectedCourse  && selectedCourse.CourseStatus && (selectedCourse.CourseStatus.toLowerCase() == 'saved' || selectedCourse.CourseStatus.toLowerCase() == 'denied')) || (!selectedCourse)"
      (click)="saveAndProceed()" (dblclick)="saveAndProceed()"
      [disabled]="(selectedCourse && (selectedCourse.CourseStatus.toLowerCase() != 'saved' && selectedCourse.CourseStatus.toLowerCase() != 'denied' )) || disableSave"
      [title]='saveButtonTitle'>SAVE</button>
  </div>
</div>