import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromBrowseStore from './store';
import { BrowseService } from './browse.service';
import { IBrowseAcademy } from './store/browse-academy.interface';
import { Observable, Subscriber } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  ActivatedRoute,
  ParamMap,
  Router,
  NavigationEnd
} from '@angular/router';

@Component({
  selector: 'yorbit-browse',
  templateUrl: './browse.component.html',
  styleUrls: ['./browse.component.scss']
})
export class BrowseComponent implements OnInit {
  browseCompSubscritions: any;
  routeParams: any;
  showCoursesFlagStatus: any;
  showSkillsFlagStatus: any;
  selectedAcademyId: string;
  isAccountBrowse: boolean;
  constructor(
    private browseStore: Store<fromBrowseStore.IbrowseAcademyReducerState>,
    private activatedRoute: ActivatedRoute,
    private browseService: BrowseService,
    private router: Router
  ) {
    this.browseCompSubscritions = {};
    this.isAccountBrowse = false;
    this.validateRouterUrl();
  }
  validateRouterUrl() {
    if (this.router.url.indexOf('accountsAndProjects') == -1) {
      this.subscribeToRouterEvents();
      this.subscribeToChildRouterEvents();
      this.getShowCoursesFlagStatus();
      this.getShowSkillsFlagStatus();
    } else {
      this.isAccountBrowse = true;
    }
  }
  subscribeToRouterEvents() {
    this.browseCompSubscritions.routerParamsSub = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        if (event.url == '/browse') {
          this.onFirstLoad();
        }
      });
  }
  getShowCoursesFlagStatus() {
    this.browseService.getShowCoursesFlagStatus().subscribe(flag => {
      this.showCoursesFlagStatus = flag;
    });
  }
  getShowSkillsFlagStatus() {
    this.browseService.getShowSkillsFlagStatus().subscribe(flag => {
      this.showSkillsFlagStatus = flag;
    });
  }
  ngOnInit() {
    if (
      this.router.url.indexOf('accountsAndProjects') == -1 &&
      this.router.url.indexOf('account') == -1
    ) {
      //this.browseStore.dispatch(new fromBrowseStore.BrowseGetAcademyList());
    }
    else{
      this.browseService.getAccountsListFromAPI();
    }
  }
  subscribeToChildRouterEvents() {
    if (this.activatedRoute.firstChild != null) {
      this.browseCompSubscritions.childRouterParamsSub = this.activatedRoute.firstChild.paramMap.subscribe(
        (params: ParamMap): void => {
          if (
            params.get('academyId') == null ||
            params.get('academyId') == undefined
          ) {
            this.onFirstLoad();
          } else {
            this.selectedAcademyId = params.get('academyId');
          }
        }
      );
    } else {
      this.onFirstLoad();
    }
  }
  onFirstLoad() {
    if (
      this.router.url.indexOf('accountsAndProjects') == -1 &&
      this.router.url.indexOf('account') == -1
    ) {
      this.browseStore
        .select(fromBrowseStore.getBrowseAcadmeyList)
        .subscribe(academyList => {
          if (academyList.AcademyTerms) {
            this.router.navigate(
              ['academy/' + academyList.AcademyTerms[0].UniqueId],
              { relativeTo: this.activatedRoute }
            );
          }
          //route to first academy
        });
    }
  }
  ngOnDestroy() {
    this.unsubscribeAllSubscriptions();
  }
  unsubscribeAllSubscriptions() {
    for (let subscriberKey in this.browseCompSubscritions) {
      let subscriber = this.browseCompSubscritions[subscriberKey];
      if (subscriber instanceof Subscriber) {
        subscriber.unsubscribe();
      }
    }
  }
  goToAcademyView() {
    if (
      this.browseCompSubscritions.childRouterParamsSub == null ||
      this.browseCompSubscritions.childRouterParamsSub == undefined
    ) {
      this.browseCompSubscritions.childRouterParamsSub = this.activatedRoute.firstChild.paramMap.subscribe(
        (params: ParamMap): void => {
          if (
            params.get('academyId') == null ||
            params.get('academyId') == undefined
          ) {
            this.onFirstLoad();
          } else {
            this.selectedAcademyId = params.get('academyId');
          }
        }
      );
    }
    this.router.navigate(['academy/' + this.selectedAcademyId], {
      relativeTo: this.activatedRoute
    });
    this.browseService.updateShowSkillsFlagStatus(false);
    this.browseService.updateShowCoursesFlagStatus(false);
  }
  goToAccountsView() {
    this.router.navigate(['accountsAndProjects'], {
      relativeTo: this.activatedRoute
    });
  }
  showSkillsView() {
    this.browseService.updateShowSkillsFlagStatus(true);
    this.browseService.updateShowCoursesFlagStatus(false);
  }
}
